import { useMemo } from 'react'
import moment from 'moment'
import { GridItem, Flex, Text, Button } from '@chakra-ui/react'
import { Link, useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
} from 'contexts/CurrencySettingsContext'
import ProjectCard from 'components/ProjectCard'
import { useUser, calculateProjectDurationLeft } from 'hooks'

export default function FilteredData({ data, filterStatus, type }) {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()
  const { user } = useUser()

  const filteredData = useMemo(() => {
    if (filterStatus[0] === 'all') {
      return data
    }
    return data?.filter((row) => filterStatus.includes(row.status))
  }, [data, filterStatus])
  const emptyTranslation = useMemo(() => {
    // const translation = filterStatus[0] === 'all' ? '' : filterStatus[0] === 'ended' ? 'Completed' : filterStatus[0] === 'live' ? 'Active' : filterStatus[0] === 'submitted' ? 'Submitted' : 'On Hold'
    const dataType = type === 'project' ? 'Project' : 'Initiative'

    return t(`profile@no${dataType}`)
  }, [type, filterStatus])

  return (
    <>
      {filteredData &&
        filteredData
          ?.sort((a, b) =>
            moment.utc(b.createdAt).diff(moment.utc(a.createdAt))
          )
          .map((slide, index) => (
            <GridItem key={`row-slide-${slide.id}-index-${index}`}>
              <ProjectCard
                goalText={t('goal')}
                card={slide}
                goalPrice={slide.activeGoal?.amount || slide.goal}
                raisedMoney={CurrencyConverter({
                  currency: currency.current,
                  amount: parseInt(slide.amount),
                })}
                CurrencyConverter={CurrencyConverter}
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? slide.amount + Number(slide.updateAmount)
                    : slide.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(slide.updateAmount),
                  currency.current
                )}
                SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                width="full"
                type={type}
                editRoute={type}
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en:
                    slide.campaignImplementorPeriod_en,
                  fundraisingType: slide.fundraisingType,
                })}
                isMobile
                language={language}
                currency={currency}
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
                navigationPush={navigationPush}
                user={user}
                cardStatusText={t(`status@${slide.status}`)}
                cardCategoryText={t(`category@${slide.category}`)}
                editText={t('edit')}
                unsubscribeText={t('unsubscribe')}
                collaborationProposalsText={t(
                  slide?.collaboratorsTotal === 1
                    ? 'collaboration proposal'
                    : 'collaboration proposals'
                )}
                collaborationProposalsTextRuEdit={t('collaboration proposals')
                  .split('...')[1]
                  .replace('запросов', 'запроса')}
                monthlyGoalText={t('monthlyGoal')}
                raisedText={t('raised')}
              />
            </GridItem>
          ))}
      <Flex
        w="full"
        position="absolute"
        flexDirection="column"
        justify="center"
        align="center"
      >
        {filteredData?.length < 1 && (
          <Flex>
            <Text>{emptyTranslation}</Text>
          </Flex>
        )}
        {data?.length < 1 && (
          <Link to={`/create-${type}`}>
            <Button color="blue.400" variant="transparent" right="0">
              {t(
                `profile@create${type === 'project' ? 'Project' : 'Initiative'}`
              )}
            </Button>
          </Link>
        )}
      </Flex>
    </>
  )
}
