import { useContext, createContext } from 'react';
import { useDonationSubscriptionsByProjectId } from 'hooks'

const calculateTotalAmount = (subscriptions) => {
    let totalAmount = 0;

    for (const subscription of subscriptions) {
        totalAmount += subscription.donationToProject
    }

    return totalAmount
}

const DonationSubscriptionContext = createContext({ donationSubscriptionsTotalAmount: 0 });

export const useDonationSubscriptions = () => (
    useContext(DonationSubscriptionContext)
)

export const DonationSubscriptionsProvider = ({ children, project }) => {
    const { donationSubscriptions } = useDonationSubscriptionsByProjectId({
        projectId: project.id,
        filter: {
            unsubscribedDate: {
                attributeExists: false
            }
        }
    })

    return (
        <DonationSubscriptionContext.Provider value={{ donationSubscriptionsTotalAmount: calculateTotalAmount(donationSubscriptions || []) }}>
            {children}
        </DonationSubscriptionContext.Provider>
    )
}
