import React from 'react'
import { Box } from '@chakra-ui/react'
import Navbar from 'components/Navbar'
const w = window.innerWidth
const MainLayout = ({ children, disableLinks = false, onClick }) => (
  <Box w="full">
    {w > 481 && <Navbar disableLinks={disableLinks} onClick={onClick} />}
    {children}
  </Box>
)

export default MainLayout
