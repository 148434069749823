import { useState } from 'react'
import { useQuery } from 'react-query'
import content from '.'

export const useGetNeeds = () => useQuery(['needs'], content.getNeeds)

export const useGetExperts = (variables) =>
  useQuery([`experts`], () => content.getExperts(variables))

export const useGetExpertById = (id) =>
  useQuery([`expert-${id}`], () => content.getItemById(id))

export const useGetGiftCard = () => useQuery(['gift-card'], content.getGiftCard)

export const useContentFromElastic = (
  initialFilter,
  key = 'get_content',
  page,
  hasSearch
) => {
  const [filters, setFilters] = useState(initialFilter)
  const { error, refetch, isLoading, data } = useQuery(
    [key, filters],
    async () => {
      return await content.getContentFromElastic(filters, page, hasSearch)
    },
    {
      enabled: true,
    }
  )

  return {
    data,
    refetch,
    isLoading,
    filters,
    setFilters,
    error,
  }
}
