import { ReactComponent as Discover } from '../../assets/icons/eclipseActive.svg'
import { ReactComponent as DiscoverInactive } from '../../assets/icons/eclipseInactive.svg'
import { ReactComponent as Collaborations } from '../../assets/icons/collaborations.svg'
import { ReactComponent as CollaborationsInactive } from '../../assets/icons/collaborationsInactive.svg'
import { ReactComponent as More } from '../../assets/icons/more.svg'
import { ReactComponent as MoreInactive } from '../../assets/icons/moreInactive.svg'
import { ReactComponent as Fundraisers } from '../../assets/icons/fundraisers.svg'
import { ReactComponent as FundraisersInactive } from '../../assets/icons/fundraisersInactive.svg'

export const MOBILE_DATA = [
  {
    label: 'home',
    text: 'Discover',
    route: '/',
    icon: {
      active: <Discover />,
      inactive: <DiscoverInactive />,
    },
  },
  {
    label: 'collab',
    text: 'Collaborations',
    route: '/collaborations',
    icon: {
      active: <Collaborations />,
      inactive: <CollaborationsInactive />,
    },
  },
  {
    label: 'fund',
    text: 'Fundraisers',
    route: '/fundraisers',
    icon: {
      active: <Fundraisers />,
      inactive: <FundraisersInactive />,
    },
  },
  {
    label: 'more',
    text: 'More',
    route: '/more',
    icon: {
      active: <More />,
      inactive: <MoreInactive />,
    },
  },
]

export const NAV_LINKS = [
  {
    title: 'Fundraisers',
    to: '/fundraisers',
    active: 'fundraisers',
  },
  {
    title: 'nav@initiatives',
    to: '/collaborations',
    active: 'collaborations',
  },
  {
    title: 'About us',
    to: '/about',
    active: 'about',
  },
  {
    title: 'nav@contact',
    to: '/contact-us',
    active: 'contact',
  },
]

export const MENU_TOP_LINKS = [
  { label: 'Fundraisers', href: '/fundraisers' },
  { label: 'Collaborations', href: '/collaborations' },
]

export const MENU_BOTTOM_LINKS = [
  { label: 'nav@contactUs', href: '/contact-us' },
  { label: 'nav@about', href: '/about' },
]
