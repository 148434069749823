import React from 'react'
import Web from './web'
import Mobile from './mobile'

const w = window.innerWidth;

const SelectGiftCard = () => {

  if (w < 481) {
    return (
      <Mobile />
    )
  }

  return (
    <Web />
  )
}

export default SelectGiftCard
