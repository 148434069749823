import { API } from 'aws-amplify'

const hashService = () => {
  const hash = ({ text, secret, expiresIn }) =>
    API.post('paymentAPI', '/sign', { body: { text, secret, expiresIn } })

  const verify = ({ token, secret }) =>
    API.post('paymentAPI', '/verify', { body: { secret, token } })

  return {
    hash,
    verify
  }
}

export default hashService
