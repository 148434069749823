import React from 'react'
import {
  Box,
  Flex,
  Heading,
  Text,
  Progress as ChakraProgress,
} from '@chakra-ui/react'
import { ReactComponent as Time } from 'assets/icons/time.svg'
import { BlueSelect } from 'components/Select/BlueSelect'
import ImageWrapper from 'components/ImageWrapper'
import AmountWithCurrency from 'components/AmountWithCurrency'

const Progress = ({
  project,
  singlePage,
  donationPage,
  dynamicalAmount = 0,
  monthlyGoalText = '',
  goalPrice = 0,
  raisedMoney = 0,
  raisedText = '',
  calculatedProjectDurationLeft,
  currency,
  currentSelectedOption = '',
  options = [],
  settings,
  thousandSeparatorText,
  changeDefaultCurrency,
  SUPPORTED_CURRENCIES,
  goalText = '',
  ...props
}) => {
  const isRecurring = project.fundraisingType === 'recurring'
  return (
    <Flex {...props}>
      {donationPage && (
        <Flex alignItems="center">
          <ImageWrapper
            mt="6"
            mr="5"
            src={`/thumbnail/small/${project.cover}`}
            alt="donation"
            objectFit="cover"
            borderRadius="8px"
            height="45px"
            width="78px"
          />
        </Flex>
      )}
      <Box mt={singlePage || donationPage ? '5' : '0'} flex="1">
        <Flex width="100%" align="baseline" justifyContent="space-between">
          <Flex>
            <Heading
              key={`card-currency-${currency.current}`}
              mr="2"
              color="gray.800"
              as="h2"
              fontSize="xl"
            >
              {settings?.currency &&
                (donationPage ? thousandSeparatorText : raisedMoney)}
            </Heading>
            {singlePage ? (
              <BlueSelect
                variant="noBorder"
                display="flex"
                type="currency"
                amount={project.amount}
                label={currency.current}
                options={options}
                selectOption={changeDefaultCurrency}
                currentSelectedOption={currentSelectedOption}
              />
            ) : (
              <Flex
                alignItems="center"
                lineHeight="1em"
                fontSize="md"
                as="span"
              >
                {currency.current} {raisedText}
              </Flex>
            )}
          </Flex>
          <Box as="span" color="gray.700" fontSize="md">
            {Math.round(+project?.total?.goalMetPercentage + dynamicalAmount)} %
          </Box>
        </Flex>
        <ChakraProgress
          my="2"
          h="6px"
          borderRadius="3px"
          colorScheme="green"
          backgroundColor="gray.500"
          value={(
            project?.total?.goalMetPercentage + dynamicalAmount
          )?.toFixed()}
        />
        {!donationPage && (
          <>
            {isRecurring && project.goals.length && (
              <Flex flex="1" alignItems="center" justifyContent="space-between">
                <Text fontSize="md">
                  {monthlyGoalText + ': '}
                  <AmountWithCurrency
                    amount={goalPrice}
                    currency={currency.current}
                    currencyRate={settings.currency[currency.current]}
                  />
                </Text>
                {singlePage && (
                  <Text fontSize="md">
                    {raisedText + ' '}
                    <AmountWithCurrency
                      amount={project.total.total}
                      currency={currency.current}
                      currencyRate={settings.currency[currency.current]}
                    />
                  </Text>
                )}
              </Flex>
            )}
            {project.status !== 'ended' && !isRecurring && (
              <Flex flex="1" alignItems="center" justifyContent="space-between">
                {calculatedProjectDurationLeft && (
                  <Flex>
                    <Time />
                    <Text ml="2" fontSize="md">
                      {calculatedProjectDurationLeft}
                    </Text>
                  </Flex>
                )}
                {goalText && (!!project.goal || !!project.totalAmount) && (
                  <Text fontSize="md">
                    {goalText}:{' '}
                    <AmountWithCurrency
                      amount={goalPrice}
                      currency={currency.current}
                      currencyRate={settings?.currency[currency.current]}
                    />
                  </Text>
                )}
              </Flex>
            )}
          </>
        )}
      </Box>
    </Flex>
  )
}

export default Progress
