import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Heading,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import FormData from './FormData'
import { useProject } from '../ProjectContext'
import BottomBar from '../BottomBar'
import { useCustomToast } from 'hooks'

const Content = () => {
  const { t, language } = useTranslation()
  const { project, onUpdate } = useProject()
  const toast = useCustomToast()
  const [date, setDate] = useState(new Date())
  const {
    register,
    getValues,
    setValue,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: project?.id,
      title_en: project?.title_en,
      title_ru: project?.title_ru,
      title_hy: project?.title_hy,
      tagline_en: project?.tagline_en,
      tagline_ru: project?.tagline_ru,
      tagline_hy: project?.tagline_hy,
      video_en: project?.video_en,
      video_ru: project?.video_ru,
      video_hy: project?.video_hy,
      creatorName_en: project?.creatorName_en,
      creatorName_ru: project?.creatorName_ru,
      creatorName_hy: project?.creatorName_hy,
      creatorSocialLink: project?.creatorSocialLink,
      goal: project?.goal,
      content_en: project?.content_en,
      content_ru: project?.content_ru,
      content_hy: project?.content_hy,
      creatorImage: project?.creatorImage,
      cover: project?.cover,
      goals: project?.goals ?? [],
      collaborations: project?.collaborations ?? [],
      totalAmount: project?.totalAmount,
      fundraisingType: project?.fundraisingType,
    },
  })
  const {
    fields: goalFields,
    append: appendGoal,
    remove: removeGoal,
  } = useFieldArray({
    control,
    name: 'goals',
  })
  const {
    fields: collaborationsFields,
    append: appendCollaborations,
    remove: removeCollaborations,
  } = useFieldArray({
    control,
    name: 'collaborations',
  })

  const tabs = useMemo(() => ['hy', 'en', 'ru'], [language])

  const onSubmit = (values) => {
    if (
      values.content_hy?.includes(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`
      )
    ) {
      values.content_hy = values.content_hy.replaceAll(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`,
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/content`
      )
    }
    if (
      values.content_en?.includes(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`
      )
    ) {
      values.content_en = values.content_en.replaceAll(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`,
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/content`
      )
    }
    if (
      values.content_ru?.includes(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`
      )
    ) {
      values.content_ru = values.content_ru.replaceAll(
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/content`,
        `${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/content`
      )
    }
    values.campaignImplementorPeriod_en = date.toString()

    if (values.goals) {
      values.goals = values.goals.map((goal) => ({
        amount: goal.amount,
        description_en: goal.description_en,
        description_ru: goal.description_ru,
        description_hy: goal.description_hy,
      }))
    }
    if (!values.totalAmount) {
      delete values.totalAmount
    }
    if (
      values.content_hy?.includes(';base64,') ||
      values.content_ru?.includes(';base64,') ||
      values.content_en?.includes(';base64,')
    ) {
      return toast({ status: 'error', title: 'Invalid content' })
    }
    onUpdate(values)
  }

  useEffect(() => {
    if (!project) {
      return
    }
    const newDate = new Date(project.campaignImplementorPeriod_en)
    if (project.campaignImplementorPeriod_en) {
      setDate(newDate)
    }
  }, [])

  // useEffect(() => {
  //   if (!project) {
  //     return
  //   }
  //   const mappedCollabs = project.collaborations.map((collab) => ({
  //     ...collab,
  //     needs_en: collab.needs_en || collab.needs,
  //     needs_ru: collab.needs_ru || collab.needs,
  //     needs_hy: collab.needs_hy || collab.needs,
  //     needsDescription_en:
  //       collab.needsDescription_en || collab.needsDescription,
  //     needsDescription_ru:
  //       collab.needsDescription_ru || collab.needsDescription,
  //     needsDescription_hy:
  //       collab.needsDescription_hy || collab.needsDescription,
  //   }))
  //   setValue('collaborations', mappedCollabs)
  // }, [project])
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ paddingBottom: '5rem', flex: '1' }}
    >
      <Box>
        <Heading as="h1">{t('createEditProject@updateContent')}</Heading>
        <Box as="span" display="block" color="gray.700" fontSize="sm" mt={2}>
          {t('createEditProject@updateContentNote')}
        </Box>
        <Tabs mt={6} isLazy={true}>
          <TabList width="100%" borderColor="border.100" pb="1px" mb={10}>
            {tabs.map((languageCode, index) => (
              <Tab
                key={`project-content-tab-list-${languageCode}`}
                tabIndex={index}
                color="gray.700"
                fontSize="xl"
                pb={4}
                _focus={{
                  outline: 'none',
                }}
                _selected={{
                  color: 'black',
                  fontWeight: 'bold',
                  borderColor: '#012F73',
                }}
                tabData={`lang_tab_${languageCode}`}
              >
                {t(`language@${languageCode}Label`)}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((languageCode, index) => (
              <TabPanel
                tabIndex={index}
                key={`project-content-tab-panel-${languageCode}`}
                p={0}
              >
                <FormData
                  project={project}
                  goals={goalFields}
                  appendGoal={appendGoal}
                  removeGoal={removeGoal}
                  collaborations={collaborationsFields}
                  appendCollaborations={appendCollaborations}
                  removeCollaborations={removeCollaborations}
                  date={date}
                  validationErrors={errors}
                  errors={errors}
                  setDate={setDate}
                  lang={languageCode}
                  register={register}
                  image={watch('creatorImage')}
                  cover={watch('cover')}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <BottomBar submitButtonText={t('submit')} />
    </form>
  )
}
export default Content
