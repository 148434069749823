import { useQuery } from 'react-query'
import { donationSubscriptionUseCase } from '../../core/factories'

const useDonationSubscriptionsByProjectId = (condition, withProjectDetails) => {
  const {
    error,
    refetch,
    isLoading,
    data: donationSubscriptions,
  } = useQuery(
    ['donation_subscriptions_by_project_id', condition],
    async () => {
      return await donationSubscriptionUseCase.donationSubscriptionsByProjectId(condition, withProjectDetails)
    })

  return {
    refetch,
    isLoading,
    donationSubscriptions,
    error,
  }
}

export default useDonationSubscriptionsByProjectId
