import React from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import Content from 'components/Content'
import Header from 'components/MobileHeader'
import Swiper from 'components/MobileSwiper'

const w = window.innerWidth

const styles =
  w > 481
    ? {
        width: '481px',
        margin: 'auto',
      }
    : {}

const Mobile = ({ data, isLoading }) => {
  const { t } = useTranslation()

  return (
    <Box pb={10} style={styles}>
      <Header
        backTo="/"
        share={{
          title: data?.title,
          description: data?.description,
        }}
      />
      {!isLoading && (
        <Flex
          w="full"
          alignItems="flex-start"
          justifyContent="center"
          direction="column"
        >
          <Swiper images={data?.images} />
          <Box px="5">
            <Heading pt="7" as="h2" fontSize="3xl">
              {data.title}
            </Heading>
            <Text
              fontSize="md"
              key={`project-createdAt`}
              my="4"
              color="gray.800"
            >
              {data.date}
            </Text>

            <Text fontSize="md" mb="9" color="rgba(26, 26, 26, 0.6)">
              {data.description}
            </Text>
            <Text
              mt={10}
              mb={5}
              as="h2"
              fontSize="xl"
              fontWeight="bold"
              letterSpacing="0.06px"
              lineHeight="22px"
            >
              {t('theStory')}
            </Text>
          </Box>
          <Box
            sx={{
              img: {
                borderRadius: '0 !important',
              },
              '> div > *:not(img)': {
                padding: '0 20px',
              },
            }}
          >
            <Content html={data.story} />
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default Mobile
