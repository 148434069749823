import { useMemo } from 'react'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import FormData from './FormData'

export default function UpdateEditor({ update, updateRef }) {
  const { t, language } = useTranslation()
  const tabs = useMemo(() => ['hy', 'en', 'ru'], [language])

  return (
    <Tabs mt={6}>
      <TabList
        width="100%"
        borderColor="border.100"
        pb="1px"
        mb={10}
        position="relative"
      >
        {tabs.map((languageCode) => (
          <Tab
            key={`project-updates-tab-list-${languageCode}`}
            color="gray.700"
            fontSize="xl"
            pb={4}
            _focus={{
              outline: 'none',
            }}
            _selected={{
              color: 'black',
              fontWeight: 'bold',
              borderColor: '#006CB7',
            }}
          >
            {t(`language@${languageCode}Label`)}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((languageCode) => (
          <TabPanel key={`project-updates-tab-panel-${languageCode}`} p={0}>
            <FormData
              lang={languageCode}
              update={update}
              updateRef={updateRef}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}
