import React from 'react'
import { useHistory } from 'react-router-dom'
import { HStack, Box, Heading, Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Back } from '../../../assets/icons/back.svg'
import { ReactComponent as Share } from '../../../assets/icons/share.svg'

const Header = () => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <>
      <HStack
        py="3"
        borderBottom="1px solid"
        borderColor='border.100'
        px="3"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex onClick={() => history.goBack()} alignItems="center" justifyContent="center" width="30px">
          <Back width="10px" height="20px" />
        </Flex>

        <Heading as="h1" fontSize="3xl">
          {t('Contact us')}
        </Heading>
        <Box align="center" width="30px"></Box>
      </HStack>
    </>
  )
}

export default Header
