import { useContext, createContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { useListTransactionsByDate } from 'hooks'

const TransactionsContext = createContext({
  transactions: [],
  transactionsTotalAmount: 0,
  oneTimeTransactionsCurrentMonthTotalAmount: 0,
})

export const useTransactions = () => useContext(TransactionsContext)

export const TransactionsProvider = ({ children, project }) => {
  const [data, setData] = useState([])
  const [isMoreLoading, setIsMoreLoading] = useState(false)
  const [nextToken, setNextToken] = useState(undefined)
  const { data: transactionsResponse, refetch } = useListTransactionsByDate({
    projectId: project.id,
    sortDirection: 'DESC',
    limit: 50,
    nextToken: nextToken,
  })

  const fetch = () => {
    setIsMoreLoading(true)
    setNextToken(transactionsResponse.nextToken)
    if (transactionsResponse.nextToken) {
      refetch(transactionsResponse.nextToken)
    }
  }
  useEffect(() => {
    if (transactionsResponse?.items) {
      setData(_.uniqBy([...data, ...transactionsResponse.items], 'id'))
      setIsMoreLoading(false)
      if (!transactionsResponse.nextToken) {
        setNextToken(null)
      }
    }
  }, [transactionsResponse])


  return (
    <TransactionsContext.Provider
      value={{
        transactions: transactionsResponse?.items ?? [],
        setLoadMore: fetch,
        isMoreLoading,
        nextToken,
        data,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  )
}
