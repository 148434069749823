import Wrapper from 'components/Wrapper'
import { useTranslation } from 'contexts/TranslationContext'

const PopularInitiatives = ({ initiatives, isLoading }) => {
  const { t } = useTranslation()
  if (!isLoading && !initiatives.length) {
    return null
  }
  return (
    <Wrapper
      type="collaborations"
      swiper={initiatives}
      isLoading={isLoading}
      squareColor="orange.400"
      title={t('Collaborations')}
    />
  )
}

export default PopularInitiatives
