import React, { useEffect } from 'react'
import moment from 'moment'
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Spinner,
  Flex,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import All from './All'
import Recent from './Recent'
import Ending from './Ending'
import { useInView } from 'react-intersection-observer'

const started = (initiative) => {
  let bool = true
  const currentDate = moment(new Date())
  const createdDate = moment(new Date(initiative.createdAt))
  const daysLeft = currentDate.diff(createdDate, 'days')
  if (daysLeft > 30) return (bool = false)
  return bool
}

const ending = (initiative) => {
  let bool = true
  const currentDate = moment(new Date())
  const endingDate = moment(new Date(initiative.campaignImplementorPeriod_en))
  const daysLeft = endingDate.diff(currentDate, 'days')
  if (daysLeft > 30) return (bool = false)
  return bool
}

const TabsCollaborations = ({
  total,
  initiatives,
  needCategories,
  onLoadMore,
  filterByCategory,
  chosenCategories,
}) => {
  const { ref: inViewRef, inView } = useInView()

  useEffect(() => {
    if (total === initiatives?.length) {
      return
    }
    if (inView) {
      onLoadMore()
    }
  }, [inView])
  return (
    <>
      <Tabs mt="2">
        <TabPanels>
          <TabPanel p="0">
            <All
              initiatives={initiatives}
              filterByCategory={filterByCategory}
              chosenCategories={chosenCategories}
              needCategories={needCategories}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {total > initiatives?.length && (
        <Box
          position="absolute"
          bottom="30%"
          zIndex={1000}
          ref={inViewRef}
          w="full"
          h={20}
          visibility="hidden"
        />
      )}
      {total > initiatives?.length && (
        <Flex mt={5} w="full" align="center" justifyContent="center">
          <Spinner color="blue.300" />
        </Flex>
      )}
    </>
  )
}

export default TabsCollaborations
