import {
  Box,
  Flex,
  Button,
  Stack,
  Heading,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Remove } from 'assets/icons/remove-goal.svg'
import { TextareaField } from 'components/TextareaFIeld'
import ImageUploader from 'pages/EditProject/Content/FormData/ImageUploader'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { ReactComponent as PlusDisabled } from 'assets/icons/plusDisabled.svg'

const OptionsForm = ({
  collaborations,
  validationErrors,
  removeCollaborations,
  appendCollaborations,
  setCollaborations,
  register,
  setValue,
  getValues = () => {},
  formLang = 'en',
  handleChange,
  imageUpload = true,
}) => {
  const { t } = useTranslation()
  return (
    <Box>
      <Heading mb="6" as="h2" fontSize="xl">
        {t('Collaborations')}
      </Heading>
      <Stack mb="10" spacing="6">
        {collaborations
          ?.filter((e) => e.needs !== null && e.needs !== undefined)
          ?.map((item, index) => (
            <Flex key={index}>
              <Flex direction="column" w="full">
                {imageUpload && (
                  <ImageUploader
                    type="image"
                    label={t('createEditProject@clickToUploadImage')}
                    name={`collaborations.${index}.image`}
                    setValue={(name, value) => {
                      const newCollaborations = [...collaborations]
                      newCollaborations[index].image = value
                      setValue('collaborations', newCollaborations)
                    }}
                    image={
                      getValues(`collaborations.${index}.image`) || item.image
                    }
                    mb={2}
                  />
                )}
                <InputField
                  char={item.needs.length}
                  id={`option_${index}`}
                  maxLength={50}
                  errorMessage={t('field is required')}
                  isInvalid={
                    !!validationErrors?.collaborations?.index?.[
                      `needs${handleChange ? '_' + formLang : ''}`
                    ]
                  }
                  value={
                    collaborations[index][
                      `needs${handleChange ? '_' + formLang : ''}`
                    ]
                  }
                  onInput={(e) => {
                    if (handleChange) {
                      handleChange(e, index)
                    } else {
                      const newCollaborations = [...collaborations]
                      newCollaborations[index].needs = e.target.value
                      setCollaborations(newCollaborations)
                    }
                  }}
                  label={t('describe the needs')}
                  {...register(
                    `collaborations.${index}.needs${
                      handleChange ? '_' + formLang : ''
                    }`,
                    {
                      required: true,
                    }
                  )}
                />
                <FormControl>
                  <FormLabel mt={2} fontWeight="normal">
                    {t('edit@collaborationDesc')}
                  </FormLabel>
                  <TextareaField
                    isInvalid={
                      !!validationErrors?.collaborations?.index?.[
                        `needsDescription${handleChange ? '_' + formLang : ''}`
                      ]
                    }
                    value={
                      collaborations[index][
                        `needsDescription${handleChange ? '_' + formLang : ''}`
                      ]
                    }
                    onInput={(e) => {
                      if (handleChange) {
                        handleChange(e, index)
                      } else {
                        const newCollaborations = [...collaborations]
                        newCollaborations[index].needsDescription =
                          e.target.value
                        setCollaborations(newCollaborations)
                      }
                    }}
                    errorMessage={t('createEditProject@thisFieldIsRequired')}
                    {...register(
                      `collaborations.${index}.needsDescription${
                        handleChange ? '_' + formLang : ''
                      }`,
                      {
                        required: false,
                        maxLength: 100,
                      }
                    )}
                    maxLength={100}
                  />
                </FormControl>
              </Flex>
              {collaborations?.length > 1 && (
                <Button
                  minW="24px"
                  w="24px"
                  h="24px"
                  maxH="24px"
                  marginLeft={'16px !important'}
                  marginTop={'45px !important'}
                  borderRadius="100px"
                  id={`option_btn_${index}`}
                  onClick={() => {
                    removeCollaborations(index)
                  }}
                >
                  <Remove width="24px" />
                </Button>
              )}
            </Flex>
          ))}
      </Stack>
      <Button
        width="max-content"
        mb="10"
        disabled={collaborations?.length >= 5}
        onClick={() => {
          appendCollaborations({
            needs: '',
            needs_en: '',
            needs_ru: '',
            needs_hy: '',
            needsDescription: '',
            needsDescription_en: '',
            needsDescription_ru: '',
            needsDescription_hy: '',
          })
        }}
      >
        <Box mr="4">
          {collaborations?.length >= 5 ? <PlusDisabled /> : <Plus />}
        </Box>
        {t('Add more options')}
      </Button>
    </Box>
  )
}

export default OptionsForm
