import { useState } from 'react'
import { Image, Flex, Box } from '@chakra-ui/react'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'
import './styles.scss'

export default function Swiper({ images }) {
    const [active, setActive] = useState(1)

    return (
        <ReactSwiper
            id="success-story-mobile-swiper"
            navigation
            slidesPerView={1}
            className={'mySwiper'}
            onSlideChange={(swiper) => setActive(swiper.activeIndex + 1)}>
            {images?.map(
                (image) => (
                    <SwiperSlide key={`success-story-image-${image}`}>
                    <Image
                        alt="success-story-image"
                        src={image}
                        h="200px"
                        w="100%"
                        objectFit="cover" />
                    </SwiperSlide>
                )
            )}

            <Flex
                py="3"
                bg="black"
                w="64px"
                justifyContent="center"
                position="absolute"
                right="0"
                bottom="0"
                zIndex={1}>
                <Box as="span" color="gray.100" fontSize="md">
                    {active} /&nbsp;
                </Box>
                <Box as="span" color="gray.100" fontSize="md">
                    {images?.length}
                </Box>
            </Flex>
        </ReactSwiper>
    )
}