import React from 'react'
import { HStack, Heading, Box, Flex, Spinner, VStack } from '@chakra-ui/react'
import _ from 'lodash'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
} from 'contexts/CurrencySettingsContext'
import Navbar from 'components/Navbar'
import ProjectCard from 'components/ProjectCard'
import { Tabs, TabList } from '@chakra-ui/react'
import { StyledTab } from '../../../components/AllProductsWrapper/Tabs'
import { useNavigation } from 'pages'
import { useUser, calculateProjectDurationLeft } from 'hooks'

const MobileFundraisers = ({
  projects,
  total,
  isLoading,
  inViewRef,
  setFilters,
}) => {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()
  const { user } = useUser()

  return (
    <Box pb="6rem">
      <HStack alignItems="center" justifyContent="center" mt="5" pr="4" pl="5">
        <Heading fontSize="3xl" as="h1">
          {t('Fundraisers')}
        </Heading>
      </HStack>
      <Tabs mt="2">
        <TabList borderColor="border.100" borderWidth="1px" borderTop="0">
          <StyledTab title="oneTime" mobile setFilters={setFilters} />
          <StyledTab title="recurring" mobile setFilters={setFilters} />
        </TabList>
      </Tabs>
      {isLoading && (!projects || projects?.length === 0) ? (
        <Flex minH="520px" w="full" align="center" justifyContent="center">
          <Spinner color="blue.400" />
        </Flex>
      ) : (
        <>
          <VStack spacing="10" px="5" pt="40px">
            {_.uniqBy(projects, 'id').map((card) => (
              <ProjectCard  goalText={t('goal')}
                card={card}
                goalPrice={card.activeGoal?.amount || card.goal}
                raisedMoney={CurrencyConverter({
                  currency: currency.current,
                  amount: parseInt(card.amount),
                })}
                CurrencyConverter={CurrencyConverter}
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? card.amount + Number(card.updateAmount)
                    : card.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(card.updateAmount), currency.current
                )}
                SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                key={card.id}
                isLoading={isLoading}
                width="full"
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
                type="fundraisers"
                editRoute="fundraisers"
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en:
                    card.campaignImplementorPeriod_en,
                  fundraisingType: card.fundraisingType,
                })}
                isMobile
                language={language}
                currency={currency}
                navigationPush={navigationPush}
                user={user}
                cardStatusText={t(`status@${card.status}`)}
                cardCategoryText={t(`category@${card.category}`)}
                editText={t('edit')}
                unsubscribeText={t('unsubscribe')}
                collaborationProposalsText={t(
                  card?.collaboratorsTotal === 1
                    ? 'collaboration proposal'
                    : 'collaboration proposals'
                )}
                collaborationProposalsTextRuEdit={t('collaboration proposals')
                  .split('...')[1]
                  .replace('запросов', 'запроса')}
                monthlyGoalText={t('monthlyGoal')}
                raisedText={t('raised')}
              />
            ))}
            {total > projects?.length && (
              <Box
                position="absolute"
                bottom="30%"
                zIndex={1000}
                ref={inViewRef}
                w="full"
                h={20}
                bg="blue.300"
                visibility="hidden"
              />
            )}
          </VStack>

          {total > projects?.length && (
            <Flex w="full" align="center" justifyContent="center">
              <Spinner color="blue.300" />
            </Flex>
          )}
        </>
      )}
      <Navbar from="fund" />
    </Box>
  )
}

export default MobileFundraisers
