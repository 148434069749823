import React from 'react'
import { Flex, Text, Link, Button, useTab, Box } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/icons/logoblack.svg'
import { useModal } from 'providers/ModalProvider'
import { useTranslation } from 'contexts/TranslationContext'
import ProfileDropdown from './../ProfileDropdown'

const OnlyLogo = ({ isGuest, username }) => {
  const { t } = useTranslation()
  const { openModal } = useModal()

  return (
    <Flex
      px={{ base: '24px', md: '60px' }}
      w="full"
      as="nav"
      bg="gray.100"
      boxShadow="0 2px 26px 0 rgba(0, 0, 0, 0.1)"
      height="80px"
      alignItems="center"
      justifyContent={{ base: 'center', md: 'space-between' }}
    >
      <Link as={ReactLink} width="166px" to="/">
        <Logo width="100%" />
      </Link>
      {isGuest ? (
        <Text fontWeight="500">
          {t(`signUp@haveAnAccount`)}
          {` `}
          <Button
            color="orange.400"
            textTransform="initial"
            onClick={() => {
              openModal('signIn')
            }}
          >
            {t('nav@signIn')}
          </Button>
        </Text>
      ) : (
        <Flex alignItems="center">
          <Text fontWeight="bold" fontSize="md">
            {username}
          </Text>
          <ProfileDropdown black />
        </Flex>
      )}
    </Flex>
  )
}

export default OnlyLogo
