import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  SimpleGrid,
  Flex,
  Box,
} from '@chakra-ui/react'
import { useProjects, useProjectsByUserId, useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import FilteredData from '../FilteredData'

const TabsCollaborations = () => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { projects: initiatives, isLoading } = useProjectsByUserId({
    owner: user.id,
    filter: {
      isCollaboration: { eq: true },
    },
  })

  if (isLoading)
    return (
      <Flex alignItems="center" justifyContent="center" height="300px">
        <Spinner color="blue.400" />
      </Flex>
    )
  return (
    <>
      <Tabs>
        <Box overflowX="scroll">
          <TabList borderColor="border.100">
            <Tab
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
            >
              {t('profile@all')}
            </Tab>
            <Tab
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
            >
              {t('profile@active')}
            </Tab>
            <Tab
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
            >
              {t('profile@completed')}
            </Tab>
            <Tab
              borderBottom="2px solid"
              borderColor="border.100"
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
            >
              {t('profile@submitted')}
            </Tab>
            <Tab
              borderBottom="2px solid"
              borderColor="border.100"
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
              whiteSpace="nowrap"
            >
              {t('profile@onHold')}
            </Tab>
          </TabList>
        </Box>

        <TabPanels px="1">
          <TabPanel position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="collaborations"
                filterStatus={['all']}
                data={initiatives}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="collaborations"
                filterStatus={['live', 'launching_soon']}
                data={initiatives}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="collaborations"
                filterStatus={['ended', 'closed', 'terminated']}
                data={initiatives}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="collaborations"
                filterStatus={[
                  'submitted',
                  'reviewed_by_ss',
                  'reviewed_by_am',
                  'reviewed_by_tns',
                  'initiative_page_development',
                  'review_initiative_page',
                  'published_by_owner',
                ]}
                data={initiatives}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="collaborations"
                filterStatus={[
                  'on_hold_by_am',
                  'on_hold_by_tns',
                  'rejected_by_ss',
                  'rejected_by_am',
                  'rejected_by_tns',
                  'rejected_by_bot',
                  'project_page_development',
                  'review_project_page',
                ]}
                data={initiatives}
              />
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default TabsCollaborations
