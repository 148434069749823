import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { ReactComponent as Logo } from 'assets/icons/logoblack.svg'

export default function MobileHeader() {
  return (
    <Flex
      position="fixed"
      zIndex="1000"
      top="0"
      left="0"
      justifyContent="center"
      alignItems="center"
      w="full"
      py={2}
      bg="white"
      borderBottomWidth="1px"
      borderColor="border.100"
    >
      <Box width={120} transition="0.4s">
        <Logo width="100%" />
      </Box>
    </Flex>
  )
}
