import { useState } from 'react'
import { useQuery } from 'react-query'
import { projectUseCase } from '../../core/factories'

const useProjects = (initialFilter, withNextToken = false, enabled = true, key = 'get_projects', cacheKey) => {
  const [filters, setFilters] = useState(initialFilter)

  const {
    error,
    refetch,
    isLoading,
    data: projects,
  } = useQuery(
    [key, filters],
    async () => {
      return await projectUseCase.getProjects(filters, cacheKey)
    },
    {
      enabled
    })

  
  return {
    projects,
    refetch,
    isLoading,
    filters,
    setFilters,
    error,
  }
}

export default useProjects
