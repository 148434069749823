import React, { useState, forwardRef, useRef } from 'react'
import { FormControl, FormLabel, Textarea, Box, Text } from '@chakra-ui/react'
import useAutoSizeTextArea from './useAutoSizeTextArea'

const chars = (char, minLength, maxLength) => {
  if (!char) {
    return `${minLength}-${maxLength}`
  }
  if (minLength && maxLength) {
    if (char < minLength) {
      return `${char} characters (minimum ${minLength} characters)`
    }
    return `${char} characters (maximum ${maxLength} characters)`
  }
  if (maxLength) {
    return `${char} / ${maxLength}`
  }

  return ''
}

export const TextAreaComponent = forwardRef((props, ref) => {
  const {
    title,
    description,
    isInvalid,
    minLength,
    maxLength,
    errorMessage,
    onChange: parentOnChange,
    ...rest
  } = props
  const [isFocus, setIsFocus] = useState(false)
  const [char, setChar] = useState(0)
  const textAreaRef = useRef(null)
  useAutoSizeTextArea(textAreaRef.current, char)
  return (
    <FormControl position="relative">
      {isInvalid && char === 0 && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage}
        </Box>
      )}
      {!!maxLength && (
        <Box
          position="absolute"
          right={0}
          fontWeight="semibold"
          fontSize="sm"
          color={isInvalid ? 'red.400' : isFocus ? 'blue.400' : 'gray.700'}
          top="calc(100% + 5px)"
        >
          {chars(char, minLength, maxLength)}
        </Box>
      )}
      <FormLabel fontSize="xl" fontWeight="700" zIndex={2}>
        {title}
        <Text fontSize="sm" fontWeight={400} mt="6px" mb="16px">
          {description}
        </Text>
      </FormLabel>

      <Textarea
        isInvalid={isInvalid}
        borderColor="border.100"
        resize="none"
        _focus={{ borderColor: 'blue.400' }}
        _hover={{ borderColor: 'blue.400' }}
        sx={{ boxShadow: 'none !important' }}
        ref={(e) => {
          ref(e)
          textAreaRef.current = e
        }}
        minLength={minLength}
        maxLength={maxLength}
        size="lg"
        overflow="hidden"
        fontSize="lg"
        onFocus={() => {
          setIsFocus(true)
        }}
        onBlur={() => {
          setIsFocus(false)
        }}
        {...rest}
        onChange={(e) => {
          parentOnChange(e)
          setChar(e.target.value.length)
        }}
      />
    </FormControl>
  )
})

TextAreaComponent.displayName = 'InputField'
