import React, { useEffect, useState, useCallback } from 'react'
import UserContext from './UserContext'
import { userUseCase } from '../../core/factories'
import { useTranslation } from 'contexts/TranslationContext'
import { Hub } from 'aws-amplify'

const SUPER_USERS = [
  'admin@rearmenia.com',
  'gogjhan@gmail.com',
  'edmondjhan@gmail.com',
  'e@monomark.io',
  'edmond@monomark.io',
  'artak@rearmenia.com',
  'gegham.samvelyan@hotmail.com',
  'gegham@monomark.io',
  'projects@armenianpeace.am',
]

export const guestUser = {
  id: 'guest',
  username: 'guest',
  email: 'guest',
  firstName: 'guest',
  lastName: 'guest',
  emailVerified: false,
  isSuper: false,
  seenSubscribeModal: false,
}

function listenToAutoSignInEvent(retrieveUser) {
  Hub.listen('auth', ({ payload }) => {
    const { event } = payload
    if (event === 'autoSignIn') {
      const user = payload.data
      retrieveUser()
    } else if (event === 'autoSignIn_failure') {
      console.log('Auto sign in failure')
    }
  })
}
const UserProvider = ({ children }) => {
  const [userObj, setUserObj] = useState({ user: guestUser, loading: true })
  const { retrieveLanguage } = useTranslation()

  const retrieveUser = useCallback(async () => {
    try {
      const signedInUser = await userUseCase.getUser()
      signedInUser.isSuper = SUPER_USERS.includes(signedInUser.email)
      setUserObj({ user: signedInUser, loading: false })
      retrieveLanguage()
    } catch (e) {
      setUserObj({ user: guestUser, loading: false })
      retrieveLanguage()
    }
  }, [])

  useEffect(() => {
    retrieveUser()
    listenToAutoSignInEvent(retrieveUser)
  }, [])

  return (
    <UserContext.Provider
      value={{
        setUser: setUserObj,
        isAdmin: SUPER_USERS.includes(userObj.user.email),
        user: userObj.user,
        setUserLoggedIn: retrieveUser,
        getUser: retrieveUser,
        isLoading: userObj.loading,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
