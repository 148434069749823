import React from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'

const Header = () => {
  const history = useHistory()
  return (
    <Flex mt="3" position="relative" py="3" alignItems="center">
      <Flex
        position="absolute"
        left="12px"
        onClick={() => history.goBack()}
        justifyContent="center"
        width="30px"
        alignItems="center"
      >
        <Close width="14px" height="14px" />
      </Flex>
    </Flex>
  )
}

export default Header
