import { useRef } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'contexts/TranslationContext';
import { useUser, useUpdateUser, useDeleteBindingCard } from 'hooks';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove-goal.svg';

export default function Remove({ onCancel, creditCard }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const { user, setUser } = useUser();
  const { deleteBindingCard } = useDeleteBindingCard();
  const { updateUser, isLoading } = useUpdateUser();
  const cancelRef = useRef();

  const onAlertClose = () => {
    onClose();
    if (onCancel) {
      onCancel();
    }
  }

  const onRemoveClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onOpen()
  }

  const onRemove = () => {
    const newCreditCards = user.creditCards.filter(card => card.bindingId !== creditCard.bindingId);
    if (newCreditCards.length === 1) {
        newCreditCards[0].isDefault = true;
    }

    updateUser(
        {
            id: user.id,
            creditCards: newCreditCards,
        },
        {
            onSuccess: (response) => {
              deleteBindingCard(creditCard.bindingId)
              setUser(oldUser => ({ ...oldUser, user: response.data.updateUser }))
            }
        }
    )
  }

  return (
    <>
        <Button
          display={{ base: 'none', sm: 'block' }}
          datatype="remove"
          color="gray.700"
          fontSize="sm"
          fontWeight="500"
          textTransform="capitalize"
          mr={3}
          mt="10px"
          opacity="0"
          transition="opacity 300ms"
          transform="translateY(-50%)"
          onClick={onRemoveClick}>
            {t('remove')}
        </Button>
        <Button
          display={{ base: 'block', sm: 'none' }}
          position="absolute"
          top="50%"
          right="20px"
          transform="translateY(-50%)"
          minW="24px"
          w="24px"
          h="24px"
          maxH="24px"
          borderRadius="100px"
          ml={4}
          onClick={onRemoveClick}>
          <RemoveIcon width="24px" />
        </Button>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onAlertClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>{t('attachedCard@remove')}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>{t('attachedCard@removeSure')}</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                minH="20px"
                minW="100px"
                color="red"
                variant="transparent"
                ref={cancelRef}
                onClick={onAlertClose}
              >
                {t('no')}
              </Button>
              <Button
                ml={3}
                minH="20px"
                minW="100px"
                variant="green"
                colorScheme="red"
                isDisabled={isLoading}
                isLoading={isLoading}
                onClick={onRemove}
              >
                {t('yes')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
    </>
  )
}
