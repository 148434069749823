// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    href: '/pre-create-project',
    label: 'Create Project',
  },
  {
    href: '/fundraisers',
    label: 'Fundraisers',
  },
  // {
  //   href: '/collaborations',
  //   label: 'footer@initiatives',
  // },
  // {
  //   href: '/blog',
  //   label: 'footer@blog',
  // },
  {
    href: '/about',
    label: 'footer@about',
  },
  {
    href: '/contact-us',
    label: 'footer@contactUs',
  },
]
