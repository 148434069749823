import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Container, Spinner, Flex, Heading, Text } from '@chakra-ui/react'
import {
  useUser,
  useProjects,
  useQueryParams,
  useUpdateProject,
  useCustomToast,
} from 'hooks'
import { MainLayout } from 'components/Layouts'
import ListView from 'components/ListView'
import Header from './Header'
import Pagination from 'components/Pagination/Index'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { applicationsListQuery } from 'elastic/queries'
import { Link } from 'pages'
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg'
import StatusSelect from './StatusSelect'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import LineClamp from 'components/LineClamp'

const PAGE_SIZE = 16
const TABS = ['fundraisers', 'collaborations']
const projectPageURL = (isProject, id, slug) =>
  `/${isProject ? 'fundraisers' : 'collaborations'}/${slug || id}`

const editProjectPageURL = (isProject, id) =>
  `/edit-${isProject ? 'fundraisers' : 'collaborations'}/${id}/application`

const ApplicationsDashboard = () => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const { user, isLoading: isUserLoading } = useUser()
  const queryParams = useQueryParams()
  const [tabIndex, setTabIndex] = useState(0)
  const searchRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(
    Number(queryParams.get('page')) || 1
  )

  const { updateProject } = useUpdateProject()
  const { projects, isLoading, setFilters, filters } = useProjects(
    applicationsListQuery({ projectType: 'fundraisers' }),
    true,
    true,
    'fundraisers'
  )
  const { navigationPush } = useNavigation()

  const [localFilters, setLocalFilters] = useState({
    category: '',
    status: '',
    search: '',
    isRecurringChecked: false,
    projectType: 'fundraisers',
  })

  useEffect(() => {
    if (!user.isSuper) {
      return
    }
    const basePath = '/applications-dashboard'
    navigationPush(basePath + `?page=${currentPage}`)
    setFilters({ ...filters, from: (currentPage - 1) * PAGE_SIZE })
  }, [currentPage])

  useEffect(() => {
    setLocalFilters({ ...localFilters, projectType: TABS[tabIndex] })
  }, [tabIndex])

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!localFilters.search) {
      searchRef.current.value = ''
    }
    let updatingObject = {
      ...filters,
      ...applicationsListQuery(localFilters),
      from: (currentPage - 1) * PAGE_SIZE,
    }
    if (!_.isEqual(filters, updatingObject)) {
      setCurrentPage(1)
    }
    setFilters(updatingObject)
  }, [localFilters])

  const onStatusChange = ({ status, id }) => {
    updateProject(
      { id, status },
      {
        onSuccess: () => {
          toast({
            title: 'Application status updated successfully',
            status: 'success',
            duration: 3000,
          })
        },
        onError: () => {
          toast({
            title: 'Application status update failed',
            status: 'error',
            duration: 3000,
          })
        },
      }
    )
  }
  if (!isUserLoading && !user.isSuper) {
    return <Redirect to={`/`} />
  }
  return (
    <MainLayout>
      <Container w="full" maxW="90%">
        <Header
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          tabs={TABS}
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
          searchRef={searchRef}
          isLoading={isLoading}
        />
        {isLoading ? (
          <Flex minH="520px" w="full" align="center" justifyContent="center">
            <Spinner color="blue.300" />
          </Flex>
        ) : (
          <>
            {projects.total.value === 0 || !projects.items.length ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                h="full"
                w="full"
              >
                <Heading as="h2" fontSize="4xl" color="blue.300">
                  {t('noResults')}
                </Heading>
              </Flex>
            ) : (
              <>
                <ListView
                  head={[
                    <Text textAlign="left">{t('dashboard@title')}</Text>,
                    t('dashboard@application'),
                    t('dashboard@status'),
                    t('dashboard@creator'),
                    t('dashboard@name'),
                    t('dashboard@email'),
                    t('dashboard@phoneNumber'),
                    '',
                  ]}
                  body={projects.items.map((project) => [
                    <Link
                      to={projectPageURL(
                        project.isProject,
                        project.id,
                        project.slug
                      )}
                    >
                      <LineClamp
                        Component={Text}
                        textDecor="underline"
                        textAlign="left"
                      >
                        {project.applicationTitle}
                      </LineClamp>
                    </Link>,
                    moment(project.createdAt).format('DD.MM.YYYY'),
                    <StatusSelect
                      defaultStatus={project.status}
                      project={project}
                      onChange={onStatusChange}
                    />,
                    project.contactFullName,
                    project.ownerName,
                    project.ownerEmail,
                    project.phoneNumber,
                    <Link
                      to={editProjectPageURL(project.isProject, project.id)}
                    >
                      <PenIcon />
                    </Link>,
                  ])}
                  height={projects.items.length}
                  projects={projects.items}
                  onStatusChange={onStatusChange}
                />
                <Pagination
                  total={projects.total.value || projects.total}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  size={PAGE_SIZE}
                />
              </>
            )}
          </>
        )}
      </Container>
    </MainLayout>
  )
}

export default ApplicationsDashboard
