import React from 'react'
import { Flex, Button } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useProject } from './ProjectContext'

const BottomBar = ({ submitButtonText }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { isLoading } = useProject()
  const w = window.innerWidth;

  return (
    <Flex
      zIndex="10"
      bg="gray.100"
      pb={w < 481 ? "16": "4"}
      pt={{ base: '0', sm: '4' }}
      px="5.5rem"
      justifyContent="flex-end"
      direction={{ base: 'column', sm: 'row' }}
      align="center"
      borderTop="1px solid"
      borderColor="#CDCDCD"
      width="full"
      bottom="0"
      left="0"
      position="fixed"
    >
      <Button
        mr={{ base: '0', sm: '12' }}
        mb={{ base: '4', sm: '0' }}
        minH="40px"
        onClick={() => history.goBack()}
        color="gray.700"
        variant="transparent"
      >
        {t('cancel')}
      </Button>
      <Button
        isDisabled={isLoading}
        variant="blue"
        type="submit">
        {submitButtonText || t('update')}
      </Button>
    </Flex>
  )
}

export default BottomBar
