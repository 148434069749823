
import React from 'react'
import {
  Box,
  Image,
  Flex,
  Heading,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'
import { Link, useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'

export default function Mobile({ card, ...props }) {
    const { navigationPush } = useNavigation()
    const { language } = useTranslation()

    return (
        <Box
            as={Link}
            to={`/blog/${card.slug}`}
            {...props}>
            <Box
                overflow="hidden"
                borderRadius="12px">
                <Box
                    overflow="hidden"
                    position="relative"
                    width="100%"
                    height={card?.image ? '160px' : 'auto'}
                >
                    {card?.image ? (
                        <LazyLoad height="160px" once>
                            <Image
                                onClick={() => navigationPush(`/blog/${card.slug}`)}
                                cursor="pointer"
                                borderRadius="12px"
                                objectFit="cover"
                                width="100%"
                                bgColor="border.100"
                                height="160px"
                                ignoreFallback={true}
                                src={card.image}
                            />
                        </LazyLoad>
                    ) : (
                        <Box
                            onClick={() => navigationPush(`/blog/${card.slug}`)}
                            cursor="pointer"
                            borderRadius="16px"
                            boxShadow="0 12px 24px 0 rgba(0,0,0,0.15)"
                            objectFit="cover"
                            width="100%"
                            bgColor="border.100"
                            height="100%"
                        />
                    )}
                </Box>
                <Flex
                    height="60%"
                    p="3"
                    flexDirection="column"
                    justifyContent={card.image ? 'space-between' : 'normal'}
                    borderRadius="0 0 12px 12px"
                >
                    <Box minH={card.image ? '135px' : 'auto'} width="100%">
                    <Heading
                        key={`card-title-${language}`}
                        noOfLines={1}
                        color={mode('black', "gray.100")}
                        as="h2"
                        fontSize={{ base: 'lg', sm: '3xl' }}>
                        {card.title}
                    </Heading>
                    <Text
                        color="gray.700"
                        fontSize="md"
                        letterSpacing="0.05px"
                        my={2}>
                        {card.date}
                    </Text>
                    <Text
                        key={`card-tagline-${language}`}
                        noOfLines={3}
                        mb="2"
                        fontSize="md"
                        color="rgba(26, 26, 26, 0.6)"
                        minHeight="63px"
                    >
                        {card.description}
                    </Text>
                    </Box>
                </Flex>
            </Box>
        </Box>
    )
}