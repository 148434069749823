import React from 'react'
import HomeNavbar from './HomeNavbar'
import OtherNavbar from './OtherNavbar'

const WebNavbar = ({
  shadow,
  isHomepage,
  isGuest,
  currentPath,
  username,
  disableLinks,
  onClick,
}) => {
  // if (isHomepage) {
  //   return <HomeNavbar isGuest={isGuest} />
  // }

  return (
    <OtherNavbar
      onClick={onClick}
      disableLinks={disableLinks}
      currentPath={currentPath}
      isGuest={isGuest}
      username={username}
      shadow={shadow}
    />
  )
}

export default WebNavbar
