import { Box, VStack } from '@chakra-ui/react'
import { useListTestimonials } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import Header from 'components/MobileHeader'
import TestimonialCard from 'components/TestimonialCard'

export default function Testimonials() {
    const { language, t } = useTranslation()
    const { data } = useListTestimonials()

    return (
        <Box>
            <Header
                heading={t('testimonials')}
                backTo="/" />
            <VStack spacing="10" px="5" mt={6}>
                {data && data[`items_${language}`].map((card) => (
                    <TestimonialCard
                        key={`mobile-testimonial-${card.id}`}
                        width="full"
                        card={card} />
                ))}
            </VStack>
        </Box>
    )
}
