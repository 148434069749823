const emailRepo = (emailService) => {
  const updatesEmail = (params) => emailService.updatesEmail(params)
  const subscribeMailerLite = (email, firstName, language) => emailService.subscribeMailerLiteNews(email, firstName, language)
  const subscribeHubspot = ({email, firstName, lastName, language}) => emailService.subscribeHubspot({email, firstName, lastName, language})

  return {
    updatesEmail,
    subscribeMailerLite,
    subscribeHubspot
  }
}

export default emailRepo