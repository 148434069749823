import React, { useState } from 'react'
import { Button, HStack, Text } from '@chakra-ui/react'

import ProfileDropdown from '../ProfileDropdown'
import { useTranslation, LANGUAGES } from 'contexts/TranslationContext'
import { BlueSelect } from 'components/Select/BlueSelect'
import { useModal } from 'providers/ModalProvider'
import HeaderSearch from '../../../HeaderSearch'
import { useUser } from 'hooks'
import { Link } from 'pages'

const Web = ({ isGuest, disableLinks = false, onClick = () => {} }) => {
  const { t, language, setLanguage } = useTranslation()
  const selectOption = (option) => setLanguage(language, option)
  const { openModal } = useModal()
  const { user } = useUser()
  const [search, setSearch] = useState(false)

  return (
    <HStack spacing="5">
      <HeaderSearch
        search={search}
        setSearch={setSearch}
        xl={true}
        white={false}
      />
      {!search && (
        <>
          <BlueSelect
            width="auto"
            display="flex"
            type="language"
            variant="noBorder"
            languageLabel="Label"
            label={t(`language@${language}LabelShort`)}
            options={Object.entries(LANGUAGES).map(([key]) => ({
              title: t(`language@${key}Label`),
              value: key,
            }))}
            selectOption={selectOption}
            currentSelectedOption={t(`language@${language}Label`)}
            offset={[0, 7]}
          />
          {!isGuest ? (
            <>
              <Text fontWeight="bold" fontSize="md">
                {user.firstName}
              </Text>
              <ProfileDropdown
                black
                onClick={onClick}
                disableLinks={disableLinks}
              />
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  openModal('signIn')
                }}
                fontWeight="bold"
                fontSize="md"
                textTransform="initial"
              >
                {t('nav@signIn')}
              </Button>
            </>
          )}

          {isGuest && (
            <Button
              borderRadius="12px"
              boxShadow="none"
              minHeight="40px"
              variant="blue"
              textTransform="capitalize"
              as={Link}
              to={`/fundraisers?type=oneTime`}
            >
              {t('donate')}
            </Button>
          )}
        </>
      )}
    </HStack>
  )
}

export default Web
