import { normalizeElasticResponse } from './helpers'

const contentUseCase = (contentRepo) => {
  const getExperts = async (variables) => {
    const response = await contentRepo.getExperts(variables)
    return response
  }
  const getNeeds = async (input) => {
    const response = await contentRepo.getNeeds(input)
    return response
  }
  const getItemById = async (input) => {
    const response = await contentRepo.getItemById(input)
    return response
  }
  const getContentFromElastic = async (condition, page, hasSearch) => {
    const elasticResponse = await contentRepo.getContentFromElastic(condition)
    const normalizedResponse = normalizeElasticResponse(elasticResponse)
    if (page === 1 && !hasSearch) {
      localStorage.setItem('experts_cache', JSON.stringify(normalizedResponse))
    }
    return normalizedResponse
  }

  const getGiftCard = async (input) => {
    const response = await contentRepo.getGiftCard(input)
    const normalizeResponse = JSON.parse(response[0].jsonPageData)
    const result = []
    const sortBy = [
      'card-popular',
      'card-thankyou',
      'card-birthday',
      'card-congrats',
      'card-arm',
      'card-other',
    ]
    for (const key of sortBy) {
      result.push([
        key.split('-')[1],
        normalizeResponse[key].map(
          (item) => item.image?.url.split('/').at(-1).split('?')[0]
        ),
      ])
    }
    return result
  }

  return {
    getExperts,
    getNeeds,
    getItemById,
    getGiftCard,
    getContentFromElastic,
  }
}

export default contentUseCase
