import { Flex, Square, useId, useRadio, Radio } from '@chakra-ui/react'
import * as React from 'react'

export const RadioOption = (props) => {
  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(props)
  const id = useId()
  const data = getCheckboxProps(props)
  const {value, children, ...rest} = data
  return (
    <label
      style={{ width: '100%' }}
      {...getLabelProps()}
    >
      <input {...getInputProps()} aria-labelledby={id} />
      <Flex
        height="100%"
        alignItems="center"
        position="relative">
        <Square
          borderRadius="8px"
          border="1px solid"
          borderColor="border.100"
          id={id}
          px={{ base: '4', sm: '6' }}
          pt="3"
          pb={{ base: '3', sm: '4' }}
          width="100%"
          h="100%"
          justifyContent="space-between"
          fontSize="lg"
          transition="all 0.2s"
          cursor="pointer"
          color="gray.800"
          _checked={{
            borderColor: 'blue.400',
          }}
          {...getCheckboxProps(props)}
        />
        <Radio
          style={{position: 'absolute', right: '24px'}}
          onClick={() => console.log('a')}
          type="radio"
          isChecked={data.isChecked}
          />
      </Flex>
    </label>
  )
}
