import { v4 as uuid } from 'uuid'

export default function useEmptyUpdate() {
    const generateEmptyUpdate = () => ({
        id: uuid(),
        data_en: '',
        data_ru: '',
        data_hy: '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
    })

    return generateEmptyUpdate
}
