import { useState } from 'react'
import { useQuery } from 'react-query'
import { projectUseCase } from '../../core/factories'

const useProjectsByUserId = (initialFilter, withNextToken = false, enabled = true, key = 'get_projects_by_user_id') => {
  const [filters, setFilters] = useState(initialFilter)

  const {
    error,
    refetch,
    isLoading,
    data: projects,
  } = useQuery(
    [key, filters],
    async () => {
      return await projectUseCase.getProjectsByUserId(filters, withNextToken)
    },
    {
      enabled
    })

  return {
    refetch,
    isLoading,
    projects,
    filters,
    setFilters,
    error,
  }
}

export default useProjectsByUserId
