import React, { useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useProjectsByPopular, useProjectsByFeatured } from 'hooks'

import MetaTags from 'components/MetaTags'
import { useTranslation } from 'contexts/TranslationContext'
import Join from './web/Join'
import { Footer } from 'components/Footer'
import Navbar from 'components/Navbar'
import About from './web/About'
import PopularProjects from './web/PopularProjects'
import PopularInitiatives from './web/PopularInitiatives'
import { Header } from './web/Header'
import MobileHeader from './MobileHeader'
import SocialMedias from './mobile/SocialMedias'
import { Box } from '@chakra-ui/react'

const w = window.innerWidth
const isFundraising = (project) => project.isProject
const isCollaboration = (project) => project.isCollaboration
const sortFeatured = (project, nextProject) =>
  project.sequence - nextProject.sequence

const Homepage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { projects: featured, isLoading: isFeaturedLoading } =
    useProjectsByFeatured({
      featured: 'true',
    })
  const { projects: popular, isLoading: isPopularLoading } =
    useProjectsByPopular({
      popular: 'true',
    })
  const popularCollaborations = useMemo(
    () => popular?.filter(isCollaboration),
    [popular]
  )
  const popularFundraisers = useMemo(
    () => popular?.filter(isFundraising),
    [popular]
  )

  useEffect(() => {
    if (!featured || !popular) return
    featured?.map((item) =>
      queryClient.setQueryData(['project_slug', item.slug], item)
    )
    popular?.map((item) =>
      queryClient.setQueryData(['project_slug', item.slug], item)
    )
  }, [featured, popular])

  return (
    <>
      <MetaTags
        url={window.location.href}
        title={t('meta@home@title')}
        description={t('meta@home@description')}
      />
      {w < 481 && <MobileHeader />}
      <Navbar from="home" />
      <Join />
      <Header
        projects={featured?.sort(sortFeatured) || []}
        isLoading={isFeaturedLoading}
      />

      <Box mt={{ base: 0, sm: 10 }}>
        <PopularProjects
          isLoading={isPopularLoading}
          projects={popularFundraisers || []}
        />
      </Box>
      <Box mt={6}>
        <PopularInitiatives
          isLoading={isPopularLoading}
          initiatives={popularCollaborations || []}
        />
      </Box>

      <About />

      {w > 481 ? <Footer /> : <SocialMedias />}
    </>
  )
}

export default Homepage
