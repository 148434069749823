/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        seenSubscribeModal
        firstName
        lastName
        phone
        country
        companyName
        city
        postalCode
        monetoryDonor
        aboutMe
        shortDescription
        youtubeLink
        facebookLink
        linkedinLink
        websiteLink
        otherLinks
        creditCards {
          bindingId
          pan
          cardholderName
          expiration
          type
          isDefault
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGiftCard = /* GraphQL */ `
  query GetGiftCard($id: ID!) {
    getGiftCard(id: $id) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      createdAt
      updatedAt
    }
  }
`;
export const listGiftCards = /* GraphQL */ `
  query ListGiftCards(
    $filter: ModelGiftCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiftCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        code
        amount
        from
        message
        toEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDonationSubscription = /* GraphQL */ `
  query GetDonationSubscription($id: ID!) {
    getDonationSubscription(id: $id) {
      id
      projectId
      bindingId
      userEmail
      donationToReArmenia
      donationToProject
      unsubscribedDate
      month
      day
      currency
      currencyRate
      createdAt
      updatedAt
    }
  }
`;
export const listDonationSubscriptions = /* GraphQL */ `
  query ListDonationSubscriptions(
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDonationSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        bindingId
        userEmail
        donationToReArmenia
        donationToProject
        unsubscribedDate
        month
        day
        currency
        currencyRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBindingCard = /* GraphQL */ `
  query GetBindingCard($id: ID!) {
    getBindingCard(id: $id) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
    }
  }
`;
export const listBindingCards = /* GraphQL */ `
  query ListBindingCards(
    $filter: ModelBindingCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBindingCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userEmail
        bindingId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      userId
      giftCardId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      statusDescription
      amount
      orderId
      subscriptionId
      donationsCountWithEmail
      currency
      currencyRate
      isValid
      isManual
      isAnonymous
      createdAt
      updatedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        giftCardId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        statusDescription
        amount
        orderId
        subscriptionId
        donationsCountWithEmail
        currency
        currencyRate
        isValid
        isManual
        isAnonymous
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUpdate = /* GraphQL */ `
  query GetUpdate($id: ID!, $createdAt: AWSDateTime!) {
    getUpdate(id: $id, createdAt: $createdAt) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      createdAt
      updatedAt
    }
  }
`;
export const listUpdates = /* GraphQL */ `
  query ListUpdates(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUpdates(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isFeatured
      isUrgent
      isPopular
      isLive
      isProject
      isCollaboration
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      creatorImage
      cover
      TIN
      category
      status
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        price
        image
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      updates_en
      updates_ru
      updates_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
      }
      implementor {
        id
        email
        fullName
      }
      collaborators {
        id
        email
        fullName
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isFeatured
        isUrgent
        isPopular
        isLive
        isProject
        isCollaboration
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        creatorImage
        cover
        TIN
        category
        status
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          price
          image
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
        }
        updates_en
        updates_ru
        updates_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
        }
        implementor {
          id
          email
          fullName
        }
        collaborators {
          id
          email
          fullName
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDraft = /* GraphQL */ `
  query GetDraft($id: ID!) {
    getDraft(id: $id) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      otherPlayers
      files
      fundraisingType
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const listDrafts = /* GraphQL */ `
  query ListDrafts(
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        budgetFiles
        applicationTitle
        owner
        ownerName
        ownerLastName
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        phases
        beneficiaries
        risks
        objective
        keyResults
        otherPlayers
        files
        fundraisingType
        category
        projectTeam
        requestedAmount
        contactFullName
        email
        phone
        links
        terms_and_conditions
        privacy_policy
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
        }
        isProject
        isCollaboration
        startDate
        endDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      domain
      projectIds
      createdAt
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        domain
        projectIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInitiative = /* GraphQL */ `
  query GetInitiative($id: ID!) {
    getInitiative(id: $id) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      updates_en
      updates_ru
      updates_hy
      collaboratorsTotal
      createdAt
      updatedAt
    }
  }
`;
export const listInitiatives = /* GraphQL */ `
  query ListInitiatives(
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiatives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        owner
        ownerName
        ownerLastName
        contactEmail
        implementor {
          id
          email
          fullName
        }
        region
        other
        applicationTitle
        about
        support
        partnership
        fullName
        phoneNumber
        image
        organizationName
        organizationLegalForm
        description
        TIN
        links
        summary
        files
        isFeatured
        isPopular
        isUrgent
        category
        collaborators {
          id
          email
          fullName
        }
        status
        cover
        requiredSupport
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        embedVideo_en
        embedVideo_ru
        embedVideo_hy
        content_en
        content_ru
        content_hy
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
        }
        updates_en
        updates_ru
        updates_hy
        collaboratorsTotal
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      currency
      createdAt
      updatedAt
    }
  }
`;
export const listSettingss = /* GraphQL */ `
  query ListSettingss(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currency
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrismicContent = /* GraphQL */ `
  query GetPrismicContent($id: ID!) {
    getPrismicContent(id: $id) {
      id
      pk
      sk
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
      }
      interestedIn {
        interested_in
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
      }
      email
      phone
      imageUrl
      linkedinLink
      link
      publishedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPrismicContents = /* GraphQL */ `
  query ListPrismicContents(
    $filter: ModelPrismicContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrismicContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pk
        sk
        jsonPageData
        title_en
        title_ru
        title_hy
        type
        image
        keywords
        firstName_en
        firstName_hy
        firstName_ru
        lastName_en
        lastName_hy
        lastName_ru
        description_en
        description_hy
        description_ru
        spheres {
          title_en
          title_hy
          title_ru
        }
        interestedIn {
          interested_in
        }
        getInvolvedStatus
        hrsPerWeek
        selectedLanguage {
          language
          language_other
        }
        email
        phone
        imageUrl
        linkedinLink
        link
        publishedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        seenSubscribeModal
        firstName
        lastName
        phone
        country
        companyName
        city
        postalCode
        monetoryDonor
        aboutMe
        shortDescription
        youtubeLink
        facebookLink
        linkedinLink
        websiteLink
        otherLinks
        creditCards {
          bindingId
          pan
          cardholderName
          expiration
          type
          isDefault
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const giftCardsByCode = /* GraphQL */ `
  query GiftCardsByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelGiftCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    giftCardsByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        code
        amount
        from
        message
        toEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const donationSubsscriptionsByCreatedAt = /* GraphQL */ `
  query DonationSubsscriptionsByCreatedAt(
    $createdAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    donationSubsscriptionsByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        bindingId
        userEmail
        donationToReArmenia
        donationToProject
        unsubscribedDate
        month
        day
        currency
        currencyRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const donationSubsscriptionsByUserEmail = /* GraphQL */ `
  query DonationSubsscriptionsByUserEmail(
    $userEmail: String
    $sortDirection: ModelSortDirection
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    donationSubsscriptionsByUserEmail(
      userEmail: $userEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        bindingId
        userEmail
        donationToReArmenia
        donationToProject
        unsubscribedDate
        month
        day
        currency
        currencyRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const donationSubscriptionsByProjectId = /* GraphQL */ `
  query DonationSubscriptionsByProjectId(
    $projectId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDonationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    donationSubscriptionsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        bindingId
        userEmail
        donationToReArmenia
        donationToProject
        unsubscribedDate
        month
        day
        currency
        currencyRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bindingCardsByUserId = /* GraphQL */ `
  query BindingCardsByUserId(
    $bindingId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBindingCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bindingCardsByUserId(
      bindingId: $bindingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEmail
        bindingId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByOrderId = /* GraphQL */ `
  query TransactionsByOrderId(
    $orderId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        giftCardId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        statusDescription
        amount
        orderId
        subscriptionId
        donationsCountWithEmail
        currency
        currencyRate
        isValid
        isManual
        isAnonymous
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByGiftCardCode = /* GraphQL */ `
  query TransactionsByGiftCardCode(
    $giftCardCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByGiftCardCode(
      giftCardCode: $giftCardCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        giftCardId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        statusDescription
        amount
        orderId
        subscriptionId
        donationsCountWithEmail
        currency
        currencyRate
        isValid
        isManual
        isAnonymous
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsBySubscriptionId = /* GraphQL */ `
  query TransactionsBySubscriptionId(
    $subscriptionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsBySubscriptionId(
      subscriptionId: $subscriptionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        giftCardId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        statusDescription
        amount
        orderId
        subscriptionId
        donationsCountWithEmail
        currency
        currencyRate
        isValid
        isManual
        isAnonymous
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByTopDonation = /* GraphQL */ `
  query TransactionsByTopDonation(
    $projectId: String
    $amount: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByTopDonation(
      projectId: $projectId
      amount: $amount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        giftCardId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        statusDescription
        amount
        orderId
        subscriptionId
        donationsCountWithEmail
        currency
        currencyRate
        isValid
        isManual
        isAnonymous
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByDate = /* GraphQL */ `
  query TransactionsByDate(
    $projectId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByDate(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        giftCardId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        statusDescription
        amount
        orderId
        subscriptionId
        donationsCountWithEmail
        currency
        currencyRate
        isValid
        isManual
        isAnonymous
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByProjectIdEmail = /* GraphQL */ `
  query TransactionsByProjectIdEmail(
    $projectId: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByProjectIdEmail(
      projectId: $projectId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        giftCardId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        statusDescription
        amount
        orderId
        subscriptionId
        donationsCountWithEmail
        currency
        currencyRate
        isValid
        isManual
        isAnonymous
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByEmailDate = /* GraphQL */ `
  query TransactionsByEmailDate(
    $email: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByEmailDate(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        giftCardId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        statusDescription
        amount
        orderId
        subscriptionId
        donationsCountWithEmail
        currency
        currencyRate
        isValid
        isManual
        isAnonymous
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const updatesByProjectId = /* GraphQL */ `
  query UpdatesByProjectId(
    $projectId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    updatesByProjectId(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUpdatesByDate = /* GraphQL */ `
  query ListUpdatesByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdatesByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        projectId
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectBySlug = /* GraphQL */ `
  query ProjectBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isFeatured
        isUrgent
        isPopular
        isLive
        isProject
        isCollaboration
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        creatorImage
        cover
        TIN
        category
        status
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          price
          image
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
        }
        updates_en
        updates_ru
        updates_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
        }
        implementor {
          id
          email
          fullName
        }
        collaborators {
          id
          email
          fullName
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectByUserId = /* GraphQL */ `
  query ProjectByUserId(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectByUserId(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isFeatured
        isUrgent
        isPopular
        isLive
        isProject
        isCollaboration
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        creatorImage
        cover
        TIN
        category
        status
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          price
          image
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
        }
        updates_en
        updates_ru
        updates_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
        }
        implementor {
          id
          email
          fullName
        }
        collaborators {
          id
          email
          fullName
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectBypopular = /* GraphQL */ `
  query ProjectBypopular(
    $popular: String
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectBypopular(
      popular: $popular
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isFeatured
        isUrgent
        isPopular
        isLive
        isProject
        isCollaboration
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        creatorImage
        cover
        TIN
        category
        status
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          price
          image
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
        }
        updates_en
        updates_ru
        updates_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
        }
        implementor {
          id
          email
          fullName
        }
        collaborators {
          id
          email
          fullName
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectByFeatured = /* GraphQL */ `
  query ProjectByFeatured(
    $featured: String
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectByFeatured(
      featured: $featured
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isFeatured
        isUrgent
        isPopular
        isLive
        isProject
        isCollaboration
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        creatorImage
        cover
        TIN
        category
        status
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          price
          image
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
        }
        updates_en
        updates_ru
        updates_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
        }
        implementor {
          id
          email
          fullName
        }
        collaborators {
          id
          email
          fullName
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const draftsByUserId = /* GraphQL */ `
  query DraftsByUserId(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    draftsByUserId(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        budgetFiles
        applicationTitle
        owner
        ownerName
        ownerLastName
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        phases
        beneficiaries
        risks
        objective
        keyResults
        otherPlayers
        files
        fundraisingType
        category
        projectTeam
        requestedAmount
        contactFullName
        email
        phone
        links
        terms_and_conditions
        privacy_policy
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
        }
        isProject
        isCollaboration
        startDate
        endDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const companyByDomain = /* GraphQL */ `
  query CompanyByDomain(
    $domain: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByDomain(
      domain: $domain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        domain
        projectIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const initiativeBySlug = /* GraphQL */ `
  query InitiativeBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    initiativeBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        owner
        ownerName
        ownerLastName
        contactEmail
        implementor {
          id
          email
          fullName
        }
        region
        other
        applicationTitle
        about
        support
        partnership
        fullName
        phoneNumber
        image
        organizationName
        organizationLegalForm
        description
        TIN
        links
        summary
        files
        isFeatured
        isPopular
        isUrgent
        category
        collaborators {
          id
          email
          fullName
        }
        status
        cover
        requiredSupport
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        embedVideo_en
        embedVideo_ru
        embedVideo_hy
        content_en
        content_ru
        content_hy
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
        }
        updates_en
        updates_ru
        updates_hy
        collaboratorsTotal
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contentByPk = /* GraphQL */ `
  query ContentByPk(
    $pk: String
    $sortDirection: ModelSortDirection
    $filter: ModelPrismicContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentByPk(
      pk: $pk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        jsonPageData
        title_en
        title_ru
        title_hy
        type
        image
        keywords
        firstName_en
        firstName_hy
        firstName_ru
        lastName_en
        lastName_hy
        lastName_ru
        description_en
        description_hy
        description_ru
        spheres {
          title_en
          title_hy
          title_ru
        }
        interestedIn {
          interested_in
        }
        getInvolvedStatus
        hrsPerWeek
        selectedLanguage {
          language
          language_other
        }
        email
        phone
        imageUrl
        linkedinLink
        link
        publishedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchDonationSubscriptions = /* GraphQL */ `
  query SearchDonationSubscriptions(
    $filter: SearchableDonationSubscriptionFilterInput
    $sort: SearchableDonationSubscriptionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDonationSubscriptions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        projectId
        bindingId
        userEmail
        donationToReArmenia
        donationToProject
        unsubscribedDate
        month
        day
        currency
        currencyRate
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchTransactions = /* GraphQL */ `
  query SearchTransactions(
    $filter: SearchableTransactionFilterInput
    $sort: SearchableTransactionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTransactions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userId
        giftCardId
        giftCardCode
        giftCardTransactionType
        email
        firstName
        lastName
        projectId
        projectTitle_en
        projectTitle_hy
        projectTitle_ru
        projectImage
        donationToReArmenia
        donationToProject
        giftCardAmount
        status
        statusDescription
        amount
        orderId
        subscriptionId
        donationsCountWithEmail
        currency
        currencyRate
        isValid
        isManual
        isAnonymous
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: SearchableProjectSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        slug
        budgetFiles
        applicationTitle
        oldCollaborationId
        sequence
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        campaignImplementorPeriod_en
        campaignImplementorPeriod_ru
        campaignImplementorPeriod_hy
        owner
        ownerName
        ownerLastName
        ownerEmail
        region
        summary
        isSubmittedByLegalEntity
        organizationName
        organizationLegalForm
        problem
        solution
        duration
        durationType
        fundraisingType
        isFeatured
        isUrgent
        isPopular
        isLive
        isProject
        isCollaboration
        popular
        featured
        video_en
        video_ru
        video_hy
        creatorName_en
        creatorName_ru
        creatorName_hy
        creatorSocialLink
        amount
        goal
        image
        files
        total {
          oneTime
          monthlyOneTime
          subscriptions
          subscriptionsTransactions
          giftCard
          total
          goalMetPercentage
        }
        totalAmount
        budgetBreakdown
        contactFullName
        contactEmail
        phoneNumber
        similarProjects
        partnership
        projectTeam
        otherDetails
        links
        creatorImage
        cover
        TIN
        category
        status
        perks {
          id
          title
          title_en
          title_hy
          title_ru
          price
          image
        }
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
        }
        updates_en
        updates_ru
        updates_hy
        content_en
        content_ru
        content_hy
        support
        collaborations {
          image
          needs
          needs_en
          needs_hy
          needs_ru
          needsDescription
          needsDescription_en
          needsDescription_hy
          needsDescription_ru
        }
        goals {
          amount
          description_en
          description_ru
          description_hy
        }
        implementor {
          id
          email
          fullName
        }
        collaborators {
          id
          email
          fullName
        }
        collaboratorsTotal
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        phases
        beneficiaries
        risks
        objective
        keyResults
        otherPlayers
        startDate
        endDate
        statusUpdatedAt
        publishedAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchInitiatives = /* GraphQL */ `
  query SearchInitiatives(
    $filter: SearchableInitiativeFilterInput
    $sort: SearchableInitiativeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchInitiatives(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        slug
        owner
        ownerName
        ownerLastName
        contactEmail
        implementor {
          id
          email
          fullName
        }
        region
        other
        applicationTitle
        about
        support
        partnership
        fullName
        phoneNumber
        image
        organizationName
        organizationLegalForm
        description
        TIN
        links
        summary
        files
        isFeatured
        isPopular
        isUrgent
        category
        collaborators {
          id
          email
          fullName
        }
        status
        cover
        requiredSupport
        title_en
        title_ru
        title_hy
        tagline_en
        tagline_ru
        tagline_hy
        embedVideo_en
        embedVideo_ru
        embedVideo_hy
        content_en
        content_ru
        content_hy
        initiatorImage
        initiatorName_en
        initiatorName_ru
        initiatorName_hy
        updates {
          id
          data_en
          data_ru
          data_hy
          createdAt
          updatedAt
        }
        updates_en
        updates_ru
        updates_hy
        collaboratorsTotal
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchSettingss = /* GraphQL */ `
  query SearchSettingss(
    $filter: SearchableSettingsFilterInput
    $sort: SearchableSettingsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSettingss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        currency
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
