const projectRepo = (projectService) => {
  const deleteProject = async (project) => projectService.deleteProject(project)

  const createProject = async (project) => projectService.createProject(project)

  const updateProject = (project) => projectService.updateProject(project)

  const retrieveProject = (id) => projectService.retrieveProject(id)

  const getProjectBySlug = (slug) => projectService.getProjectBySlug(slug)

  const updateProjectPerks = (project) =>
    projectService.updateProjectPerks(project)

  const updateProjectStatus = async ({ id, status }) =>
    projectService.updateProjectStatus({ id, status })

  const getProjectsFromElastic = (condition) =>
    projectService.getProjectsFromElastic(condition)

  const getProjectsByUserId = (condition) =>
    projectService.getProjectsByUserId(condition)

  const getProjectsByPopular = (condition) =>
    projectService.getProjectsByPopular(condition)

  const getProjectsByFeatured = (condition) =>
    projectService.getProjectsByFeatured(condition)

  const searchProjects = (condition) => projectService.searchProjects(condition)

  return {
    createProject,
    updateProject,
    retrieveProject,
    updateProjectPerks,
    updateProjectStatus,
    deleteProject,
    getProjectBySlug,
    getProjectsFromElastic,
    getProjectsByUserId,
    getProjectsByPopular,
    getProjectsByFeatured,
    searchProjects
  }
}

export default projectRepo
