import React, { useState } from 'react'
import { API } from 'aws-amplify'
import {
  Box,
  Heading,
  Text,
  Flex,
  useColorModeValue as mode,
  Button,
} from '@chakra-ui/react'
import { VerifyForm } from './VerifyForm'
import { Link } from 'pages'
import AuthWrapper from 'components/AuthWrapper'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'
import { authUseCase } from 'core/factories'
import { useNavigation } from 'pages'
import MobileVerify from './MobileVerify'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import useCustomToast from 'hooks/useCustomToast'

const w = window.innerWidth

const Verify = () => {
  const { t } = useTranslation()
  const params = useQueryParams()
  const [loading, setLoading] = useState(false)
  const {
    register: verify,
    handleSubmit: handleVerify,
    formState: { errors: verifyErrors },
  } = useForm()
  const { navigationPush } = useNavigation()
  const { language } = useTranslation()
  const toast = useCustomToast()

  const onResend = async () => {
    try {
      await authUseCase.resendSignUp(params.get('email'))
      toast({
        status: 'success',
        title: t('verify@resend'),
        isClosable: true,
        duration: 5000,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const onVerify = async (data) => {
    setLoading(true)
    try {
      await authUseCase.confirmSignUp({
        code: data.code,
        email: params.get('email'),
      })
      await API.post('mailerLiteAPI', '/subscribe', {
        body: {
          email: params.get('email'),
          firstName: params.get('firstName'),
          language: `MAILER_SUB_GROUP_${language.toUpperCase()}`,
        },
      }).catch((err) => console.log(err))
      setLoading(false)
      const donationRedirectURL = localStorage.getItem('redirectURL')
      navigationPush(
        params.get('from') === '/donation'
          ? donationRedirectURL
          : `/signup/thank-you?firstName=${params.get('firstName')}`
      )
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  if (w < 481) {
    return (
      <MobileVerify
        handleVerify={handleVerify}
        onVerify={onVerify}
        verifyErrors={verifyErrors}
        verify={verify}
        loading={loading}
        onResend={onResend}
      />
    )
  }

  return (
    <AuthWrapper
      ceoCompany="CEO of Kamala"
      ceoName="Samvel Papoyan"
      ceoDescription="“reArmenia is short description Though I’ll stop short of calling it a rule lorem ipsum`.”"
      url="/assets/images/auth.jpg"
    >
      <Box w="full" maxW="lg">
        <Link>
          <Button
            display={{ base: 'inline-flex', md: 'none' }}
            position="absolute"
            top="20px"
            right="20px"
            variant="transparent"
          >
            <Close width="20px" />
          </Button>
        </Link>
        <Heading
          as="h1"
          fontSize="5xl"
          fontWeight="extrabold"
          letterSpacing="tight"
          textAlign="center"
        >
          {t('verify@heading')}
        </Heading>

        <Box
          minW={{ md: '350px' }}
          rounded="xl"
          bg={{ md: mode('gray.100', 'gray.700') }}
          pt={{ base: '8', md: '12' }}
          pb="8"
        >
          <VerifyForm
            handleVerify={handleVerify}
            onVerify={onVerify}
            verifyErrors={verifyErrors}
            verify={verify}
            loading={loading}
            onResend={onResend}
          />
        </Box>
      </Box>
      <Flex
        position="absolute"
        bottom="2rem"
        spacing="4"
        direction={{ base: 'column-reverse', md: 'row' }}
        mt="6"
        align="center"
        justify="space-between"
      >
        <Text color="gray.700" fontSize="sm" fontWeight="semibold">
          {t('verify@haveAnAccount')}{' '}
          <Button fontSize="sm" color="orange.400">
            <Link to="/sign-in">{t('verify@signIn')}</Link>
          </Button>
        </Text>
      </Flex>
    </AuthWrapper>
  )
}

export default Verify
