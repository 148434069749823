import React from 'react'
import { Box, AspectRatio, Flex } from '@chakra-ui/react'
import Header from './Header'
import Information from './Information'
import Tabs from './Tabs'
import Perks from './Perks'
import Share from './Share'
import NavbarButtons from './NavbarButtons'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Play } from 'assets/icons/play2.svg'
import ImageWrapper from 'components/ImageWrapper'
import ProjectHelperInfo from 'components/ProjectHelperInfo'

const MobileProject = ({
  project,
  getProject,
  projectId,
  play,
  setPlay,
  getId,
  url,
  param,
  updates,
  isUpdatesLoading,
}) => {
  const { language, t } = useTranslation()

  return (
    <Box>
      <Header project={project} />
      <AspectRatio h="211px" w="100%" ratio={16 / 9}>
        {project && project[`video_${language}`] ? (
          play ? (
            <Flex
              height="100%"
              width="100%"
              zIndex="5"
              allow="autoplay"
              as="iframe"
              title="Armenia"
              allowFullScreen
              src={`https://www.youtube.com/embed/${getId(
                project[`video_${language}`]
              )}${url}`}
            />
          ) : (
            <Flex
              bgImage={`url(https://img.youtube.com/vi/${getId(
                project[`video_${language}`]
              )}/0.jpg)`}
              height="100%"
              bgSize="cover"
              bgPos="center"
              bgRepeat="no-repeat"
              width="100%"
            >
              <Play
                cursor="pointer"
                onClick={() => setPlay(true)}
                p="5"
                backgroundColor="rgba(255, 255, 255, .5)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="12px"
                width="50px"
                height="50px"
              />
            </Flex>
          )
        ) : project?.cover ? (
          <ImageWrapper
            src={`/thumbnail/medium/${project.cover_key || project.cover}`}
            alt="Project"
            style={{ objectFit: 'cover' }}
            width="100%"
            height="100%"
          />
        ) : (
          <Box bg="border.100" width="100%" height="100%" />
        )}
      </AspectRatio>
      <Box flex="1" px="5" pt="4">
        <Information initiator={false} project={project} />
      </Box>
      {project.fundraisingType === 'recurring' && (
        <ProjectHelperInfo
          title={t('project@recurringHelperTitle')}
          description={t('project@recurringHelperDescription')}
          mx={4}
          px={7}
        />
      )}
      <Tabs
        updates={updates}
        isUpdatesLoading={isUpdatesLoading}
        project={project}
        getProject={getProject}
        projectId={projectId}
      />
      <Perks param={param} project={project} />
      <Box py="10">
        <Share project={project} />
      </Box>
      {/* { // TODO: Bring back when similiar projects will be ready } */}
      {/* <SimiliarProjects project={project} /> */}
      {project.status === 'live' && (
        <NavbarButtons param={param} project={project} />
      )}
    </Box>
  )
}

export default MobileProject
