import React, { useRef, useState } from 'react'
import { Button, Box, Flex, Text, Stack, Input } from '@chakra-ui/react'
import { FaFile, FaTimesCircle } from 'react-icons/fa'
import { useUploadFiles } from '../../hooks'
import { useTranslation } from 'contexts/TranslationContext'
import FileUpload from 'components/FileUpload'
import { ReactComponent as Attach } from '../../assets/icons/attach.svg'

const BudgetFiles = ({
  setValue,
  getValues,
  filesWrapperProps,
  errors,
  register,
  budgetFiles,
}) => {
  const { mutate: filesUpload } = useUploadFiles()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const inputRef = useRef(null)
  const { t } = useTranslation()

  const handleClick = () => inputRef.current?.click()
  const uploadFiles = (event) => {
    const { files } = event.target

    if (files && files[0]) {
      setLoading(true)
      const newFiles = Object.values(files)
      filesUpload(
        {
          data: newFiles,
        },
        {
          onSuccess: (data) => {
            setError('')
            const urls = data.map((image) => image.key)
            setValue('budgetFiles', [...getValues('budgetFiles'), ...urls])
            setLoading(false)
          },
          onError: (e) => {
            setError(e)
            setLoading(false)
          },
        }
      )
    }
  }

  const onRemove = (e, file) => {
    e.preventDefault()
    e.stopPropagation()
    setValue('budgetFiles', [
      ...getValues('budgetFiles').filter((f) => f !== file),
    ])
  }

  return (
    <Box>
      <Flex mb="1">
        <Attach />
        <Button
          ml="2"
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          fontSize="sm"
          fontWeight="regular"
          textTransform="capitalize"
          color={!!error || !!errors.budgetFiles ? 'red.400' : 'gray.800'}
          isLoading={loading}
          onClick={handleClick}
        >
          {!!error ? error : t('Attach a budget sheet')}
          <Text
            color="gray.700"
            fontWeight={400}
            fontSize="sm"
            textAlign="left"
            whiteSpace="pre-wrap"
            mt="6px"
            textTransform='none'
          >
            {t('Attach a budget sheet Text')}
          </Text>
        </Button>
        <Input
          display="none"
          {...register('budgetFiles', { required: true })}
        />
      </Flex>
      <Stack {...filesWrapperProps} spacing="2">
        {budgetFiles &&
          budgetFiles?.map((file) => (
            <Flex
              key={`edit-project-application-file-${file}`}
              borderWidth="1px"
              borderColor="border.100"
              borderRadius="7px"
              pl={3}
              py="14px"
              alignItems="center"
              position="relative"
              _hover={{
                button: {
                  opacity: 1,
                },
              }}
            >
              <FaFile color="gray.600" />
              <a
                style={{ marginLeft: '0.875rem' }}
                href={`${
                  process.env.REACT_APP_S3_BUCKET_URL
                }/public/${file.replaceAll(' ', '+')}`}
                download
                ml={3}
              >
                {file}
              </a>
              <Button
                position="absolute"
                right="12px"
                top="50%"
                transform="translateY(-50%)"
                bg="none"
                border="none"
                minW="auto"
                minH="auto"
                opacity="0"
                transition="opacity 200ms"
                onClick={(e) => onRemove(e, file)}
              >
                <FaTimesCircle color="gray.600" />
              </Button>
            </Flex>
          ))}
      </Stack>
      <input
        multiple
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={uploadFiles}
        id="budgetFiles"
      />
    </Box>
  )
}

FileUpload.defaultProps = {
  getValues: () => {},
  setValue: () => {},
  filesWrapperProps: {},
}

export default BudgetFiles
