import React, { useMemo } from 'react'
import { Box, VStack, Flex, Avatar } from '@chakra-ui/react'
import moment from 'moment'
import Content from 'components/Content'
import { useInitiative } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'

const Update = ({ initiative }) => {
  const { owner, implementor } = useInitiative()
  const { language, t } = useTranslation()
  
  const userInfo = useMemo(
    () => {
      if (implementor) {
        return implementor;
      }
      return owner;
    },
    [owner, implementor],
  )

  return (
    <Box>
      {initiative && initiative.updates?.length > 0 ? (
        <VStack spacing={6}>
          {initiative.updates.map(
            (update) => (
              <Box
                pt={7}
                px={6}
                w="100%"
                pb="60px"
                borderRadius="16px"
                borderWidth={1}
                borderColor="border.100">
                <Flex alignItems="center">
                  <Avatar bg="#dddddd" src={initiative.creatorImage} size="sm" />
                  <Box ml="3">
                    <Box fontWeight="medium" color="gray.800" fontSize="md" as="span">
                      {initiative[`initiatorName_${language}`]}
                    </Box>
                    <Box fontSize="sm" color="gray.700" fontWeight="medium">
                      {moment(update.createdAt).format('MMMM DD, YYYY')} | {moment(update.createdAt).fromNow()}
                    </Box>
                  </Box>
                </Flex>
                <Box
                  height="1px"
                  backgroundColor="border.100"
                  my={5} />
                <Content html={update[`data_${language}`]} />
              </Box>
            )
          )}
        </VStack>
      ) : (
        <Box
          my="12"
          fontWeight="800"
          display="block"
          fontSize="6xl"
          color="blue.300"
          as="span"
        >
          {t('project@updates')}{' '}
          <Box as="span" fontWeight="400">
            {t('project@updatesText')}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Update
