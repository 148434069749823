import {
  Stack,
  Box,
  Heading,
  Checkbox,
  Flex,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { InputField } from 'components/InputField'
import FilesUpload from 'components/FilesUpload'

const ContactInformation = ({
  register,
  errors,
  setLegal,
  legal,
  setValue,
  getValues,
  files,
}) => {
  const { t } = useTranslation()

  return (
    <Box width="100%">
      <Heading mb="3" fontSize="1.625rem" as="h2">
        {t('Contact Information')}
      </Heading>
      <Stack mb="5.5rem" spacing="6">
        <InputField
          isInvalid={!!errors.contactFullName}
          errorMessage={t(
            'createEditProject@contactPersonFullNameErrorMessage'
          )}
          label={t('createEditProject@contactPersonFullName')}
          {...register('contactFullName', { required: true })}
        />
        <Stack direction={{ base: 'column', sm: 'row' }} spacing="6">
          <InputField
            errorMessage={t('createEditProject@emailErrorMessage')}
            label={t('createEditProject@email')}
            isInvalid={!!errors.email}
            {...register('email', { required: true, pattern: /\S+@\S+\.\S+/ })}
          />
          <InputField
            errorMessage={t('createEditProject@phoneNumberErrorMessage')}
            label={t('createEditProject@phoneNumber')}
            type="number"
            pattern="[0-9+]"
            isInvalid={!!errors.phone}
            {...register('phone', { required: true })}
          />
        </Stack>
        <Checkbox
          alignItems="flex-start"
          _focusVisible={{ boxShadow: 'outline' }}
          _focus={{ boxShadow: 'none' }}
          value={legal}
          onChange={() => setLegal(!legal)}
        >
          {t('createEditProject@submitFormRequiredNote')}
        </Checkbox>
        {legal && (
          <>
            <InputField
              isInvalid={!!errors.organizationLegalForm}
              errorMessage={t(
                'createEditProject@organizationLegalFormErrorMessage'
              )}
              label={t('createEditProject@organizationLegalForm')}
              {...register('organizationLegalForm', { required: true })}
            />
            <Stack
              alignItems="flex-end"
              direction={{ base: 'column', sm: 'row' }}
              spacing="6"
            >
              <InputField
                isInvalid={!!errors.organizationName}
                errorMessage={t(
                  'createEditProject@organizationNameErrorMessage'
                )}
                label={t('createEditProject@organizationName')}
                {...register('organizationName', { required: true })}
              />
              <InputField
                {...register('tin', { required: true })}
                isInvalid={!!errors.tin}
                errorMessage={t('createEditProject@TINErrorMessage')}
                label={t('createEditProject@TIN')}
              />
            </Stack>
          </>
        )}
        <InputField
          {...register('otherDetails')}
          label={t('createEditProject@otherDetails')}
        />
        <FilesUpload files={files} setValue={setValue} getValues={getValues} />
        <InputField
          {...register('links')}
          label={t('createEditProject@largestLinks')}
        />
        <Flex direction="column">
          <Checkbox
            {...register('terms_and_conditions', { required: true })}
            isInvalid={!!errors.terms_and_conditions}
            mb="2"
          >
            {t('createEditProject@agreeWith')}&nbsp;
            <Link
              style={{ color: '#ED8B00' }}
              target="_blank"
              to="/terms-and-conditions"
            >
              {t('createEditProject@termsConditions')}
            </Link>
          </Checkbox>
          <Checkbox
            {...register('privacy_policy', { required: true })}
            isInvalid={!!errors.privacy_policy}
          >
            {t('createEditProject@haveRead')}&nbsp;
            <Link
              style={{ color: '#ED8B00' }}
              target="_blank"
              to="/privacy-policy"
            >
              {t('createEditProject@privacyPolicy')}
            </Link>
          </Checkbox>
        </Flex>
      </Stack>
    </Box>
  )
}

export default ContactInformation
