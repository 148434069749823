import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

//TODO: add comment to convert to useGAPageView() hook
const GoogleAnalyticsWrapper = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
  }, [location.pathname])

  return <Box>{children}</Box>
}

export default GoogleAnalyticsWrapper
