import React from 'react'
import {
  Heading,
  Text,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import useForgetPassword from './../hooks/auth/useForgetPassword'
import useCustomToast from 'hooks/useCustomToast'

const ResetPasswordModal = ({ setModal }) => {
  const toast = useCustomToast()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  const { forgetPassword, isLoading, data, error } = useForgetPassword()

  const onRestore = (data) => {
    if (isLoading) return
    forgetPassword(data.email, {
      onError: (e) => {
        toast({
          status: 'error',
          title: t(`forgotPassword@${e.code}`),
          isClosable: true,
          duration: 5000,
        })
      },
      onSuccess: () => {
        toast({
          status: 'success',
          title: t('forgotPassword@successMessage'),
          isClosable: true,
          position: 'bottom-right',
          duration: 5000,
        })
        setModal('resetVerify', data.email)
      },
    })
  }

  return (
    <>
      <ModalHeader marginTop="15px">
        <Heading fontSize="3xl" as="h2" fontWeight="extrabold">
          {t('forgotPassword@heading')}
        </Heading>
      </ModalHeader>
      <ModalCloseButton
        color="#DDD"
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
        id="reset_password_modal_close"
      />
      <ModalBody>
        <form onSubmit={handleSubmit(onRestore)}>
          <InputField
            size="lg"
            errorMessage={t('forgotPassword@emailErrorMessage')}
            label={t('forgotPassword@email')}
            isInvalid={!!errors.email}
            {...register('email', {
              required: true,
              pattern: /\S+@\S+\.\S+/,
            })}
            id="reset_password_modal_email"
          />
          <Button
            marginTop="2rem"
            w="full"
            variant="blue"
            type="submit"
            isLoading={isLoading}
            id="reset_password_modal_submit"
          >
            {t('forgotPassword@submit')}
          </Button>
        </form>
      </ModalBody>
      <ModalFooter justifyContent="center">
        <Text color="gray.800" fontSize="sm" fontWeight="semibold">
          {t('forgotPassword@haveAnAccount')}{' '}
          <Button
            fontSize="sm"
            color="orange.400"
            textTransform="initial"
            onClick={() => {
              setModal('signIn')
            }}
            id="reset_password_modal_signin"
          >
            {t('forgotPassword@signIn')}
          </Button>
        </Text>
      </ModalFooter>
    </>
  )
}

export default ResetPasswordModal
