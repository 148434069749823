import { FormControl, FormLabel, Input, Box, Text, useColorModeValue as mode } from '@chakra-ui/react';
import * as React from 'react';

export const InputField = React.forwardRef((props, ref) => {
	const {
		label,
		description = '',
		labelProps,
		whiteSpace,
		textarea,
		hidden,
		isInvalid,
		errorMessage,
		maxLength,
		char,
		height,
		formControlProps,
		size,
		...rest
	} = props;

	return (
		<FormControl position="relative" display={hidden ? 'none' : 'block'} {...formControlProps}>
			{isInvalid && (
				<Box top="calc(100% + 5px)" fontSize="sm" fontWeight="semibold" position="absolute" color="red.400">
					{errorMessage}
				</Box>
			)}
			{label && (
				<FormLabel
					fontWeight={!description ? 400 : 700}
					fontSize={size || 'xl'}
					bg={{
						base: mode('gray.100', 'gray.800'),
						md: mode('gray.100', 'gray.700')
					}}
					zIndex={2}
					{...labelProps}
				>
					{label}
					{description && (
						<Text fontSize="sm" fontWeight={400} mt={1} mb={4}>
							{description}
						</Text>
					)}
				</FormLabel>
			)}
			<Input
				_focus={{ borderColor: 'blue.400' }}
				_hover={{ borderColor: 'blue.400' }}
				sx={{ boxShadow: 'none !important' }}
				maxLength={maxLength}
				isInvalid={isInvalid}
				hidden={hidden}
				borderColor="gray.600"
				borderWidth="1px"
				ref={ref}
				fontSize="lg"
				{...rest}
			/>
		</FormControl>
	);
});

InputField.displayName = 'InputField';
InputField.defaultProps = {
	formControlProps: {}
};
