import { useState } from 'react'
import {
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Box,
  Input,
  Text,
} from '@chakra-ui/react'
import { RadioGroup } from './RadioGroup'
import { useTranslation } from 'contexts/TranslationContext'
import PerkCard from 'components/PerkCard'
import OneTimeRecurring from 'pages/Donation/OneTimeRecurring'
import {
  useCurrencySettings,
  thousandSeparator,
} from 'contexts/CurrencySettingsContext'
import CurrencySwitcher from 'components/CurrencySwitcher'

const DonatePart = ({
  PRICE,
  donation,
  setValue,
  amountInputHeading,
  other,
  perk,
  handleAmount,
  watch,
  project,
  isContribution,
}) => {
  const { t } = useTranslation()
  const [showInputZero, setShowInputZero] = useState(false)
  const { currency } = useCurrencySettings()

  return (
    <Stack w="full" mt="12" spacing="8">
      <Box w="full">
        {perk !== null ? (
          <>
            <Box mb="1" as="span" fontWeight="500" fontSize="xl">
              {t('donation@perk')}
            </Box>
            <PerkCard
              title={perk.title ?? ''}
              price={perk.price ?? 0}
              image={perk.imageKey ?? perk.image}
              date={new Date()}
              isSelectable={false}
              convertPrice={false}
            />
          </>
        ) : (
          <>
            <FormControl>
              <FormLabel
                fontSize="xl"
                fontWeight="bold"
                color="gray.800"
                bg="gray.100"
                zIndex={2}
              >
                {amountInputHeading
                  ? amountInputHeading
                  : t('Enter your donation')}
              </FormLabel>
              {currency.current !== 'AMD' && (
                <Text fontSize="sm" fontWeight="medium" mb="2">
                  {t('donation@note')}{' '}
                </Text>
              )}
              <InputGroup alignItems="center">
                <InputLeftElement
                  top="unset"
                  width="90px"
                  children={
                    <CurrencySwitcher
                      onChange={() => {
                        setValue('amount', 0, '')
                      }}
                      showDetails={false}
                      isDonation
                    />
                  }
                />
                <Input
                  borderRadius="12px"
                  _focus={{
                    boxShadow: 'none',
                  }}
                  borderColor="border.100"
                  maxLength="12"
                  _hover={{
                    borderColor: 'blue.300',
                  }}
                  pattern="[0-9,]*"
                  value={
                    showInputZero ? '' : thousandSeparator(donation.amount)
                  }
                  onFocus={(e) => setShowInputZero(e.target.value === '0')}
                  onBlur={() => setShowInputZero(false)}
                  onChange={(e) => {
                    setShowInputZero(!e.target.value)
                    setValue(
                      'amount',
                      e.target.value.replace(/[^0-9]|^0+(?!$)/g, '')
                    )
                  }}
                  pl="6.5rem"
                  textAlign="right"
                  fontWeight="extrabold"
                  fontSize="3xl"
                  height="86px"
                />
              </InputGroup>
            </FormControl>
          </>
        )}
      </Box>

      {!isContribution && (
        <>
          <FormControl>
            <FormLabel
              fontSize="xl"
              fontWeight="bold"
              color="gray.800"
              bg="gray.100"
              zIndex={2}
            >
              {t('donation@donateToReArmenia')}
            </FormLabel>
            <Text mb="5" color="gray.700" fontSize="md">
              {t('donation@donateToReArmeniaFee')}
            </Text>
            <RadioGroup
              width="100%"
              name="amount"
              price={perk !== null ? perk.price : donation.amount}
              defaultValue="20$"
              options={PRICE}
              onChange={handleAmount}
              borderRadius="12px"
            />
          </FormControl>
          {other && (
            <FormControl>
              <FormLabel
                fontSize="xl"
                fontWeight="semibold"
                color="black"
                bg="gray.100"
                zIndex={2}
              >
                {t('donation@other')}
              </FormLabel>
              <InputGroup alignItems="center">
                <InputLeftElement
                  top="unset"
                  width="100px"
                  pointerEvents="none"
                  children={
                    <Box as="span" fontSize="xl">
                      ({currency.current})
                    </Box>
                  }
                />
                <Input
                  _focus={{
                    boxShadow: 'none',
                  }}
                  _hover={{
                    borderColor: 'blue.300',
                  }}
                  maxLength="12"
                  borderColor="border.100"
                  value={thousandSeparator(
                    donation.reArmenia,
                    currency.current
                  )}
                  pattern="[0-9,]*"
                  onChange={(e) =>
                    setValue(
                      'reArmenia',
                      e.target.value.replace(/[^0-9]|^0+(?!$)/g, '')
                    )
                  }
                  pl="6.5rem"
                  textAlign="right"
                  fontSize="xl"
                  height="60px"
                />
              </InputGroup>
            </FormControl>
          )}
        </>
      )}
      {project.fundraisingType === 'recurring' && (
        <Box>
          <Box
            display="inline-block"
            mb="5"
            as="span"
            fontSize="xl"
            fontWeight="bold"
            color="gray.800"
            bg="gray.100"
            zIndex={2}
          >
            {t('Select Donation Type')}
          </Box>
          <OneTimeRecurring
            paymentType={watch('paymentType')}
            setValue={setValue}
            price={thousandSeparator(donation.amount, currency.current)}
          />
        </Box>
      )}
    </Stack>
  )
}

export default DonatePart
