import React from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const ContactInfo = () => {
  const { t } = useTranslation()

  return (
    <Flex
      mt="2rem"
      pb="8rem"
      pl="6"
      flexDirection="column"
      borderStyle="solid"
      borderColor="rgba(0, 0, 0, 0.2)"
      flex="1"
    >
      <Heading fontSize="xl" fontWeight="bold" as="h4">
        {t('Phone')}
      </Heading>
      <Text mb="8" fontSize="4xl" as="span">
        +374 77 110863
      </Text>
      <Heading fontSize="xl" fontWeight="bold" as="h4">
        {t('Email')}
      </Heading>
      <Box mb="8" fontSize="4xl" href="mailto:info@armenianpeace.am" as="a">
        info@armenianpeace.am
      </Box>
    </Flex>
  )
}

export default ContactInfo
