import { useMutation } from 'react-query'
import { projectUseCase } from '../../core/factories'

const useUpdateProject = () => {
  const {
    mutate: updateProject,
    data,
    isLoading,
  } = useMutation(projectUseCase.updateProject)

  return {
    updateProject,
    isLoading,
    data,
  }
}

export default useUpdateProject
