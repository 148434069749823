import React from 'react'
import { Box, Container, Text, Img } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import MobileThankYouSubscribe from './Mobile'
import { MainLayout } from 'components/Layouts'
import { useQueryParams } from 'hooks'

const w = window.innerWidth

const ThankYouActNow = () => {
  const { t } = useTranslation()
  const queryParams = useQueryParams()
  const description = t(
    'We are so glad that you are interested in collaborating with Armenian Peace on (project name)! With such effective collaboration  project success is guaranteed!'
  ).replace('(project name)', queryParams.get('projectTitle'))
  if (w < 481) {
    return <MobileThankYouSubscribe description={description} />
  }

  return (
    <MainLayout>
      <Container pt="10" maxW="6xl">
        <Img src="/assets/images/thankYou.jpg" width="300px" minH="218px" />
        <Box mb="2rem" fontSize={{ base: '1.5rem', sm: '2rem' }}>
          {t('Thank you for collaborating')}
        </Box>
        <Text fontSize="lg">{description}</Text>
      </Container>
    </MainLayout>
  )
}

export default ThankYouActNow
