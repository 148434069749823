const donationUseCase = (donationRepo) => {
  const payment = (params) => donationRepo.payment(params)
  const checkOrderStatus = (params) => donationRepo.checkOrderStatus(params)
  return {
    payment,
    checkOrderStatus
  }
}

export default donationUseCase
