import { useMutation } from 'react-query'
import { initiativeUseCase } from '../../core/factories'

const useUpdateInitiative = () => {
  const { mutate: updateInitiative, isLoading } = useMutation(
    initiativeUseCase.updateInitiative
  )

  return {
    updateInitiative,
    isLoading,
  }
}

export default useUpdateInitiative
