import { useMutation } from 'react-query'
import useUser from '../user/useUser'
import { userUseCase } from '../../core/factories'
import { guestUser } from '../../contexts/UserContext/UserProvider'

const useSignOut = () => {
  const { setUser } = useUser()

  const { mutate } = useMutation(userUseCase.signOut)

  const signOut = () => {
    setUser((user) => ({ ...user, user: guestUser }))
    mutate()
  }

  return signOut
}

export default useSignOut
