import moment from 'moment'

export const normalizeDraft = (draft) => {
  let result = {}
  for (const [key, value] of Object.entries(draft)) {
    if (value || key === 'isProject' || key === 'isCollaboration') {
      result[key] = value
    }
  }
  return result
}

export const normalizeInput = (draft) => {
  const result = normalizeDraft(draft)

  if (result.startDate) {
    result.startDate = moment(result.startDate).format('MM.DD.YYYY')
  }
  if (result.endDate) {
    result.endDate = moment(result.endDate).format('MM.DD.YYYY')
  }
  return result
}

export const normalizeResponse = (draft) => {
  const result = normalizeDraft(draft)

  if (result.files && typeof result.files === 'string') {
    try {
      result.files = JSON.parse(result.files)
    } catch (err) {
      console.log(err)
      result.files = []
    }
  }
  if (result.startDate) {
    result.startDate = new Date(result.startDate)
  }
  if (result.endDate) {
    result.endDate =new Date(result.endDate)
  }
  return result
}

export const normalizeListResponse = (response) => {
  let drafts = response.data.draftsByUserId
  return {
    ...drafts,
    items: drafts.items.map((item) => ({
      ...item,
      status: 'draft',
      formattedCreationDate: moment(item.createdAt).format('DD.MM.YYYY'),
    })),
  }
}
