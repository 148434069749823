import React, { useState } from 'react'
import { Heading, Box, Button, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Auth } from 'aws-amplify'
import { useTranslation } from 'contexts/TranslationContext'
import AuthWrapper from 'components/AuthWrapper'
import { Link, useNavigation } from 'pages'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { PasswordField } from 'components/PasswordField'
import useCustomToast from 'hooks/useCustomToast'
import Mobile from './Mobile'

const w = window.innerWidth

const ChangePassword = () => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  const [loading, setLoading] = useState(false)
  const { navigationPush } = useNavigation()
 const toast = useCustomToast()

  const submit = (data) => {
    setLoading(true)
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, data.password, data.newPassword)
      })
      .then(() => {
        toast({
          status: 'success',
          title: t('changePassword@success'),
          isClosable: true,
          duration: 5000,
        })
        navigationPush('/profile/settings')
      })
      .catch((err) => {
        setLoading(false)
        if (
          err.code === 'InvalidPasswordException' ||
          err.code === 'InvalidParameterException'
        ) {
          toast({
            status: 'error',
            title: t('changePassword@passwordRequirements'),
            isClosable: true,
            duration: 5000,
          })
        } else if (err.code === 'LimitExceededException') {
          toast({
            status: 'error',
            title: t('changePassword@limitExceeded'),
            isClosable: true,
            duration: 5000,
          })
        } else {
          toast({
            title: t('changePassword@incorrectPassword'),
            status: 'error',
            duration: 5000,
            position: 'top',
          })
        }
      })
  }

  if( w < 481 ) {
    return <Mobile
    register={register}
    errors={errors}
    handleSubmit={handleSubmit}
    submit={submit}
    loading={loading}
    />
  }

  return (
    <AuthWrapper
      ceoCompany="CEO of Kamala"
      ceoName="Samvel Papoyan"
      ceoDescription="“reArmenia is short description Though I’ll stop short of calling it a rule lorem ipsum`.”"
      url="/assets/images/auth.jpg"
    >
      <Box w="full" maxW="lg">
        {/* <Button display={{base: 'none', sm: 'inline-flex'}} position="absolute" top="20px" left="20px" color="black" variant="transparent" as={Link} to="/">
                    <Logo/>
                </Button> */}
        <Link to="/profile/settings">
          <Button
            display={{ base: 'inline-flex', md: 'none' }}
            position="absolute"
            top="20px"
            right="20px"
            variant="transparent"
          >
            <Close width="20px" />
          </Button>
        </Link>
        <Heading
          as="h1"
          fontSize="5xl"
          fontWeight="extrabold"
          letterSpacing="tight"
          textAlign="center"
        >
          {t('changePassword@heading')}
        </Heading>

        <Box
          minW={{ md: '350px' }}
          rounded="xl"
          bg={{ md: "gray.100" }}
          pt={{ base: '8', md: '12' }}
          pb="8"
        >
          <form onSubmit={handleSubmit(submit)}>
            <Stack spacing="6">
              <PasswordField
                size="lg"
                isInvalid={!!errors.password}
                errorMessage={t('changePassword@oldPasswordErrorMessage')}
                {...register('password', { required: true })}
                label={t('changePassword@oldPassword')}
              />
              <PasswordField
                size="lg"
                errorMessage={t('changePassword@newPasswordErrorMessage')}
                isInvalid={!!errors.newPassword}
                {...register('newPassword', { required: true })}
                label={t('changePassword@newPassword')}
              />
              <Button
                isLoading={loading}
                type="submit"
                variant="blue"
                size="lg"
                fontSize="md"
              >
                {t('changePassword@submit')}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </AuthWrapper>
  )
}

export default ChangePassword
