import { useCallback, useEffect, useState } from 'react'
import { Flex, Checkbox, Box, Select, Button } from '@chakra-ui/react'
import { useUser, useUpdateProject } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import StatusMenu from 'components/StatusMenu'
import { Link } from 'pages'

export default function TypeCheckboxGroup({ project }) {
  const { t } = useTranslation()
  const { user } = useUser()
  const { updateProject, isLoading: isUpdateLoading } = useUpdateProject()
  const [data, setData] = useState()

  const onUpdateProject = useCallback(
    (key, value) => {
      if (!project) return
      setData((data) => ({ ...data, [key]: value }))
      let update = {
        id: project.id,
        [key]: value,
      }
      if (value === 'live') {
        update.publishedAt = new Date().toISOString()
      }
      updateProject(update, {
        onSuccess: (response) => setData(response.data.updateProject),
      })
    },
    [project]
  )

  useEffect(() => {
    if (!project) return
    setData(project)
  }, [project])

  if (!user.isSuper || !data) {
    return <></>
  }

  return (
    <Box my="4">
      <Flex mb="6">
        <Select
          variant="unstyled"
          maxW="120px"
          value={data.sequence}
          onChange={(e) => onUpdateProject('sequence', e.target.value)}
          placeholder="Sequence"
        >
          {[1, 2, 3, 4, 5].map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        <Checkbox
          mr="4"
          isDisabled={isUpdateLoading}
          isChecked={data.popular === 'true'}
          colorScheme="green"
          onChange={() =>
            onUpdateProject(
              'popular',
              data.popular === 'true' ? 'false' : 'true'
            )
          }
        >
          {t('popular')}
        </Checkbox>
        <Checkbox
          mr="4"
          isDisabled={isUpdateLoading}
          isChecked={data.isUrgent}
          colorScheme="red"
          onChange={() =>
            onUpdateProject('isUrgent', data.isUrgent ? false : true)
          }
        >
          {t('urgent')}
        </Checkbox>
        <Checkbox
          isDisabled={isUpdateLoading}
          isChecked={data.featured === 'true'}
          colorScheme="blue"
          onChange={() =>
            onUpdateProject(
              'featured',
              data.featured === 'true' ? 'false' : 'true'
            )
          }
        >
          {t('featured')}
        </Checkbox>
      </Flex>
      <Flex>
        <StatusMenu
          type="fundraisers"
          card={data}
          onValueChange={async (status) => {
            onUpdateProject('status', status)
          }}
        />
        <Link
          to={`/edit-${project.isProject ? 'fundraisers' : 'collaborations'}/${
            project.id
          }/content`}
        >
          <Button ml={4} color="orange.400" variant="transparent">
            {t('edit')}
          </Button>
        </Link>
      </Flex>
    </Box>
  )
}
