import React from 'react'
import { Flex, Link as ChakraLink } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { MOBILE_DATA } from '../constants'

const MobileNavbar = ({ from }) => {
  const { language, t } = useTranslation()
  return (
    <Flex
      zIndex="1000"
      bg="gray.100"
      w="full"
      h="50px"
      bottom="0"
      left="0"
      borderTop="1px solid"
      borderColor="border.100"
      justifyContent="space-between"
      alignItems="center"
      px="1.875rem"
      position="fixed"
    >
      {MOBILE_DATA.map(({label, icon, text, route}) => (
        <ChakraLink
          key={label}
          display="inline-flex"
          flexDirection="column"
          alignItems="center"
          fontWeight="700"
          fontSize={language === "en" ? "10px" : "9px"}
          color={from === label ? "blue.300" : "gray.700"}
          as={Link}
          to={route}
          >
            {from === label ? icon.active : icon.inactive}
            {t(text)}
        </ChakraLink>
      ))}
    </Flex>
  )
}

export default MobileNavbar
