import React from 'react'
import {
  Box,
  useRadio,
} from '@chakra-ui/react'

const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box
      flex="1"
      as="label">
      <input {...input} />
      <Box
        textAlign="center"
        {...checkbox}
        cursor="pointer"
        boxShadow="md"
        _checked={{
          bg: "blue.300",
          color: "gray.100",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py="9px"
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default RadioCard
