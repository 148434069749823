import React, { useState } from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser } from 'hooks'
import { Stack, Heading, Text, Button, Flex } from '@chakra-ui/react'
import { Link, useNavigation } from 'pages'
import InviteModal from '../../../components/InviteModal'
import Remove from '../../../components/CreditCards/Remove'
import { useModal } from 'providers/ModalProvider'

const Join = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { openModal } = useModal()
  const { navigationPush } = useNavigation()
  return (
    <Flex
      pt={{ base: 24, sm: 4 }}
      pb={6}
      px="4"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading
        as="h1"
        mb="3"
        fontSize="4xl"
        fontWeight="600"
        textAlign="center"
      >
        {t('home@title')}
      </Heading>
      <Text
        maxW="900px"
        mb="5"
        textAlign="center"
        color="gray.700"
        fontSize="lg"
      >
        {t(`home@text`)}
      </Text>
      <Flex direction="row">
        <Button
          variant="blue"
          as={Link}
          to={`/fundraisers?type=oneTime`}
          width={{ base: '175px', sm: '250px' }}
        >
          {t('donate')}
        </Button>
        <Button
          onClick={() => {
            if (user.id === 'guest') {
              openModal('signIn', '/pre-create-project')
              return
            }
            navigationPush('/pre-create-project')
          }}
          variant="border"
          id="homepage_create_button"
          width={{ base: '175px', sm: '250px' }}
          ml={{ base: 2, sm: 4 }}
        >
          {t('Create Project')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default Join
