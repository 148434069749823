import { API, graphqlOperation } from 'aws-amplify'
import { listSettingss as listSettingsQuery } from '../../graphql/queries'

const settingsService = () => {
  const getSettings = async (id) => {
    const settings = await API.graphql(
      graphqlOperation(listSettingsQuery, { limit: 1 })
    )

    return settings.data.listSettingss.items[0]
  }

  return {
    getSettings,
  }
}

export default settingsService
