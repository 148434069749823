import React from 'react'
import Content from 'components/Content'
import { useInitiative } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'

const Story = () => {
  const { initiative } = useInitiative()
  const { language } = useTranslation()
  return <Content html={initiative[`content_${language}`]} />
}

export default Story
