import { Text } from '@chakra-ui/react'
import { convert, thousandSeparator } from 'contexts/CurrencySettingsContext'

export default function AmountWithCurrency({
  currencyRate = 1,
  amount,
  currency = 'AMD',
  ...props
}) {
  return (
    <Text minWidth="max-content" as="span" {...props}>
      {thousandSeparator(convert({ amount, currencyRate }), currency)}&nbsp;
      {currency}
    </Text>
  )
}
