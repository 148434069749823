import React from 'react'
import { Flex, Button, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser } from 'hooks'
import { useNavigation } from 'pages'

export default function ActNow({ initiative }) {
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const { user } = useUser()

  return (
    <>
      <Flex
        zIndex="1000"
        bg="gray.100"
        w="full"
        bottom="0"
        left="0"
        borderTop="1px solid"
        borderColor="border.100"
        justifyContent="space-between"
        pt="3"
        pb="2.125rem"
        px="3"
        position="fixed"
        spacing="3"
      >
        <Button
          width="full"
          variant="blue"
          onClick={() =>
            user.id === 'guest'
              ? navigationPush('/sign-in')
              : navigationPush(`/act-now?InitiativeId=${initiative?.id}`)
          }
        >
          {t('initiative@actNow')}
        </Button>
      </Flex>
    </>
  )
}
