import React from 'react'
import { Flex, Heading, Text, IconButton, Button } from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { useTranslation } from 'contexts/TranslationContext'
import { useHistory } from 'react-router-dom'
import { Link } from 'pages'

const w = window.innerWidth

const Mobile = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <MainLayout>
      <Flex bg="blue.400" justifyContent="center" alignItems="center" py="10px">
        <IconButton
          width="15px"
          onClick={() => history.goBack()}
          pos="absolute"
          left={0}
        >
          <Close width="15px" fill="white" />
        </IconButton>
        <Heading fontWeight={600} fontSize="xl" color="gray.100">
          {t('Create Project')}
        </Heading>
      </Flex>
      <Flex mt="125px" flexDir="column" px="30px" alignItems="center">
        <Heading fontSize="2xl" textAlign="center">
          {t('preCreateMobile@heading')}
        </Heading>
        <Text mt="16px" mb="24px" textAlign="center">
          {t('preCreateMobile@text')}
        </Text>
        <Link to="/fundraisers">
          <Button px="6" variant="blue">
            {t('fundraisers')}
          </Button>
        </Link>
      </Flex>
    </MainLayout>
  )
}

export default Mobile
