import { useState, useCallback } from 'react'
import { useUser } from 'hooks'
import Editor from 'components/Editor'
import DatePicker from 'components/Date'
import './editor.scss'

export default function FormData({ update, lang, updateRef }) {
  const [data, setData] = useState(update)
  const { isAdmin } = useUser()

  const onDateChange = useCallback((date) => {
    setData((data) => ({ ...data, createdAt: date }))
    updateRef.current.createdAt = date
  }, [])

  const onDataChange = useCallback((value) => {
    setData((data) => ({ ...data, [`updates_${lang}`]: value }))
    updateRef.current[`data_${lang}`] = value
  }, [])
  
  return (
    <>
      {isAdmin && (
        <DatePicker
          mb={4}
          showTimeSelect={true}
          startDate={new Date(data.createdAt)}
          setStartDate={onDateChange}
        />
      )}
      <Editor
        key={`update-editor-${update.id}}`}
        content={data[`data_${lang}`]}
        onValueUpdate={onDataChange}
      />
    </>
  )
}
