import React from 'react'
import { Container, Flex } from '@chakra-ui/react'
import ContactInfo from './ContactInfo'
import Fields from './Fields'
import { Footer } from 'components/Footer'
import MobileContactUs from './MobileContactUs'
import { MainLayout } from 'components/Layouts'
import { useTranslation } from 'contexts/TranslationContext'
import MetaTags from 'components/MetaTags'

const w = window.innerWidth

const ContactUs = () => {
  const { t } = useTranslation()
  if (w < 481) {
    return (
      <>
        <MetaTags
          url={window.location.href}
          title={t('meta@contact@title')}
          description={t('meta@contact@description')}
        />
        <MobileContactUs />
      </>
    )
  }
  return (
    <>
      <MetaTags
        url={window.location.href}
        title={t('meta@contact@title')}
        description={t('meta@contact@description')}
      />
      <MainLayout>
        <Container px={{ base: '6', md: '8' }} maxW="8xl">
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            pt="3rem"
            pb="5rem"
          >
            <Fields />
            <ContactInfo />
          </Flex>
        </Container>
        <Footer />
      </MainLayout>
    </>
  )
}

export default ContactUs
