import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, A11y } from 'swiper'
import {
  Box,
  Flex,
  Heading,
  Text,
  SkeletonText,
  Skeleton,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'

// Import Swiper styles
import 'swiper/swiper.scss'

SwiperCore.use([Navigation, A11y])

export default function MobileFeatureSwiper ({ projects, isLoading, type }) {
  const [active, setActive] = useState(1)
  const [feature, setFeature] = useState()
  const { t, language } = useTranslation()

  useEffect(() => {
    if (projects && projects[0]) {
      setFeature({
        id: projects[active - 1]?.id,
        summary: projects[active - 1][`tagline_${language}`],
        slug: projects[active - 1].slug,
        title: projects[active - 1][`title_${language}`],
        type: projects[active - 1]?.isProject ? 'fundraisers' : 'collaborations' 
      })
    }
  }, [projects, active, language])

  if (projects?.length < 1) return <></>
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton mb="4" w="full" height="200px" />
          <Box mb="10" px="5">
            <Skeleton w="full" mb="2" height="29px" />
            <SkeletonText noOfLines={2} spacing="2" />
          </Box>
        </>
      ) : (
        <Link style={{ flex: 1 }} to={`/${feature?.type}/${feature?.slug || feature?.id}`}>
          <Swiper
            style={{ width: '100%', height: '200px', position: 'relative' }}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            onSlideChange={(swiper) => setActive(swiper.activeIndex + 1)}
          >
            {projects?.map((slide, index) => (
              <SwiperSlide key={index}>
                <Flex
                  id="image-wrapper"
                  insetX="0"
                  insetY="0"
                  w="full"
                  h="full"
                >
                  <Box
                    w="full"
                    h="full"
                    bgImage={`url(${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/small/${slide.cover})`}
                    bgSize="cover"
                    bgPos="center"
                    bgRepeat="no-repeat"
                  />
                  <Heading
                    position="absolute"
                    bottom="12px"
                    left="20px"
                    color="gray.100"
                    fontSize="md"
                    as="h2"
                    fontWeight="700"
                    textTransform="uppercase"
                  >
                    {t('featured')}
                  </Heading>
                </Flex>
              </SwiperSlide>
            ))}
            <Flex
              position="absolute"
              bottom="0"
              right="0"
              alignItems="center"
              justifyContent="center"
              w="64px"
              height="40px"
              background="black"
              zIndex="100"
            >
              <Box as="span" color="gray.100" fontSize="md">
                {active} /&nbsp;
              </Box>
              <Box as="span" color="gray.100" fontSize="md">
                {projects?.length}
              </Box>
            </Flex>
          </Swiper>
          <Flex w="full" zIndex={5} minH="215px">
            <Flex
              flex="1"
              flexWrap="wrap"
              justifyContent="space-between"
              maxW="full"
            >
              <Flex
                maxW="full"
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                px="5"
                pt="4"
                pb="10"
              >
                <Box maxW="full">
                  <Heading
                    color="black"
                    fontSize="3xl"
                    as="h2"
                    fontWeight="700"
                    noOfLines={3}
                  >
                    {feature?.title}
                  </Heading>
                  <Text 
                    mt="2"
                    color="gray.700"
                    fontSize="md"
                    noOfLines={3}
                  >
                    {feature?.summary}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Link>
      )}
    </>
  )
}
