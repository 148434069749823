import { useEffect, useState, memo } from 'react'
import {
  Spinner,
  GridItem,
  SimpleGrid,
  Heading,
  Flex,
  Box,
} from '@chakra-ui/react'
import _ from 'lodash'
import AllProductsWrapper from 'components/AllProductsWrapper'
import ProjectCard from 'components/ProjectCard'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
} from 'contexts/CurrencySettingsContext'
import {
  useUser,
  useQueryParams,
  useProjects,
  calculateProjectDurationLeft,
} from 'hooks'
import { useQueryClient } from 'react-query'
import { useInView } from 'react-intersection-observer'
import MobileFundraisers from './MobileFundraisers'
import MetaTags from 'components/MetaTags'
import { useNavigation } from 'pages'
import { projectsQuery } from 'elastic/queries'
import ProjectList from 'components/ProjectList'

const w = window.innerWidth

const getProjectsFromCache = (queryParams) => {
  const key = queryParams.get('type') || 'oneTime'
  const cachedProjects = localStorage.getItem(`projects_fundraisers_${key}`)
  let parsedProjects = []
  try {
    parsedProjects = JSON.parse(cachedProjects)
  } catch (e) {
    console.log(e)
  }
  return parsedProjects
}

const Projects = ({ items, refetch, total, updateFilters }) => {
  const queryClient = useQueryClient()
  const queryParams = useQueryParams()
  const { user } = useUser()
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const [isLoading, toggleLoading] = useState(false)
  const [projects, setProjects] = useState([])
  const [filters, setFilters] = useState({
    region: queryParams.get('region'),
    category: queryParams.get('category'),
    text: queryParams.get('text'),
    priceMax: queryParams.get('priceMax'),
    duration: queryParams.get('duration'),
    type: queryParams.get('type'),
  })

  const onLoadMore = () => {
    toggleLoading(true)
    refetch()
  }

  /* new projects fetched */ useEffect(() => {
    setProjects((projects) => {
      if (!projects) {
        return items
      }
      return _.uniqBy([...projects, ...items], 'id')
    })
    toggleLoading(false)
  }, [items])

  /* update URL params */ useEffect(() => {
    projects?.map((item) =>
      queryClient.setQueryData(['project_slug', item.slug], item)
    )
    let queryParams = ''
    for (const [key, value] of Object.entries(filters)) {
      if (value !== null) {
        queryParams += `&${key}=${value}`
      }
    }
    window.history.pushState(
      {},
      '',
      window.location.pathname + queryParams.replace('&', '?')
    )
  }, [projects, filters])

  /* filters updated */ useEffect(() => {
    if (projects.length === 0 || filters.category !== null) {
      toggleLoading(true)
      setProjects([])
      updateFilters(filters.type, filters.category)
      return
    }
    if (projects.length === 0 || projects[0].fundraisingType === filters.type) {
      return
    }
    const cachedProjects = getProjectsFromCache(queryParams)
    if (!cachedProjects || cachedProjects.length === 0) {
      toggleLoading(true)
      setProjects([])

      updateFilters(filters.type, filters.category)
      return
    }
    setProjects(cachedProjects)
    updateFilters(filters.type, filters.category)
  }, [filters])

  useEffect(() => {
    /* set projects from cache */
    const cachedProjects = getProjectsFromCache(queryParams)
    if (!cachedProjects || cachedProjects.length === 0) {
      toggleLoading(true)
      return
    }
    setProjects(cachedProjects)
  }, [])

  if (w < 481) {
    return (
      <>
        <MetaTags
          title={t('meta@funds@title')}
          description={t('meta@funds@description')}
          url={window.location.href}
        />
        <MobileFundraisers
          total={total}
          isLoading={isLoading}
          projects={projects}
          setFilters={setFilters}
        />
      </>
    )
  }
  return (
    <>
      <MetaTags
        title={t('meta@funds@title')}
        description={t('meta@funds@description')}
      />
      <AllProductsWrapper
        type="fundraisers"
        filters={filters}
        setFilters={setFilters}
        url="/assets/images/project.jpg"
      >
        {/*
        1. THIS LOADING IS BEING SHOW ONLY ONE TIME, BY THE FIRST
        2. IF REQUEST MADE BUT WE HAVE CACHED PROJECTS WE HIDE LOADING
      */}
        {isLoading && (!projects || projects?.length === 0) ? (
          <Flex minH="520px" w="full" align="center" justifyContent="center">
            <Spinner color="blue.400" />
          </Flex>
        ) : (
          <Box minH="720px" pb={10} position="relative">
     
              {projects?.length > 0 ? (
                <ProjectList
                  fetchMore={onLoadMore}
                  hasMore={total > projects?.length}
                  length={projects.length}
                >
                  {_.uniqBy(projects, 'id').map((card) => (
                    <GridItem key={`project-card-${card.id}`} colSpan={1}>
                      <ProjectCard
                        goalText={t('goal')}
                        card={card}
                        goalPrice={card.activeGoal?.amount || card.goal}
                        raisedMoney={CurrencyConverter({
                          currency: currency.current,
                          amount: parseInt(card.amount),
                        })}
                        CurrencyConverter={CurrencyConverter}
                        thousandSeparatorText={thousandSeparator(
                          currency.current === 'AMD'
                            ? card.amount + Number(card.updateAmount)
                            : card.amount /
                                Number(settings?.currency[currency.current]) +
                                Number(card.updateAmount),
                          currency.current
                        )}
                        SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                        type="fundraisers"
                        calculatedProjectDurationLeft={calculateProjectDurationLeft(
                          {
                            t,
                            campaignImplementorPeriod_en:
                              card.campaignImplementorPeriod_en,
                            fundraisingType: card.fundraisingType,
                          }
                        )}
                        language={language}
                        currency={currency}
                        settings={settings}
                        changeDefaultCurrency={changeDefaultCurrency}
                        navigationPush={navigationPush}
                        user={user}
                        cardStatusText={t(`status@${card.status}`)}
                        cardCategoryText={t(`category@${card.category}`)}
                        editText={t('edit')}
                        unsubscribeText={t('unsubscribe')}
                        collaborationProposalsText={t(
                          card?.collaboratorsTotal === 1
                            ? 'collaboration proposal'
                            : 'collaboration proposals'
                        )}
                        collaborationProposalsTextRuEdit={t(
                          'collaboration proposals'
                        )
                          .split('...')[1]
                          .replace('запросов', 'запроса')}
                        monthlyGoalText={t('monthlyGoal')}
                        raisedText={t('raised')}
                      />
                    </GridItem>
                  ))}
                </ProjectList>
              ) : (
                <Flex
                  top="0"
                  left="0"
                  position="absolute"
                  alignItems="center"
                  justifyContent="center"
                  h="full"
                  w="full"
                >
                  <Heading as="h2" fontSize="4xl" color="blue.400">
                    {t('noResults')}
                  </Heading>
                </Flex>
              )}
          </Box>
        )}
      </AllProductsWrapper>
    </>
  )
}

const withProjects =
  (Component) =>
  ({ ...props }) => {
    const queryParams = useQueryParams()
    const size = 12
    const { projects, isLoading, setFilters } = useProjects(
      projectsQuery(queryParams.get('type') || 'oneTime'),
      true,
      true,
      'fundraisers',
      'projects_fundraisers'
    )

    const refetch = () => {
      setFilters((filters) => {
        return {
          ...filters,
          from: (filters.from || 0) + size,
        }
      })
    }

    const updateFilters = (type, category) => {
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          ...projectsQuery(type, category),
          from: 0,
        }
      })
    }

    return (
      <Component
        {...props}
        updateFilters={updateFilters}
        items={projects?.items || []}
        cachedItems={projects?.cachedItems}
        total={projects?.total?.value || projects?.total}
        isLoading={isLoading}
        refetch={refetch}
      />
    )
  }

const areEqual = (prevProps, nextProps) => {
  if (!nextProps.isLoading) {
    // RE-RENDER
    return false
  }
  // PREVENT RENDER
  return true
}

export default withProjects(memo(Projects, areEqual))
