import { useState } from 'react'
import { API } from 'aws-amplify'
import { Link as ReactLink } from 'react-router-dom'
import {
  Stack,
  HStack,
  Box,
  Image,
  Button,
  Flex,
  Text,
  IconButton,
  Checkbox,
  Link,
  Radio,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser, useUpdateUser, useQueryParams } from 'hooks'
import { BANK_CARD_TYPES } from '_constants'
import CreditCardItem from './CreditCardItem'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { ReactComponent as FrontCard } from 'assets/icons/card-front.svg'
import { ReactComponent as BackCard } from 'assets/icons/card-back.svg'

export default function CreditCards({
  page,
  removable,
  makeDefault,
  privacyPolicy,
  showIcons,
  selected,
  onClick,
  attachCard,
  setValue,
}) {
  const queryParams = useQueryParams()
  const { t, language } = useTranslation()
  const { user, setUser } = useUser()
  const { updateUser, isLoading } = useUpdateUser()
  const [read, toggleRead] = useState(true)
  const [error, setError] = useState(false)
  const [loading, toggleLoading] = useState(false)
  const [showSelectedCard, setShowSelectedCard] = useState(true)

  const onCardAdd = () => {
    if (loading) {
      return
    }
    if (!read) {
      return setError(true)
    }
    toggleLoading(true)

    let redirectUrl = `${window.location.protocol}//${window.location.host}/${language}/check-binding`

    if (page === 'donation') {
      redirectUrl += `?donationInfo=${queryParams.get(
        'projectId'
      )},${queryParams.get('amount')}`
    }

    const data = {
      body: {
        userEmail: user.email,
        description: `Dear ${user.firstName} ${user.lastName}, fill in your card details so we can attach a card to your profile. Transaction ID - `,
        redirectUrl,
        language,
      },
    }

    API.post('paymentAPI', '/binding', data)
      .then(
        ({ formUrl }) => (window.location = formUrl.replace('_binding', ''))
      )
      .catch(console.log)
      .finally(() => toggleLoading(false))
  }

  const onMakeDefault = (index) => {
    if (isLoading) return
    const newCreditCards = user.creditCards.map((creditCard, i) => ({
      ...creditCard,
      isDefault: i === index,
    }))

    updateUser(
      {
        id: user.id,
        creditCards: newCreditCards,
      },
      {
        onSuccess: (response) =>
          setUser((oldUser) => ({
            ...oldUser,
            user: response.data.updateUser,
          })),
      }
    )
  }

  const onCardClick = (creditCard, index) => () => {
    if (onClick) {
      setShowSelectedCard(true)
      return onClick(creditCard.bindingId)
    }

    onMakeDefault(index)
  }

  if (!removable && (user.id === 'guest' || !user.creditCards?.length)) {
    return <></>
  }

  return (
    <Wrapper
      showIcons={showIcons}
      error={error}
      read={read}
      privacyPolicy={
        privacyPolicy && (!user.creditCards || user.creditCards.length === 0)
      }
      toggleRead={toggleRead}
      setError={setError}
    >
      <Stack spacing={3}>
        {user.creditCards?.map((creditCard, index) => {
          const isSelected = selected
            ? creditCard.bindingId === selected
            : creditCard.isDefault

          return (
            <CreditCardItem
              key={`credit-card-${creditCard.pan}-${index}`}
              removable={removable}
              index={index}
              isSelected={isSelected && showSelectedCard}
              makeDefault={makeDefault}
              creditCard={creditCard}
              onClick={onClick}
              onCardClick={onCardClick}
            />
          )
        })}

        {removable ? (
          <>
            {user?.creditCards?.length ? (
              <Flex
                py={5}
                px={6}
                borderRadius={{ base: '12px', sm: '8px' }}
                borderWidth="1px"
                borderColor="border.500"
                alignItems="center"
                cursor="pointer"
                bg="border.500"
                onClick={onCardAdd}
              >
                <IconButton
                  w="24px"
                  h="24px"
                  minW="24px"
                  minH="24px"
                  borderRadius="100px"
                  icon={<Plus />}
                  variant="blue"
                  mr={4}
                />
                <Text color="gray.800" fontSize="md" fontWeight="700">
                  {t('profile@settings-payments-attach-new-card')}
                </Text>
              </Flex>
            ) : (
              <Button
                onClick={onCardAdd}
                variant="blue"
                borderRadius={{ base: '12px', sm: '8px' }}
                fontSize="md"
                fontWeight="700"
              >
                {t('profile@settings-payments-attach-card')}
              </Button>
            )}
          </>
        ) : (
          <>
            <CardSavingSelection
              Icon={BackCard}
              text={t('Attach a new card')}
              type="attach"
              isSelected={!selected && attachCard}
              onClick={() => {
                setValue('bindingId', '')
                setValue('attachCard', true)
                setShowSelectedCard(false)
              }}
            />
            <CardSavingSelection
              Icon={FrontCard}
              text={t('Donate with another card')}
              type="withoutAttaching"
              isSelected={!selected && !attachCard}
              onClick={() => {
                setValue('bindingId', '')
                setValue('attachCard', false)
                setShowSelectedCard(false)
              }}
            />
          </>
        )}
      </Stack>
    </Wrapper>
  )
}
const CardSavingSelection = ({ text, type, isSelected, Icon, ...props }) => (
  <Flex
    py={{ base: 4, sm: 5 }}
    px={6}
    borderRadius={{ base: '12px', sm: '8px' }}
    borderWidth="1px"
    borderColor={isSelected ? 'blue.300' : 'border.500'}
    position="relative"
    alignItems="center"
    justifyContent="space-between"
    cursor={isSelected ? 'default' : 'pointer'}
    _hover={{
      'button[datatype="remove"]': {
        opacity: 1,
      },
    }}
    {...props}
  >
    <Flex alignItems="center">
      <Icon />
      <Text ml="2" color="black.100" fontSize="md">
        {text}
      </Text>
    </Flex>
    <Flex alignItems="center">
      <Radio value="1" isChecked={isSelected}></Radio>
    </Flex>
  </Flex>
)
const Wrapper = ({
  privacyPolicy,
  read,
  toggleRead,
  error,
  setError,
  showIcons,
  children,
}) => {
  const { t } = useTranslation()

  const onChange = (e) => {
    toggleRead(e.target.value)
    if (e.target.value) {
      setError(false)
    }
  }

  return (
    <>
      {children}
      {showIcons && (
        <HStack
          mt={8}
          spacing={1}
          justifyContent={{ base: 'center', sm: 'flex-start' }}
        >
          {BANK_CARD_TYPES.map((bankCardType) => (
            <Image
              key={`bank-card-type-${bankCardType}`}
              src={`/assets/images/${bankCardType}.png`}
              alt={bankCardType}
              w="28px"
              h="20px"
            />
          ))}
        </HStack>
      )}
      {privacyPolicy && (
        <Checkbox
          mt={8}
          mb="1"
          value={read}
          borderColor={error ? 'red.400' : 'border.200'}
          onChange={onChange}
        >
          {t('profile@settings-payments-have-read')}&nbsp;
          <Link as={ReactLink} target="_blank" to="/privacy-policy">
            {t('profile@settings-payments-privacy-policy')}
          </Link>
        </Checkbox>
      )}
    </>
  )
}
