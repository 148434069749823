const userUseCase = (userRepo) => {
  const getUser = async () => userRepo.getUser()

  const getUserById = async (id) => userRepo.getUserById(id)

  const editEmail = async (email) => userRepo.editEmail(email)

  const verifyEmail = async (code) => userRepo.verifyEmail(code)

  const editUserInfo = async (userInfo) => userRepo.editUserInfo(userInfo)

  const changePassowrd = async (passwords) => userRepo.changePassowrd(passwords)

  const signOut = async () => userRepo.signOut()

  const updateUser = async (input) => userRepo.updateUser(input)

  return {
    getUser,
    getUserById,
    editEmail,
    verifyEmail,
    editUserInfo,
    changePassowrd,
    signOut,
    updateUser,
  }
}

export default userUseCase
