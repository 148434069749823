import React from 'react'
import Header from './Header'
import Trusters from './Trusters'
import CLevel from './CLevel'
import Advisory from './Advisory'
import Procedures from './Procedures'
import CoreTeam from './CoreTeam'
import Partners from './Partners'
import Supporters from './Supporters'
import Contact from './Contact'
import { Footer } from '../../components/Footer'
import MobileAbout from './MobileAbout'
import Strategic from './Strategic'
import { MainLayout } from 'components/Layouts'
import { useTranslation } from 'contexts/TranslationContext'
import MetaTags from 'components/MetaTags'
import NewAdvisory from './NewAdvisory'

const About = () => {
  const { t } = useTranslation()
  const w = window.innerWidth

  if (w < 481) {
    return (
      <>
        <MetaTags
          url={window.location.href}
          title={t('meta@about@title')}
          description={t('meta@about@description')}
        />
        <MobileAbout />
      </>
    )
  }
  return (
    <>
      <MetaTags
        url={window.location.href}
        title={t('meta@about@title')}
        description={t('meta@about@description')}
      />
      <MainLayout>
        <Header />
        {/* <Trusters /> */}
        {/* <CLevel /> */}
        {/* <Strategic /> */}
        {/* <Advisory /> */}
        {/* <NewAdvisory /> */}
        <Procedures />
        {/* <CoreTeam /> */}
        <Partners />
        {/* <Supporters /> */}
        <Contact />
        <Footer />
      </MainLayout>
    </>
  )
}

export default About
