const settingsUseCase = (settingsUseCase) => {
  const getSettings = async () => {
    const settings = await settingsUseCase.getSettings()

    if (settings?.currency) {
      settings.currency = JSON.parse(settings.currency)
    }

    return settings
  }

  return {
    getSettings,
  }
}

export default settingsUseCase
