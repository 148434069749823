import { API } from 'aws-amplify'

const ElasticService = () => {
  const getContentFromElastic = (condition) => {
    let environment = process.env.REACT_APP_ENV
    if (environment === 'local') {
      environment = 'development'
    }
    return API.post('elastic', '/proxy', {
      body: {
        index: `content_${environment}`,
        path: '_search',
        method: 'POST',
        data: condition,
      },
    })
  }
  return { getContentFromElastic }
}

export default ElasticService
