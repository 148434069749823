import { Helmet } from 'react-helmet'

export const DefaultHead = () => (
  <Helmet>
    <title>Armenian Peace</title>
    <meta name="description" content="Collaboration Platform" />
    <meta property="og:title" content="Armenian Peace" />
    <meta property="og:description" content="Collaboration Platform" />
    <meta
      property="og:image"
      content="https://armenianpeace.am/assets/images/homepage.jpg"
    />
  </Helmet>
)

export default function MetaTags({
  title,
  description,
  url,
  image = 'https://ap.am/ap_cover.png',
}) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:image" content={image} />
      <meta
        property="fb:app_id"
        content={process.env.REACT_APP_FACEBOOK_APP_ID}
      />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Armenian Peace" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta
        name="facebook-domainverification"
        content={process.env.REACT_APP_FACEBOOK_DOMAIN_VERIFICATION_KEY}
      />
      <meta property="og:image:alt" content={title} />
    </Helmet>
  )
}
