import { Flex, Box } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { ReactComponent as Arrow } from 'assets/icons/arrowRight.svg'
import Remove from '../Remove'
import { Routes, useProject } from '../ProjectContext'
import useRoutes from '../useRoutes'

const TAB_PROPS = {
  w: 'full',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: '4',
  py: '2',
  color: '#000000',
  fontWeight: '500',
}

export default function Tabs() {
  const { language } = useTranslation()
  const { project } = useProject()
  const location = useLocation()
  const routes = useRoutes({ status: project?.status })

  return (
    <>
      <Flex
        justifyContent="space-between"
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex
          align="flex-start"
          w="full"
          maxW={{ base: 'full', md: '217px' }}
          mr="20"
          py="4"
          height="100%"
          direction="column"
          mb="4"
          border="1px solid"
          borderColor="border.100"
          borderRadius="16px"
        >
          {routes.map(({ path, label, to }, index) => {
            const isActive = [
              `/${language}${to}`,
              `/${language}${to}/`,
            ].includes(location.pathname)
            return (
              <Link
                key={index}
                style={{ width: '100%' }}
                to={to}>
                <Box
                  {...TAB_PROPS}
                  key={`edit-project-tab-item-${path}`}
                  mb={index === 0 ? 3 : ''}
                  bg={isActive ? 'rgba(0,0,0, 0.1)' : 'transparent'}
                >
                  {label}
                  {isActive && <Arrow />}
                </Box>
              </Link>
            )
          })}
          <Remove tabProps={TAB_PROPS} />
        </Flex>
        <Box flex="1">
          <Routes status={project?.status} />
        </Box>
      </Flex>
    </>
  )
}
