import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import Amplify from 'aws-amplify'
import awsConfig from './aws-exports'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import {
  SIGNIN_REDIRECT_URI,
  SIGNOUT_REDIRECT_URI,
  GA_TRACKING_ID,
} from './config.js'
import App from './App'
import reportWebVitals from './reportWebVitals'

Amplify.configure({
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: SIGNIN_REDIRECT_URI,
    redirectSignOut: SIGNOUT_REDIRECT_URI,
  },
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// if (process.env.NODE_ENV === 'development') {
//   console.log(`GoogleAnalytics disabled in development mode`)
//   console.log(`Sentry disabled in development mode`)
// } else {
//   ReactGA.initialize(GA_TRACKING_ID)
//   Sentry.init({
//     // TODO: this should go into .env
//     dsn: process.env.REACT_APP_SENTRY,
//     integrations: [new Integrations.BrowserTracing()],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   })
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// serviceWorkerRegistration.register();
