import React from 'react'
import { Box, VStack } from '@chakra-ui/react'
import Header from 'components/MobileHeader'
import BlogCard from 'components/BlogCard'
import { useTranslation } from 'contexts/TranslationContext'

const MobileBlog = ({ data }) => {
  const { language, t } = useTranslation()
 
  return (
    <Box pb={10}>
      <Header
        heading={t('blog')}
        backTo="/" />
      <VStack spacing="10" px="5" mt={6}>
        {data && data[`items_${language}`].map((card) => (
          <BlogCard
            key={`mobile-blog-${card.id}`}
            width="full"
            card={card}
          />
        ))}
      </VStack>
    </Box>
  )
}

export default MobileBlog
