import { useState } from 'react'
import {
  Spinner,
  GridItem,
  SimpleGrid,
  Button,
  Heading,
  Flex,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useUser,
  useDonationSubscriptionsByUserEmail,
  useUpdateDonationSubscription,
  calculateProjectDurationLeft,
} from 'hooks'
import ProjectCard from 'components/ProjectCard'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
} from 'contexts/CurrencySettingsContext'
import { useNavigation } from 'pages'

const AlertDialogModal = ({
  t,
  language,
  onClose,
  isOpen,
  onUnsubscribe,
  isLoading,
  donationSubscription = null,
  project,
}) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{t('card@alertWarningHeading')}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {t('card@unsubscribeAlertWarningDescription').replace(
            'PROJECT_NAME',
            project && project[`title_${language}`]
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            isDisabled={isLoading}
            minH="20px"
            minW="100px"
            variant="transparent"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={(e) => onUnsubscribe(e, donationSubscription)}
            minH="20px"
            minW="100px"
            variant="green"
            colorScheme="red"
            ml={3}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {t('unsubscribe')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const UnsubscribeText = ({
  t,
  unsubscribe,
  currency,
  monthlyPaidText,
  startedFromText,
}) => (
  <>
    {monthlyPaidText}: {SUPPORTED_CURRENCY_SYMBOLS[currency.current]}
    <CurrencyConverter
      amount={unsubscribe.amount}
      currency={currency.current}
    />
    <br />
    {startedFromText}: {moment(unsubscribe.createdAt).format('DD MMMM, YYYY')}
  </>
)

export default function Subscriptions() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { updateDonationSubscription } = useUpdateDonationSubscription()
  const [activeCard, setActiveCard] = useState(null)
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()
  const { user } = useUser()
  const {
    donationSubscriptions,
    isLoading,
    refetch,
  } = useDonationSubscriptionsByUserEmail({
    userEmail: user.email,
    filter: {
      unsubscribedDate: {
        attributeExists: false,
      },
    },
  })
  const onAlertOpen = (e, donationSubscription) => {
    setActiveCard(donationSubscription)
    e.preventDefault()
    onOpen()
  }

  const onUnsubscribe = (e, donationSubscription) => {
    e.stopPropagation()
    updateDonationSubscription(
      {
        id: donationSubscription.id,
        unsubscribedDate: new Date().toISOString(),
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
        },
        onError: (e) => console.log('>>> UNSUBSCRIBE ERROR', e),
      }
    )
  }
  return (
    <>
      {isLoading ? (
        <Flex minH="520px" w="full" align="center" justifyContent="center">
          <Spinner color="blue.400" />
        </Flex>
      ) : (
        <SimpleGrid
          px={{ base: 5, md: 0 }}
          minH="720px"
          position="relative"
          w="full"
          pt="12"
          pb="20"
          columns={{
            base: 1,
            md: 2,
            lg: 3,
          }}
          spacing="8"
        >
          {donationSubscriptions?.length > 0 ? (
            donationSubscriptions
            .filter((sub) => !!sub.project)
              .sort((a, b) =>
                moment.utc(b.createdAt).diff(moment.utc(a.createdAt))
              )
              .map((donationSubscription) => (
                <GridItem
                  key={`donation-subscription-card-${donationSubscription.id}`}
                  colSpan={1}
                >
                  <ProjectCard  goalText={t('goal')}
                    card={donationSubscription.project}
                    goalPrice={CurrencyConverter({
                      amount:
                        donationSubscription.project.activeGoal?.amount ||
                        donationSubscription.project.goal,
                      currency: currency.current,
                    })}
                    raisedMoney={CurrencyConverter({
                      currency: currency.current,
                      amount: parseInt(donationSubscription.project.amount),
                    })}
                    CurrencyConverter={CurrencyConverter}
                    thousandSeparatorText={thousandSeparator(
                      currency.current === 'AMD'
                        ? donationSubscription.project.amount +
                            Number(donationSubscription.project.updateAmount)
                        : donationSubscription.project.amount /
                            Number(settings?.currency[currency.current]) +
                            Number(donationSubscription.project.updateAmount), currency.current
                    )}
                    SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                    calculatedProjectDurationLeft={calculateProjectDurationLeft(
                      {
                        t,
                        campaignImplementorPeriod_en:
                          donationSubscription.project
                            .campaignImplementorPeriod_en,
                        fundraisingType:
                          donationSubscription.project.fundraisingType,
                      }
                    )}
                    unsubscribe={{
                      amount: donationSubscription.amount,
                      createdAt: donationSubscription.createdAt,
                    }}
                    donationSubscription={donationSubscription}
                    onAlertOpen={onAlertOpen}
                    language={language}
                    currency={currency}
                    settings={settings}
                    changeDefaultCurrency={changeDefaultCurrency}
                    UnsubscribeText={UnsubscribeText}
                    startedFromText={t('profile@startedFrom')}
                    monthlyPaidText={t('profile@monthlyPaid')}
                    navigationPush={navigationPush}
                    user={user}
                    cardStatusText={t(
                      `status@${donationSubscription.project.status}`
                    )}
                    cardCategoryText={t(
                      `category@${donationSubscription.project.category}`
                    )}
                    editText={t('edit')}
                    unsubscribeText={t('unsubscribe')}
                    collaborationProposalsText={t(
                      donationSubscription.project?.collaboratorsTotal === 1
                        ? 'collaboration proposal'
                        : 'collaboration proposals'
                    )}
                    collaborationProposalsTextRuEdit={t(
                      'collaboration proposals'
                    )
                      .split('...')[1]
                      .replace('запросов', 'запроса')}
                    monthlyGoalText={t('monthlyGoal')}
                    raisedText={t('raised')}
                  />
                </GridItem>
              ))
          ) : (
            <Flex
              top="0"
              left="0"
              position="absolute"
              alignItems="center"
              justifyContent="center"
              h="full"
              w="full"
            >
              <Heading
                as="h2"
                fontSize={{ base: 'xl', md: '5xl' }}
                color="blue.400"
              >
                {t('noResults')}
              </Heading>
            </Flex>
          )}
        </SimpleGrid>
      )}
      <AlertDialogModal
        t={t}
        language={language}
        onClose={onClose}
        donationSubscription={activeCard}
        isOpen={isOpen}
        onUnsubscribe={onUnsubscribe}
      />
    </>
  )
}
