import React from 'react'
import { Box, Flex, Skeleton, SkeletonText } from '@chakra-ui/react'

const ProjectSkeletonCardMobile = () => (
  <Box>
    <Box position="relative" width="280px" height="160px">
      <Skeleton borderRadius="12px" width="100%" height="100%" />
    </Box>
    <Flex
      p="3"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box minH="82px">
        <SkeletonText mt="2" noOfLines={2} spacing="4" />
      </Box>
    </Flex>
  </Box>
)

export default ProjectSkeletonCardMobile
