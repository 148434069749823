import { Flex, Box, Heading } from '@chakra-ui/react'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import ImageWrapper from 'components/ImageWrapper'
import Progress from 'components/Progress'
import { calculateProjectDurationLeft, projectProgress } from 'hooks'
import {
  CurrencyConverter,
  SUPPORTED_CURRENCIES,
  thousandSeparator,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import UserInfo from 'components/UserInfo'

const Header = ({
  user,
  goBack,
  title,
  amount,
  project,
  language,
  t,
  isContribution,
}) => {
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const newProject = {
    ...project,
    amount,
  }
  const progressData = projectProgress(newProject, currency?.current)

  return (
    <Box>
      <Flex
        w="full"
        borderBottom="1px solid"
        borderColor="border.100"
        justifyContent="center"
        position="relative"
        alignItems="center"
        py="3"
      >
        <Flex
          position="absolute"
          left="12px"
          onClick={goBack}
          width="30px"
          height="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {title}
        </Heading>
      </Flex>
      <Flex flexDirection="row" alignItems="center" px="5" mt="8">
        <Box align="center" position="relative">
          <ImageWrapper
            src={`/thumbnail/small/${project?.cover}`}
            alt="donation"
            objectFit="cover"
            borderRadius="12px"
            height="70px"
            minW="121px"
          />
        </Box>
        <Flex flexDirection="row" alignItems="center" pl="4">
          <Box>
            <Heading mb="4" fontSize="md" as="h2">
              <Box fontWeight="normal" as="span">
                {t('donation@heading')}
              </Box>
              &nbsp;
              {project?.title_en
                ? project[`title_${language}`]
                : project?.applicationTitle}
            </Heading>
            {project?.owner && !isContribution && (
              <UserInfo
                name={project[`creatorName_${language}`]}
                image={project?.creatorImage}
              />
            )}
          </Box>
        </Flex>
      </Flex>
      {(!!project.totalAmount || project.fundraisingType === 'recurring') && (
        <Progress
          goalText={t('goal')}
          project={{ ...project, updateAmount: progressData.amount }}
          raisedMoney={CurrencyConverter({
            currency: currency.current,
            amount: parseInt(project.amount),
          })}
          dynamicalAmount={progressData.progress}
          monthlyGoalText={t('monthlyGoal')}
          goalPrice={project.activeGoal?.amount || project.goal}
          raisedText={t('raised')}
          thousandSeparatorText={thousandSeparator(
            currency.current === 'AMD'
              ? project.amount + Number(progressData.amount)
              : project.amount / Number(settings?.currency[currency.current]) +
                  Number(progressData.amount),
            currency.current
          )}
          SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
          calculatedProjectDurationLeft={calculateProjectDurationLeft({
            t,
            campaignImplementorPeriod_en: {
              ...project,
              updateAmount: progressData.amount,
            }.campaignImplementorPeriod_en,
            fundraisingType: {
              ...project,
              updateAmount: progressData.amount,
            }.fundraisingType,
          })}
          currency={currency}
          settings={settings}
          changeDefaultCurrency={changeDefaultCurrency}
          width="90%"
          mx="auto"
          mt="4"
        />
      )}
    </Box>
  )
}

export default Header
