import { API, graphqlOperation } from 'aws-amplify'
import {
  deleteBindingCard as removeBindingCard,
} from '../../graphql/mutations'

const bindingCardService = () => {
  const deleteBindingCard = async (id) => {
    await API.graphql(graphqlOperation(removeBindingCard, { input: { id } }))
  }

  return {
    deleteBindingCard,
  }
}

export default bindingCardService
