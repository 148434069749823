import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import {
  Button,
  Stack,
  Text,
  IconButton,
  Flex,
  Box,
  Heading,
  HStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Link, useNavigation } from 'pages'
import { PasswordField } from 'components/PasswordField'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams, useSignIn, useUser } from 'hooks'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import useCustomToast from 'hooks/useCustomToast'

export const SigninForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  const queryParams = useQueryParams()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { signIn } = useSignIn()
  const { getUser } = useUser()
  const { navigationPush, navigationReplace } = useNavigation()
  const toast = useCustomToast()
  const history = useHistory()

  const submit = (data) => {
    setLoading(true)
    signIn(
      {
        ...data,
        email: data.email.toLowerCase(),
      },
      {
        onSuccess: async () => {
          await getUser()
          if (!!queryParams.get('token')) {
            return navigationPush(
              `/collaboration-request?token=${queryParams.get(
                'token'
              )}&initiativeId=${queryParams.get(
                'initiativeId'
              )}&email=${queryParams.get('email')}&response=${queryParams.get(
                'response'
              )}`
            )
          }
          const from = queryParams.get('from')
          if (from) {
            if (from === '/donation') {
              const redirectURL = localStorage.getItem('redirectURL')
              navigationReplace(redirectURL || '/')
              return
            }
            navigationReplace(queryParams.get('from'))
            return
          }
          navigationReplace('/')
        },
        onError: (err) => {
          setLoading(false)
          if (err.code === 'UserNotConfirmedException') {
            navigationPush(`/verify?email=${data.email}`)
          }
          toast({
            title: t(`login@${err.code}`),
            status: 'error',
            duration: 5000,
            position: 'top',
          })
        },
      }
    )
  }

  return (
    <Box pb="5rem">
      <Flex
        w="full"
        justifyContent="center"
        position="relative"
        alignItems="center"
        mb="10"
        borderBottom="1px solid"
        borderColor="border.100"
        py="3"
      >
        <Flex
          position="absolute"
          left="12px"
          onClick={() => navigationPush('/more')}
          width="30px"
          height="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {t('login@heading')}
        </Heading>
      </Flex>
      <HStack justifyContent="center" mb="10" spacing="2">
        <IconButton
          width="59px"
          h="59px"
          onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
          p="0"
          icon={<Facebook width="59px" height="59px" />}
        />
        <IconButton
          width="59px"
          h="59px"
          onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
          p="0"
          icon={<Google width="59px" height="59px" />}
        />
      </HStack>
      <Box px="5">
        {' '}
        <form onSubmit={handleSubmit(submit)}>
          {(!!errors.password || !!errors.email) && (
            <Text mb="4" ml="2" color="red.400">
              {t('login@credentialsRequired')}
            </Text>
          )}
          <Stack spacing="6">
            <InputField
              borderRadius="12px"
              size="lg"
              label={t('login@email')}
              {...register('email', {
                required: true,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
              })}
            />
            <PasswordField
              borderRadius="12px"
              size="lg"
              label={t('login@password')}
              // type="password"
              {...register('password', { required: true })}
            />
            <Button
              display="inline-block"
              color="gray.700"
              textAlign="left"
              mt="3"
            >
              <Link to="/forgot-password">{t('login@forgotPassword')}</Link>
            </Button>
            <Button
              isLoading={loading}
              mb="4"
              w="full"
              type="submit"
              size="lg"
              variant="blue"
              colorScheme="blue"
            >
              {t('login@heading')}
            </Button>

            <Text
              display="inline-block"
              textAlign="center"
              color="gray.700"
              fontSize="sm"
              fontWeight="semibold"
            >
              {t('login@dontHaveAccount')}{' '}
              <Button color="orange.400">
                <Link to="/sign-up">{t('login@signUp')}</Link>
              </Button>
            </Text>
          </Stack>
        </form>
      </Box>
    </Box>
  )
}
