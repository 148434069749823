import moment from 'moment';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS } from "@contentful/rich-text-types";

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => `
      <img
        src="https://${node.data.target.fields.file.url}"
        height={node.data.target.fields.file.details.image.height}
        width={node.data.target.fields.file.details.image.width}
        alt={node.data.target.fields.description}
      />
    `,
  }
}

const generateContent = (items) => items.map(item => {
  const images = item.fields.images?.map(image => image?.fields?.file?.url && `https:${image.fields.file.url}`) || []
  return {
    id: item.sys.id,
    title: item.fields.title,
    slug: item.fields.slug,
    description: item.fields.description,
    date: moment(item.fields.date).format('ll'),
    content: documentToHtmlString(item.fields.content, renderOptions),
    images,
    image: images[0],
  }
})

const blogUseCase = (blogRepo) => {
  const getBlog = async (slug) => {
    const response = await blogRepo.getBlog(slug)
    let result = null;
    if (response.items?.length > 0) {
      result = generateContent(response.items)[0]
    }
    return result
  };

  const listBlog = async () => {
    const response = await blogRepo.listBlog();

    const filterLang = (items, lang) => 
      items.filter(item => item.metadata.tags[0].sys.id === lang)

    const result = {
      skip: response.skip,
      total: response.total,
      limit: response.limit,
      items_en: generateContent(filterLang(response.items, 'en')),
      items_ru: generateContent(filterLang(response.items, 'ru')),
      items_hy: generateContent(filterLang(response.items, 'hy')),
    }

    return result;
  };

  return {
    getBlog,
    listBlog,
  }
}

export default blogUseCase
