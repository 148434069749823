import React, { useEffect, useState } from 'react'
import {
  Tabs as ChakraTabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
} from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'
import Story from '../Story'
import Update from '../Update'
import Donations from './Donations'
import NeedCard from '../NeedCard'
import { useNavigation } from 'pages'
import { thousandSeparator } from 'contexts/CurrencySettingsContext'

const Tabs = ({
  project,
  projectId,
  updates,
  isUpdatesLoading,
  tabsSectionRef,
}) => {
  const { t, language } = useTranslation()
  const [tabIndex, setTabIndex] = useState(0)
  const tabs = ['story', 'updates', 'donors']
  const queryParams = useQueryParams()
  const history = useHistory()
  const { navigationPush } = useNavigation()
  const { pathname } = useLocation()

  const handleTabsChange = (index) => {
    console.log(tabs[index])
    if (queryParams.get('tab') !== tabs[index]) {
      history.replace(`${pathname}?tab=${tabs[index]}`)
    }
    setTabIndex(index)
  }
  useEffect(() => {
    const tab = queryParams.get('tab')
    if (tab) {
      setTabIndex(tabs.indexOf(tab))
      return
    }
    if (
      project?.status === 'ended' ||
      project?.status === 'closed' ||
      project?.status === 'terminated'
    ) {
      setTabIndex(tabs.indexOf('updates'))
      history.replace(`${pathname}?tab=updates`)
      return
    }
    history.replace(`${pathname}?tab=story`)
  }, [project])

  return (
    <ChakraTabs
      mt="10"
      maxW="735px"
      overflowX="hidden"
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <Box>
        <TabList
          borderColor="border.100"
          borderBottom="1px"
          pb="2px"
          ref={tabsSectionRef}
        >
          <Tab
            position="relative"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              borderBottom: '2px',
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="4"
            flex="1"
          >
            {t('story')}
          </Tab>
          {(project.status === 'live' ||
            project.status === 'launching_soon' ||
            project.status === 'ended' ||
            project.status === 'terminated' ||
            project.status === 'closed' ||
            project.status === 'waiting_for_transfer_confirmation') && (
            <Tab
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                borderBottom: '2px',
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              flex="1"
            >
              {t('updates')}
            </Tab>
          )}
          {project.isProject && (
            <Tab
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                borderBottom: '2px',
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="4"
              flex="1"
            >
              {t('Donors list')}
            </Tab>
          )}
        </TabList>
      </Box>

      <TabPanels pt="13">
        <TabPanel px="5">
          <Story project={project} />
        </TabPanel>
        {(project.status === 'live' ||
          project.status === 'launching_soon' ||
          project.status === 'ended' ||
          project.status === 'closed' ||
          project.status === 'terminated' ||
          project.status === 'waiting_for_transfer_confirmation') && (
          <TabPanel px="5">
            <Update
              project={project}
              updates={updates}
              isUpdatesLoading={isUpdatesLoading}
            />
          </TabPanel>
        )}
        {project.isProject && (
          <TabPanel px="5">
            <Donations projectId={projectId} project={project} />
          </TabPanel>
        )}
      </TabPanels>
    </ChakraTabs>
  )
}

export default Tabs
