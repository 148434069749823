import React, { useState } from 'react'
import {
  Stack,
  Box,
  Heading,
  Flex,
  Text,
  InputLeftElement,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { TextAreaComponent } from 'components/TextAreaComponent'
import DatePicker from 'components/SingleDate'
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg'

const Details = ({ register, errors, clearErrors, setValue, watch }) => {
  const { t } = useTranslation()
  const [showDateErrors, setShowDateErrors] = useState('')
  const changeDate = (name, value) => {
    setShowDateErrors(name)
    setValue(name, value)
    if (!value) return
    clearErrors(name)
  }
  return (
    <Box maxW="600px" width="100%">
      <Heading mb="6" fontSize="6xl" as="h2">
        {t('details')}
      </Heading>
      <Stack mb="5.5rem" spacing="10">
        <TextAreaComponent
          isInvalid={!!errors.beneficiaries}
          title={t('projectCreate@Beneficiaries') + '*'}
          description={t('projectCreate@BeneficiariesText')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          minLength={200}
          maxLength={500}
          {...register('beneficiaries', {
            required: true,
            minLength: 200,
            maxLength: 500,
            onChange: (e) => {},
          })}
        />
        <TextAreaComponent
          isInvalid={!!errors.objective}
          title={t('projectCreate@CreateObjective') + '*'}
          description={t('projectCreate@CreateObjectiveText')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          minLength={200}
          maxLength={500}
          {...register('objective', {
            required: true,
            minLength: 200,
            maxLength: 500,
            onChange: (e) => {},
          })}
        />
        <TextAreaComponent
          isInvalid={!!errors.keyResults}
          title={t('projectCreate@KeyResults') + '*'}
          description={t('projectCreate@KeyResultsText')}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          minLength={200}
          maxLength={500}
          {...register('keyResults', {
            required: true,
            minLength: 200,
            maxLength: 500,
            onChange: (e) => {},
          })}
        />
        <Flex flexDir="column">
          <Text
            fontSize="sm"
            fontWeight="regular"
            color="black"
            zIndex={2}
            mb="18px"
          >
            <Text fontSize="xl" fontWeight={700}>
              {t('projectCreate@Implementation') + '*'}
            </Text>
            {t('createEditProject@campaignImplementationPeriod')}
          </Text>
          <Flex>
            <Box position="relative">
              <InputLeftElement
                zIndex={4}
                py="2"
                px="10px"
                pointerEvents="none"
                color="gray.700"
                children={<Calendar />}
              />
              <DatePicker
                date={watch('startDate')}
                clearErrors={clearErrors}
                register={register}
                changeDate={changeDate}
                setValue={setValue}
                name={'startDate'}
                minDate={new Date()}
                datePlaceholder={t('projectCreate@StartDate')}
                errorMessage={
                  watch('startDate') &&
                  watch('endDate') &&
                  watch('startDate') > watch('endDate')
                    ? showDateErrors === 'startDate'
                      ? t('createEditProject@endDateErrorMessage')
                      : ''
                    : t('createEditProject@thisFieldIsRequired')
                }
                isInvalid={
                  !!errors.startDate ||
                  (watch('startDate') &&
                    watch('endDate') &&
                    watch('startDate') > watch('endDate'))
                }
              />
            </Box>
            <Box position="relative" ml="28px">
              <InputLeftElement
                zIndex={4}
                py="2"
                px="10px"
                pointerEvents="none"
                color="gray.700"
                children={<Calendar />}
              />
              <DatePicker
                minDate={new Date()}
                datePlaceholder={t('projectCreate@EndDate')}
                clearErrors={clearErrors}
                register={register}
                changeDate={changeDate}
                setValue={setValue}
                errorMessage={
                  watch('startDate') &&
                  watch('endDate') &&
                  watch('startDate') > watch('endDate')
                    ? showDateErrors === 'endDate'
                      ? t('createEditProject@endDateErrorMessage')
                      : ''
                    : t('createEditProject@thisFieldIsRequired')
                }
                name={'endDate'}
                date={watch('endDate')}
                isInvalid={
                  !!errors.endDate ||
                  (watch('startDate') &&
                    watch('endDate') &&
                    watch('startDate') > watch('endDate'))
                }
              />
            </Box>
          </Flex>
        </Flex>
        <TextAreaComponent
          isInvalid={!!errors.phases}
          title={t('projectCreate@Phases')}
          description={t('projectCreate@PhasesText')}
          minLength={0}
          maxLength={1000}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          {...register('phases', {
            required: false,
            minLength: 0,
            maxLength: 1000,
            onChange: (e) => {},
          })}
        />
        <TextAreaComponent
          isInvalid={!!errors.risks}
          title={t('projectCreate@Risks') + '*'}
          description={t('projectCreate@RisksText')}
          minLength={200}
          maxLength={500}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          {...register('risks', {
            required: true,
            minLength: 200,
            maxLength: 500,
            onChange: (e) => {},
          })}
        />
        <TextAreaComponent
          isInvalid={!!errors.otherPlayers}
          title={t('projectCreate@SimilarProjects') + '*'}
          description={t('projectCreate@SimilarProjectsText')}
          minLength={200}
          maxLength={500}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          {...register('otherPlayers', {
            required: true,
            minLength: 200,
            maxLength: 500,
            onChange: (e) => {},
          })}
        />
      </Stack>
    </Box>
  )
}

export default Details
