import React from 'react'
import {
  Flex,
  Button,
  Heading,
} from '@chakra-ui/react'
import { ReactComponent as Close} from '../../../assets/icons/close.svg'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'

const Header = () => {
  const {t} = useTranslation()
  const {navigationPush} = useNavigation()
  return (
    <Flex
      bg="blue.300"
      w="full"
      py="3"
      alignItems="center"
      justifyContent="center"
      position="relative">
        <Button
          onClick={() => navigationPush('/')}
          left="12px"
          position="absolute"
          h="30px"
          w="30px"
          variant="unstyled">
          <Close height="14px" width="14px"/>
        </Button>
        <Heading
          as="h1"
          fontSize="xl"
          color="gray.100">
          {t('Create Project')}
        </Heading>
    </Flex>
  )
}

export default Header
