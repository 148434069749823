import { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import {
  useQueryParams,
  useVerifyToken,
  useCollabRequest,
  useUser,
  useGetProject,
  useUpdateProject,
} from 'hooks'
import { useNavigation } from 'pages'
import useCustomToast from 'hooks/useCustomToast'

export default function CollaborationRequest() {
  const queryParams = useQueryParams()
  const toast = useCustomToast()
  const { navigationPush, navigationReplace } = useNavigation()
  const { user } = useUser()
  const { refetch, data: initiative } = useGetProject({
    projectId: queryParams.get('initiativeId') ?? '',
  })
  const { updateProject: updateInitiative } = useUpdateProject()
  const { collaboratorRequest } = useCollabRequest()
  const { mutate: verify, isLoading: isVerifyLoading } = useVerifyToken()

  const onReject = async (data) => {
    await collaboratorRequest(
      {
        userId: data.userIid,
        initiativeId: data.initiativeId,
        addresses: [data.userEmail],
        contactEmail: data.userEmail,
        fullname: data.fullname,
        isCollab: data.isCollab,
        rejection: true,
        description: data.description,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Rejected successfully',
            status: 'success',
            isClosable: true,
            duration: 5000,
          })
          navigationPush(`/`)
        },
      }
    )
  }

  useEffect(() => {
    if (user.id === 'guest') {
      toast({
        title: `Sign in to continue`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
      return navigationReplace(
        `/sign-in?token=${queryParams.get(
          'token'
        )}&initiativeId=${queryParams.get(
          'initiativeId'
        )}&email=${queryParams.get('email')}&response=${queryParams.get(
          'response'
        )}`
      )
    }
    refetch()
  }, [])

  useEffect(async () => {
    if (!initiative) return
    const token = queryParams.get('token') ?? ''
    const initiativeId = queryParams.get('initiativeId')
    const email = queryParams.get('email')
    const response = queryParams.get('response')
    const secret = `${email}_${initiativeId}`
    if (response === 'reject') {
      toast({
        title: 'Rejected',
        status: 'success',
        isClosable: true,
        duration: 5000,
      })
      return navigationPush(`/`)
    }
    if (user.id !== initiative.owner) {
      toast({
        title: `You don't have permission`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
      return navigationPush(`/`)
    }
    if (!token || !initiativeId || !email) {
      toast({
        title: `Missing required query params`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
      return navigationPush(`/`)
    }

    const collaborators = initiative?.collaborators?.map(
      (collab) => collab.email
    )
    if (collaborators?.includes(email)) {
      toast({
        title: 'Email already registered',
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
      return navigationPush(`/fundraisers/${initiativeId}`)
    }

    const input = {
      id: initiative.id,
      collaborators: initiative.collaborators ?? [],
      implementor: initiative.implementor ?? {},
      collaboratorsTotal: initiative.collaboratorsTotal
        ? initiative.collaboratorsTotal + 1
        : 1,
    }

    const data = await verify(
      { token, secret },
      {
        onSuccess: (data) => {
          console.log(data)

          if (data.isCollab) {
            input.collaborators.push({
              id: data.userId,
              email: data.userEmail,
              fullName: data.fullname,
            })
          } else {
            input.implementor = {
              id: data.userId,
              email: data.userEmail,
              fullName: data.fullname,
            }
          }
        },
        onError: (error) => {
          console.log(error)
        },
      }
    )

    toast({
      title: 'Accepted successfully',
      status: 'success',
      isClosable: true,
      duration: 5000,
    })
    updateInitiative(input, {
      onSuccess: () => {
        toast({
          title: 'Accepted successfully',
          status: 'success',
          isClosable: true,
          duration: 5000,
        })
        navigationPush(`/fundraisers/${initiativeId}`)
      },
    })
  }, [initiative])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}
