import { Auth } from 'aws-amplify'

const authService = () => {
  const signUp = async ({ email, password, ...attrs }) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        given_name: attrs.firstName,
        family_name: attrs.lastName,
        phone_number: attrs.phone,
        address: attrs.country,
      },
      autoSignIn: {
        enabled: true,
      },
    })
  }

  const confirmSignUp = async ({ email, code }) => {
    await Auth.confirmSignUp(email, code)
  }

  const resendSignUp = async (email) => {
    await Auth.resendSignUp(email)
  }

  const signIn = async ({ email, password }) => {
    await Auth.signIn(email, password)
    const credentials = await Auth.currentCredentials()
  }

  const socialSignIn = async ({ provider }) => {
    const credentials = await Auth.currentCredentials()
    return Auth.federatedSignIn({ provider })
  }

  const forgetPassword = (email) => {
    return Auth.forgotPassword(email)
  }

  const forgetPasswordSubmit = async ({ email, code, password }) => {
    await Auth.forgotPasswordSubmit(email, code, password)
  }

  return {
    signUp,
    resendSignUp,
    confirmSignUp,
    forgetPassword,
    forgetPasswordSubmit,
    signIn,
    socialSignIn,
  }
}

export default authService
