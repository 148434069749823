import React from 'react'
import { Flex, Box, Image, Heading, Text } from '@chakra-ui/react'

const Left = ({ title, description, image }) => (
  <Flex
    px="4"
    _hover={{
      '& > div': {
        borderColor: 'blue.300',
      },
      '& span': {
        bgColor: 'blue.300',
      },
    }}
    w="full"
    flexDirection={{ base: 'column-reverse', md: 'row' }}
    justifyContent="center"
    alignItems={{ base: 'center', md: 'unset' }}
    position="relative"
  >
    <Flex
      py="20"
      flex="1"
      overflow="hidden"
      justifyContent="center"
      flexDirection="column"
      borderRight={{ base: '0', md: '2px solid #DDDEE3' }}
      position="relative"
    >
      <Flex
        justifyContent={{ base: 'center', md: 'flex-end' }}
        alignItems="center"
        mb="4"
        position="relative"
      >
        <Heading
          textAlign={{ base: 'center', md: 'right' }}
          pr={{ base: '0', md: '4rem' }}
          fontSize="4xl"
          as="h3"
        >
          {title}
        </Heading>
        <Box
          display={{ base: 'none', md: 'block' }}
          as="span"
          right="-29px"
          bg="#DDDEE3"
          minH="50px"
          minW="50px"
          borderRadius="8px"
          transform="rotate(45deg)"
          position="absolute"
        />
      </Flex>
      <Text
        textAlign={{ base: 'center', md: 'right' }}
        pr={{ base: '0', md: '4rem' }}
      >
        {description}
      </Text>
    </Flex>
    <Flex
      borderLeft={{ base: '0', md: '2px solid #DDDEE3' }}
      borderColor="#DDDEE3"
      flex="1"
    >
      <Box pl={{ base: '0', md: '5rem' }}>
        <Image minW="220px" height="220px" src={image} />
      </Box>
    </Flex>
  </Flex>
)

export default Left
