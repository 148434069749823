import { Box, Flex, Image, Td, Text } from '@chakra-ui/react'
import AmountWithCurrency from 'components/AmountWithCurrency'
import ImageWrapper from 'components/ImageWrapper'
import LineClamp from 'components/LineClamp'
import MonthlyLabel from 'components/MonthlyLabel'
import moment from 'moment'
import { Link } from 'pages'
import React from 'react'

export default function List({ transactions, lang = 'en', t }) {
  if (!transactions) {
    return null
  }
  return (
    <Box width="full">
      {transactions.map((item) => (
        <ListItem
          key={item.id}
          image={item.projectImage}
          title={item[`projectTitle_${lang}`] || item.projectId}
          donationToProject={item.donationToProject}
          donationToReArmenia={item.donationToReArmenia}
          date={item.createdAt}
          currency={item.currency}
          currencyRate={item.currencyRate}
          projectId={item.projectId}
          subscriptionId={item.subscriptionId}
          t={t}
        />
      ))}
    </Box>
  )
}
const w = window.innerWidth
const ListItem = ({
  image,
  title,
  donationToReArmenia,
  donationToProject,
  date,
  currency,
  currencyRate,
  projectId,
  subscriptionId,
  t,
}) => (
  <Flex
    borderBottomWidth={1}
    borderColor="border.100"
    pb="4"
    mb="4"
    width="full"
    justifyContent="space-between"
    alignItems="center"
  >
    <Link to={`/fundraisers/${projectId}`}>
      <ImageWrapper
        src={`/thumbnail/small/${image}`}
        ignoreFallback={false}
        alt="project cover"
        objectFit="cover"
        borderRadius={{ base: '12px', sm: '8px' }}
        width={{ base: '60px', sm: '80px' }}
        height={{ base: '60px', sm: '80px' }}
      />
    </Link>

    <Flex
      ml="4"
      flex="1"
      justifyContent="space-between"
      alignItems={{ base: 'start', sm: 'center' }}
      flexDir={{ base: 'column', sm: 'row' }}
    >
      <Box>
        {subscriptionId && w > 481 && (
          <MonthlyLabel width="max-content" ml="0" mb="1" color="white" />
        )}
        <Link to={`/fundraisers/${projectId}`}>
          <LineClamp
            Component={Text}
            fontWeight={600}
            color="gray.800"
            textAlign="left"
          >
            {title}
          </LineClamp>
        </Link>
        <Flex alignItems="center">
          {subscriptionId && w < 481 && (
            <MonthlyLabel width="max-content" ml="0" my="0" mr="2" />
          )}
          <Text color="gray.700" fontWeight="500" fontSize={{ base: 'xs', sm: 'md' }}>
            {moment(date).format('DD.MM.YYYY')}
          </Text>
        </Flex>
      </Box>
      <Box textAlign={{ base: 'left', sm: 'right' }}>
        <Text
          color="gray.700"
          fontWeight="400"
          fontSize={{ base: 'xs', sm: 'sm' }}
        >
          {t('donation@yourDonation')}:{' '}
          <AmountWithCurrency
            amount={donationToProject}
            currency={currency}
            currencyRate={currencyRate}
          />
        </Text>
        <Text
          color="gray.700"
          fontWeight="400"
          fontSize={{ base: 'xs', sm: 'sm' }}
        >
          {t('donation@reArmeniaTip')}:{' '}
          <AmountWithCurrency
            amount={donationToReArmenia}
            currency={currency}
            currencyRate={currencyRate}
          />
        </Text>
        <Text fontWeight="bold">
          {t('donation@total')}:{' '}
          <AmountWithCurrency
            amount={
              Number(donationToReArmenia || 0) + Number(donationToProject)
            }
            currency={currency}
            currencyRate={currencyRate}
          />
        </Text>
      </Box>
    </Flex>
  </Flex>
)
