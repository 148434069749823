import { Square, useId, useRadio } from '@chakra-ui/react'
import * as React from 'react'

export const RadioOption = (props) => {
  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(props)
  const id = useId()

  return (
    <label style={{ width: '100%', margin: 0, height: '100%' }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <Square
        id={id}
        px="4"
        width="100%"
        height="100%"
        py="3"
        fontSize="sm"
        transition="all 0.2s"
        textAlign="center"
        cursor="pointer"
        color="gray.800"
        _hover={{
          bg: 'orange.400',
          color: "gray.100",
        }}
        _checked={{
          bg: 'blue.400',
          color: "gray.100",
        }}
        _focus={{ shadow: '0 12px 14px 0 rgba(1,47,115,0.1)' }}
        {...getCheckboxProps(props)}
      />
    </label>
  )
}
