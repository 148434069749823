import {
  Button,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

export default function DraftModal({ closeModal }) {
  const { t } = useTranslation()
  return (
    <>
      <ModalCloseButton
        color="#000"
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
      />
      <ModalBody mt="16" px="8">
        <Heading fontSize="xl" textAlign="center">
          {t('draft@modal@title')}
        </Heading>
        <Text fontSize="md" textAlign="center" mt={2}>
          {t('draft@modal@description')}
        </Text>
      </ModalBody>
      <ModalFooter justifyContent="center" mt="4" mb="12">
        <Button
          variant="white"
          minW="200px"
          onClick={() => {
            closeModal({}, { action: 'leave' })
          }}
          mr="2"
          id="draft_modal_leave"
        >
          {t('draft@modal@leave')}
        </Button>
        <Button
          ml="2"
          variant="green"
          minW="200px"
          onClick={() => {
            closeModal({}, { action: 'save' })
          }}
          id="draft_modal_save"
        >
          {t('navigation@draft')}
        </Button>
      </ModalFooter>
    </>
  )
}
