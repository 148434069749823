import { Container, Grid } from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'

import React from 'react'

export default function Layout({ onFormSubmit, children, isLoading }) {
  return (
    <MainLayout>
      <Container
        minH="520px"
        maxW="7xl"
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        {isLoading ? (
          <>{children}</>
        ) : (
          <form onSubmit={onFormSubmit} noValidate>
            <Grid
              mt={{ base: 0, sm: 12 }}
              position="relative"
              minH="100%"
              templateColumns="repeat(12, 1fr)"
              w={{ base: 'full', sm: '1145px' }}
              gap="17px"
            >
              {children}
            </Grid>
          </form>
        )}
      </Container>
    </MainLayout>
  )
}
