import { API, graphqlOperation } from 'aws-amplify'
import { draftsByUserId, getDraft as getDraftQuery } from 'graphql/queries'
import {
  createDraft as createDraftMutation,
  updateDraft as updateDraftMutation,
  deleteDraft as deleteDraftMutation,
} from 'graphql/mutations'

const DraftAppSyncService = () => {
  const createDraft = (input) =>
    API.graphql(graphqlOperation(createDraftMutation, { input }))

  const updateDraft = (input) =>
    API.graphql(graphqlOperation(updateDraftMutation, { input }))

  const deleteDraft = (id) =>
    API.graphql(graphqlOperation(deleteDraftMutation, { input: { id } }))

  const getDraftById = (id) =>
    API.graphql(graphqlOperation(getDraftQuery, { id }))

  const getFundraiserDraftsByUserId = (userId) =>
    API.graphql(
      graphqlOperation(draftsByUserId, {
        owner: userId,
        filter: { isProject: { eq: true } },
      })
    )
  const getCollaborationDraftsByUserId = (userId) =>
    API.graphql(
      graphqlOperation(draftsByUserId, {
        owner: userId,
        filter: { isCollaboration: { eq: true } },
      })
    )
  return {
    createDraft,
    updateDraft,
    deleteDraft,
    getDraftById,
    getFundraiserDraftsByUserId,
    getCollaborationDraftsByUserId,
  }
}

export default DraftAppSyncService
