/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "elastic",
            "endpoint": "https://ez0qycuf36.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "email",
            "endpoint": "https://kycnz9o601.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "mailerLiteAPI",
            "endpoint": "https://in2t0xam76.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "paymentAPI",
            "endpoint": "https://k9uxkj949d.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "sharingAPI",
            "endpoint": "https://t4w9o2q5jl.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "syncPrismicWithDDB",
            "endpoint": "https://cxq4gn3mg5.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "user",
            "endpoint": "https://1gdd4814d2.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://dcv2ce7oezbthfk7d73hev46tm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-avoyeglluzcqbc75jcchwdg22i",
    "aws_cognito_identity_pool_id": "us-east-1:5ca76ba4-07c1-42f4-b09c-9a87df7fb46e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MwD8CnKQX",
    "aws_user_pools_web_client_id": "29mpp2vtvafcbursv2h14jur6k",
    "oauth": {
        "domain": "rearmenia668c7d55-668c7d55-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://ap.am/,https://www.armenianpeace.am/,https://ap.am/,https://ap.am/ru/,https://ap.am/hy/",
        "redirectSignOut": "http://localhost:3000/en/,https://ap.am/,https://www.armenianpeace.am/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "rearmeniae4cf535a688d4c98a974b86967d6a64f130714-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
