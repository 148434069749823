const emailUseCase = (emailRepo) => {
  const updatesEmail = (params) => emailRepo.updatesEmail(params)

  const subscribeMailerLiteNews = async ({
    email,
    firstName,
    lastName,
    language,
  }) => {
    const response = await emailRepo.subscribeMailerLite({ email, firstName, language });
    if (response?.success === 'user already subscribed!') {
      throw new Error(response?.success)
    }
    await emailRepo.subscribeHubspot({ email, firstName, lastName, language });
  }

  return {
    updatesEmail,
    subscribeMailerLiteNews,
  }
}

export default emailUseCase
