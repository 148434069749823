import { useState, useRef } from 'react'
import { useParams, Redirect, useLocation } from 'react-router-dom'
import { Box, AspectRatio, Flex, Container } from '@chakra-ui/react'
import Tabs from './Tabs'
import { Footer } from 'components/Footer'
import Information from './Information'
import { TransactionsProvider, DonationSubscriptionsProvider } from 'contexts'
import { useGetProject, useUser, useUtm } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Play } from 'assets/icons/play.svg'
import MobileProject from './MobileProject'
import { MainLayout } from 'components/Layouts'
import ImageWrapper from 'components/ImageWrapper'
import MetaTags from 'components/MetaTags'
import useGetUpdatesByProjectId from 'hooks/update/useGetProjectUpdates'
import { GENERAL_USER_VISIBLE_STATUSES } from '_constants'
import { useNavigation } from 'pages'

const Project = () => {
  const { navigationPush } = useNavigation()
  const w = window.innerWidth
  const location = useLocation()
  const param = useUtm(location)
  const { user } = useUser()
  const { projectId } = useParams()
  const [play, setPlay] = useState(false)
  const {
    refetch: getProject,
    data: project,
    isLoading,
  } = useGetProject({
    projectId,
  })

  const infoSectionRef = useRef()

  const { language } = useTranslation()

  const {
    data: updates,
    isLoading: isUpdatesLoading,
    refetch: refetchUpdates,
  } = useGetUpdatesByProjectId({
    projectId: project?.id,
    sortDirection: 'DESC',
  })
  if (!isLoading && !project) {
    navigationPush('/404')
  }
  const url = play
    ? '?autoplay=1&controls=1&enablejsapi=1&rel=0'
    : '?controls=0'

  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)

    return match && match[2].length === 11 ? match[2] : null
  }

  if (!project) {
    return null
  }
  if (
    !GENERAL_USER_VISIBLE_STATUSES.includes(project.status) &&
    !user.isSuper &&
    project.owner !== user.id
  ) {
    return <Redirect to={`/${language}/404`} />
  }
  console.log(project?.cover)
  if (w < 481) {
    return (
      <>
        <MetaTags
          url={window.location.href}
          title={`${project[`title_${language}`]} | Armenian Peace`}
          description={project[`tagline_${language}`] || project.summary}
          image={
            !!project.cover
              ? `${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/${project.cover}`
              : 'https://ap.am/ap_cover.png'
          }
        />
        <DonationSubscriptionsProvider project={project}>
          <TransactionsProvider project={project}>
            <MobileProject
              play={play}
              updates={updates}
              isUpdatesLoading={isUpdatesLoading}
              setPlay={setPlay}
              getId={getId}
              url={url}
              project={project}
              getProject={getProject}
              projectId={projectId}
              isLoading={isLoading}
            />
          </TransactionsProvider>
        </DonationSubscriptionsProvider>
      </>
    )
  }
  return (
    <>
      <MetaTags
        title={`${project[`title_${language}`]} | Armenian Peace`}
        description={project[`tagline_${language}`] || project.summary}
        // image={`${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/${project.cover}`}
        image={`${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/${project.cover}`}
      />
      <DonationSubscriptionsProvider project={project}>
        <TransactionsProvider project={project}>
          {/* <GoalsProvider project={project}> */}
          <MainLayout>
            <Container maxW="7xl">
              <Flex w="full" pb="9rem" justifyContent="space-between">
                <Box
                  minHeight="480px"
                  maxW="735px"
                  w="full"
                  mr={{ base: '0', lg: '5rem' }}
                >
                  <AspectRatio h="480px" w="100%" ratio={16 / 9}>
                    {project && project[`video_${language}`] ? (
                      play ? (
                        <Flex
                          height="100%"
                          width="100%"
                          zIndex="5"
                          allow="autoplay"
                          as="iframe"
                          title="Armenia"
                          allowFullScreen
                          src={`https://www.youtube.com/embed/${getId(
                            project[`video_${language}`]
                          )}${url}`}
                        />
                      ) : (
                        <Flex
                          bgImage={`url(https://img.youtube.com/vi/${getId(
                            project[`video_${language}`]
                          )}/0.jpg)`}
                          height="100%"
                          bgSize="cover"
                          bgPos="center"
                          bgRepeat="no-repeat"
                          width="100%"
                        >
                          <Box
                            cursor="pointer"
                            onClick={() => setPlay(true)}
                            p="5"
                            backgroundColor="rgba(255, 255, 255, .5)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="20px"
                            width="80px"
                            height="80px"
                          >
                            <Play width="30px" />
                          </Box>
                        </Flex>
                      )
                    ) : project?.cover ? (
                      <ImageWrapper
                        src={`/thumbnail/medium/${project.cover}`}
                        alt="Project"
                        style={{ objectFit: 'cover' }}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <Box bg="border.100" width="100%" height="100%" />
                    )}
                  </AspectRatio>
                  <Box
                    display={{ base: 'block', lg: 'none' }}
                    flex="1"
                    px="5"
                    pt="12"
                  >
                    <Information project={project} />
                  </Box>
                  <Tabs
                    updates={updates}
                    isUpdatesLoading={isUpdatesLoading}
                    refetchUpdates={refetchUpdates}
                    project={project}
                    getProject={getProject}
                  />
                </Box>
                <Box
                  display={{ base: 'none', lg: 'block' }}
                  flex="1"
                  minW="450px"
                  maxW="464px"
                  pt="12"
                  ref={infoSectionRef}
                >
                  <Information
                    infoSectionRef={infoSectionRef}
                    param={param}
                    project={project}
                  />
                </Box>
              </Flex>
            </Container>
            <Footer />
          </MainLayout>
          {/* </GoalsProvider> */}
        </TransactionsProvider>
      </DonationSubscriptionsProvider>
    </>
  )
}

export default Project
