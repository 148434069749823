import React from 'react'
import {
  Container,
  Heading,
  Text,
  Button,
  SimpleGrid,
  GridItem,
  Flex,
  Image,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'

const IMAGES = [
  '1_UNION.png',
  '2_AZATAZEN.png',
  '3_EFF_GOV.png',
  '4_Arshakuni.png',
  '5_Bardzunk.png',
  '6_Zhogh.png',
  '7_HENK.png',
  '8_VOMA.png',
]

const Partners = () => {
  const { t } = useTranslation()
  return (
    <>
      <Container maxW="6xl">
        <Heading mb="20" fontSize="6xl" as="h2">
          {t('about@initiators')}
        </Heading>
        <SimpleGrid
          pb="9.25rem"
          position="relative"
          w="full"
          columns={{
            base: 2,
            md: 3,
          }}
          spacing="24"
        >
          {IMAGES.map((image) => (
            <GridItem key={image}>
              <Image src={`/assets/images/${image}`} />
            </GridItem>
          ))}
        </SimpleGrid>
      </Container>

      <Container maxW="6xl">
        <Heading mb="6" fontSize="6xl" as="h2">
          {t('about@partners')}
        </Heading>
        <SimpleGrid
          mb="10"
          position="relative"
          w="full"
          columns={{
            base: 3,
            md: 4,
            lg: 6,
          }}
          spacing="8"
        >
          <GridItem display="flex" alignItems="center">
            <Image src={'/assets/images/idram.png'} />
          </GridItem>
          <GridItem display="flex" alignItems="center">
            <Image src={'/assets/images/100.png'} />
          </GridItem>
        </SimpleGrid>
      </Container>
      <Flex
        alignItems="center"
        position="relative"
        w="436px"
        h="172px"
        bg="#FAFAFA"
      >
        <Button
          px="12"
          right="-60px"
          position="absolute"
          variant="blue"
          as={Link}
          to={'/contact-us'}
        >
          Become a partner
        </Button>
      </Flex>
    </>
  )
}

export default Partners
