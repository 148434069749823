import React, { useEffect, useMemo } from 'react'
import { Flex, Container, Text, Box } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams, useProjects, useGetProject } from 'hooks'
import ShareTransactionSocial from 'components/ShareTransactionSocial'
import { MainLayout } from 'components/Layouts'
import { Footer } from 'components/Footer'
import { ReactComponent as Reached } from 'assets/icons/reachedBig.svg'
import { SimilarProjects } from 'pages/Projects/SimilarProjects'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { ReactComponent as Logo } from 'assets/icons/logoBlackAP.svg'
import { useNavigation } from 'pages'
import { similarProjectsQuery } from 'elastic/queries'
// import useGetNeeds from 'hooks/needs/useGetNeeds'

const w = window.innerWidth

const ThankYouText = ({ text, title, isContribution }) => (
  <>
    {!isContribution ? (
      <Text textAlign="center" fontSize="md">
        {text.split('[project name]')[0]}
        <Text as="span" fontWeight={700} fontSize="md">
          {title}
        </Text>
        {text.split('[project name]')[1]}
      </Text>
    ) : (
      <Text textAlign="center" fontSize="md">
        {text}
      </Text>
    )}
  </>
)
const ThankYou = () => {
  const { t, language } = useTranslation()
  const params = useQueryParams()
  const { data: project } = useGetProject({ projectId: params.get('id') })
  const isContribution = params.get('id') === 'rearmenia'
  const isCollaboration = params.get('collaboration')?.trim() === 'true'
  console.log(isCollaboration)
  const { projects, isLoading, setFilters } = useProjects(
    similarProjectsQuery({
      category: project?.category,
      region: project?.region,
      projectId: params.get('id'),
      isCollaboration,
    }),
    true,
    true,
    'similar_fundraisers',
    'similar_projects_fundraisers'
  )
  // const { data: needCategories } = useGetNeeds()

  useEffect(() => {
    if (!project) {
      return
    }
    setFilters(
      similarProjectsQuery({
        category: project?.category,
        region: project?.region,
        projectId: params.get('id'),
        isCollaboration,
      })
    )
  }, [project])

  const link = useMemo(() => {
    if (params.get('id') === 'rearmenia') {
      return `https://armenianpeace.am/${language}`
    }
    return `${process.env.REACT_APP_BASE_URL}/${language}/${
      isCollaboration ? 'collaborations' : 'fundraisers'
    }/${params.get('id')}`
  }, [])

  const thankYouText = (isMonthly, isContribution, isCollaboration) => {
    if (isCollaboration) {
      return t('thankyou@thankYouForCollaboration')
    }
    if (isContribution) {
      return isMonthly === 'true'
        ? t('contribution@thankYouTextMonthly')
        : t('contribution@thankYouText')
    } else {
      return isMonthly === 'true'
        ? t('thankyou@thankYouForMonthly')
        : t('thankyou@thankYouFor')
    }
  }
  const { navigationPush } = useNavigation()

  return (
    <MainLayout>
      <Box display={{ base: 'flex', sm: 'none' }} py="0.375rem" pl="3" w="full">
        <Flex
          onClick={() => navigationPush('/')}
          w="30px"
          h="30px"
          align="center"
          justify="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
      </Box>
      <Container
        mt={{ base: 'none', sm: '72px' }}
        display="flex"
        alignItems="center"
        flexDir="column"
        maxW="750px"
      >
        <Reached minH="218px" />
        <Text
          fontSize={{ base: '4xl', sm: '6xl' }}
          textAlign="center"
          fontWeight={{ base: 700, sm: 500 }}
          mt={4}
          mb={{ base: 8, sm: 6 }}
        >
          {isCollaboration
            ? t('thankyou@titleCollaboration')
            : t('thankyou@title')}
        </Text>

        <ThankYouText
          text={thankYouText(
            params.get('monthly'),
            isContribution,
            isCollaboration
          )}
          isContribution={isContribution}
          title={params.get('title')}
        />
        <Text mt={2} textAlign="center" fontSize="md">
          {isCollaboration
            ? t('thankyou@thankYouForCollaboration2')
            : t('thankyou@chargeWillAppear')}
        </Text>
        <Box
          w="full"
          h="1px"
          bg="gray.400"
          my={6}
          display={{ base: 'block', sm: 'none' }}
        />
        <Text
          mt={{ base: 'none', sm: '54px' }}
          textAlign="center"
          fontSize="2xl"
          fontWeight={700}
        >
          {isContribution
            ? t('contribution@multiplyImpact')
            : t('thankyou@multiplyImpact')}
        </Text>

        <ShareTransactionSocial link={link} />
      </Container>
      <SimilarProjects
        needCategories={[]}
        projects={projects?.items || []}
        isLoading={isLoading}
        isContribution={isContribution}
        isCollaboration={isCollaboration}
      />
      <Flex
        px={{ base: 5, xl: 100 }}
        py={{ base: 5, sm: 10 }}
        position="relative"
        mt={{ base: 12, sm: '130px' }}
        mb={{ base: 10, sm: '176px' }}
        bg="gray.200"
      >
        <Flex flexDir="column" maxW="650px">
          <Text fontSize="6xl" fontWeight={500} mb="8">
            {t('thankYou@thankYouAboutReArmenia')}
          </Text>
          <Text lineHeight="32px">
            {t('thankYou@thankYouAboutReArmeniaText1')}
            <br />
            <br />
            {t('thankYou@thankYouAboutReArmeniaText2')}
          </Text>
        </Flex>
        <Flex
          position="absolute"
          top="-57px"
          right="164px"
          display={{ base: 'none', xl: 'block' }}
        >
          <Logo />
        </Flex>
      </Flex>
      {w > 481 && <Footer />}
    </MainLayout>
  )
}

export default ThankYou
