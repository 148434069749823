const ContentfulService = ({ client }) => {
  const get = async ({ contentType, slug }) => client.getEntries({ content_type: contentType, 'fields.slug[match]': slug })

  const list = async (contentType) => client.getEntries({ content_type: contentType })

  return {
    get,
    list,
  }
}

export default ContentfulService
