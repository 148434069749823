import { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'

import Web from './Web'
import Mobile from './Mobile'

const w = window.innerWidth

const Cart = ({
  donation,
  isLoading,
  project,
  type,
  giftCard,
  isGiftCardDisabled,
  watch,
  isGiftCardForm,
  appliedGiftCard,
  isContribution,
}) => {
  const [giftCardAmount, setGiftCardAmount] = useState(null)
  const [isValid, toggleValid] = useState(false)
  const checkObject = {
    firstName: watch('firstName'),
    lastName: watch('lastName'),
    email: watch('email'),
    amount: watch('amount'),
    yourMessage: watch('yourMessage'),
    from: watch('from'),
    toEmail: watch('toEmail'),
  }
  useEffect(() => {
    let valid = true
    if (!checkObject.firstName) {
      valid = false
    }
    if (!checkObject.lastName) {
      valid = false
    }
    if (!checkObject.email) {
      valid = false
    }
    if (!checkObject.amount || parseFloat(checkObject.amount) <= 0) {
      valid = false
    }
    if (isGiftCardForm && !checkObject.yourMessage) {
      valid = false
    }
    if (isGiftCardForm && !checkObject.from) {
      valid = false
    }
    if (isGiftCardForm && !checkObject.toEmail) {
      valid = false
    }
    toggleValid(valid)
  }, [checkObject])

  const onDonate = () => {
    let params = {}
    if (type === 'project') {
      params.category = 'Projects donate'
      params.action = 'donate click'
      params.label = `${project.title_en} : ${project.id}`
    }
    if (type === 'giftCard') {
      params.category = 'Gift Card'
      params.action = 'order gift card click'
      params.label = 'Gift Card'
    }
    ReactGA.event({
      ...params,
      transport: 'xhr',
    })
  }

  const props = {
    donation,
    type,
    giftCard,
    isLoading,
    isValid,
    giftCardAmount,
    isGiftCardDisabled,
    setGiftCardAmount,
    appliedGiftCard,
    onDonate,
    project,
    isContribution,
  }

  useEffect(() => {
    if (isGiftCardDisabled) {
      setGiftCardAmount(null)
    }
  }, [isGiftCardDisabled])

  if (w < 481) {
    return <Mobile {...props} />
  }

  return <Web {...props} />
}

Cart.defaultProps = {
  type: 'project',
}

export default Cart
