import {
  FormControl,
  FormLabel,
  Textarea,
  Box,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'

export const TextareaField = React.forwardRef((props, ref) => {
  const { label, isInvalid, errorMessage, ...rest } = props
  return (
    <FormControl position="relative">
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage}
        </Box>
      )}
      <FormLabel
        fontSize="sm"
        fontWeight="regular"
        bg={{
          base: mode('gray.100', 'gray.800'),
          md: mode('gray.100', 'gray.700'),
        }}
        zIndex={2}
      >
        {label}
      </FormLabel>
      <Textarea
        isInvalid={isInvalid}
        borderColor="border.100"
        resize="vertical"
        _focus={{ borderColor: 'blue.300' }}
        _hover={{ borderColor: 'blue.300' }}
        sx={{ boxShadow: 'none !important' }}
        ref={ref}
        size="lg"
        fontSize="lg"
        {...rest}
      />
    </FormControl>
  )
})

TextareaField.displayName = 'InputField'
