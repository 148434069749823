import React from 'react'
import { Box } from '@chakra-ui/react'
import { useProjectsByUserId, useUser } from 'hooks'
import Header from './Header'
import MyFundraisers from './Fundraisers'
import MyCollaborations from './Collaborations'
import Empty from '../../../components/Empty'

const collaborationsFilter = (project) => !!project.isCollaboration
const fundraisersFilter = (project) => !!project.isProject

const ProfilePage = () => {
  const { user } = useUser()
  const { projects, isLoading } = useProjectsByUserId({
    owner: user.id,
  })

  return (
    <Box>
      <Header />
      {!isLoading && projects?.length < 1 && <Empty />}
      <MyFundraisers
        isLoading={isLoading}
        projects={projects?.filter(fundraisersFilter)}
      />
      <MyCollaborations
        isLoading={isLoading}
        initiatives={projects?.filter(collaborationsFilter)}
      />
    </Box>
  )
}

export default ProfilePage
