import { Stack, useRadioGroup, Flex, Text} from '@chakra-ui/react'
import * as React from 'react'
import { RadioOption } from './RadioOption'

export const RadioGroup = (props) => {
  const { name, options, onChange, defaultValue, ...rest } = props
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    defaultValue,
  })
  return (
    <Stack
      overflow="hidden"
      {...getRootProps(rest)}
    >
      {Object.entries(options).map(([key, value]) => (
        <RadioOption key={key} {...getRadioProps({ value: key })}>
          <Flex
            flexDir='column'
            fontWeight="600">
            {value.title}
            <Text color="gray.700" fontSize="sm" pr='50px'>{value.description}</Text>
          </Flex>
        </RadioOption>
      ))}
    </Stack>
  )
}
