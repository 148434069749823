import { useMemo } from 'react'
import _ from 'lodash'
import { Box, Heading, Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useListTransactionsByTopDonation } from 'hooks'
import moment from 'moment'
import {
  useCurrencySettings,
  CurrencyConverter,
} from 'contexts/CurrencySettingsContext'
import { Link } from 'pages'
import { useTransactions } from 'contexts'
import { ReactComponent as Papik } from '../../../assets/icons/papiktatik.svg'
import MonthlyLabel from 'components/MonthlyLabel'

const LIMIT = 50

export default function Donates({ projectId, project }) {
  const {
    setLoadMore,
    nextToken,
    data: transactions,
    isMoreLoading,
  } = useTransactions()

  const { data: topData } = useListTransactionsByTopDonation({
    projectId: project.id,
    sortDirection: 'DESC',
    limit: 5,
  })

  const { t } = useTranslation()
  const { currency } = useCurrencySettings()

  const topDataItems = useMemo(() => {
    if (!topData?.items) return []
    return _.uniqBy(topData.items, 'id')
  }, [topData])

  const transactionsItems = useMemo(() => {
    if (!transactions) return []
    return _.uniqBy(transactions, 'id')
  }, [transactions])

  if (transactions?.length === 0) {
    return <></>
  }

  return (
    <>
      <>
        <Heading fontSize="lg" as="h3">
          {t('topDonations')}
        </Heading>
        <Box>
          {topDataItems.map((transaction) => (
            <Transaction
              key={`top-transaction-mobile-${transaction.id}`}
              transaction={transaction}
              currency={currency}
              projectId={projectId}
            />
          ))}
        </Box>
        <Link
          to={
            project?.perks?.length > 0
              ? `/donate/${project?.id}`
              : `/donation?projectId=${project.id}`
          }
        >
          {project.status !== 'ended' && (
            <Button mb="4" mt="6" minH="40px" w="full" variant="green">
              {t('Donate and join the list')}
            </Button>
          )}
        </Link>
        <Heading mt="10" fontSize="lg" as="h3">
          {t('project@peopleDonated')}
        </Heading>
        <Box>
          {transactionsItems.map((transaction) => (
            <Transaction
              key={`one-time-transaction-mobile-${transaction.id}`}
              transaction={transaction}
              currency={currency}
              projectId={project.id}
            />
          ))}
        </Box>
        {nextToken !== null && (
          <Button
            mt="6"
            mx="auto"
            isLoading={isMoreLoading}
            onClick={() => setLoadMore()}
            variant="transparent"
          >
            {t('more')}
          </Button>
        )}
      </>
    </>
  )
}

const Transaction = ({ transaction, currency, projectId }) => (
  <Flex
    key={transaction.id}
    justifyContent="space-between"
    borderBottom="1px solid"
    borderColor="border.100"
    py="5"
  >
    <Box>
      <Box
        display="flex"
        alignItems="center"
        fontSize="md"
        fontWeight="500"
        as="span"
      >
        {transaction.isAnonymous
          ? 'Anonymous'
          : transaction.firstName + ' ' + transaction.lastName}
        {transaction.subscriptionId && <MonthlyLabel />}
      </Box>
      <Box display="block" fontSize="sm" as="span" color="gray.700">
        {moment(transaction.createdAt).fromNow()}
      </Box>
    </Box>
    {transaction.double && (
      <Flex ml="2" mr="2" alignItems="center">
        <Box mr="2" display="block" whiteSpace="nowrap" as="span">
          x 2
        </Box>
        <Papik height="30px" />
      </Flex>
    )}
    <Box fontSize="md" fontWeight="600" as="span">
      {transaction.donationToProject > transaction.currencyRate
        ? Math.floor(
            transaction.donationToProject /
              Number(transaction.currencyRate || 1)
          )
        : Number(
            transaction.donationToProject /
              Number(transaction.currencyRate || 1)
          ).toFixed(2)}{' '}
      {transaction.currency || 'AMD'}
    </Box>
  </Flex>
)
