import { useEffect } from 'react'
import { css } from '@emotion/react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  VKShareButton,
} from 'react-share'
import { BiLink } from 'react-icons/bi'
import {
  Flex,
  IconButton,
  Button,
  useClipboard,
  useMediaQuery,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import useCustomToast from 'hooks/useCustomToast'
import { FaFacebookF, FaTwitter, FaLinkedin, FaVk } from 'react-icons/fa'

const styles = {
  socialShareButton: css`
    width: 59px;
    height: 59px;
    border-radius: 24px;
    margin-right: 20px;
    margin-bottom: 20px;

    svg {
      height: 17.7px;
      width: auto;

      path {
        fill: white;
      }
    }

    @media (max-width: 768px) {
      margin-left: 10px;
      margin-right: 10px;
    }
  `,
}

export default function ShareTransactionSocial({ link }) {
  const { t } = useTranslation()
  const [isLessThanMD] = useMediaQuery('(max-width: 768px)')
  const { hasCopied, onCopy } = useClipboard(link)
  const toast = useCustomToast()

  useEffect(() => {
    if (!hasCopied) return
    toast({
      title: t('copied'),
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }, [hasCopied])

  return (
    <Flex
      mt={6}
      flexFlow="wrap"
      px={{ base: 5, md: 0 }}
      mb={'70px'}
      justifyContent={{ base: 'center', md: 'flex-start' }}
    >
      <FacebookShareButton url={link} description={'Armenian Peace'}>
        <IconButton
          variant="socialIcon"
          mx="3"
          icon={<FaFacebookF fontSize="18px" color="#1877F2" />}
          _hover={{ filter: 'brightness(1.05)' }}
        />
      </FacebookShareButton>
      <TwitterShareButton url={link} description={'Armenian Peace'}>
        <IconButton
          variant="socialIcon"
          mx="3"
          icon={<FaTwitter fontSize="18px" color="#1FA1F1" />}
          _hover={{ filter: 'brightness(1.05)' }}
        />
      </TwitterShareButton>
      <VKShareButton url={link} description={'Armenian Peace'}>
        <IconButton
          variant="socialIcon"
          mx="3"
          icon={<FaVk fontSize="18px" color="#5181B8" />}
          _hover={{ filter: 'brightness(1.05)' }}
        />
      </VKShareButton>
      <LinkedinShareButton url={link} description={'Armenian Peace'}>
        <IconButton
          variant="socialIcon"
          mx="3"
          icon={<FaLinkedin fontSize="18px" color="#2867B2" />}
          _hover={{ filter: 'brightness(1.05)' }}
        />
      </LinkedinShareButton>
      <Button
        color="gray.800"
        fontSize="md"
        fontWeight="500"
        border="1px"
        borderColor="border.100"
        px={{ base: 0, md: '27px !important' }}
        mx="3"
        height="59px"
        width={{ base: '59px', md: 'auto' }}
        borderRadius={24}
        onClick={onCopy}
      >
        <BiLink
          color="black"
          style={{
            marginRight: isLessThanMD ? 0 : '20px',
            width: 'auto',
            height: '18px',
          }}
        />
        {!isLessThanMD && t('copyLink')}
      </Button>
    </Flex>
  )
}
