import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
import { RadioGroup } from './RadioGroup'

export default function OneTimeRecurring({ setValue, price }) {
  const handlePaymentType = (value) => setValue('paymentType', value)
  const { t } = useTranslation()
  const { currency } = useCurrencySettings()

  const paymentOptions = {
    recurring: {
      title: t('payment@recurring'),
      description: t('payment@recurringDesc').replace('(donation amount)',price + ` ${currency.current}`),
    },
    oneTime: {
      title: t('payment@oneTime'),
      description: t('payment@oneTimeDesc').replace('(donation amount)', price + ` ${currency.current}`),
    },
  }

  return (
    <RadioGroup
      width="100%"
      name="paymentType"
      defaultValue="recurring"
      options={paymentOptions}
      onChange={handlePaymentType}
    />
  )
}
