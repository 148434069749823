import React, { useRef } from 'react'
import { Flex, Heading, Button, Box, HStack } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link, useNavigation } from 'pages'
import ProjectSkeletonCard from 'components/ProjectSkeletonCard'
import ProjectCard from 'components/ProjectCard'
import Swiper from './Swiper'
import { ReactComponent as Left } from 'assets/icons/left.svg'
import { ReactComponent as Right } from 'assets/icons/right.svg'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
} from 'contexts/CurrencySettingsContext'
import { useUser, calculateProjectDurationLeft } from 'hooks'
import './styles.scss'

const Wrapper = ({ section, title, swiper, type, isLoading }) => {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()
  const { user } = useUser()
  const navPrevButton = useRef(null)
  const navNextButton = useRef(null)

  const onBeforeInit = (Swiper) => {
    if (typeof Swiper.params.navigation !== 'boolean') {
      const navigation = Swiper.params.navigation
      navigation.prevEl = navPrevButton.current
      navigation.nextEl = navNextButton.current
    }
  }

  return (
    <Box className="wrapper" pt={{ base: '4', sm: '0' }}>
      <Flex
        mb="10"
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center">
          <Heading
            pl={{ base: 8, sm: '5.5rem' }}
            fontSize={{ base: '5xl', sm: '7xl' }}
            as="h2"
            wordBreak="break-word"
            textTransform="capitalize"
          >
            {title}
          </Heading>
        </Flex>
        <Flex
          w={{ sm: 'full', md: 'unset' }}
          justifyContent="center"
          pr={{ base: '0', md: '5.5rem' }}
          my="4"
          display={{ base: 'none', sm: 'flex' }}
          alignItems="center"
        >
          <Link
            to={type === 'fundraisers' ? '/fundraisers' : '/collaborations'}
          >
            <Button>{t('seeAll')}</Button>
          </Link>
          <Button mx="10" ref={navPrevButton} variant="transparent">
            <Left title="" />
          </Button>
          <Button ref={navNextButton} variant="transparent">
            <Right title="" />
          </Button>
        </Flex>
      </Flex>
      <Box display={{ base: 'none', sm: 'block' }}>
        <Swiper
          isLoading={isLoading}
          isSM={false}
          type={type}
          section={section}
          title={title}
          swiper={swiper}
          onBeforeInit={onBeforeInit}
        />
      </Box>
      <HStack
        display={{ base: 'flex', sm: 'none' }}
        spacing="0"
        pl="10"
        overflowX="scroll"
      >
        {isLoading
          ? [1, 2].map((item) => (
              <Box key={item} minW="100%" ml="-6" pr="6" w="100%">
                <ProjectSkeletonCard />
              </Box>
            ))
          : swiper
              ?.sort((a, b) => (a.status < b.status) - (a.status > b.status))
              .map((item) => (
                <Box key={item.id} minW="100%" ml="-6" pr="6" w="100%">
                  <ProjectCard
                    goalText={t('goal')}
                    card={item}
                    goalPrice={CurrencyConverter({
                      amount: item.activeGoal?.amount || item.goal,
                      currency: currency.current,
                    })}
                    raisedMoney={CurrencyConverter({
                      currency: currency.current,
                      amount: parseInt(item.amount),
                    })}
                    ml="5px"
                    CurrencyConverter={CurrencyConverter}
                    thousandSeparatorText={thousandSeparator(
                      currency.current === 'AMD'
                        ? item.amount + Number(item.updateAmount)
                        : item.amount /
                            Number(settings?.currency[currency.current]) +
                            Number(item.updateAmount),
                      currency.current
                    )}
                    SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                    editRoute="fundraisers"
                    type={type}
                    calculatedProjectDurationLeft={calculateProjectDurationLeft(
                      {
                        t,
                        campaignImplementorPeriod_en:
                          item.campaignImplementorPeriod_en,
                        fundraisingType: item.fundraisingType,
                      }
                    )}
                    language={language}
                    currency={currency}
                    settings={settings}
                    changeDefaultCurrency={changeDefaultCurrency}
                    navigationPush={navigationPush}
                    user={user}
                    cardStatusText={t(`status@${item.status}`)}
                    cardCategoryText={t(`category@${item.category}`)}
                    editText={t('edit')}
                    unsubscribeText={t('unsubscribe')}
                    collaborationProposalsText={t(
                      item?.collaboratorsTotal === 1
                        ? 'collaboration proposal'
                        : 'collaboration proposals'
                    )}
                    collaborationProposalsTextRuEdit={t(
                      'collaboration proposals'
                    )
                      .split('...')[1]
                      .replace('запросов', 'запроса')}
                    monthlyGoalText={t('monthlyGoal')}
                    raisedText={t('raised')}
                  />
                </Box>
              ))}
      </HStack>
    </Box>
  )
}

export default Wrapper
