import { useQuery } from 'react-query'
import { initiativeUseCase } from '../../core/factories'

const useGetInitiative = ({ initiativeId }) => {
  const { error, refetch, data, isLoading } = useQuery(
    ['initiative', initiativeId],
    () => initiativeUseCase.retrieveInitiative(initiativeId))

  return {
    error,
    initiative: data,
    refetch,
    isLoading,
  }
}

export default useGetInitiative
