import { Flex, Box, Heading, Text } from '@chakra-ui/react'
import { ReactComponent as GuaranteeIcon } from '../../assets/icons/guaranteeBlack.svg'

export default function Guarantee({title, description}) {
  return (
    <Flex
      alignItems="center"
      mt="8"
      py="8"
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor="border.100"
    >
      <Box minW="64px">
        <GuaranteeIcon height="64px" width="64px" />
      </Box>
      <Box ml="6">
        <Heading mb="2" as="h2" fontSize="xl">
          {title}
        </Heading>
        <Text color="gray.700">{description}</Text>
      </Box>
    </Flex>
  )
}
