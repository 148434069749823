import { API, graphqlOperation } from 'aws-amplify'
import { createGiftCard as createGiftCardMutation } from '../../graphql/mutations'
import {
  getGiftCard as getGiftCardQuery,
  listGiftCards as listGiftCardsQuery,
  giftCardsByCode as giftCardsByCodeQuery,
} from '../../graphql/queries'

const giftCardService = () => {
  const createGiftCard = (input) =>
    API.graphql(graphqlOperation(createGiftCardMutation, { input }))

  const getGiftCard = (id) =>
    API.graphql(graphqlOperation(getGiftCardQuery, { id }))

  const listGiftCards = (variables) =>
    API.graphql(graphqlOperation(listGiftCardsQuery, variables))

  const listGiftCardsByCode = (variables) =>
    API.graphql(graphqlOperation(giftCardsByCodeQuery, variables))

  return {
    createGiftCard,
    getGiftCard,
    listGiftCards,
    listGiftCardsByCode,
  }
}

export default giftCardService
