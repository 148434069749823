export const getDefaultBindingId = (user) => {
  if (user.id === 'guest') {
    return ''
  }

  if (!user.creditCards || user.creditCards.length === 0) {
    return ''
  }

  return user.creditCards.find((creditCard) => creditCard.isDefault).bindingId
}

export const compareBonusAvailableAmount = (giftCard, amount) => {
  if (!giftCard.id) {
    return {
      makePayment: true,
      paymentAmount: amount,
    }
  }
  return {
    makePayment: giftCard.availableAmount < amount,
    paymentAmount: amount - giftCard.availableAmount,
    hasGiftCard: true,
  }
}

export const checkUtm = (string) => {
  if (string.search.includes('utm')) {
    const source = string.search.split('&')[0]
    const medium = string.search.split('&')[1]
    const campaign = string.search.split('&')[2]
    const newParam = source + '&' + medium + '&' + campaign

    return newParam
  }
  return null
}

export const generateDescription = ({
  firstName,
  lastName,
  email,
  paymentType,
  projectTitle,
}) =>
  `Dear ${firstName} ${lastName}, you are ${
    paymentType === 'recurring' ? 'monthly' : ''
  } donating to "${projectTitle}" with ${email} email address. Transaction ID - `

export const createEmptyTransactionBody = ({
  project,
  user,
  hasDefaultAmount,
  defaultAmount,
  isContribution,
}) => {
  const isGuest = user?.id === 'guest'
  return {
    bindingId: getDefaultBindingId(user || {}),
    amount: hasDefaultAmount ? defaultAmount : 0,
    reArmenia: isContribution
      ? 0
      : hasDefaultAmount
      ? (defaultAmount * 7.5) / 100
      : 0,
    email: isGuest ? '' : user?.email,
    firstName: isGuest ? '' : user?.firstName,
    lastName: isGuest ? '' : user?.lastName,
    paymentType:
      project?.fundraisingType === 'recurring' ? 'recurring' : 'oneTime',
    attachCard: true,
    subscribeNews: true,
    isAnonymous: false,
  }
}

export const createTransactionBody = ({
  user,
  project,
  formAmount,
  currency,
  currencyRate,
  email,
  firstName,
  lastName,
  language,
  data,
  paymentType,
  utm,
  appliedGiftCard,
  isContribution,
}) => {
  let amount = parseInt(formAmount)
  if (!isContribution && data.reArmenia) {
    amount += Number(data.reArmenia)
  }
  const body = {
    amount: parseInt(`${parseInt(amount * currencyRate)}00`),
    language: 'en',
    description: generateDescription({
      firstName,
      lastName,
      email,
      paymentType,
      projectTitle: project.title_en,
    }),
    redirectUrl: `${window.location.protocol}//${window.location.host}/${language}/check-transaction`,
    transactionInfo: {
      projectId: project.id,
      projectTitle: project.title_en || project.applicationTitle,
      donationToReArmenia: Number(data.reArmenia * currencyRate).toFixed(2),
      donationToProject: Number(Number(data.amount) * currencyRate).toFixed(
        currency.current === 'AMD' ? 0 : 2
      ),
      userId: user?.id ?? '',
      email,
      firstName,
      lastName,
      subscribeForMonthly: data.paymentType === 'recurring',
      isAnonymous: data.isAnonymous,
      attachCard: user.id === 'guest' ? false : data.attachCard,
      isManual: false,
      utm,
      currency: currency.current,
      currencyRate,
    },
  }
  body.clientId = email

  if (data.bindingId) {
    body.localBindingId = data.bindingId
    body.attachCard = false
  }

  const compareResult = compareBonusAvailableAmount(
    appliedGiftCard.current,
    amount
  )
  if (compareResult.hasGiftCard) {
    body.amount = `${compareResult.paymentAmount}00`
    body.transactionInfo.giftCardAmount =
      appliedGiftCard.current.availableAmount
    body.transactionInfo.giftCardId = appliedGiftCard.current.id
    body.transactionInfo.giftCardCode = appliedGiftCard.current.code
    body.transactionInfo.giftCardTransactionType = 'spend'
  }
  return { body, compareResult }
}
