import { Box, Flex, Button, Text } from '@chakra-ui/react'
import {
  useCurrencySettings,
  thousandSeparator,
} from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'
import {} from 'hooks'
import { Link } from 'pages'
import ApplyGiftCard from './ApplyGiftCard'

const Cart = ({
  onDonate,
  donation,
  giftCard,
  type,
  isLoading,
  isValid,
  isGiftCardDisabled,
  giftCardAmount,
  setGiftCardAmount,
  appliedGiftCard,
  project,
  isContribution,
}) => {
  const { t } = useTranslation()
  const { currency } = useCurrencySettings()

  return (
    <Box pt="8" w="full">
      <Box
        mx="auto"
        pt="6"
        px="6"
        pb="5"
        border="1px solid"
        borderColor="border.100"
        borderRadius="16px"
      >
        {!isContribution && (
          <>
            <Flex mb="4" justifyContent="space-between">
              <Box fontWeight="regular" color="gray.800" fontSize="lg">
                {t(
                  `donation@${
                    isGiftCardDisabled ? 'yourMonthlyDonation' : 'yourDonation'
                  }`
                )}
              </Box>
              <Box
                as="span"
                fontWeight="medium"
                fontSize="lg"
                whiteSpace="nowrap"
              >
                {thousandSeparator(Number(donation.amount), currency.current)}{' '}
                {currency.current}
              </Box>
            </Flex>
            <Flex mb="4" justifyContent="space-between">
              <Box fontWeight="regular" color="gray.800" fontSize="lg">
                {t(
                  `donation@${
                    isGiftCardDisabled ? 'reArmeniaTipMonthly' : 'reArmeniaTip'
                  }`
                )}
              </Box>
              <Box
                as="span"
                fontWeight="medium"
                fontSize="lg"
                whiteSpace="nowrap"
              >
                {thousandSeparator(
                  Number(donation.reArmenia),
                  currency.current
                )}{' '}
                {currency.current}
              </Box>
            </Flex>
          </>
        )}
        {giftCardAmount && giftCardAmount > 0 ? (
          <Flex justifyContent="space-between">
            <Box fontWeight="regular" color="gray.700" fontSize="lg">
              {t('donation@bonus')}
            </Box>
            <Box
              as="span"
              fontWeight="medium"
              fontSize="lg"
              whiteSpace="nowrap"
            >
              {thousandSeparator(Number(giftCardAmount), currency.current)}{' '}
              {currency.current}
            </Box>
          </Flex>
        ) : (
          <></>
        )}
        <Flex justifyContent="space-between">
          <Box fontWeight="bold" color="gray.800" fontSize="lg">
            {t(`donation@${isGiftCardDisabled ? 'totalMonthly' : 'total'}`)}
          </Box>
          <Box fontWeight="bold" fontSize="lg">
            {thousandSeparator(
              (Number(donation.reArmenia) ?? 0) +
                (Number(donation.amount) ?? 0),
              currency.current
            )}{' '}
            {currency.current}
          </Box>
        </Flex>
      </Box>
      {/* TODO: UNCOMMENT WHEN GIFTS CARD WILL BE ENABLED */}
      {/* {giftCard && !isGiftCardDisabled && (
        <ApplyGiftCard
          isGiftCardDisabled={isGiftCardDisabled}
          appliedGiftCard={appliedGiftCard}
          setGiftCardAmount={setGiftCardAmount}
        />
      )} */}
    </Box>
  )
}

export default Cart
