const donationRepo = (donationService) => {
  const payment = (params) => donationService.payment(params)
  const checkOrderStatus = (params) => donationService.checkOrderStatus(params)
  return {
    payment,
    checkOrderStatus
  }
}

export default donationRepo
