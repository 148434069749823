import React, { useState } from 'react'
import {
  Box,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { ReactComponent as AngleDown } from 'assets/icons/angleDown.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'

const MenuButtonBox = ({ children, variant, ...props }) => {
  if (variant === 'border') {
    return (
      <Box
        fontWeight="bold"
        justifyContent="space-between"
        px={4}
        py={2}
        {...props}
      >
        {children}
      </Box>
    )
  }

  if (variant === 'noBorder') {
    return (
      <Box fontWeight="600" {...props}>
        {children}
      </Box>
    )
  }

  if (variant === 'big') {
    return (
      <Box fontWeight="700" fontSize="3xl" {...props}>
        {children}
      </Box>
    )
  }

  return (
    <Box
      justifyContent="center"
      borderBottom="2px"
      borderColor="transparent"
      transition="all 0.2s"
      whiteSpace="nowrap"
      fontSize="lg"
      fontWeight="regular"
      bg="gray.100"
      zIndex={2}
      px="2"
      {...props}
    >
      {children}
    </Box>
  )
}

export const BlueSelect = ({
  label,
  selectOption,
  options,
  isInvalid,
  errorMessage,
  display,
  type,
  white = false,
  width = 'auto',
  offset = [0, 0],
  amount = 0,
  currentSelectedOption,
  variant,
  maxW,
  languageLabel = '',
  isDisabled = false,
  minW,
  ...props
}) => {
  const [showOverflow, setShowOverflow] = useState(false)
  return (
    <Box
      width={width}
      maxW={maxW}
      minW={minW}
      display={display}
      justifyContent="flex-end"
      position="relative"
      overflow={showOverflow ? 'visible' : 'hidden'}
      {...props}
    >
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          left="2"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage}
        </Box>
      )}
      <Menu
        offset={offset}
        variant={variant}
        onOpen={() => setShowOverflow(true)}
        onClose={() => setTimeout(() => setShowOverflow(false), 400)}
        closeOnSelect
      >
        <MenuButton  as={variant === 'big' ? Button : MenuButton} w={width}>
          <MenuButtonBox
            variant={variant}
            label={label}
            display="flex"
            alignItems="center"
            whiteSpace="pre"
            color={white ? 'gray.100' : 'black'}
          >
            {label}
            <AngleDown
              fill={'black'}
              style={{ marginLeft: 10, fill: white ? '#ffffff' : 'gray.800' }}
              width={10}
              height={8}
            />
          </MenuButtonBox>
        </MenuButton>
        <MenuList
          boxShadow="0 12px 16px 0 rgba(0,0,0,0.15)"
          border="none"
        >
          {options.map((option) => (
            <MenuItem
            isDisabled={isDisabled} 
              key={`${type}-${option.title}`}
              onClick={() => selectOption(option.value)}
              icon={
                <CheckIcon
                  opacity={currentSelectedOption === option.title ? 1 : 0}
                  width="13px"
                />
              }
            >
              {option.title}
              {amount > 0 && (
                <>
                  {` | `}
                  {option.amount}
                </>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}
