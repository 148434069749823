import { useState } from 'react'
import { useQuery } from 'react-query'
import { userUseCase } from '../../core/factories'

const useGetUser = () => {
  const [userId, setUserId] = useState('')

  const { error, data } = useQuery(
    ['user', userId],
    async () => {
      return await userUseCase.getUserById(userId)
    },
    {
      enabled: !!userId,
    }
  )

  const getUserById = (id) => {
    setUserId(id)
  }

  return {
    error,
    user: data,
    getUserById,
  }
}

export default useGetUser
