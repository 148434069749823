import { CATEGORY, GENERAL_USER_VISIBLE_STATUSES } from '_constants'

export const applicationsListQuery = (
  {
    projectType = 'fundraisers',
    isRecurringChecked = false,
    category,
    status,
    search,
  },
  PAGE_SIZE = 16
) => {
  const must = () => {
    const mustArray = [
      {
        match:
          projectType === 'fundraisers'
            ? {
                isProject: true,
              }
            : {
                isCollaboration: true,
              },
      },
    ]

    if (projectType === 'fundraisers' && !isRecurringChecked) {
      mustArray.push({
        match: {
          fundraisingType: 'oneTime',
        },
      })
    }

    if (category) {
      mustArray.push({
        match: {
          category,
        },
      })
    }
    if (status) {
      mustArray.push({
        match: {
          status,
        },
      })
    }

    if (search) {
      mustArray.push({
        multi_match: {
          query: search,
          type: 'phrase_prefix',
          fields: ['applicationTitle', 'title_en', 'title_hy', 'title_ru'],
        },
      })
    }

    return mustArray
  }
  return {
    size: PAGE_SIZE,
    from: 0,
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `      
                if (doc['status.keyword'].value == 'submitted') {return  1}     
                else if (doc['status.keyword'].value == 'reviewed_by_ss') {return  2}     
                else if (doc['status.keyword'].value == 'reviewed_by_am') {return  3}       
                else if (doc['status.keyword'].value == 'reviewed_by_ab') {return  4} 
                else if (doc['status.keyword'].value == 'reviewed_by_bot') {return  5} 
                else if (doc['status.keyword'].value == 'project_page_development') {return  6} 
                else if (doc['status.keyword'].value == 'review_project_page') {return  7} 
                else if (doc['status.keyword'].value == 'published_by_owner') {return  8} 
                else if (doc['status.keyword'].value == 'launching_soon') {return  9} 
                else if (doc['status.keyword'].value == 'on_hold_by_am') {return  10} 
                else if (doc['status.keyword'].value == 'on_hold_by_tns') {return  11} 
                else if (doc['status.keyword'].value == 'rejected_by_ss') {return  12} 
                else if (doc['status.keyword'].value == 'rejected_by_am') {return  13} 
                else if (doc['status.keyword'].value == 'rejected_by_tns') {return  14} 
                else if (doc['status.keyword'].value == 'rejected_by_ab') {return  15} 
                else if (doc['status.keyword'].value == 'rejected_by_bot') {return  16} 
                return 20;`,
          },
          order: 'asc',
        },
      },
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `
          if (doc['status.keyword'].value == 'submitted') {return doc['createdAt'].value.getMillis()}     
           return doc['statusUpdatedAt'].value.getMillis();`,
          },
          order: 'desc',
        },
      },
    ],
    query: {
      bool: {
        must: must(),
        must_not: [
          {
            terms: {
              status: ['live', 'ended', 'closed', 'terminated'],
            },
          },
        ],
      },
    },
  }
}

export const adminProjectsQuery = (
  { type = 'fundraisers', category, status, search, projectType = 'oneTime' },
  PAGE_SIZE = 16
) => {
  const typeObject =
    type === 'fundraisers'
      ? {
          isProject: true,
        }
      : {
          isCollaboration: true,
        }
  const must = () => {
    const mustArray = [
      {
        match: typeObject,
      },
      {
        terms: {
          status: [
            'live',
            'ended',
            'closed',
            'terminated',
            'waiting_for_transfer_confirmation',
          ],
        },
      },
    ]
    if (type === 'fundraisers') {
      mustArray.push({
        match: {
          fundraisingType: projectType,
        },
      })
    }
    if (category) {
      mustArray.push({
        match: {
          category,
        },
      })
    }
    if (status) {
      mustArray.push({
        match: {
          status,
        },
      })
    }

    if (search) {
      mustArray.push({
        multi_match: {
          query: search,
          type: 'phrase_prefix',
          fields: ['title_en', 'title_hy', 'title_ru', 'applicationTitle'],
        },
      })
    }
    return mustArray
  }
  return {
    from: 0,
    size: PAGE_SIZE,
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `       
            if (doc['status.keyword'].value == 'live') {return  1} 
            else if (doc['status.keyword'].value == 'ended') {return  2}
            else if (doc['status.keyword'].value == 'waiting_for_transfer_confirmation') {return  3}
            else if (doc['status.keyword'].value == 'closed') {return  4} 
            else if (doc['status.keyword'].value == 'terminated') {return  5} 
              return 6;`,
          },
          order: 'asc',
        },
      },
      { publishedAt: { order: 'desc' } },
    ],
    query: {
      bool: {
        must: must(),
      },
    },
  }
}

export const similarProjectsQuery = ({
  category,
  region,
  projectId,
  isCollaboration,
}) => ({
  size: 4,
  from: 0,
  sort: [
    {
      _script: {
        type: 'number',
        script: {
          lang: 'painless',
          source: `      
              if (doc['category.keyword'].value == '${category}') {return  1}
              else if (doc['region.keyword'].value == '${region}') {return  2}
              return doc['createdAt'].value.getMillis();`,
        },
        order: 'asc',
      },
    },
  ],
  query: {
    bool: {
      must: [
        {
          terms: {
            status: ['live'],
          },
        },
        {
          match: {
            isProject: !isCollaboration,
          },
        },
      ],
      must_not: [{ match: { id: projectId } }],
    },
  },
})

export const transactionsHistoryQuery = ({ from = 0, size = 10, email }) => {
  return {
    size,
    from,
    sort: [{ createdAt: { order: 'desc' } }],
    query: {
      bool: {
        must: [
          {
            match: {
              email,
            },
          },
        ],
      },
    },
  }
}

export const projectsQuery = (fundraisingType = 'oneTime', category) => {
  const categoryTerms = {
    terms: {
      category: category ? [category] : CATEGORY,
    },
  }
  const size = 12
  return {
    size,
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `int sortOrder = 0;       
          if (doc['status.keyword'].value == 'live') {sortOrder =  1}     
          else if (doc['status.keyword'].value == 'launching_soon') {sortOrder =  2}     
          else if (doc['status.keyword'].value == 'ended') {sortOrder =  3}     
          else if (doc['status.keyword'].value == 'closed') {sortOrder =  4}       
          else if (doc['status.keyword'].value == 'terminated') {sortOrder =  5} 
          sortOrder;`,
          },
          order: 'asc',
        },
      },
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `
          if (doc['status.keyword'].value == 'live') {return doc['publishedAt'].value.getMillis()}     
           return 0;`,
          },
          order: 'desc',
        },
      },
      { 'total.goalMetPercentage': { order: 'desc' } },
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              isProject: true,
            },
          },
          {
            match: {
              fundraisingType: fundraisingType || 'oneTime',
            },
          },
          {
            terms: {
              status: GENERAL_USER_VISIBLE_STATUSES,
            },
          },
          categoryTerms,
        ],
      },
    },
  }
}

export const collaborationsQuery = (category) => {
  const categoryTerms = {
    terms: {
      category: category ? [category] : CATEGORY,
    },
  }
  return {
    size: 24,
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `int sortOrder = 0;       
            if (doc['status.keyword'].value == 'live') {sortOrder =  1}     
            else if (doc['status.keyword'].value == 'launching_soon') {sortOrder =  2}     
            else if (doc['status.keyword'].value == 'ended') {sortOrder =  3}     
            else if (doc['status.keyword'].value == 'closed') {sortOrder =  4}       
            else if (doc['status.keyword'].value == 'terminated') {sortOrder =  5} 
            sortOrder;`,
          },
          order: 'asc',
        },
      },
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `
            if (doc['status.keyword'].value == 'live') {
              if(doc['publishedAt'].size()==0) {return 10}
              return doc['publishedAt'].value.getMillis()}     
             return 0;`,
          },
          order: 'desc',
        },
      },
      // fix elastic bug change collaboratorsTotal type to number
      // { collaboratorsTotal: { order: 'desc' } },
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              isCollaboration: true,
            },
          },
          {
            terms: {
              status: GENERAL_USER_VISIBLE_STATUSES,
            },
          },
          categoryTerms,
        ],
      },
    },
  }
}
