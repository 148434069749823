import React, { useMemo } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Date = ({
  startDate,
  setStartDate,
  label,
  minDate,
  showTimeSelect,
  ...rest
}) => {
  const datePickerForms = useMemo(() => {
    if (showTimeSelect) {
      return {
        showTimeSelect: true,
        dateFormat: 'Pp',
      }
    }
    return {}
  }, [showTimeSelect])
  return (
    <FormControl {...rest}>
      <FormLabel
        fontSize="md"
        fontWeight="regular"
        color="black"
        zIndex={2}
        px="2"
      >
        {label}
      </FormLabel>
      <DatePicker
        minDate={minDate}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        {...datePickerForms}
      />
    </FormControl>
  )
}

export default Date
