import React from 'react'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  Flex,
} from '@chakra-ui/react'
import { ReactComponent as Back } from '../../../assets/icons/back.svg'
import { CATEGORY, REGIONS } from '_constants'
import { useTranslation } from 'contexts/TranslationContext'

const Filter = ({
  filters,
  setFilters,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation()
  return (
    <Drawer
      size="full"
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
  
        <DrawerHeader
          py="12px"
          w="full"
          borderBottom="1px solid"
          borderColor="border.100"
          display="flex"
          alignItems="center"
          justifyContent="center">
            <Flex
              alignItems="center"
              justifyContent="center"
              w="30px"
              h="30px"
              onClick={onClose}
              left="12px"
              position="absolute">
              <Back/>
            </Flex>
            Filters
            <Button
              isDisabled={!filters?.region && !filters?.category}
              position="absolute"
              right="15px"
              color={(!filters?.region && !filters?.category) ? 'gray.700' : "blue.300"}
              textTransform="capitalize"
              variant="transparent"
              onClick={() => setFilters({
                text: '',
                category: '',
                region: ''
              })}>
              {t('Remove')}
            </Button>
        </DrawerHeader>
        <DrawerBody
          p="1.5rem 1.25rem 3rem 1.25rem">
        
            <Box
              mb="4"
              display="block"
              as="span"
              textTransform="uppercase"
              color="gray.700"
              fontWeight="700"
              fontSize="sm">
              {t('Categories')}
            </Box>
            <Flex
              mb="5"
              flexWrap="wrap"
              alignItems="flex-start">
              {
                CATEGORY.map(category => (
                  <Box
                    mb="2"
                    mr="2"
                    bg={filters?.category === category ? 'blue.300' : 'transparent'}
                    fontSize="md"
                    border="1px solid"
                    borderRadius="12px"
                    p="3"
                    borderColor={filters?.category === category ? 'blue.300' : 'border.100'}
                    as="span"
                    onClick={() => setFilters({...filters, category: category})}
                    color={filters?.category === category ? "gray.100" : 'gray.800'}>
                    {t(`category@${category}`)}
                  </Box>
                ))
              }
            </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default Filter
