import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Box } from '@chakra-ui/react'
import { useProject } from '../../ProjectContext'
import FilesUpload from 'pages/CreateProject/BudgetFiles'

export default function BudgetFiles() {
  const { project, onUpdate } = useProject()
  const { setValue, getValues, reset, register, formState: { errors }, watch } = useForm({
    defaultValues: {
      budgetFiles: project?.budgetFiles || [],
    },
  })

  const budgetFiles = watch('budgetFiles', [])

  const onUpload = useCallback(
    (key, value) => {
      setValue(key, value)
      const input = {
        id: project.id,
        budgetFiles: value,
      }
      onUpdate(input)
    },
    [project]
  )

  useEffect(() => {
    if (!project) return
    reset({'budgetFiles': project.budgetFiles})
  }, [project])

  return (
    <Box mt="4">
      <FilesUpload budgetFiles={budgetFiles} register={register} errors={errors} setValue={onUpload} getValues={getValues} />
    </Box>
  )
}
