const blogRepo = (contentfulService) => {
  const getBlog = async (slug) => contentfulService.get({ contentType: 'newsSection', slug })

  const listBlog = async () => contentfulService.list('newsSection')

  return {
    getBlog,
    listBlog,
  }
}

export default blogRepo
