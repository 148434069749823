import React from 'react'
import { Flex, Heading, Image, Box, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const Header = () => {
  const { t } = useTranslation()
  return (
    <Flex
      justifyContent="space-between"
      w="full"
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
      style={{ minHeight: 'calc(100vh - 109px)' }}
      mb={16}
    >
      <Flex
        direction="column"
        pl={{ base: '1.875rem', sm: '2.5rem', md: '9.25rem' }}
        pr={{ base: '1.875rem', sm: '2.5rem', md: '4rem' }}
        flex="1"
        justifyContent="center"
      >
        <Heading
          my={{ base: '5', sm: '8' }}
          textAlign="flexS"
          fontWeight="700"
          fontSize="5xl"
          color="blue.300"
          as="h1"
        >
          {t('rearmenia')}
        </Heading>
        <Text fontSize="2xl">{t('about@description')}</Text>
        <Heading
          my={{ base: '5', sm: '8' }}
          textAlign="flexS"
          fontWeight="700"
          fontSize="5xl"
          color="blue.300"
          as="h1"
        >
          {t('about@WhoWe')}
        </Heading>
        <Text fontSize="2xl">{t('about@WhoWeDesc')}</Text>
      </Flex>
      <Box
        mt={{ base: '12', md: '0' }}
        position="relative"
        height={{ base: '300px', md: 'calc(100vh - 109px)' }}
        w={{ base: '100%', md: '30%' }}
      >
        <Image
          w="100%"
          h="100%"
          objectFit="cover"
          src="/assets/images/about.jpg"
        />
        <Box
          bottom={{ base: 'unset', md: '5%' }}
          left={{ base: 'unset', md: '-48px' }}
          top={{ base: '-32px', md: 'unset' }}
          right={{ base: '5%', md: 'unset' }}
          position="absolute"
          bg="blue.400"
          borderRadius="100px"
          width={{ base: '64px', md: '96px' }}
          height={{ base: '64px', md: '96px' }}
        />
      </Box>
    </Flex>
  )
}

export default Header
