import { useState } from 'react'
import { useQuery } from 'react-query'
import { projectUseCase } from '../../core/factories'

const useProjectsByFeatured = (initialFilter, withNextToken = false, enabled = true, key = 'get_projects_by_featured') => {
  const [filters, setFilters] = useState(initialFilter)

  const {
    error,
    refetch,
    isLoading,
    data: projects,
  } = useQuery(
    [key, filters],
    async () => {
      return await projectUseCase.getProjectsByFeatured(filters, withNextToken)
    },
    {
      enabled
    })

  return {
    refetch,
    isLoading,
    projects,
    filters,
    setFilters,
    error,
  }
}

export default useProjectsByFeatured
