import * as React from 'react'
import { Box, Stack } from '@chakra-ui/react'
import { FooterHeading } from './FooterHeading'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import links from './links'

export const LinkGridMobile = (props) => {
  const { t } = useTranslation()

  return (
    <Stack style={{ marginBottom: '15px' }} alignItems="center" {...props}>
      {links.map(({ href, label }) => (
        <FooterHeading key={`link-grid-mobile-${href}`}>
          <Link to={href}>
            <Box fontSize="xl">{t(label)}</Box>
          </Link>
        </FooterHeading>
      ))}
    </Stack>
  )
}
