import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  SimpleGrid,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import { Link } from 'pages'
import { useUser, useProjectsByUserId } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import FilteredData from './FilteredData'
import { useDeleteDraft, useFundraisersDraftsByUserId } from 'core/Draft/hooks'
import { useModal } from 'providers/ModalProvider'

const Projects = () => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { projects, isLoading } = useProjectsByUserId({
    owner: user.id,
    filter: {
      isProject: { eq: true },
    },
  })
  const { data: draftData, refetch } = useFundraisersDraftsByUserId(user.id)
  const { mutate: deleteDraft, isLoading: isDraftDeletionLoading } =
    useDeleteDraft()
  const { openModal, closeModal } = useModal()
  const onDraftDelete = (id) => {
    openModal('deleteDraft', null, false, () => {}, {
      onDelete: () => {
        deleteDraft(id, {
          onSuccess: () => {
            refetch()
          },
          onError: (err) => {
            console.log('error', err)
          },
          onSettled: () => {
            closeModal()
          },
        })
      },
      isDraftDeletionLoading,
    })
  }

  if (isLoading)
    return (
      <Flex alignItems="center" justifyContent="center" height="300px">
        <Spinner color="blue.400" />
      </Flex>
    )

  return (
    <>
      {projects?.length > 0 && (
        <Link to="/pre-create-project">
          <Button
            display={{ base: 'inline-flex', md: 'none' }}
            color="blue.400"
            minH="50px"
            variant="transparent"
            textTransform="capitalize"
          >
            {t('profile@createProject')}
          </Button>
        </Link>
      )}
      <Tabs>
        <TabList
          position="relative"
          sx={{
            '&': {
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            },
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          borderColor="border.100"
        >
          <Tab
            textTransform="capitalize"
            borderColor="border.100"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
          >
            {t('profile@all')}
          </Tab>
          <Tab
            textTransform="capitalize"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
          >
            {t('profile@active')}
          </Tab>
          <Tab
            textTransform="capitalize"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
          >
            {t('profile@completed')}
          </Tab>
          <Tab
            textTransform="capitalize"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
          >
            {t('profile@submitted')}
          </Tab>
          <Tab
            textTransform="capitalize"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
            whiteSpace="nowrap"
          >
            {t('profile@onHold')}
          </Tab>
          <Tab
            textTransform="capitalize"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
            whiteSpace="nowrap"
          >
            {t('status@draft')}
          </Tab>
          {projects?.length > 0 && (
            <Link to="/pre-create-project">
              <Button
                display={{ base: 'none', md: 'inline-flex' }}
                color="blue.400"
                variant="transparent"
                right="0"
                top="0"
                bottom="0"
                my="auto"
                position="absolute"
                textTransform="capitalize"
              >
                {t('profile@createProject')}
              </Button>
            </Link>
          )}
        </TabList>

        <TabPanels>
          <TabPanel p="0" position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={['all']}
                data={projects}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={[
                  'live',
                  'launching_soon',
                  'waiting_for_transfer_confirmation',
                ]}
                data={projects}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={['ended', 'closed', 'terminated']}
                data={projects}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={[
                  'submitted',
                  'reviewed_by_ss',
                  'reviewed_by_am',
                  'reviewed_by_tns',
                  'reviewed_by_bot',
                  'project_page_development',
                  'review_project_page',
                  'published_by_owner',
                ]}
                data={projects}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={[
                  'on_hold_by_am',
                  'on_hold_by_tns',
                  'rejected_by_ss',
                  'rejected_by_am',
                  'rejected_by_tns',
                  'rejected_by_bot',
                  'project_page_development',
                  'review_project_page',
                ]}
                data={projects}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={['all']}
                data={draftData?.items}
                onDraftDelete={onDraftDelete}
              />
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default Projects
