import React from 'react'
import { Stack, Box, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import Goals from 'components/GoalsForm'
import FilesUpload from './BudgetFiles'
import RequestedAmount from './RequestedAmount'

const Fundraiser = ({
  register,
  errors,
  setValue,
  goals,
  fundraisingType,
  append,
  remove,
  oldId,
  getValues,
  budgetFiles,
}) => {
  const { t } = useTranslation()
  return (
    <Box maxW="600px" width="100%">
      <Box display={'flex'}>
        <Heading mb="6" fontSize="6xl" as="h2">
          {fundraisingType === 'oneTime'
            ? t('projectCreate@oneTime')
            : t('projectCreate@recurring')}
        </Heading>
      </Box>
      {!!oldId && (
        <Text mb="8" color="gray.700" fontSize="md">
          {t('Collaboration ID:')} {oldId}
        </Text>
      )}
      {fundraisingType === 'recurring' && (
        <>
          <Text fontWeight={600} fontSize="md" color="gray.800">
            {t('projectCreate@recurringText1')}
          </Text>
          <Text fontSize="md" pt={5} pb="30px">
            {t('projectCreate@recurringText2')}
          </Text>
        </>
      )}
      <Stack spacing="6">
        {fundraisingType === 'oneTime' ? (
          <>
            <RequestedAmount errors={errors} register={register} />
            <Box mt="32px !important">
              <Goals
                goals={goals}
                errors={errors}
                remove={remove}
                register={register}
                append={append}
                getValues={getValues}
              />
            </Box>
          </>
        ) : (
          <Goals
            goals={goals}
            errors={errors}
            remove={remove}
            register={register}
            append={append}
            getValues={getValues}
            isRequired={true}
          />
        )}
        <Box h="1px" w="100%" bg="border.100" mt="32px" mb="24px" />
        <FilesUpload
          budgetFiles={budgetFiles}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
        />
      </Stack>
    </Box>
  )
}

export default Fundraiser
