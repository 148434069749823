import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useLocalStorage, useGetSettings } from 'hooks'
import { LANGUAGES, useTranslation } from './TranslationContext'

export const thousandSeparator = (number, currency = 'AMD', toFixed = true) => {
  if (!number) return 0
  return Number(number)
    .toFixed(currency === 'AMD' || !toFixed ? 0 : 2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const LOCAL_STORAGE_DEFAULT_CURRENCY_KEY = 'default_currency'
const LOCAL_STORAGE_SETTINGS_KEY = 'settings_cache'

export const SUPPORTED_CURRENCIES = ['AMD', 'RUB', 'USD', 'EUR']
export const SUPPORTED_CURRENCY_SYMBOLS = {
  AMD: '֏',
  RUB: '₽',
  USD: '$',
  EUR: '€',
}

export const CurrencySettingsContext = createContext({
  translatedCurrency: '',
  currency: { current: '', default: '' },
  settings: {},
  changeDefaultCurrency: () => {},
  convertCurrency: () => {},
})

export const useCurrencySettings = () => useContext(CurrencySettingsContext)

export const CurrencyConverter = ({ amount, currency, normalize = true }) => {
  const { settings } = useCurrencySettings()

  let price = amount
  if (currency === 'RUB') {
    price = amount / (settings?.currency ? settings?.currency['RUB'] : 0)
  }
  if (currency === 'USD') {
    price = amount / (settings?.currency ? settings?.currency['USD'] : 0)
  }
  if (currency === 'EUR') {
    price = amount / (settings?.currency ? settings?.currency['EUR'] : 0)
  }

  return normalize ? thousandSeparator(price, currency) : price
}

export const convert = ({ amount, currencyRate }) => {
  return amount / currencyRate
}

export const SettingsProvider = ({ children }) => {
  const { data: response, isLoading } = useGetSettings()
  const { put, get } = useLocalStorage()
  const { t, language } = useTranslation()
  const [currency, setCurrency] = useState({
    current:
      get(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY) || LANGUAGES[language].currency,
    default: get(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY),
  })
  const [settings, setSettings] = useState({})

  const translatedCurrency = useMemo(() => {
    return t(`currency@${currency.current.toLowerCase()}`)
  }, [language, currency])

  const changeDefaultCurrency = useCallback(
    (value) => {
      setCurrency({ default: value, current: value })
      put(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY, value)
    },
    [setCurrency]
  )

  useEffect(() => {
    if (!response) {
      try {
        const settings_cache = JSON.parse(get(LOCAL_STORAGE_SETTINGS_KEY))
        setSettings(settings_cache)
      } catch (err) {
        console.log(err)
      }
      return
    }
    const settings = { ...response }
    if (settings?.currency) settings.currency = JSON.parse(settings.currency)
    put(LOCAL_STORAGE_SETTINGS_KEY, JSON.stringify(settings))
    setSettings(settings)
  }, [response])

  useEffect(() => {
    setCurrency({
      current:
        get(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY) || LANGUAGES[language].currency,
      default: get(LOCAL_STORAGE_DEFAULT_CURRENCY_KEY),
    })
  }, [language])

  return (
    <CurrencySettingsContext.Provider
      value={{
        isLoading,
        settings,
        currency,
        changeDefaultCurrency,
        translatedCurrency,
      }}
    >
      {children}
    </CurrencySettingsContext.Provider>
  )
}
