import React from 'react'
import {
  Tabs as ChakraTabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import Story from './Story'
import Details from './Details'
import Update from './Update'

const Tabs = ({ initiative }) => {
  const { user } = useUser()
  const { t } = useTranslation()

  return (
    <ChakraTabs px={{ base: '5', lg: '8' }} maxW="642px">
      <TabList
        sx={{
          '&': {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        overflowX={{ base: 'scroll', sm: 'visible' }}
        overflowY={{ base: 'hidden', sm: 'visible' }}
        borderColor="border.100"
      >
        <Tab
          _active={{ backgroundColor: 'transparent' }}
          _focus={{ outline: 'none' }}
          _selected={{
            color: 'blue.400',
            borderColor: 'blue.400',
            fontWeight: 'bold',
          }}
          py="5"
        >
          {t('story')}
        </Tab>
        {user.isSuper && (
          <Tab
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
          >
            {t('details')}
          </Tab>
        )}
        {(initiative.status === 'live' ||
          initiative.status === 'launching_soon' ||
          initiative.status === 'ended') && (
          <Tab
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="5"
          >
            {t('updates')}
          </Tab>
        )}
      </TabList>

      <TabPanels>
        <TabPanel>
          <Story />
        </TabPanel>
        {user.isSuper && (
          <TabPanel>
            <Details initiative={initiative} />
          </TabPanel>
        )}
        <TabPanel>
          <Update initiative={initiative} />
        </TabPanel>
      </TabPanels>
    </ChakraTabs>
  )
}

export default Tabs
