import React from 'react'
import { Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const BottomBar = ({
  isDraftLoading,
  saveDraft,
  tabIndex,
  next,
  prev,
  limit,
}) => {
  const { t } = useTranslation()

  return (
    <Flex
      zIndex="10"
      bg="gray.100"
      py="4"
      px={{ base: '2rem', md: '5.5rem' }}
      justifyContent="flex-end"
      borderTop="1px solid"
      borderColor="#CDCDCD"
      width="full"
      bottom="0"
      left="0"
      position="fixed"
    >
      <Button
        variant="transparent"
        color="gray.700"
        textDecor="underline"
        textTransform="none"
        onClick={saveDraft}
        isLoading={isDraftLoading}
      >
        {t('navigation@draft')}
      </Button>
      <Button
        id="create_project_back"
        variant="white"
        mx={8}
        px="56px"
        onClick={() => prev()}
      >
        {t('donation@back')}
      </Button>
      {tabIndex === limit && (
        <Button
          id="create_project_submit"
          variant="green"
          px="56px"
          type="submit"
        >
          {t('submit')}
        </Button>
      )}
      {tabIndex !== limit && (
        <Button
          id="create_project_next"
          variant="green"
          px="56px"
          onClick={next}
        >
          {t('createEditProject@next')}
        </Button>
      )}
    </Flex>
  )
}

export default BottomBar
