import DefaultLayout from '../containers/DefaultLayout'
import Homepage from './Homepage'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Register from './Register'
import CreateProject from './CreateProject'
import PreCreateProject from './PreCreateProject'
import Donation from './Donation'
import Project from './Project'
import Projects from './Projects'
import Initiatives from './Initiatives'
import SelectGiftCard from './SelectGiftCard'
import OrderGiftCard from './OrderGiftCard'
import CollaborationRequest from './CollaborationRequest'
import Initiative from './Initiative'
import Profile from './Profile'
import ProfileCollab from './Profile/MobileProfile/MyCollaborations'
import ProfileFund from './Profile/MobileProfile/MyFundraisers'
import MySettings from './Profile/MobileProfile/Settings'
import EditProject from './EditProject'
import Terms from './Terms'
import ContactUs from './ContactUs'
import PaymentsPage from './PaymentPage'
import MobileDonation from './MobileDonation'
import MobileInitiative from './MobileInitiative'
import ChangePassword from './ChangePassword'
import Verify from './Verify'
import ResetPassword from './ResetPassword'
import Search from './Search'
import Privacy from './Privacy'
import About from './About'
import ThankYouGiftCard from './ThankYouGiftCard'
import ThankYou from './ThankYou'
import ThankYouSubscribe from './ThankYouSubscribe'
import CheckTransaction from './CheckTransaction'
import CheckBinding from './CheckBinding'
import Done from './Done'
import ThankYouSignUp from './ThankYouSignUp'
import MobileMore from './MobileMore'
import MobileActNow from './MobileActNow'
import MobileContribute from './MobileContribute'
import ThankYouActNow from './ThankYouAckNow'
import Testimonials from './Testimonials'
import SuccessStory from './SuccessStory'
import Blog from './Blog'
import BlogSingle from './BlogSingle'
import UnsubscribeDonation from './UnsubscribeDonation'
import Fallback from './Fallback'
import ProjectsDashboard from './ProjectsDashboard'
import ApplicationsDashboard from './ApplicationsDashboard'
import SetPassword from './SetPassword'

const routes = [
  {
    Component: CheckTransaction,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/check-transaction',
  },
  {
    Component: CheckBinding,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/check-binding',
  },
  {
    Component: UnsubscribeDonation,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/unsubscribe-donation/:subscriptionId',
  },
  {
    Component: ThankYouSubscribe,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/subscribe/thank-you',
  },
  {
    Component: ThankYou,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/donate/thank-you',
  },
  /* TODO: UNCOMMENT WHEN GIFTS CARD WILL BE ENABLED */
  // {
  //   Component: ThankYouGiftCard,
  //   Layout: DefaultLayout,
  //   secured: false,
  //   exact: true,
  //   path: '/:lang/gift-card/thank-you',
  // },
  {
    Component: About,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/about',
  },
  {
    Component: Search,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/search',
  },
  {
    Component: ResetPassword,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/reset-password',
  },
  {
    Component: Verify,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/verify',
  },
  {
    Component: SetPassword,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/set-password',
  },
  {
    Component: ChangePassword,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/change-password',
  },
  {
    Component: MobileInitiative,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/act/:initiativeId',
  },
  {
    Component: MobileDonation,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/donate/:projectId',
  },
  {
    Component: CollaborationRequest,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/collaboration-request',
  },
  {
    Component: PaymentsPage,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/payment',
  },
  {
    Component: ContactUs,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/contact-us',
  },
  {
    Component: Privacy,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/privacy-policy',
  },
  {
    Component: Terms,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/terms-and-conditions',
  },
  {
    Component: EditProject,
    Layout: DefaultLayout,
    secured: false,
    exact: false,
    path: [
      '/:lang/edit-fundraisers/:projectId',
      '/:lang/edit-collaborations/:projectId',
    ],
  },
  {
    Component: Profile,
    Layout: DefaultLayout,
    secured: true,
    exact: false,
    path: '/:lang/profile',
  },
  {
    Component: ProfileCollab,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/my-collaborations',
  },
  {
    Component: ProfileFund,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/my-fundraisers',
  },
  {
    Component: MySettings,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/my-settings/:tab',
  },
  /* TODO: UNCOMMENT WHEN GIFTS CARD WILL BE ENABLED */
  // {
  //   Component: SelectGiftCard,
  //   Layout: DefaultLayout,
  //   secured: false,
  //   exact: true,
  //   path: '/:lang/select-gift-card',
  // },
  // {
  //   Component: OrderGiftCard,
  //   Layout: DefaultLayout,
  //   secured: false,
  //   exact: true,
  //   path: '/:lang/order-gift-card/:type',
  // },

  {
    Component: Done,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/done',
  },
  {
    Component: Initiatives,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: ['/:lang/collaborations', '/:lang/initiatives', '/:lang/initiative'],
  },
  {
    Component: Projects,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: ['/:lang/fundraisers', '/:lang/project', '/:lang/projects'],
  },
  {
    Component: Project,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: ['/:lang/fundraisers/:projectId', '/:lang/collaborations/:projectId'],
  },
  {
    Component: Donation,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/donation',
  },
  {
    Component: CreateProject,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/create-project',
  },
  {
    Component: PreCreateProject,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/pre-create-project',
  },
  {
    Component: Homepage,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang',
  },
  {
    Component: Login,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/sign-in',
  },
  {
    Component: Register,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/sign-up',
  },
  {
    Component: ForgotPassword,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/forgot-password',
  },
  {
    Component: ThankYouSignUp,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/signup/thank-you',
  },
  {
    Component: ThankYouActNow,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/collaborate/thank-you',
  },
  {
    Component: MobileMore,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/more',
  },
  {
    Component: MobileActNow,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/act-now',
  },
  {
    Component: MobileContribute,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/contribute',
  },
  {
    Component: Blog,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/blog',
  },
  {
    Component: BlogSingle,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/blog/:slug',
  },
  {
    Component: SuccessStory,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/success-story/:slug',
  },
  {
    Component: Testimonials,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/testimonials',
  },
  {
    Component: ProjectsDashboard('fundraisers'),
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/fundraisers-dashboard',
  },
  {
    Component: ProjectsDashboard('collaborations'),
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/collaborations-dashboard',
  },
  {
    Component: ApplicationsDashboard,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/applications-dashboard',
  },
  {
    Component: Fallback,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: [
      '/:lang/project/:projectId',
      '/:lang/projects/:projectId',
      '/:lang/fundraiser/:projectId',
      '/:lang/initiative/:projectId',
      '/:lang/initiatives/:projectId',
      ' /:lang/fundraiser/:projectId',
      '/:lang/initiative/:projectId',
      '/:lang/collaboration/:projectId',
    ],
  },
]

export default routes
