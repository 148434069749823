import { Flex, Image, Box, Text, Button, Radio } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser } from 'hooks'
import HammerWrapper from './HammerWrapper'
import Remove from './Remove'

const w = window.innerWidth

export default function CreditCardItem({
  isSelected,
  index,
  removable,
  makeDefault,
  creditCard,
  onClick,
  onCardClick,
}) {
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <HammerWrapper creditCard={creditCard} removable={removable}>
      <Flex
        py={{ base: 4, sm: 5 }}
        px={6}
        borderRadius={{ base: '12px', sm: '8px' }}
        borderWidth="1px"
        borderColor={isSelected ? 'blue.300' : 'border.500'}
        position="relative"
        alignItems="center"
        justifyContent="space-between"
        cursor={(onClick || makeDefault) && 'pointer'}
        _hover={{
          'button[datatype="remove"]': {
            opacity: 1,
          },
        }}
        onClick={onCardClick(creditCard, index)}
      >
        <Flex alignItems="center">
          <Image
            src={`/assets/images/${creditCard.type}.png`}
            w="28px"
            h="20px"
            alt={creditCard.type}
          />
          <Text ml="2" color="black.100" fontSize="md">
            {creditCard.pan}
          </Text>
        </Flex>
        <Flex alignItems="center">
          {w > 481 && removable && <Remove creditCard={creditCard} />}
          {user.creditCards.length > 1 && (
            <>
              {creditCard.isDefault && (
                <Text color="gray.700" fontSize="sm" fontWeight="500" mr="1">
                  {t('profile@settings-payments-default-selected')}
                </Text>
              )}
              {makeDefault && !creditCard.isDefault && (
                <Button
                  variant="transparent"
                  color="blue.300"
                  fontSize="sm"
                  fontWeight="500"
                  textTransform="lowercase"
                  mr="1"
                >
                  {t('profile@settings-payments-make-default')}
                </Button>
              )}
            </>
          )}
          {(user.creditCards.length > 1 || !removable) && (
            <Radio value="1" isChecked={isSelected}></Radio>
          )}
        </Flex>
      </Flex>
    </HammerWrapper>
  )
}
