import { Storage } from 'aws-amplify'

const filesService = () => {
  const uploadFiles = (data) =>
    Promise.all(
      data.map((item) => {
        if (item.size / 1024 > 10000) {
          throw 'File is above 10mb!'
        }
        return Storage.put(item.name, item)
      })
    )

  return {
    uploadFiles,
  }
}

export default filesService
