import { useTranslation } from 'contexts/TranslationContext'
import WrapperMobile from './Wrapper'

const Projects = ({projects, isLoading}) => {
  const { t } = useTranslation()
  
  return (
    <WrapperMobile
      type="fundraisers"
      swiper={{ items: projects }}
      isLoading={isLoading}
      fromSwiper={true}
      title={t('My Fundraisers')}
      showAll={true}
      route="my-fundraisers"
    />
  )
}

export default Projects
