import React from 'react'
import { Flex, Avatar, Box } from '@chakra-ui/react'

const UserInfo = ({ image, name, info, link }) => {
  const isUrlOrImageKey =
  image?.includes('.s3.amazonaws.com')
    ?  image
    : `${process.env.REACT_APP_S3_BUCKET_URL}/public/${image}`

  if (link) {
    return (
      <Flex
        target="_blank"
        as="a"
        href={link}
        alignItems="center">
        <Avatar bg="gray.500" src={isUrlOrImageKey} width="37px" height="37px"/>
        <Box
          ml="3">
          <Box _hover={{textDecor: 'underline'}} fontWeight="medium" fontSize="md" as="span">
            {name}
          </Box>
          <Box fontSize="sm" color="gray.700">
            {info}
          </Box>
        </Box>
      </Flex>
    )
  }
  return (
    <Flex alignItems="center">
      <Avatar bg="gray.500" src={isUrlOrImageKey} size="sm" width="37px" height="37px" />
      <Box ml="3">
        <Box
          fontWeight="medium"
          color="gray.800"
          fontSize="sm"
          as="span"
          noOfLines={2}
        >
          {name}
        </Box>
        <Box fontSize="md" color="gray.700">
          {info}
        </Box>
      </Box>
    </Flex>
  )
}

export default UserInfo
