import React from 'react'
import { Flex, Text, Box, Button } from '@chakra-ui/react'
import { useUser, calculateProjectDurationLeft } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import ProjectCard from 'components/ProjectCard'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
} from 'contexts/CurrencySettingsContext'
import { useNavigation } from 'pages'
import Wrapper from 'components/Wrapper'

export const SimilarProjects = ({
  projects,
  isLoading,
  isContribution,
  isCollaboration,
  needCategories,
}) => {
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  return (
    <Box
      px={{ base: 0, md: 92 }}
      display={!isLoading && !projects.length ? 'none' : 'block'}
    >
      <Flex
        mb={{ base: 0, xl: '54px' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text ml={4} fontSize="6xl" fontWeight={600}>
          {isContribution
            ? t('contribution@similarProjects')
            : isCollaboration
            ? t('Similar Initiatives')
            : t('Similar Projects')}
        </Text>
        {/* <Button
          display={{ base: 'none', sm: 'block' }}
          fontSize="md"
          fontWeight={700}
          color="blue.400"
          variant="transparent"
          onClick={() => navigationPush('/fundraisers')}
        >
          {t('See all')}
        </Button> */}
      </Flex>
      {/* <Flex justifyContent="center" display={{ base: 'none', xl: 'flex' }}>
        {projects.map((card) => (
          <ProjectCard
            w="100%"
            goalText={t('goal')}
            card={card}
            goalPrice={card.activeGoal?.amount || card.goal}
            inNeedOfText={t('inNeedOf')}
            raisedMoney={CurrencyConverter({
              currency: currency.current,
              amount: parseInt(card.amount),
            })}
            CurrencyConverter={CurrencyConverter}
            thousandSeparatorText={thousandSeparator(
              currency.current === 'AMD'
                ? card.amount + Number(card.updateAmount)
                : card.amount / Number(settings?.currency[currency.current]) +
                    Number(card.updateAmount),
              currency.current
            )}
            SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
            type="fundraisers"
            calculatedProjectDurationLeft={calculateProjectDurationLeft({
              t,
              campaignImplementorPeriod_en: card.campaignImplementorPeriod_en,
              fundraisingType: card.fundraisingType,
            })}
            language={language}
            currency={currency}
            settings={settings}
            changeDefaultCurrency={changeDefaultCurrency}
            navigationPush={navigationPush}
            user={user}
            cardStatusText={t(`status@${card.status}`)}
            cardCategoryText={t(`category@${card.category}`)}
            editText={t('edit')}
            unsubscribeText={t('unsubscribe')}
            monthlyGoalText={t('monthlyGoal')}
            raisedText={t('raised')}
            mx={4}
            collaborationProposalsText={t(
              card?.collaboratorsTotal === 1
                ? 'collaboration proposal'
                : 'collaboration proposals'
            )}
            collaborationProposalsTextRuEdit={t('collaboration proposals')
              .split('...')[1]
              .replace('запросов', 'запроса')}
          />
        ))}
      </Flex> */}

      <Wrapper
        display={{ base: 'block', xl: 'none' }}
        type={isCollaboration ? 'collaborations' : 'fundraisers'}
        swiper={projects}
        isLoading={isLoading}
        isBackgroundGrey={true}
      />
      <Button
        display={{ base: 'block', sm: 'none' }}
        fontSize="md"
        fontWeight={700}
        color="blue.400"
        variant="transparent"
        m={6}
        onClick={() => navigationPush('/fundraisers')}
      >
        {t('See all')}
      </Button>
    </Box>
  )
}
