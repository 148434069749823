const giftCardRepo = (giftCardService) => {
  const getGiftCard = async (id) => {
    if (!id) {
      throw Error('ID is required')
    }
    giftCardService.getGiftCard(id)
  }

  const listGiftCards = async (variables) =>
    giftCardService.listGiftCards(variables)

  const listGiftCardsByCode = async (variables) =>
    giftCardService.listGiftCardsByCode(variables)

  const createGiftCard = async (input) =>
    giftCardService.createGiftCard(input)

  return {
    createGiftCard,
    getGiftCard,
    listGiftCards,
    listGiftCardsByCode,
  }
}

export default giftCardRepo
