import { useMemo } from 'react'
import { Box, Flex, Stack, Heading, Checkbox } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { InputField } from 'components/InputField'
import DatePicker from 'components/Date'
import Editor from 'components/Editor'
import Goals from 'components/GoalsForm'
import Options from 'components/OptionsForm'
import ImageUploader from './ImageUploader'
import { useQueryParams } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import './editor.scss'

import 'react-datepicker/dist/react-datepicker.css'

export default function FormData({
  lang,
  watch,
  getValues,
  setValue,
  register,
  validationErrors,
  image,
  cover,
  date,
  project,
  setDate,
  collaborations,
  errors,
  goals,
  appendGoal,
  removeGoal,
  appendCollaborations,
  removeCollaborations,
}) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useQueryParams()
  const showBoth = params.has('showBoth')
  const isCollaboration = project.isCollaboration
  const isFundraisers = useMemo(
    () => showBoth || pathname.includes('fundraisers'),
    [pathname]
  )

  const handleChange = (e, index) => {
    const newArray = collaborations.map((item, idx) => {
      const newObj = {
        ...item,
      }
      if (index === idx) {
        newObj.needs = e.target.value
      }
      delete newObj.id
      return newObj
    })
    setValue('collaborations', newArray)
  }

  return (
    <>
      <Box
        mb="10"
        maxWidth="508px"
        width="100%"
        css={{
          label: {
            paddingLeft: 0,
            fontSize: '12px',
          },
        }}
      >
        <InputField
          id={`creatorName_${lang}`}
          mb={8}
          label={t('createEditProject@projectCreatorName')}
          {...register(`creatorName_${lang}`)}
        />
        <InputField
          id={`creatorSocialLink_${lang}`}
          mb={8}
          label={t('Fundraiser Creator Link')}
          {...register('creatorSocialLink')}
        />
        <Box as="span" display="block" fontSize="sm" color="gray.800" mb={1}>
          {t('createEditProject@projectCreatorImage')}
        </Box>
        <Box as="p" fontSize="sm" color="gray.700" mb={5}>
          {t('createEditProject@projectCreatorImageInfo')}
        </Box>
        <ImageUploader
          type="image"
          label={t('createEditProject@clickToUploadImage')}
          name="creatorImage"
          setValue={setValue}
          image={image}
        />
        <InputField
          id={`title_${lang}`}
          labelProps={{ mt: 6 }}
          mb={4}
          label={t('createEditProject@projectTitle')}
          {...register(`title_${lang}`)}
        />
        <InputField
          id={`tagline_${lang}`}
          mb={8}
          label={t('createEditProject@projectTagline')}
          {...register(`tagline_${lang}`)}
        />
        <Box as="span" display="block" fontSize="sm" color="gray.800" mb={1}>
          {t('createEditProject@uploadProjectCover')}
        </Box>
        <Box as="p" fontSize="sm" color="gray.700" mb={5}>
          {t('createEditProject@uploadProjectCoverInfo')}
        </Box>
        <ImageUploader
          type="cover"
          label={t('createEditProject@clickToUploadImage')}
          name="cover"
          setValue={setValue}
          image={cover}
        />
        <Box as="span" display="block" fontSize="sm" color="gray.800" mt={8}>
          {t('createEditProject@embedVideo')}
        </Box>
        <Box as="p" fontSize="sm" color="gray.700" mt={1}>
          {t('createEditProject@embedVideoInfo')}
        </Box>
        <Flex
          mt={3}
          mb={7}
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="flex-end"
        >
          <InputField id={`video_${lang}`} {...register(`video_${lang}`)} />
        </Flex>
        {project.fundraisingType !== 'recurring' &&
          (!isCollaboration || showBoth) && (
            <Stack
              spacing="6"
              mb="70px"
              alignItems="flex-end"
              direction={{ base: 'column', sm: 'row' }}
            >
              <InputField
                id={`goal_${lang}`}
                label={t('createEditProject@goal')}
                value={watch('goal')}
                onChange={(e) =>
                  setValue('goal', e.target.value.replace(/[^0-9.]/g, ''))
                }
              />
              <DatePicker
                minDate={new Date()}
                label={t('createEditProject@campaignImplementationPeriod')}
                startDate={date}
                setStartDate={setDate}
                zIndex="3"
              />
            </Stack>
          )}
        <Box
          mb="10"
          borderBottom="1px solid"
          borderColor="border.100"
          w="full"
        />
        {isFundraisers && (
          <>
            <Box mb={10}>
              <Heading mb="10" as="h2" fontSize="xl">
                {t('Fundraisers')}
              </Heading>
              <Goals
                isEdit={true}
                goals={goals}
                errors={errors}
                remove={removeGoal}
                register={register}
                append={appendGoal}
                getValues={getValues}
                language={lang}
              />
            </Box>
          </>
        )}
        {(isCollaboration ||
          (!!collaborations && collaborations.length > 0)) && (
          <Options
            collaborations={collaborations}
            validationErrors={validationErrors}
            appendCollaborations={appendCollaborations}
            removeCollaborations={removeCollaborations}
            handleChange={handleChange}
            register={register}
            formLang={lang}
            setValue={setValue}
            getValues={getValues}
          />
        )}
        <Box borderBottom="1px solid" borderColor="border.100" w="full" />
      </Box>
      <Heading mb="5" as="h2" fontSize="xl">
        {t('createEditProject@content')}
      </Heading>
      <Editor
        id={`content_${lang}`}
        content={getValues(`content_${lang}`)}
        onValueUpdate={(value) => setValue(`content_${lang}`, value)}
      />
    </>
  )
}
