import { useState } from 'react'
import { useQuery } from 'react-query'
import { initiativeUseCase } from '../../core/factories'

const useInitiatives = (condition) => {
  const { error, data, isLoading, refetch } = useQuery(
    ['initiative', condition],
    async () => await initiativeUseCase.searchInitiatives(condition))

  return {
    refetch,
    error,
    isLoading,
    initiatives: data,
  }
}

export default useInitiatives
