import { useState, useCallback } from 'react'
import { Box, Checkbox, Text, Button, Spinner } from '@chakra-ui/react'
import BackButton from 'components/BackButton'
import { GridItem } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import { Link, Redirect, useNavigation } from 'pages'
import { useUser, useQueryParams, useGetProject, usePayment } from 'hooks'
import { useModal } from 'providers/ModalProvider'
import { useTranslation } from 'contexts/TranslationContext'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import Guarantee from 'components/Guarantee'
import CartAmountForm from 'components/CartAmountForm'
import Cart from 'components/Cart'
import PaymentGuestUserForm from 'components/PaymentGuestUserForm'
import AttachedCards from './AttachedCards'
import SignUpSection from './SignUpSection'
import { checkUtm, createTransactionBody } from './donationHelpers'
import Layout from './Layout'
import Header from './MobileDonation/Header'
import useDonationForm from './useDonationForm'
import PoweredBy from './PowerdBy'

const w = window.innerWidth

const Donation = () => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const location = useLocation()
  const { navigationPush } = useNavigation()
  const { user } = useUser()
  const { openModal } = useModal()
  const { currency, settings } = useCurrencySettings()
  const { t, language } = useTranslation()
  const isContribution = queryParams.get('projectId') === 'ap'
  const { isLoading: isLoadingProject, data: project } = useGetProject({
    projectId: queryParams.get('projectId') ?? '',
  })
  const { mutate: payment } = usePayment()
  const [isLoading, toggleLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    isValid,
    afterSignUpRedirectURL,
    appliedGiftCard,
  } = useDonationForm({
    isContribution,
    user,
    project,
    hasDefaultAmount: queryParams.has('amount'),
    defaultAmount: queryParams.get('amount'),
    projectId: queryParams.get('projectId'),
  })

  const onDonate = useCallback(
    async (data) => {
      toggleLoading(true)
      const currencyRate =
        currency.current === 'AMD' ? 1 : settings?.currency[currency.current]
      const utm = checkUtm(location)

      const getFieldValue = (field) => {
        if (user.id === 'guest') {
          return data[field]
        }
        return user[field]
      }

      const { body, compareResult } = createTransactionBody({
        project,
        user,
        formAmount: getValues('amount'),
        currency,
        currencyRate,
        email: getFieldValue('email'),
        firstName: getFieldValue('firstName'),
        lastName: getFieldValue('lastName'),
        language,
        data,
        paymentType: getValues('paymentType'),
        utm,
        appliedGiftCard,
        isContribution,
      })
      if (compareResult.makePayment) {
        const path = body.localBindingId ? '/bind' : '/payment'
        await payment(
          { path, body },
          {
            onSuccess: async (res) => {
              const result = JSON.parse(res.data)
              if (result.formUrl) {
                if (path === '/payment') {
                  if (result.formUrl) {
                    window.location = result.formUrl.replace('_binding', '')
                    return
                  }
                }
              }
              toggleLoading(false)
              navigationPush(`/check-transaction?orderId=${result.orderId}`)
            },
            onError: (err) => {
              console.log(err)
              toggleLoading(false)
            },
          }
        )
      }
    },
    [project, currency.current]
  )

  if (isLoadingProject) {
    return (
      <Layout isLoading={isLoadingProject}>
        <Spinner color="blue.700" />
      </Layout>
    )
  }

  return (
    <>
      {w <= 481 && (
        <Header
          title={isContribution ? t('payment@donateToAP') : t('payment@donate')}
          goBack={() => {
            if (isContribution) {
              navigationPush('/fundraisers')
              return
            }
            history.goBack()
          }}
          user={user}
          project={project}
          amount={watch('amount')}
          language={language}
          t={t}
          isContribution={isContribution}
        />
      )}
      <Layout onFormSubmit={handleSubmit(onDonate)}>
        {w > 481 && <GridItem colSpan={1} />}
        <GridItem colSpan={{ base: 12, sm: 6 }}>
          {w > 481 && (
            <BackButton
              to={isContribution ? `/` : `/fundraisers/${project.id}`}
            />
          )}
          <Box w="full" mb="12">
            <CartAmountForm
              watch={watch}
              project={project}
              donation={{
                amount: watch('amount'),
                reArmenia: isContribution ? 0 : watch('reArmenia'),
              }}
              setValue={setValue}
              errors={errors}
              register={register}
              getValues={getValues}
              isContribution={isContribution}
            />
            {user.creditCards && user.creditCards?.length !== 0 && (
              <AttachedCards
                getValues={getValues}
                watch={watch}
                setValue={setValue}
                user={user}
                title={t('donation@attachedCards')}
                onChange={(bindingId) => setValue('bindingId', bindingId)}
              />
            )}
            {user.id === 'guest' && (
              <>
                <SignUpSection
                  title={t(
                    `donation@${watch('paymentType') || 'oneTime'}@signUp@title`
                  )}
                  description={t(
                    `donation@${
                      watch('paymentType') || 'oneTime'
                    }@signUp@description`
                  )}
                  buttonText={t('donation@login@signUp')}
                  redirectURL={afterSignUpRedirectURL}
                  openModal={openModal}
                />
                <PaymentGuestUserForm
                  register={register}
                  errors={errors}
                  title={t('donation@addPersonalInfo')}
                  firstName={t('donation@firstName')}
                  lastName={t('donation@lastName')}
                  email={t('donation@email')}
                />
              </>
            )}
            {user.id !== 'guest' &&
              (!user.creditCards || user.creditCards?.length === 0) && (
                <Box>
                  <Checkbox mt={6} {...register('attachCard')}>
                    <Text fontSize="sm" fontWeight={400}>
                      {t('donation@saveCard')}
                    </Text>
                  </Checkbox>
                </Box>
              )}
            <Checkbox mt={4} {...register('isAnonymous')}>
              <Text fontSize="sm" lineHeight="16px">
                {t('donation@donateAnonymouslyText')
                  .split('/')
                  .map((text, index) =>
                    text === 'bold' ? (
                      <Text key={`text-${index}`} as="span" fontWeight="bold">
                        {t('donation@donateAnonymouslyText@bold')}
                      </Text>
                    ) : (
                      text
                    )
                  )}
              </Text>
            </Checkbox>
            {user.id === 'guest' && (
              <Checkbox
                borderColor="border.100"
                mt={4}
                {...register('subscribeNews')}
              >
                <Text fontSize="sm" lineHeight="16px">
                  {t('donation@subscribeOnDonateText')
                    .split('/')
                    .map((text, index) =>
                      text === 'bold' ? (
                        <Text key={`text-${index}`} as="span" fontWeight="bold">
                          {t('donation@subscribeOnDonateText@bold')}
                        </Text>
                      ) : (
                        text
                      )
                    )}
                </Text>
              </Checkbox>
            )}

            <Guarantee
              title={t('donation@guarantee')}
              description={t('donation@guaranteeText')}
            />
            {w < 481 && (
              <Cart
                isLoading={isLoading}
                project={project}
                donation={{
                  amount: watch('amount'),
                  reArmenia: isContribution ? 0 : watch('reArmenia'),
                }}
                giftCard={true}
                isGiftCardDisabled={watch('paymentType') !== 'oneTime'}
                watch={watch}
                width="full"
                appliedGiftCard={appliedGiftCard}
                isContribution={isContribution}
              />
            )}
            <Box mt="8">
              <Text
                textAlign="left"
                fontSize="sm"
                color="gray.700"
                fontWeight="400"
                mb="4"
              >
                {t('donation@terms')}{' '}
                <Link target="_blank" to="/terms-and-conditions">
                  <Box color="gray.800" as="span" fontWeight="500">
                    {t('donation@termsUse')}
                  </Box>
                </Link>
                {t('donation@privacy')}
                <Link target="_blank" to="/privacy-policy">
                  <Box color="gray.800" as="span" fontWeight="500">
                    {' '}
                    {t('donation@policy')}.
                  </Box>
                </Link>
              </Text>
              <Button
                mb="4"
                mt="2"
                variant="green"
                w="full"
                type="submit"
                disabled={!isValid || isLoading}
                borderRadius="12px"
                isLoading={isLoading}
              >
                {t('donation@donate')}
              </Button>
              <PoweredBy
                text={t('Powered By')}
                mt={8}
                pb={(w < 481) & !isContribution ? 12 : 0}
              />
            </Box>
          </Box>
        </GridItem>
        {w > 481 && (
          <>
            <GridItem colSpan={1} />{' '}
            <GridItem colSpan={{ base: 6, sm: 4 }}>
              <Cart
                isLoading={isLoading}
                project={project}
                donation={{
                  amount: watch('amount'),
                  reArmenia: isContribution ? 0 : watch('reArmenia'),
                }}
                giftCard={true}
                isGiftCardDisabled={watch('paymentType') !== 'oneTime'}
                watch={watch}
                width="full"
                appliedGiftCard={appliedGiftCard}
                isContribution={isContribution}
              />
            </GridItem>
          </>
        )}
      </Layout>
    </>
  )
}

export default Donation
