import React from 'react'
import { Flex, Box, Image, Heading, Text } from '@chakra-ui/react'

const Right = ({ title, description, image }) => (
  <Flex
    px="4"
    _hover={{
      '& > div': {
        borderColor: 'blue.300',
      },
      '& span': {
        bgColor: 'blue.300',
      },
    }}
    w="full"
    justifyContent="center"
    flexDirection={{ base: 'column', md: 'row' }}
    alignItems={{ base: 'center', md: 'unset' }}
    position="relative"
  >
    <Flex
      flex="1"
      justifyContent="flex-end"
      borderRight={{ base: '0', md: '2px solid #DDDEE3' }}
      borderColor="#DDDEE3"
      position="relative"
    >
      <Box pr={{ base: '0', md: '5rem' }}>
        <Image minW="220px" height="220px" src={image} />
      </Box>
    </Flex>
    <Flex
      py="20"
      position="relative"
      justifyContent="center"
      flexDirection="column"
      overflow="hidden"
      borderLeft={{ base: '0', md: '2px solid #DDDEE3' }}
      flex="1"
    >
      <Flex mb="4" alignItems="center" position="relative">
        <Heading
          textAlign={{ base: 'center', md: 'left' }}
          pl={{ base: '0', md: '4rem' }}
          fontSize="4xl"
          as="h3"
        >
          {title}
        </Heading>
        <Box
          display={{ base: 'none', md: 'block' }}
          as="span"
          left="-29px"
          bg="#DDDEE3"
          minH="50px"
          minW="50px"
          borderRadius="8px"
          transform="rotate(45deg)"
          position="absolute"
        />
      </Flex>
      <Text
        textAlign={{ base: 'center', md: 'left' }}
        pl={{ base: '0', md: '4rem' }}
      >
        {description}
      </Text>
    </Flex>
  </Flex>
)

export default Right
