import React from 'react'
import { Box } from '@chakra-ui/react'
import Header from './Header'
import ContactInfo from './ContactInfo'
import Fields from './Fields'

const MobileContactUs = () => {
  return (
    <Box>
      <Header />
      <Fields />
      <ContactInfo />
    </Box>
  )
}

export default MobileContactUs
