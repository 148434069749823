import React from 'react'
import { Flex, Img, Box, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  CurrencyConverter,
  SUPPORTED_CURRENCY_SYMBOLS,
} from 'contexts/CurrencySettingsContext'
import ImageWrapper from 'components/ImageWrapper'

const PerkCard = ({
  image,
  title,
  price,
  date,
  expImage,
  isSelectable = true,
  convertPrice = true,
  onSelect = () => {},
}) => {
  const { t, language } = useTranslation()
  const { currency } = useCurrencySettings()
  return (
    <Flex
      onClick={isSelectable && onSelect}
      cursor={isSelectable ? 'pointer' : 'default'}
      mt="4"
      alignItems="flex-start"
      p="3"
      border="1px solid"
      borderColor="border.100"
      borderRadius="12px"
    >
      <ImageWrapper
        mr="4"
        objectFit="cover"
        width="80px"
        src={`/thumbnail/small/${image}`}
        alt="reward"
        minW="80px"
        height="80px"
        borderRadius="8px"
      />
      <Flex
        flex="1"
        overflow="hidden"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Box
          maxW="100%"
          mb="2"
          fontWeight="700"
          fontSize={{ base: 'xl', sm: 'xl' }}
          as="span"
        >
          {title}
        </Box>
        <Box
          lineHeight="1em"
          mb="2"
          fontSize={{ base: 'xl', sm: '4xl' }}
          fontWeight="bold"
          as="span"
        >
          &nbsp;
          {convertPrice ? (
            <CurrencyConverter currency={currency.current} amount={price} />
          ) : (
            price
          )}
          &nbsp;
          <Box fontSize="md" fontWeight="light" as="span">
            {currency.current}
          </Box>
        </Box>
        {isSelectable && (
          <Button
            ml="auto"
            minH="unset"
            height="unset"
            color="orange.400"
            variant="transparent"
            onClick={onSelect}
          >
            {t('select')}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default PerkCard
