import { ButtonGroup, IconButton } from '@chakra-ui/react'
import * as React from 'react'
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg'
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg'
import { ReactComponent as Linkedin } from 'assets/icons/linkedin.svg'
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg'

export const SocialMediaLinks = (props) => (
  <ButtonGroup spacing={8} color="gray.600" {...props}>
    <IconButton
      p="0"
      as="a"
      href="https://www.facebook.com/PeaceArmenian"
      aria-label="facebook"
      icon={<Facebook />}
    />
    <IconButton
      p="0"
      as="a"
      href="https://www.instagram.com/armenian_peacefound"
      aria-label="instagram"
      icon={<Instagram />}
    />
    <IconButton
      p="0"
      as="a"
      href="https://www.linkedin.com/company/armenian-peace/"
      aria-label="Linkedin"
      icon={<Linkedin />}
    />
    <IconButton
      p="0"
      as="a"
      href="https://twitter.com/ArmenianPeace1"
      aria-label="Twitter"
      icon={<Twitter />}
    />
  </ButtonGroup>
)
