import { extendTheme } from '@chakra-ui/react'
import Button from './Button'

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({
  config,
  components: {
    Button,
    Input: {
      sizes: {
        md: {
          field: {
            height: '43px',
            fontSize: '1rem',
          },
        },
        lg: {
          field: {
            height: '48px',
            fontSize: '1rem',
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        minH: '100px',
      },
    },
    Table: {
      baseStyle: {
        thead: {
          tr: {
            bg: '#F3F3F3',
            h: '72px',
            th: {
              borderColor: '#CDCDCD',
              color: 'black',
              fontWeight: 'bold',
              fontSize: '16px',
              maxWidth: '208px',
              p: '26px 40px',
              textTransform: 'none',
            },
          },
        },
        tbody: {
          tr: {
            h: '72px',
            td: {
              borderColor: '#CDCDCD',
              color: 'black',
              fontSize: '14px',
              maxWidth: '208px',
              overflow: 'hidden',
            },
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        icon: {},
        label: {},
        control: {
          borderColor: 'border.200',
          borderWidth: 1,
          borderRadius: 3,
          _checked: {
            _disabled: {
              bg: 'border.200',
              bgColor: 'border.200',
              borderColor: 'border.200',
            },
            borderColor: 'orange.400',
            bg: 'orange.400',
            bgColor: 'orange.400',
            _hover: {
              borderColor: '#F49D22',
              bg: '#F49D22',
              bgColor: '#F49D22',
            },
          },
          _hover: {
            borderColor: 'orange.400',
          },
        },
      },
    },
    Radio: {
      baseStyle: {
        icon: {},
        label: {},
        control: {
          borderColor: 'border.200',
          borderWidth: 1,
          _checked: {
            borderColor: 'orange.400',
            bg: 'orange.400',
            bgColor: 'orange.400',
            border: 'none',
            _disabled: {
              bg: 'border.200',
              bgColor: 'border.200',
              borderColor: 'border.200',
            },
            _hover: {
              borderColor: '#F49D22',
              bg: '#F49D22',
              bgColor: '#F49D22',
            },
          },
          _disabled: {
            bg: 'border.200',
            bgColor: 'border.200',
            borderColor: 'border.200',
          },
          _hover: {
            borderColor: 'orange.400',
          },
        },
      },
    },
    Select: {
      sizes: {
        md: {
          field: {
            height: '43px',
            fontSize: '1rem',
          },
        },
        lg: {
          field: {
            height: '48px',
            fontSize: '1rem',
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          zIndex: '10000',
          bg: 'blue.400',
          overflow: 'hidden',
          color: 'white',
          fontSize: 'md',
          borderRadius: '16px',
          py: '3',
          border: 'none',
        },
        button: {
          fontSize: 'md',
        },
        item: {
          height: '8',
          _hover: {
            bg: 'rgba(255, 255,255, 0.15)',
          },
          _focus: {
            bg: 'rgba(255, 255,255, 0.15)',
          },
        },
      },
      variants: {
        border: {
          button: {
            sizes: {
              base: {
                display: 'none',
              },
              sm: {
                display: 'block',
              },
            },
            color: 'black.100',
            fill: 'white',
            mt: 8,
            borderColor: 'border.100',
            borderRadius: '12px',
            borderWidth: 1,
          },
        },
      },
    },
  },
  fonts: {
    body: 'MontserratArm, Montserrat',
    heading: 'MontserratArm, Montserrat',
  },
  styles: {
    global: {
      '*:focus': {
        boxShadow: 'none !important',
      },
      '*[data-focus]': {
        boxShadow: 'none !important',
      },
      'html, body': {
        color: '#343434',
        fontWeight: 400,
        fontSize: '1rem',
      },
      a: {
        textDecoration: 'none !important',
      },
      input: {
        '&::placeholder': {
          color: 'placeholder.100',
        },
      },
      button: {
        _focus: {
          boxShadow: 'none !important',
        },
      },
    },
  },
  fontSizes: {
    xs: '0.625rem', // 10px
    sm: '0.75rem', // 12px
    md: '0.875rem', // 14px
    lg: '1rem', // 16px
    xl: '1.125rem', // 18px
    '2xl': '1.25rem', //20px
    '3xl': '1.375rem', // 22px
    '4xl': '1.5rem', // 24px
    '5xl': '1.75rem', // 28px
    '6xl': '2rem', // 32px
    '7xl': '2.5rem', // 40px
  },
  colors: {
    placeholder: {
      100: '#6d6d6d',
    },
    blue: {
      200: '#006CB7',
      300: '#006CB7',
      400: '#006CB7',
    },
    orange: {
      300: '#FC8600',
      400: '#ED8B00',
    },
    green: {
      200: '#3EC997',
      300: '#2EC974',
      400: '#22BE68',
      500: '#00C35D',
    },
    gray: {
      100: '#FFFFFF',
      200: '#FAFAFA',
      300: '#F5F4F7',
      400: '#EAECEF',
      500: '#DDDDDD',
      600: '#CDCDCD',
      700: '#737373',
      800: '#272727',
    },
    border: {
      100: '#DDDDDD',
      200: '#BCC3CC',
      300: '#B6B6B6',
      400: '#CDCDCD',
      500: '#EAECEF',
    },
    red: {
      400: '#DB4151',
    },
    social: {
      facebook: '#1877F2',
      twitter: '#1FA1F1',
      linkedin: '#2867B2',
      vkontakte: '#5181B8',
    },
  },
})

export default theme
