import Wrapper from 'components/Wrapper'
import { useTranslation } from 'contexts/TranslationContext'

const PopularProjects = ({ projects, isLoading }) => {
  const { t } = useTranslation()
  return (
    <Wrapper
      type="fundraisers"
      isLoading={isLoading}
      squareColor="#002D78"
      title={t('Fundraisers')}
      swiper={projects.sort((a,b) =>  b.total.goalMetPercentage - a.total.goalMetPercentage )}
      isBackgroundGrey={true}
    />
  )
}

export default PopularProjects
