import React from 'react'
import { Flex, Img, Heading, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'

const Contact = () => {
  const { t } = useTranslation()
  return (
    <Flex bg="blue.300">
      <Flex justify="center" pb="14rem" w="full" mx="auto" direction="column">
        <Img
          mr="4.75rem"
          maxW="100%"
          src="/assets/images/work.jpg"
          width="100%"
          height="auto"
        />
        <Flex
          flex={1}
          justifyContent="center"
          align="center"
          flexDirection="column"
        >
          <Heading
            mt="2.8rem"
            mb="10"
            color="gray.100"
            textAlign="center"
            mx="4"
            fontSize="3xl"
            as="h2"
            dangerouslySetInnerHTML={{ __html: t('about@ifYouInterested') }}
          ></Heading>
          <Flex direction="column">
            <Link to="/contact-us">
              <Button mb="4" variant="orange">
                {t('about@contact')}
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Contact
