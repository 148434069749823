import { API, DataStore, graphqlOperation } from 'aws-amplify'
import { getInitiative, listInitiatives, searchInitiatives as searchInitiativesInput } from '../../graphql/queries'
import {
  createInitiative as create,
  updateInitiative as update,
  deleteInitiative as removeInitiative,
} from '../../graphql/mutations'

const initiativeService = () => {
  const createInitiative = async (initiative) => {
    await API.graphql(graphqlOperation(create, { input: initiative }))
  }

  const addCollaborator = async ({ id, collaborator }) => {
    await API.graphql(
      graphqlOperation(updateInitiative, {
        input: { id, collaborators: [collaborator] },
      })
    )
  }

  const setImplementor = async ({ id, collaborator }) => {
    await API.graphql(
      graphqlOperation(updateInitiative, {
        input: { id, implementor: collaborator },
      })
    )
  }

  const updateStatus = async (params) => {
    await API.graphql(graphqlOperation(updateInitiative, { input: params }))
  }

  const updateInitiative = (initiative) =>
    API.graphql(graphqlOperation(update, { input: initiative }))

  const retrieveInitiative = async (id) => {
    const initiative = await API.graphql(
      graphqlOperation(getInitiative, { id })
    )

    return initiative.data.getInitiative
  }

  const getInitiatives = async (filter) => {
    const condition = {}
    if (Object.keys(filter).length > 0) {
      condition.filter = filter
    }

    const projects = await API.graphql(
      graphqlOperation(listInitiatives, condition)
    )
    return projects.data.listInitiatives.items
  }

  const searchInitiatives = async (condition) => {

    const projects = await API.graphql(
      graphqlOperation(searchInitiativesInput, condition)
    )
    return projects.data.searchInitiatives
  }

  const collaboratorRequest = async (params) => {
    try {
      await API.post('email', '/collabEmail', {
        body: params,
      })
    } catch (err) {
      console.log('Error', err)
    }
  }

  const deleteInitiative = async (initiative) => {
    await API.graphql(graphqlOperation(removeInitiative, { input: initiative }))
  }

  return {
    createInitiative,
    addCollaborator,
    updateStatus,
    updateInitiative,
    retrieveInitiative,
    getInitiatives,
    collaboratorRequest,
    setImplementor,
    deleteInitiative,
    searchInitiatives,
  }
}

export default initiativeService
