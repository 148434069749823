import React from 'react'
import {
  Button,
  Stack,
  Box,
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Link } from 'pages'
import { InputField } from 'components/InputField'
import { Select } from 'components/Select'
import { useUser, useUpdateUser, useSignOut } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { COUNTRY_LIST } from '_constants'
import { ReactComponent as Back } from 'assets/icons/back.svg'
import { useNavigation } from 'pages'
import Subscriptions from '../Subscriptions'
import Payments from './Payments'
import useCustomToast from 'hooks/useCustomToast'
import History from '../History'

const TABS = {
  0: 'personal',
  1: 'subscriptions',
  // 2: 'history',
  2: 'payments',
  personal: 0,
  subscriptions: 1,
  // history: 2,
  payments: 2,
}

const Settings = () => {
  const { user, getUser } = useUser()
  const { t } = useTranslation()
  const { tab } = useParams()
  const toast = useCustomToast()
  const signOut = useSignOut()
  const { navigationPush } = useNavigation()

  const { updateUser, isLoading } = useUpdateUser()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      ...user,
    },
  })

  const onChange = (tab) => navigationPush(`/my-settings/${TABS[tab]}`)

  const submit = (data) => {
    const { firstName, lastName, phone, id, country } = data
    const input = {
      id,
      firstName,
      lastName,
      phone,
      country,
    }

    updateUser(input, {
      onSuccess: () => {
        getUser()
        toast({
          status: 'success',
          title: t('profile@updatedSuccessfully'),
          isClosable: true,
          duration: 5000,
        })
      },
      onError: (e) => console.log(e),
    })
  }

  return (
    <>
      <Flex
        w="full"
        justifyContent="center"
        position="relative"
        alignItems="center"
        py="3"
      >
        <Flex
          as={Link}
          to="/more"
          position="absolute"
          left="12px"
          minH="30px"
          minW="30px"
          alignItems="center"
          justifyContent="center"
        >
          <Back width="10px" height="20px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {t('profile@tab3')}
        </Heading>
      </Flex>
      <Tabs defaultIndex={TABS[tab]} onChange={onChange}>
        <TabList
          borderBottomWidth={0}
          width="100%"
          overflowX="scroll"
          pb="10px"
        >
          <Tab
            py="3"
            borderBottomWidth={1}
            borderColor="border.100"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{
              outline: 'none',
            }}
            _selected={{
              color: 'blue.400',
              fontWeight: 'bold',
              borderColor: 'blue.400',
            }}
            flex="1"
            px="8"
          >
            {t('profile@personal')}
          </Tab>
          <Tab
            py="3"
            borderBottomWidth={1}
            borderColor="border.100"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{
              outline: 'none',
            }}
            _selected={{
              color: 'blue.400',
              fontWeight: 'bold',
              borderColor: 'blue.400',
            }}
            flex="1"
            px="8"
          >
            {t('profile@tab4')}
          </Tab>
          {/* <Tab
            py="3"
            borderBottomWidth={1}
            borderColor="border.100"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{
              outline: 'none',
            }}
            _selected={{
              color: 'blue.400',
              fontWeight: 'bold',
              borderColor: 'blue.400',
            }}
            flex="1"
            px="8"
          >
            {t('profile@history')}
          </Tab> */}
          <Tab
            py="3"
            borderBottomWidth={1}
            borderColor="border.100"
            _active={{ backgroundColor: 'transparent' }}
            _focus={{
              outline: 'none',
            }}
            _selected={{
              color: 'blue.400',
              fontWeight: 'bold',
              borderColor: 'blue.400',
            }}
            flex="1"
            px="8"
          >
            {t('profile@settings-payments')}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel padding={0}>
            <Box px="5">
              <form onSubmit={handleSubmit(submit)}>
                <Stack pb="12" mt="3" spacing="6">
                  <InputField
                    size="md"
                    isInvalid={!!errors.firstName}
                    errorMessage={t('profile@settings-firstNameErrorMessage')}
                    {...register('firstName', { required: true })}
                    label={t('profile@settings-firstName')}
                  />
                  <InputField
                    size="sm"
                    isInvalid={!!errors.lastName}
                    errorMessage={t('profile@settings-lastNameErrorMessage')}
                    {...register('lastName', { required: true })}
                    label={t('profile@settings-lastName')}
                  />
                  <InputField
                    size="sm"
                    isDisabled
                    {...register('email', { required: true })}
                    errorMessage={t('profile@settings-emailErrorMessage')}
                    label={t('profile@settings-email')}
                  />
                  <InputField
                    size="sm"
                    {...register('phone')}
                    label={t('profile@settings-phoneNumber')}
                  />
                  <Select
                    type="country"
                    placeholder={t('profile@settings-country')}
                    display="block"
                    {...register('country')}
                    options={COUNTRY_LIST}
                    label={t('profile@settings-country')}
                  />
                  <Flex alignItems="center" justifyContent="center">
                    <Link to="/change-password">
                      <Button color="blue.400" variant="transparent">
                        {t('profile@settings-changePassword')}
                      </Button>
                    </Link>
                  </Flex>
                  <Button isLoading={isLoading} type="submit" variant="blue">
                    {t('profile@settings-submit')}
                  </Button>
                </Stack>
              </form>
            </Box>
            {user.id !== 'guest' && (
              <Flex
                onClick={signOut}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button py="5" fontSize="xl">
                  {t('Sign out')}
                </Button>
              </Flex>
            )}
          </TabPanel>
          <TabPanel padding={0}>
            <Subscriptions />
          </TabPanel>
          {/* <TabPanel padding={0}>
            <History />
          </TabPanel> */}
          <TabPanel padding={0}>
            <Payments />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default Settings
