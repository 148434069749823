import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { API } from 'aws-amplify'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import { useUser, useQueryParams } from 'hooks'
import useCustomToast from 'hooks/useCustomToast'

const w = window.innerWidth;

const CheckBinding = () => {
 const toast = useCustomToast()
  const location = useLocation()
  const queryParams = useQueryParams()
  const { navigationReplace } = useNavigation()
  const { t } = useTranslation()
  const { getUser } = useUser();

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const orderId = query.get('orderId')
    if (orderId) {
      const body = {
        orderId: orderId,
      }
      API.post('paymentAPI', '/bindingStatus', {
        body,
      })
        .then((res) => {
          if (res.success) {
            getUser()
            toast({
              title: t(`payment@orderStatus-${res.orderStatus}`),
              status: res.orderStatus === 2 ? 'success' : 'error',
              duration: 5000,
              isClosable: true,
            })
          }

          if (queryParams.has('donationInfo')) {
            const [projectId, amount] = queryParams.get('donationInfo').split(',')
            let path = `/donation?projectId=${projectId}`
            if (amount && amount !== 'null') {
              path += `&amount=${amount}`
            }
            return navigationReplace(path)
          }
          if (w > 481) {
            return navigationReplace('/profile/settings/payments')
          }
          navigationReplace('/my-settings/payments')
        })
        .catch((e) => {
            console.log(e)
            navigationReplace('/')
        })
    }
  }, [])

  return (
    <Flex
        width="100%"
        minHeight="100vh"
        alignItems="center"
        justifyContent="center"
    >
        <Spinner size="lg" />
    </Flex>
  )
}

export default CheckBinding
