import React from 'react'
import { HStack, Flex } from '@chakra-ui/react'
import { ReactComponent as Share } from 'assets/icons/share.svg'
import { ReactComponent as Back } from '../../../assets/icons/back.svg'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'contexts/TranslationContext'

const Header = ({ project }) => {
  const { replace, goBack, action }  = useHistory()
  const { language } = useTranslation()

  const navigateBack = () => {
    const isOpenedWithURL = action === "POP"
    if (isOpenedWithURL) {
      return replace('/')
    }
    goBack()
  }

  const share = async () => await navigator.share({
    title: project[`title_${language}`],
    url: `${process.env.REACT_APP_BASE_URL}/${language}/fundraisers/${project.slug || project.id}`,
    text: project[`tagline_${language}`],
  })
  
  return (
    <HStack
      px="3"
      alignItems="center"
      justifyContent="space-between"
      py="7px"
    >
      <Flex
        onClick={navigateBack}
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center"
      >
        <Back width="10px" height="20px" />
      </Flex>
      <Flex
        onClick={share}
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center">
        <Share />
      </Flex>
    </HStack>
  )
}

export default Header
