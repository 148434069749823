const donationSubscriptionUseCase = (donationSubscriptionRepo, projectUseCase) => {
  const updateDonationSubscription = (donationSubscription) => donationSubscriptionRepo.updateDonationSubscription(donationSubscription)

  const donationSubscriptionsByUserEmail = async (condition) => {
    const donationSubscriptions = await donationSubscriptionRepo.donationSubscriptionsByUserEmail(condition)
    const items = donationSubscriptions.data.donationSubsscriptionsByUserEmail.items

    for (const item of items) {
      item.project = await projectUseCase.retrieveProject(item.projectId)
      item.amount = item.donationToProject

      if (item.donationToReArmenia) {
        item.amount += item.donationToReArmenia
      }
    }

    return items
  }

  const donationSubscriptionsByProjectId = async (condition, withProjectDetails) => {
    const donationSubscriptions = await donationSubscriptionRepo.donationSubscriptionsByProjectId(condition)
    const items = donationSubscriptions.data.donationSubscriptionsByProjectId.items

    if (withProjectDetails) {
      for (const item of items) {
        item.project = await projectUseCase.retrieveProject(item.projectId)
        item.amount = item.donationToProject
  
        if (item.donationToReArmenia) {
          item.amount += item.donationToReArmenia
        }
      }
    }

    return items
  }
  
  return {
    updateDonationSubscription,
    donationSubscriptionsByUserEmail,
    donationSubscriptionsByProjectId,
  }
}

export default donationSubscriptionUseCase
