import {
  Fragment,
  useCallback,
  useState,
  useContext,
  createContext,
  useEffect,
} from 'react'
import { useParams, Switch } from 'react-router-dom'
import useCustomToast from 'hooks/useCustomToast'
import Route from 'pages/Route'
import { useNavigation, Redirect } from 'pages'
import useRoutes from './useRoutes'
import {
  useGetProject,
  useUser,
  useUpdateProject,
  useDeleteProject,
} from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'

const ProjectContext = createContext({})

export const Routes = ({ status }) => {
  const routes = useRoutes({ status })

  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={`edit-project-tab-content-${route.path}`}
          exact={false}
          secured={true}
          Layout={Fragment}
          path={route.path}
          component={route.component}
        />
      ))}
    </Switch>
  )
}

export const useProject = () => useContext(ProjectContext)

export const ProjectProvider = ({ children }) => {
  const { projectId } = useParams()
  const { user } = useUser()
  const { t } = useTranslation()
  const { navigationPush } = useNavigation()
  const { deleteProject, isLoading: isRemoveLoading } = useDeleteProject()
  const { updateProject } = useUpdateProject()
 const toast = useCustomToast()
  const {
    refetch,
    data: project,
    isLoading: isProjectLoading,
  } = useGetProject({ projectId })
  const [isLoading, toggleLoading] = useState(true)

  const onRemove = useCallback(() => {
    deleteProject(
      {
        id: projectId,
      },
      {
        onSuccess: () => {
          toast({
            status: 'success',
            title: t('createEditProject@projectRemovedSuccessfully'),
            isClosable: true,
            duration: 5000,
          })
          navigationPush('/profile/fundraisers')
        },
      }
    )
  }, [projectId])

  const onUpdate = useCallback((values) => {
    updateProject(values, {
      onSuccess: () => {
        refetch()
        toast({
          title: t('createEditProject@updatedSuccessfully'),
          status: 'success',
          isClosable: true,
          duration: 5000,
        })
      },
    })
  }, [])

  useEffect(() => {
    toggleLoading(isProjectLoading)
  }, [isProjectLoading])

  if (project === null) {
    return <Redirect to="/404" />
  }
  if (isProjectLoading) return <></>
  if (user?.id !== project?.owner && !user?.isSuper) return <>Access denied</>

  return (
    <ProjectContext.Provider
      value={{ project, isLoading, onUpdate, onRemove, isRemoveLoading }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

export const withProject =
  (Component) =>
  ({ ...props }) => {
    return (
      <ProjectProvider>
        <Component {...props} />
      </ProjectProvider>
    )
  }
