import { useQuery } from 'react-query'
import { testimonialUseCase } from '../../core/factories'
import { useContentful } from 'contexts/ContentfulContext'

const useListTestimonials = () => {
  const { client } = useContentful();

  const query = useQuery(
    ['list_testimonials'],
    () => testimonialUseCase({ client }).listTestimonials())

  return query
}

export default useListTestimonials
