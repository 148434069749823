const userRepo = (userService) => {
  const getUser = async () => userService.getUser()

  const createUser = async (input) => userService.createUser(input)

  const updateUser = async (input) => userService.updateUser(input)

  const getUserById = async (id) => userService.getUserById(id)

  const editEmail = async (email) => userService.editEmail(email)

  const verifyEmail = async (code) => userService.verifyEmail(code)

  const editUserInfo = async (userInfo) => userService.editUserInfo(userInfo)

  const changePassowrd = async (passwords) =>
    userService.changePassowrd(passwords)

  const signOut = async () => userService.signOut()

  return {
    getUser,
    createUser,
    getUserById,
    editEmail,
    verifyEmail,
    editUserInfo,
    changePassowrd,
    signOut,
    updateUser,
  }
}

export default userRepo
