import { Storage } from 'aws-amplify'

const imageService = () => {
  const uploadImage = async ({ name, file }) => {
    if (file.name.includes('.gif')) {
      const result = await Storage.put(name, file, {
        contentType: 'image',
      })
      await Storage.put(`thumbnail/medium/${name}`, file, {
        contentType: 'image',
      })
      return result.key
    }
    const result = await Storage.put(name, file, {
      contentType: 'image',
    })

    return result.key
  }

  const getImageUrl = async (key) => `${await Storage.get(key)}`

  return {
    uploadImage,
    getImageUrl,
  }
}

export default imageService
