import React from 'react'
import { Box, Flex, Heading, Img } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import Navbar from 'components/Navbar'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Flex flexDirection="column" minH="100vh" w="full">
      <Navbar />
      <Box
        zIndex="1"
        top="20%"
        left="15%"
        position="absolute"
        bg="orange.400"
        borderRadius="100%"
        height="103px"
        width="103px"
      />
      <Box
        zIndex="1"
        top="15%"
        right="25%"
        position="absolute"
        opacity="0.5"
        bg="blue.400"
        borderRadius="100%"
        height="60px"
        width="60px"
      />
      <Box
        zIndex="1"
        top="50%"
        right="10%"
        position="absolute"
        bg="blue.400"
        borderRadius="100%"
        height="103px"
        width="103px"
      />
      <Box
        zIndex="1"
        top="60%"
        left="8%"
        opacity="0.1"
        position="absolute"
        bg="blue.400"
        borderRadius="100%"
        height="204px"
        width="204px"
      />
      <Flex
        zIndex="10"
        position="relative"
        px="4"
        mx="auto"
        w="full"
        flex="1"
        maxW="7xl"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Heading
          textAlign="center"
          color="blue.400"
          fontSize={{ base: '2rem', sm: '4rem' }}
          mb="5"
          as="h2"
        >
          {t('notFound@oops')}&nbsp;
          <Box fontWeight="light" as="span">
            {t('notFound@heading')}
          </Box>
        </Heading>
        <Box
          textAlign="center"
          mb="12"
          as="span"
          fontSize={{ base: '3xl', sm: '4xl' }}
          alignItems="center"
          justify="center"
        >
          {t('notFound@note')}
        </Box>
        <Img
          alignSelf="flex-end"
          height="380px"
          width="376px"
          src="/assets/images/img_404.png"
        />
      </Flex>
    </Flex>
  )
}

export default NotFound
