import { useMutation } from 'react-query'
import { projectUseCase } from '../../core/factories'

const useDeleteProject = () => {
  const { mutate: deleteProject, isLoading } = useMutation(
    projectUseCase.deleteProject
  )

  return {
    deleteProject,
    isLoading,
  }
}

export default useDeleteProject
