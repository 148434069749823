import React from 'react'
import { HStack, Flex } from '@chakra-ui/react'
import { ReactComponent as Share } from 'assets/icons/share.svg'
import { ReactComponent as Back } from '../../../assets/icons/back.svg'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'

const Header = ({initiative}) => {
  const { navigationPush } = useNavigation()
  const { language } = useTranslation()
  return (
    <HStack
      pr="3"
      pl="3"
      alignItems="center"
      justifyContent="space-between"
      py="7px"
    >
      <Flex
        onClick={() => navigationPush('/collaborations')}
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center"
      >
        <Back width="10px" height="20px" />
      </Flex>
      <Flex
        onClick={async () => await navigator.share({
          title: initiative[`title_${language}`],
          url: `${process.env.REACT_APP_BASE_URL}/${language}/collaborations/${initiative.slug || initiative.id}`,
          text: initiative[`tagline_${language}`],
        })}
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center"
      >
        <Share width="30" height="30px" />
      </Flex>
    </HStack>
  )
}

export default Header
