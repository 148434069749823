import { useMutation } from 'react-query'
import { transactionUseCase } from '../../core/factories'

const useCreateTransaction = () => {
  const data = useMutation(transactionUseCase.createTransaction)

  return data
}

export default useCreateTransaction
