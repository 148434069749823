import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Content from 'components/Content'
import { useTranslation } from 'contexts/TranslationContext'
import NeedCard from './NeedCard'
import { useNavigation } from 'pages'
const w = window.innerWidth
const Story = ({ project }) => {
  const { language, t } = useTranslation()
  const { navigationPush } = useNavigation()
  return (
    <Box>
      {w < 481 && project.collaborations && project.collaborations.length > 0 && (
        <Box mb={8}>
          {project.collaborations.map((collaboration) => (
            <NeedCard
              collaboration={collaboration}
              t={t}
              lang={language}
              selectAction={() => {
                navigationPush(
                  `/act-now?InitiativeId=${project?.id}&need=${collaboration.needs_en}`
                )
              }}
              ended={project.status !== 'live'}
            />
          ))}
        </Box>
      )}

      {project && project[`content_${language}`] && (
        <Content html={project[`content_${language}`]} />
      )}
    </Box>
  )
}

export default Story
