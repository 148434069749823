import React from 'react'
import { Heading, HStack, Button, Box } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import useCustomToast from 'hooks/useCustomToast'

const Share = ({ project }) => {
  const { t, language } = useTranslation()
 const toast = useCustomToast()

  async function share() {
    if ('clipboard' in navigator) {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return await navigator.clipboard.writeText(
        `${process.env.REACT_APP_BASE_URL}/${language}/fundraisers/${project.slug || project.id}`
      )
    } else {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return document.execCommand(
        'copy',
        true,
        `${process.env.REACT_APP_BASE_URL}/${language}/fundraisers/${project.slug || project.id}`
      )
    }
  }

  return (
    <Box
      bg="#fafafa"
      px="5"
      pt="10"
      pb="12">
      <Heading as="h1" fontSize="3xl">
        {t('Share with friends')}
      </Heading>
      <HStack mt="4">
        <Button
          onClick={async () => await navigator.share({
            title: project[`title_${language}`],
            url: `${process.env.REACT_APP_BASE_URL}/${language}/fundraisers/${project.slug || project.id}`,
            text: project[`tagline_${language}`],
          })}
          boxShadow="0 12px 24px 0 rgba(0,0,0,0.15)"
          minH="40px"
          width="full"
          variant="blue">
          {t('share')}
        </Button>
        <Button
          onClick={share}
          width="full"
          minH="40px"
          variant="transparent"
          border="1px solid"
          borderColor="border.100"
        >
          {t('copy')}
        </Button>
      </HStack>
    </Box>
  )
}

export default Share
