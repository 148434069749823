import React from 'react'
import { useLocation } from 'react-router-dom'
import { useUser } from 'hooks'
import MobileNavbar from './MobileNavbar'
import WebNavbar from './WebNavbar'

const Navbar = ({ from, shadow, disableLinks, onClick }) => {
  const { user } = useUser()
  const location = useLocation()
  const isHomepage = location.pathname.length < 5
  const isGuest = user.id === 'guest' ? true : false
  const w = window.innerWidth

  const currentPath = location.pathname.includes('donation')
    ? 'donation'
    : location.pathname.includes('contact')
    ? 'contact'
    : location.pathname.includes('about')
    ? 'about'
    : location.pathname.includes('order-gift-card')
    ? 'gift-card'
    : location.pathname.includes('collaborations') &&
      !location.pathname.includes('profile') &&
      !location.pathname.includes('edit-')
    ? 'collaborations'
    : location.pathname.includes('fundraisers') &&
      !location.pathname.includes('profile') &&
      !location.pathname.includes('edit-')
    ? 'fundraisers'
    : location.pathname

  if (w < 481) {
    return <MobileNavbar from={from} />
  }

  return (
    <WebNavbar
      onClick={onClick}
      disableLinks={disableLinks}
      isHomepage={isHomepage}
      currentPath={currentPath}
      isGuest={isGuest}
      username={user.firstName}
      shadow={shadow}
    />
  )
}

export default Navbar
