import { Img, Box, Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import React from 'react'

const Empty = () => {
  const {t} = useTranslation()
  return (
    <Flex
      alignItems="center"
      my="200px"
      flexDirection="column"
      mx="auto">
      <Img
        width="178px"
        minH="140px"
        src="/assets/images/empty.png"/>
      <Box
        textAlign="center"
        fontSize="3xl"
        as="span">
        {t('we could’t find anything to show')}
      </Box>
    </Flex>
  )
}

export default Empty
