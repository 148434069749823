import React from 'react'
import {
  FormControl,
  FormLabel,
  Box,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { Select as SelectFieldCustom } from '../Select'

export const SelectField = React.forwardRef((props, ref) => {
  const {
    label,
    options,
    isInvalid,
    errorMessage,
    placeholder,
    value,
    onChange,
    display,
    type,
    width,
    buttonProps = {},
    size = 'md',
  } = props

  return (
    <FormControl width={width} display={display} position="relative">
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          left="2"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage}
        </Box>
      )}
      {label && (
        <FormLabel
          whiteSpace="nowrap"
          fontSize={size === 'md' ? 'sm' : 'md'}
          _focus={{ color: 'blue.300' }}
          fontWeight="regular"
          color="black"
          bg={{
            base: mode("gray.100", 'gray.800'),
            md: mode("gray.100", 'gray.700'),
          }}
          zIndex={2}
          px="2"
        >
          {label}
        </FormLabel>
      )}
      <SelectFieldCustom
        type={type}
        size={size}
        border="1px solid"
        borderRadius="6px"
        // w="full"
        // h="48px"
        borderColor={isInvalid ? 'red.400' : 'border.100'}
        fontSize="lg"
        // px="4"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        buttonProps={buttonProps}
      />
    </FormControl>
  )
})

SelectField.displayName = 'SelectField'
