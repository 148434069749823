import { Box, Heading, Text } from '@chakra-ui/react'
import UserInfo from 'components/UserInfo'
import { useInitiative } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import TypeCheckboxGroup from './TypeCheckboxGroup'

const Information = ({ initiative }) => {
  const { owner, implementor } = useInitiative()
  const { t, language } = useTranslation()
  return (
    <>
      <Box
        mb="5"
        fontSize="sm"
        textTransform="uppercase"
        fontWeight="bold"
        color="blue.400"
        as="span"
      >
        {t(`status@${initiative.status}`)} |{' '}
        {t(`category@${initiative.category}`)}
      </Box>
      <Heading mb="3" fontSize="5xl" as="h1">
        {initiative[`title_${language}`]
          ? initiative[`title_${language}`]
          : initiative.applicationTitle}
      </Heading>
      <Text mb="5" fontSize="lg" opacity="0.6">
        {initiative[`tagline_${language}`]}
      </Text>
      <TypeCheckboxGroup initiative={initiative} />
      {
        owner && (
          <UserInfo
            image={initiative.initiatorImage}
            name={initiative[`initiatorName_${language}`]}
          />
        ) /* FIXME: no info info="1 projects ・1 initiatives  |  Yerevan, Armenia" */
      }
      {implementor && (
        <>
          <Box my="5" bg="border.100" height="1px" w="full" />
          <UserInfo
            name={`${implementor.firstName} ${implementor.lastName}`}
            info={t('implementor')}
          />
        </>
      )}
      <Box my="6" bg="border.100" height="1px" w="full" />
      <Box
        fontSize="sm"
        color="gray.700"
        textTransform="lowercase">
        {initiative?.collaboratorsTotal || 0}{' '}
        {initiative?.collaboratorsTotal && initiative?.collaboratorsTotal > 1
          ? t('initiative@collaborators')
          : t('collaborator')}
      </Box>
      {/* <ActNow initiative={initiative} /> */}
      <Box
        mt="4"
        mb="16"
        border="1px solid"
        borderRadius="16px"
        borderColor="border.100"
        py="6"
        px="8"
      >
        <Box
          mb="3"
          fontSize="lg"
          fontWeight="medium"
          as="span"
          textTransform="capitalize"
        >
          {t('initiative@aboutCollaboration')}
        </Box>
        <Text color="gray.700" fontSize="sm">
          {t('initiative@aboutCollaborationDetails')}
        </Text>
      </Box>
    </>
  )
}

export default Information
