const transactionRepo = (transactionService) => {
  const getTransaction = async (id) => {
    if (!id) {
      throw Error('ID is required')
    }
    transactionService.getTransaction(id)
  }

  const listTransactions = async (variables) =>
    transactionService.listTransactions(variables)

  const listTransactionsByGiftCardCode = async (variables) => {
    return transactionService.listTransactionsByGiftCardCode(variables)
  }

  const listTransactionsByDate = async (variables) => {
    return transactionService.listTransactionsByDate(variables)
  }

  const listTransactionsByTopDonation = async (variables) => {
    return transactionService.listTransactionsByTopDonation(variables)
  }

  const createTransaction = async (input) =>
    transactionService.createTransaction(input)

  const getTransactionsFromElastic = (condition) =>
    transactionService.getTransactionsFromElastic(condition)

  const searchTransactions = (condition) =>
    transactionService.searchTransactions(condition)

  return {
    searchTransactions,
    createTransaction,
    getTransaction,
    listTransactions,
    listTransactionsByGiftCardCode,
    listTransactionsByDate,
    listTransactionsByTopDonation,
    getTransactionsFromElastic,
  }
}

export default transactionRepo
