import { useMutation } from 'react-query'
import { donationSubscriptionUseCase } from '../../core/factories'

const useUpdateDonationSubscription = () => {
  const {
    mutate: updateDonationSubscription,
    data,
    isLoading,
  } = useMutation(donationSubscriptionUseCase.updateDonationSubscription)

  return {
    updateDonationSubscription,
    data,
    isLoading,
  }
}

export default useUpdateDonationSubscription
