import { useCallback, useEffect, useState } from 'react'
import { Flex, Checkbox, Box } from '@chakra-ui/react'
import { useUser, useUpdateInitiative } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import StatusMenu from 'components/StatusMenu'

export default function TypeCheckboxGroup({ initiative }) {
  const { user } = useUser()
  const { updateInitiative, isLoading: isUpdateLoading } = useUpdateInitiative()
  const { t } = useTranslation()
  const [data, setData] = useState()
  const onUpdateInitiative = useCallback(
    (key, value) => {
      if (!initiative) return
      setData((data) => ({ ...data, [key]: value }))
      updateInitiative(
        {
          id: initiative.id,
          [key]: value,
        },
        {
          onSuccess: (response) => setData(response.data.updateInitiative),
        }
      )
    },
    [initiative]
  )

  useEffect(() => {
    if (!initiative) return
    setData(initiative)
  }, [initiative])

  if (!user.isSuper || !data) {
    return <></>
  }

  return (
    <Box mb="4">
      <Flex mb="4">
        <Checkbox
          mr="2"
          isDisabled={isUpdateLoading}
          isChecked={data.popular === 'true'}
          colorScheme="green"
          onChange={() =>
            onUpdateInitiative('popular', data.popular === 'true' ? 'false' : 'true')
          }
        >
          {t('popular')}
        </Checkbox>
        <Checkbox
          mr="2"
          isDisabled={isUpdateLoading}
          isChecked={data.isUrgent}
          colorScheme="red"
          onChange={() =>
            onUpdateInitiative('isUrgent', data.isUrgent ? false : true)
          }
        >
          {t('urgent')}
        </Checkbox>
        <Checkbox
          isDisabled={isUpdateLoading}
          isChecked={data.isFeatured}
          colorScheme="blue"
          onChange={() =>
            onUpdateInitiative('featured', data.featured === 'true' ? 'false' : 'true')
          }
        >
          {t('featured')}
        </Checkbox>
      </Flex>
      <StatusMenu
        type="collaborations"
        card={data}
        onValueChange={(status) => onUpdateInitiative('status', status)}
      />
    </Box>
  )
}
