import { useTranslation } from 'contexts/TranslationContext'
import WrapperMobile from './Wrapper'

const Initiatives = ({initiatives, isLoading}) => {
  const { t } = useTranslation()

  return (
      <WrapperMobile
        type="collaborations"
        swiper={{ items: initiatives }}
        isLoading={isLoading}
        fromSwiper={true}
        title={t('My Collaborations')}
        showAll={true}
        route="my-collaborations"
      />
  )
}

export default Initiatives
