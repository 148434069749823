import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useSignOut } from 'hooks'
import View from './Menu'

const Menu = ({ black, display, isGuest,  }) => {
  const { t, language, setLanguage } = useTranslation()
  const signOut = useSignOut()
  const { isOpen, onToggle } = useDisclosure()
  const location = useLocation()

  const itemStyles = {
    fontSize: '5xl',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'gray.100',
    borderColor: 'transparent',
    transition: 'all 0.2s',
  }

  const props = {
    isGuest,
    black,
    t,
    language,
    setLanguage,
    signOut,
    isOpen,
    onToggle,
    location,
    itemStyles,
    display,
  }

  return <View {...props} />
}

export default Menu
