const giftCardUseCase = (giftCardRepo, transactionsRepo) => {
  const getGiftCard = async (id) => {
    if (!id) {
      throw Error('ID is required')
    }
    giftCardRepo.getGiftCard(id)
  }

  const listGiftCards = async (variables) =>
    giftCardRepo.listGiftCards(variables)

  const listGiftCardsByCode = async (variables) => {
    const giftCardResponse = await giftCardRepo.listGiftCardsByCode(variables)
    const giftCard = giftCardResponse.data.giftCardsByCode.items[0];
    if (giftCard) {
      const transactionsResponse = await transactionsRepo.listTransactionsByGiftCardCode({ giftCardCode: variables.code })
      const filterSpendTransactions = (items) =>
        items.filter(item => item.giftCardTransactionType !== 'buy')
      const getSpendAmount = (items) => {
        let spendAmount = 0;
        items.map(item => spendAmount += item.giftCardAmount);
        return spendAmount
      }
      const totalTransactionsSpent = getSpendAmount(filterSpendTransactions(transactionsResponse.data.transactionsByGiftCardCode.items));
      giftCard.availableAmount = giftCard.amount - totalTransactionsSpent;
    }
    return giftCard || {};
  }

  const createGiftCard = async (input) =>
    giftCardRepo.createGiftCard(input)

  return {
    createGiftCard,
    getGiftCard,
    listGiftCards,
    listGiftCardsByCode,
  }
}

export default giftCardUseCase
