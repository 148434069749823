import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { ReactComponent as Papik } from '../../assets/icons/papiktatik.svg'
import { ReactComponent as Reached } from 'assets/icons/reached.svg'
import MonthlyLabel from 'components/MonthlyLabel'
import AmountWithCurrency from 'components/AmountWithCurrency'

const Double = () => (
  <Flex ml="2" mr="2" alignItems="center">
    <Box mr="2" display="block" whiteSpace="nowrap" as="span">
      x 2
    </Box>
    <Papik height="30px" />
  </Flex>
)

const ListItem = ({
  data,
  dataLength,
  index,
  reachedText = 'Reached',
  goalText = 'Goal',
  variant,
  dateFromNow,
  Amount,
  language,
}) => (
  <Flex
    justifyContent="space-between"
    borderBottomWidth={variant === 'donor' && index + 1 !== dataLength ? 1 : 0}
    borderStyle="solid"
    borderColor="border.100"
    py={variant === 'donor' ? '4' : variant === 'goal' ? '4' : '3'}
  >
    <Flex alignItems="center">
      {variant === 'goal' && (
        <Flex
          minW="24px"
          h="24px"
          bg="gray.300"
          borderRadius="100px"
          alignItems="center"
          justifyContent="center"
          mr={3}
        >
          <Text color="gray.800" fontSize="sm" fontWeight="semibold">
            {index + 1}
          </Text>
        </Flex>
      )}
      <Box>
        <Flex
          alignItems="center"
          fontSize="md"
          fontWeight="500"
          color="gray.800"
          as="span"
        >
          {variant === 'goal' ? (
            <>
              <Text as="span" mr="2">{goalText}:</Text>
              {Amount}{' '}
              {data.isReached && (
                <Text display="flex" as="span" color="gray.700" fontSize="md">
                  {' '}
                  <Reached style={{ margin: '0 4px' }} /> {reachedText}
                </Text>
              )}
            </>
          ) : (
            <>
              {' '}
              {data.isAnonymous
                ? 'Anonymous'
                : data.firstName + ' ' + data.lastName}
              {data.subscriptionId && <MonthlyLabel />}
            </>
          )}
        </Flex>
        <Box display="block" fontSize="sm" as="span" color="gray.700">
          {variant === 'donor' ? dateFromNow : data[`description_${language}`]}
        </Box>
      </Box>
      {data.double && <Double />}
    </Flex>
    {variant === 'donor' && (
      <Box fontWeight="600" fontSize="md" as="span" color="gray.800">
        {data.donationToProject > data.currencyRate
          ? Math.floor(data.donationToProject / Number(data.currencyRate || 1))
          : Number(
              data.donationToProject / Number(data.currencyRate || 1)
            ).toFixed(2)}{' '}
        {data.currency || 'AMD'}
      </Box>
    )}
  </Flex>
)

export default ListItem
