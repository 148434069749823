import React from 'react'
import { Heading, Box } from '@chakra-ui/react'
import CreditCards from 'components/CreditCards'

export default function AttachedCards({
  watch,
  getValues,
  setValue,
  onChange,
  title = '',
  user,
}) {

  if (user.id === 'guest') {
    return null
  }

  return (
    <Box
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor="border.100"
      mt={8}
      py={6}
    >
      {user.creditCards?.length > 0 && (
        <Heading as="h2" fontSize="xl" mb={5}>
          {title}
        </Heading>
      )}
      <CreditCards
        removable={false}
        page="donation"
        selected={watch('bindingId')}
        attachCard={watch('attachCard')}
        onClick={onChange}
        setValue={setValue}
      />
    </Box>
  )
}
