import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react'
import {
  Stack,
  Box,
  Text,
  Flex,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { TextareaField } from 'components/TextareaFIeld'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { ReactComponent as DisabledPlus } from 'assets/icons/disabledPlus.svg'
import { ReactComponent as Remove } from 'assets/icons/remove-goal.svg'
import { ReactComponent as RemoveDisabled } from 'assets/icons/remove-goal-disabled.svg'

const w = window.innerWidth
const LIMIT = 5

export default function GoalsForm({
  goals,
  errors,
  remove,
  register,
  append,
  getValues,
  language,
  isEdit,
  isRequired,
  fundraisingTypeProp = '',
}) {
  const { t } = useTranslation()
  const fundraisingType = fundraisingTypeProp || getValues('fundraisingType')

  const onAddGoal = useCallback(() => {
    if (goals.length === LIMIT) return
    append({
      amount: '',
      description_en: '',
      description_ru: '',
      description_hy: '',
    })
  }, [goals])
  useEffect(() => {
    if (fundraisingType === 'recurring' && goals.length === 0) {
      append({
        amount: '',
        description_en: '',
        description_ru: '',
        description_hy: '',
      })
    }
  }, [])
  return (
    <Box>
      {goals.map((item, index) => (
        <SingleGoal
          key={`create-project-recurring-${index}`}
          index={index}
          errors={errors}
          goals={goals}
          remove={remove}
          register={register}
          fundraisingType={fundraisingType}
          formLang={language}
          isEdit={isEdit}
          isRequired={isRequired}
          id={`create-project-recurring-${index}`}
        />
      ))}
      <Button
        mt={goals.length > 0 ? 6 : 0}
        fontSize="md"
        color="gray.800"
        onClick={onAddGoal}
        isDisabled={goals.length === LIMIT}
        textTransform="none"
      >
        <Box mr="4">
          {goals.length === LIMIT ? (
            <DisabledPlus width="24px" />
          ) : (
            <Plus width="24px" />
          )}
        </Box>
        {fundraisingType === 'oneTime' ? t('addGoal') : t('addMonthlyGoal')}
      </Button>
    </Box>
  )
}

function SingleGoal({
  index,
  fundraisingType,
  goals,
  errors,
  register,
  remove,
  formLang,
  isEdit,
  isRequired,
  ...rest
}) {
  const { t, language } = useTranslation()
  const inputLeftElementRef = useRef(null)
  const inputRightElementRef = useRef(null)
  // const [inputPaddingLeft, setInputPaddingLeft] = useState(0)
  const [inputPaddingRight, setInputPaddingRight] = useState(0)
  const goalText =
    fundraisingType === 'oneTime'
      ? t('createEditProject@goal')
      : t('createEditProject@monthlyGoal')
  const showRemoveButton = useMemo(() => {
    if (isEdit && goals.length < 2) {
      return false
    }
    if (!isRequired) {
      return true
    }
    return goals.length > 1
  }, [isRequired, goals])

  //TODO Uncomment when fixed monthly
  // useEffect(() => {
  //   if (!inputLeftElementRef.current) return
  //   setInputPaddingLeft(inputLeftElementRef.current.clientWidth + 20)
  // }, [inputLeftElementRef, language])

  useEffect(() => {
    if (!inputRightElementRef.current) return
    setInputPaddingRight(inputRightElementRef.current.clientWidth + 5)
  }, [inputRightElementRef, language])

  return (
    <Flex mb={index + 1 !== goals.length ? 9 : 0}>
      <Flex
        minW="24px"
        h="24px"
        bg="gray.300"
        borderRadius="100px"
        alignItems="center"
        justifyContent="center"
        mt="9px"
        mr={4}
      >
        <Text color="gray.800" fontSize="sm" fontWeight="semibold">
          {index + 1}
        </Text>
      </Flex>
      <Stack spacing={5} w="100%">
        <InputGroup w="calc(100% - 34px)">
          <InputLeftElement
            ref={inputLeftElementRef}
            ml={4}
            w="auto"
            pointerEvents="none"
            color="gray.700"
            lineHeight="43px"
            noOfLines={1}
            maxW={w > 481 ? 'auto' : '100px'}
            children={goalText}
          />
          <Input
            pl={inputLeftElementRef.current?.clientWidth + 20 + 'px'}
            pr={`${inputPaddingRight}px`}
            _focus={{ borderColor: 'blue.300' }}
            _hover={{ borderColor: 'blue.300' }}
            borderColor="border.100"
            borderWidth="1px"
            sx={{ boxShadow: 'none !important' }}
            pattern="[0-9]*"
            isInvalid={!!errors?.goals && !!errors.goals[index]?.amount}
            type="number"
            {...register(`goals.${index}.amount`, {
              required: true,
              valueAsNumber: true,
              validate: {
                positive: (value) => parseFloat(value) > 0,
              },
            })}
            {...rest}
          />
          <InputRightElement
            ref={inputRightElementRef}
            w="auto"
            pointerEvents="none"
            color="black"
            pr={6}
            children={t('currency@amd')}
          />
          {!!errors?.goals && !!errors.goals[index]?.amount && (
            <Box
              top="calc(100% + 5px)"
              fontSize="sm"
              fontWeight="semibold"
              position="absolute"
              color="red.400"
            >
              {t('createEditProject@thisFieldIsRequired')}
            </Box>
          )}
        </InputGroup>
        <TextareaField
          labelProps={{ margin: 0 }}
          {...register(
            `goals.${index}.description_${formLang ? formLang : 'en'}`,
            {
              required: !isEdit,
            }
          )}
          isInvalid={!!errors?.goals && !!errors.goals[index]?.description_en}
          errorMessage={t('createEditProject@thisFieldIsRequired')}
          placeholder={t('createEditProject@describeGoal')}
          _placeholder={{ color: 'gray.700' }}
        />
      </Stack>
      <Button
        minW="24px"
        w="24px"
        h="24px"
        right="34px"
        maxH="24px"
        borderRadius="100px"
        mt="10px"
        ml="10px"
        isDisabled={!showRemoveButton}
        onClick={() => remove(index)}
      >
        {showRemoveButton ? (
          <Remove width="24px" />
        ) : (
          <RemoveDisabled width="24px" />
        )}
      </Button>
    </Flex>
  )
}
