import { useQuery } from 'react-query'
import { transactionUseCase } from '../../core/factories'

const useListTransactionsByTopDonation = (variables) => {
  const data = useQuery(['transactions-top', variables], () => transactionUseCase.listTransactionsByTopDonation(variables))

  return data;
}

export default useListTransactionsByTopDonation
