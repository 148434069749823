import { useMutation } from 'react-query'
import { projectUseCase } from '../../core/factories'

const useCreateProject = () => {
  const { mutate: createProject, isLoading } = useMutation(
    projectUseCase.createProject
  )

  return {
    createProject,
    isLoading,
  }
}

export default useCreateProject
