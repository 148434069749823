import { HStack, useRadioGroup, Box, Flex } from '@chakra-ui/react'
import {
  SUPPORTED_CURRENCY_SYMBOLS,
  thousandSeparator,
} from 'contexts/CurrencySettingsContext'
import * as React from 'react'
import { RadioOption } from './RadioOption'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'

export const RadioGroup = ({
  name,
  options,
  onChange,
  defaultValue,
  price,
  ...rest
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    defaultValue,
  })
  const { currency } = useCurrencySettings()
  return (
    <HStack
      height="61px"
      borderRadius="12px"
      bg="#EDE8EB"
      px="1"
      py="1"
      overflow="hidden"
      borderColor="border.100"
      {...getRootProps(rest)}
    >
      {Object.entries(options).map(([key, value]) => (
        <RadioOption key={key} {...getRadioProps({ value: key })} borderRadius="10px">
          <Flex alignItems="center" direction="column">
            <Box as="span" fontSize="md" fontWeight="bold">
              {key === 'other' ? value : `${value}%`}
            </Box>
            <Box as="span" fontSize="md">
              {key !== 'other' &&
                thousandSeparator((price * value) / 100, currency.current) +
                  ` ${SUPPORTED_CURRENCY_SYMBOLS[currency.current]}`}
            </Box>
          </Flex>
        </RadioOption>
      ))}
    </HStack>
  )
}
