import React from 'react'
import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
  useBreakpointValue,
  useDisclosure,
  Image,
} from '@chakra-ui/react'
import { Link, useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import PaymentModal from 'components/PaymentModal'

const About = () => {
  const size = useBreakpointValue({ base: 'full', sm: 'xl' })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const { navigationPush } = useNavigation()

  return (
    <Box mt={24}>
      <Flex
        height="400px"
        // bg="#fafafa"
        direction="column"
        alignItems="left"
        justifyContent="center"
        background="url(/assets/images/banner.png)"
        color="white"
      >
        <Image
          src="/assets/images/join.png"
          height="400px"
          position="absolute"
          right="0"
          zIndex={1}
          objectFit="cover"
        />
        <Box margin="auto" w="65%">
          <Heading
            // fontWeight="600"
            as="h2"
            fontSize={{ base: '5xl', sm: '2.5rem' }}
            mb="5"
            position="relative"
            zIndex={2}
          >
            {t('homepage@contributionHeading')}
          </Heading>
          <Button
            w="250px"
            variant="green"
            mt={4}
            position="relative"
            zIndex={2}
            as={Link}
            to="/donation?projectId=ap"
          >
            {t('homepage@contributionButtonText')}
          </Button>
          <PaymentModal size={size} isOpen={isOpen} onClose={onClose} />
        </Box>
      </Flex>
      <Flex
        mb={24}
        mt={{ base: 8, sm: 24 }}
        px="4"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box maxW="832px">
          <Heading mb="8" fontSize={{ base: '3xl', sm: '2.5rem' }} as="h2">
            {t('homepage@aboutHeading')}
          </Heading>
          <Text color="gray.700" fontSize={{ base: 'xl', sm: '1.25rem' }}>
            {t('homepage@aboutDescription1')}
          </Text>
          <Text
            mb="6"
            fontSize={{ base: 'xl', sm: '1.25rem' }}
            fontWeight="500"
          >
            {t('homepage@aboutBold')}
          </Text>
          <Text
            color="gray.700"
            fontSize={{ base: 'xl', sm: '1.25rem' }}
            dangerouslySetInnerHTML={{
              __html: t('homepage@aboutDescription2'),
            }}
          />
          {/* <Text
            color="black"
            fontSize={{ base: 'xl', sm: '1.25rem' }}
            fontWeight="400"
          >
            {t('homepage@aboutDescription3')}
          </Text> */}
        </Box>
      </Flex>
    </Box>
  )
}

export default About
