import { useState, useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Box,
  Input,
  Button,
  Text,
  Flex,
  Stack,
} from '@chakra-ui/react'
import PerkCard from 'components/PerkCard'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import NoScrollbar from 'components/NoScrollbar'
import {
  useCurrencySettings,
  thousandSeparator,
} from 'contexts/CurrencySettingsContext'

const DonateModal = ({ isOpen, onClose, project, param }) => {
  const { t, language } = useTranslation()
  const [amount, setAmount] = useState()
  const { navigationPush } = useNavigation()
  const newDate = new Date()
  const { currency, settings } = useCurrencySettings()

  const onSelect = useCallback(
    (perkIndex) => {
      let query = param ? param + '&' : '?'
      const currentCurrency =
        currency.current !== 'AMD' ? settings.currency[currency.current] : 1
      const perkPrice = (
        project?.perks[perkIndex].price / currentCurrency
      ).toFixed(2)
      navigationPush(
        `/donation${query}perkIndex=${perkIndex}&projectId=${project.id}&amount=${perkPrice}`
      )
    },
    [project, currency]
  )

  const onSubmit = useCallback(() => {
    if (!amount) return
    navigationPush(`/donation?projectId=${project.id}&amount=${amount}`)
  }, [amount])

  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent position="relative" pb="4" width="532px">
        <ModalHeader>{t('project@donateProject')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          onKeyDown={(e) => {
            if (e.key === 'Enter' && amount) {
              return onSubmit()
            }
          }}
          position="relative"
        >
          <NoScrollbar Component={Box} overflow="scroll" maxH="480px">
            <FormControl mb="6">
              <FormLabel
                fontSize="xl"
                fontWeight="regular"
                color="black"
                bg="gray.100"
                zIndex={2}
                px="2"
              >
                {t('project@enterDonation')}
              </FormLabel>
              <Flex flexDirection="column" align="center">
                <InputGroup alignItems="center">
                  <InputLeftElement
                    top="unset"
                    width="100px"
                    pointerEvents="none"
                    children={
                      <Box as="span" fontSize="3xl" fontWeight="bold">
                        {currency.current}
                      </Box>
                    }
                  />
                  <Input
                    maxLength="12"
                    _focus={{
                      boxShadow: 'none',
                    }}
                    borderColor="border.100"
                    _hover={{
                      borderColor: 'blue.300',
                    }}
                    pl="6.5rem"
                    textAlign="right"
                    fontWeight="extrabold"
                    fontSize="6xl"
                    height="86px"
                    value={thousandSeparator(amount)}
                    onChange={(e) =>
                      setAmount(e.target.value.replace(/[^0-9]|^0+(?!$)/g, ''))
                    }
                    pattern="[0-9,]*"
                  />
                </InputGroup>
              </Flex>
            </FormControl>
            <Button
              minH="40px"
              _disabled={{
                background: '#CDCDCD',
              }}
              _hover={{
                backgroundColor: '#26CB75',
                _disabled: {
                  background: '#CDCDCD',
                },
              }}
              disabled={!amount}
              w="full"
              isDisabled={!amount}
              variant="green"
              onClick={onSubmit}
            >
              {t('project@donate')}
            </Button>
            <Text
              display={project?.perks?.length > 0 ? 'inline-block' : 'none'}
              fontSize="xl"
              fontWeight="regular"
              color="black"
              bg="gray.100"
              zIndex={2}
              px="2"
              mt="8"
              mb="4"
            >
              {t('project@orSelectReward')}
            </Text>
            <Stack spacing="4">
              {project.perks?.map((perk, index) => (
                <PerkCard
                  param={param}
                  key={`donation-modal-title-${perk.title}-${index}`}
                  image={perk.imageKey || perk.image || ''}
                  title={perk[`title_${language}`] ?? ''}
                  price={perk.price ?? 0}
                  date={newDate}
                  onSelect={() => onSelect(index)}
                />
              ))}
            </Stack>
          </NoScrollbar>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DonateModal
