import { Box, Button, Text, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import moment from 'moment'
import { useTransactions } from 'contexts'
import SeeAllModal from './SeeAllModal'
import ListItem from 'components/ListItem'
import {
  CurrencyConverter,
  useCurrencySettings,
  SUPPORTED_CURRENCY_SYMBOLS,
} from 'contexts/CurrencySettingsContext'

export default function DonationList({ project }) {
  const { setLoadMore, nextToken, data, isMoreLoading } = useTransactions()
  const { t, language } = useTranslation()
  const { currency } = useCurrencySettings()
  const { onOpen, isOpen, onClose } = useDisclosure()
  if (data.length === 0) return null
  return (
    <>
      <SeeAllModal
        isMoreLoading={isMoreLoading}
        nextToken={nextToken}
        setLoadMore={setLoadMore}
        transactions={{ items: data }}
        projectId={project.id}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Box
        mb="16"
        mt={project.status === 'ended' ? 10 : 0}
        border="1px solid"
        borderRadius="16px"
        borderColor="border.100"
      >
        {data.length > 0 && (
          <Box px="8" mt={6}>
            <Text color="gray.700" fontWeight="500" textTransform="uppercase">
              {t('project@peopleDonated')}
            </Text>
            {data?.slice(0, 3).map((transaction, index) => (
              <ListItem
                key={`one-time-transaction-${transaction.id}`}
                data={transaction}
                goalAmount={CurrencyConverter({
                  amount: transaction.amount,
                  currency: currency.current,
                })}
                reachedText={t('reached')}
                goalText={t(
                  project?.fundraisingType === 'oneTime'
                    ? 'goal'
                    : 'monthlyGoal'
                )}
                index={index}
                SUPPORTED_CURRENCY_SYMBOLS={SUPPORTED_CURRENCY_SYMBOLS}
                language={language}
                dataLength={data.length}
                dateFromNow = {moment(transaction.createdAt).fromNow()}
                variant={'donor'}
              />
            ))}
            {data.length > 3 && (
              <Button mt={4} mb={6} onClick={onOpen} variant="transparent">
                {t('seeAll')}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}
