import { useEffect, useState } from 'react'
import { TabList, Tab, Tabs } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryParams } from 'hooks'

export const StyledTab = ({
  title,
  onClick,
  setFilters,
  filters,
  mobile = false,
  ...props
}) => {
  const queryParams = useQueryParams()
  const history = useHistory()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  return (
    <Tab
      _active={{ background: 'initial' }}
      _selected={{
        fontWeight: 'extrabold',
        color: 'blue.300',
        borderColor: 'blue.300',
      }}
      onClick={() => {
        if (queryParams.get('type') !== title) {
          history.replace(`${pathname}?type=${title}`)
          setFilters({ ...filters, type: title })
        }
      }}
      fontWeight="semibold"
      color="gray.700"
      w={{ base: '50%', md: '160px' }}
      fontSize="lg"
      py="22px"
      {...props}
    >
      {t(title)}
    </Tab>
  )
}

export default function FundTabs({ setFilters, filters }) {
  const queryParams = useQueryParams()
  const [tabIndex, setTabIndex] = useState(0)
  const tabs = ['oneTime', 'recurring']

  useEffect(() => {
    if (filters.type) {
      setTabIndex(filters.type === 'oneTime' ? 0 : 1)
    }
    if (!queryParams.has('type')) {
      setFilters({ ...filters, type: tabs[tabIndex] })
    }
  }, [])

  return (
    <Tabs
      height="100%"
      index={tabIndex}
      onChange={setTabIndex}
    >
      <TabList borderBottom="none">
        {tabs.map((tabTitle, index) => (
          <StyledTab
            key={tabTitle + index}
            filters={filters}
            setFilters={setFilters}
            title={tabTitle}
          />
        ))}
      </TabList>
    </Tabs>
  )
}
