import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams, useSignUp, useValidateEmail } from 'hooks'
import { COUNTRY_OBJECTS } from '_constants'
import Mobile from './Mobile'
import Web from './Web'
import useCustomToast from 'hooks/useCustomToast'
import { useParams } from 'react-router-dom'

const Register = () => {
  const w = window.innerWidth

  const toast = useCustomToast()
  const validateEmail = useValidateEmail()
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { signUp } = useSignUp()
  const { t } = useTranslation()
  const { navigationPush } = useNavigation()
  const params = useQueryParams()
  const password = useRef({})
  password.current = watch('password', '')

  const onSubmit = (data) => {
    const input = {
      ...data,
      country,
    }
    if ((!!phone && phone.length < 5) || (!!phone && phone[0] !== '+')) {
      return setPhoneError(true)
    }

    if (!validateEmail(input.email)) {
      return toast({
        status: 'error',
        title: 'Invalid email format',
        isClosable: true,
      })
    }
    const phoneFormatted = phone
      .replace(/\s/g, '')
      .replace('(', '')
      .replace(')', '')

    const createdDate = new Date().toISOString()
    delete input.privacy_policy
    delete input.terms_and_conditions
    delete input.confirm_password
    setLoading(true)
    signUp(
      {
        ...input,
        email: data.email.toLowerCase(),
        phone: phoneFormatted,
        createdAt: createdDate,
        updatedAt: createdDate,
      },
      {
        onSuccess: async () => {
          setLoading(false)

          toast({
            status: 'success',
            title: t('signUp@confirmationEmailMessage'),
            isClosable: true,
            duration: 5000,
          })

          navigationPush(
            `/verify?email=${data.email.toLowerCase()}&firstName=${
              data.firstName
            }&from=${params.get('from')}`
          )
        },
        onError: (e) => {
          console.log(e)
          if (e.code === 'InvalidParameterException') {
            return toast({
              status: 'error',
              title: t('signUp@phoneNumberErrorMessage'),
              isClosable: true,
              duration: 5000,
            })
          }
          toast({
            status: 'error',
            title: t(`signUp@${e.code}`),
            isClosable: true,
            duration: 5000,
          })
          setLoading(false)
        },
      }
    )
  }

  useEffect(() => {
    if (!country) return
    const newValue = COUNTRY_OBJECTS.filter((item) => item.name === country)[0]
      ?.dialCode
    setPhone(newValue)
  }, [country])

  if (w < 481) {
    return (
      <Mobile
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        validateEmail={validateEmail}
        country={country}
        setCountry={setCountry}
        phone={phone}
        setPhone={setPhone}
        phoneError={phoneError}
        loading={loading}
        password={password}
      />
    )
  }
  return (
    <Web
      register={register}
      errors={errors}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      validateEmail={validateEmail}
      country={country}
      setCountry={setCountry}
      phone={phone}
      setPhone={setPhone}
      phoneError={phoneError}
      loading={loading}
      password={password}
    />
  )
}

export default Register
