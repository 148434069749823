import { API, graphqlOperation } from 'aws-amplify'
import { createTransaction as createTransactionMutation } from '../../graphql/mutations'
import {
  getTransaction as getTransactionQuery,
  listTransactions as listTransactionsQuery,
  transactionsByDate,
  transactionsByGiftCardCode as transactionsByGiftCardCodeQuery,
  transactionsByTopDonation,
  searchTransactions as searchTransactionsQuery,
} from '../../graphql/queries'

const transactionService = () => {
  const createTransaction = (input) =>
    API.graphql(graphqlOperation(createTransactionMutation, { input }))

  const getTransaction = (id) =>
    API.graphql(graphqlOperation(getTransactionQuery, { id }))

  const searchTransactions = (variables) =>
    API.graphql(graphqlOperation(searchTransactionsQuery, variables))

  const listTransactions = (variables) =>
    API.graphql(graphqlOperation(listTransactionsQuery, variables))

  const listTransactionsByGiftCardCode = (variables) =>
    API.graphql(graphqlOperation(transactionsByGiftCardCodeQuery, variables))

  const listTransactionsByDate = (variables) =>
    API.graphql(graphqlOperation(transactionsByDate, variables))

  const listTransactionsByTopDonation = (variables) =>
    API.graphql(graphqlOperation(transactionsByTopDonation, variables))

  const getTransactionsFromElastic = (condition) =>
    API.post('elastic', '/proxy', {
      body: {
        index: 'transaction',
        path: '_search',
        method: 'POST',
        data: condition,
      },
    })
  return {
    searchTransactions,
    getTransactionsFromElastic,
    createTransaction,
    getTransaction,
    listTransactions,
    listTransactionsByGiftCardCode,
    listTransactionsByDate,
    listTransactionsByTopDonation,
  }
}

export default transactionService
