import { API, graphqlOperation } from 'aws-amplify'
import {
  getProject,
  projectBySlug,
  projectByUserId,
  projectBypopular,
  projectByFeatured,
  searchProjects as searchProjectsQuery,
} from '../../graphql/queries'
import {
  createProject as create,
  updateProject as update,
  deleteProject as removeProject,
} from '../../graphql/mutations'

const projectService = () => {
  const createProject = async (project) => {
    await API.graphql(graphqlOperation(create, { input: project }))
  }

  const updateProject = async (project) => {
    return await API.graphql(graphqlOperation(update, { input: project }))
  }

  const updateProjectStatus = async ({ id, status }) => {
    await API.graphql(graphqlOperation(update, { input: { id, status } }))
  }

  const updateProjectPerks = async ({ id, perks }) => {
    await API.graphql(graphqlOperation(update, { input: { id, perks } }))
  }

  const retrieveProject = async (id) => {
    const project = await API.graphql(graphqlOperation(getProject, { id }))

    return project.data.getProject
  }

  const getProjectBySlug = async (slug) => {
    const project = await API.graphql(graphqlOperation(projectBySlug, { slug }))
    return project.data.projectBySlug.items[0] || null
  }

  const deleteProject = async (project) => {
    await API.graphql(graphqlOperation(removeProject, { input: project }))
  }

  const getProjectsFromElastic = (condition) =>
    API.post('elastic', '/proxy', {
      body: {
        index: 'project',
        path: '_search',
        method: 'POST',
        data: condition,
      },
    })

  const getProjectsByUserId = (condition) =>
    API.graphql(graphqlOperation(projectByUserId, condition))

  const getProjectsByPopular = (condition) =>
    API.graphql(graphqlOperation(projectBypopular, condition))

  const getProjectsByFeatured = (condition) =>
    API.graphql(graphqlOperation(projectByFeatured, condition))

  const searchProjects = (condition) =>
    API.graphql(graphqlOperation(searchProjectsQuery, condition))

  return {
    createProject,
    updateProject,
    retrieveProject,
    updateProjectPerks,
    updateProjectStatus,
    deleteProject,
    getProjectBySlug,
    getProjectsFromElastic,
    getProjectsByUserId,
    getProjectsByPopular,
    getProjectsByFeatured,
    searchProjects,
  }
}

export default projectService
