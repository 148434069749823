import React from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const ContactInfo = () => {
  const { t } = useTranslation()

  return (
    <Flex
      mt={{ base: '2rem', md: '0' }}
      pl={{ base: '0', lg: '5rem' }}
      flexDirection="column"
      borderLeftWidth={{ base: '0', md: '0.5px' }}
      borderStyle="solid"
      borderColor="rgba(0, 0, 0, 0.2)"
      flex="1"
    >
      <Heading mb="16" as="h2" fontSize={{ base: 'xl', sm: '4xl' }}>
        {t('contactUs@heading2')}
      </Heading>
      {/* <Box
                mb="8"
                fontSize="5xl"
                as="span">
                +37494 380 390
            </Box> */}
      <Box
        mb="8"
        fontSize={{ base: 'xl', sm: '3xl' }}
        href="mailto:info@armenianpeace.am"
        as="a"
      >
        {t('contactUs@form-emailAddressShort')}: info@armenianpeace.am
      </Box>
      <Box
        mb="8"
        fontSize={{ base: 'xl', sm: '3xl' }}
        href="tel:+37477110863"
        as="a"
      >
        {t('contactUs@tell')}: +374 77 110863
      </Box>
      {/* <Box fontSize={{ base: 'xl', sm: '3xl' }} as="span">
        {t('contactUs@addressShort')} {t('contactUs@address')}
      </Box> */}
    </Flex>
  )
}

export default ContactInfo
