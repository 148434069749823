import moment from 'moment'

const calcDuplicateTransaction = (total, maxAmount, donationToProject) => {
  if (maxAmount - total > donationToProject) {
    return donationToProject * 2
  }

  const amountToDuplicate = maxAmount - total
  const amount = amountToDuplicate - donationToProject

  return amount + amountToDuplicate * 2
}

const transactionUseCase = (transactionRepo) => {
  const getTransaction = async (id) => {
    if (!id) {
      throw Error('ID is required')
    }
    transactionRepo.getTransaction(id)
  }

  const listTransactions = async (variables) => {
    try {
      let transactions = await transactionRepo.listTransactionsByGiftCardCode(
        variables
      )

      if (variables.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1') {
        const duplicateCursorIndex =
          transactions.data.transactionsByDate.items.findIndex(
            (item) => item.id === '950585a5-6932-4a6a-96f0-56f7d2582ad9'
          )
      }

      let total = 0
      transactions.data.transactionsByDate.items =
        transactions.data.transactionsByDate.items.map((transaction) => {
          if (
            transaction.projectId === '3699d58d-d9d0-45e0-aba8-f36e41401211'
          ) {
            transaction.double = false
            if (total < 20_000_000) {
              transaction.double = true
              const amount = calcDuplicateTransaction(
                total,
                20_000_000,
                transaction.donationToProject
              )
              // transaction.donationToProject = transaction.donationToProject * 2
              // total += transaction.donationToProject
              transaction.donationToProject = amount
              total += amount
            }
          }
          if (
            transaction.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1'
          ) {
            transaction.double = false
            if (total < 14_000_000) {
              transaction.double = true
              const amount = calcDuplicateTransaction(
                total,
                14_000_000,
                transaction.donationToProject
              )
              // transaction.donationToProject = transaction.donationToProject * 2
              // total += transaction.donationToProject
              transaction.donationToProject = amount
              total += amount
            }
          }
          return transaction
        })
      transactions = transactions.sort((a, b) => a.createdAt - b.createdAt)
      return transactions
    } catch (e) {
      console.log('#ed list transactions error', e)
    }

    return []
  }

  const listTransactionsByGiftCardCode = async (variables) => {
    try {
      let transactions = await transactionRepo.listTransactions(variables)
      let total = 0
      transactions = transactions
        .sort((a, b) => a.createdAt - b.createdAt)
        .map((transaction) => {
          if (
            transaction.projectId === '3699d58d-d9d0-45e0-aba8-f36e41401211'
          ) {
            if (total < 20000000) {
              transaction.amount = transaction.amount * 2
              total += transaction.amount
            }
          }
        })
      return transactions
    } catch (e) {
      console.log('#ed list transactions error', e)
    }

    return []
  }

  const listTransactionsByDate = async (variables) => {
    try {
      let transactions = await transactionRepo.listTransactionsByDate(variables)
      let total = 0

      if (variables.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1') {
        const duplicateCursorIndex =
          transactions.data.transactionsByDate.items.findIndex(
            (item) => item.id === '51ac221f-384f-449e-a128-8b1b4a14e412'
          )
        if (duplicateCursorIndex !== -1) {
          for (let item of transactions.data.transactionsByDate.items.slice(
            duplicateCursorIndex,
            transactions.data.transactionsByDate.items.length
          )) {
            item.double = true
            item.donationToProject = item.donationToProject * 2
          }
        }
      }
      transactions.data.transactionsByDate.items =
        transactions.data.transactionsByDate.items.map((transaction) => {
          if (
            transaction.projectId === '3699d58d-d9d0-45e0-aba8-f36e41401211'
          ) {
            transaction.double = false
            if (total < 20000000) {
              transaction.double = true
              transaction.donationToProject = transaction.donationToProject * 2
              total += transaction.donationToProject
            }
          }
          if (
            transaction.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1'
          ) {
            transaction.double = false
            if (total < 14000000) {
              transaction.double = true
              transaction.donationToProject = transaction.donationToProject * 2
              total += transaction.donationToProject
            }
          }
          return transaction
        })

      return transactions.data.transactionsByDate
    } catch (e) {
      console.log(e)
    }
  }

  const listTransactionsByTopDonation = async (variables) => {
    try {
      let transactions = await transactionRepo.listTransactionsByTopDonation(
        variables
      )
      let total = 0
      transactions.data.transactionsByTopDonation.items =
        transactions.data.transactionsByTopDonation.items.map((transaction) => {
          if (
            transaction.projectId === '3699d58d-d9d0-45e0-aba8-f36e41401211'
          ) {
            transaction.double = false
            if (total < 20000000) {
              transaction.double = true
              transaction.donationToProject = transaction.donationToProject * 2
              total += transaction.donationToProject
            }
          }
          if (
            transaction.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1'
          ) {
            transaction.double = false
            if (total < 14000000) {
              transaction.double = true
              transaction.donationToProject = transaction.donationToProject * 2
              total += transaction.donationToProject
            }
          }
          return transaction
        })

      return transactions.data.transactionsByTopDonation
    } catch (e) {
      console.log(e)
    }
  }

  const createTransaction = async (input) =>
    transactionRepo.createTransaction(input)

  const getTransactionsFromElastic = (input) =>
    transactionRepo.getTransactionsFromElastic(input)

  const getTransactionsByEmail = async (condition) => {
    const response = await transactionRepo.getTransactionsFromElastic(condition)
    if (!response) {
      return []
    }
    return {
      total: response.data.total.value,
      items: response.data.hits.map(({ _source }) => ({
        ..._source,
        date: moment(_source.createdAt).format('DD.MM.YYYY'),
        displayAmount: Number(
          _source.donationToProject / Number(_source.currencyRate || 1)
        ).toFixed(2),
        currency: _source.currency || 'AMD',
      })),
    }
  }

  return {
    getTransactionsByEmail,
    getTransactionsFromElastic,
    createTransaction,
    getTransaction,
    listTransactions,
    listTransactionsByGiftCardCode,
    listTransactionsByDate,
    listTransactionsByTopDonation,
  }
}

export default transactionUseCase
