/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGiftCard = /* GraphQL */ `
  mutation CreateGiftCard(
    $input: CreateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    createGiftCard(input: $input, condition: $condition) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateGiftCard = /* GraphQL */ `
  mutation UpdateGiftCard(
    $input: UpdateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    updateGiftCard(input: $input, condition: $condition) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteGiftCard = /* GraphQL */ `
  mutation DeleteGiftCard(
    $input: DeleteGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    deleteGiftCard(input: $input, condition: $condition) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      createdAt
      updatedAt
    }
  }
`;
export const createDonationSubscription = /* GraphQL */ `
  mutation CreateDonationSubscription(
    $input: CreateDonationSubscriptionInput!
    $condition: ModelDonationSubscriptionConditionInput
  ) {
    createDonationSubscription(input: $input, condition: $condition) {
      id
      projectId
      bindingId
      userEmail
      donationToReArmenia
      donationToProject
      unsubscribedDate
      month
      day
      currency
      currencyRate
      createdAt
      updatedAt
    }
  }
`;
export const updateDonationSubscription = /* GraphQL */ `
  mutation UpdateDonationSubscription(
    $input: UpdateDonationSubscriptionInput!
    $condition: ModelDonationSubscriptionConditionInput
  ) {
    updateDonationSubscription(input: $input, condition: $condition) {
      id
      projectId
      bindingId
      userEmail
      donationToReArmenia
      donationToProject
      unsubscribedDate
      month
      day
      currency
      currencyRate
      createdAt
      updatedAt
    }
  }
`;
export const deleteDonationSubscription = /* GraphQL */ `
  mutation DeleteDonationSubscription(
    $input: DeleteDonationSubscriptionInput!
    $condition: ModelDonationSubscriptionConditionInput
  ) {
    deleteDonationSubscription(input: $input, condition: $condition) {
      id
      projectId
      bindingId
      userEmail
      donationToReArmenia
      donationToProject
      unsubscribedDate
      month
      day
      currency
      currencyRate
      createdAt
      updatedAt
    }
  }
`;
export const createBindingCard = /* GraphQL */ `
  mutation CreateBindingCard(
    $input: CreateBindingCardInput!
    $condition: ModelBindingCardConditionInput
  ) {
    createBindingCard(input: $input, condition: $condition) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
    }
  }
`;
export const updateBindingCard = /* GraphQL */ `
  mutation UpdateBindingCard(
    $input: UpdateBindingCardInput!
    $condition: ModelBindingCardConditionInput
  ) {
    updateBindingCard(input: $input, condition: $condition) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
    }
  }
`;
export const deleteBindingCard = /* GraphQL */ `
  mutation DeleteBindingCard(
    $input: DeleteBindingCardInput!
    $condition: ModelBindingCardConditionInput
  ) {
    deleteBindingCard(input: $input, condition: $condition) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      userId
      giftCardId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      statusDescription
      amount
      orderId
      subscriptionId
      donationsCountWithEmail
      currency
      currencyRate
      isValid
      isManual
      isAnonymous
      createdAt
      updatedAt
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      userId
      giftCardId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      statusDescription
      amount
      orderId
      subscriptionId
      donationsCountWithEmail
      currency
      currencyRate
      isValid
      isManual
      isAnonymous
      createdAt
      updatedAt
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      userId
      giftCardId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      statusDescription
      amount
      orderId
      subscriptionId
      donationsCountWithEmail
      currency
      currencyRate
      isValid
      isManual
      isAnonymous
      createdAt
      updatedAt
    }
  }
`;
export const createUpdate = /* GraphQL */ `
  mutation CreateUpdate(
    $input: CreateUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    createUpdate(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      createdAt
      updatedAt
    }
  }
`;
export const updateUpdate = /* GraphQL */ `
  mutation UpdateUpdate(
    $input: UpdateUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    updateUpdate(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      createdAt
      updatedAt
    }
  }
`;
export const deleteUpdate = /* GraphQL */ `
  mutation DeleteUpdate(
    $input: DeleteUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    deleteUpdate(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isFeatured
      isUrgent
      isPopular
      isLive
      isProject
      isCollaboration
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      creatorImage
      cover
      TIN
      category
      status
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        price
        image
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      updates_en
      updates_ru
      updates_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
      }
      implementor {
        id
        email
        fullName
      }
      collaborators {
        id
        email
        fullName
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isFeatured
      isUrgent
      isPopular
      isLive
      isProject
      isCollaboration
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      creatorImage
      cover
      TIN
      category
      status
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        price
        image
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      updates_en
      updates_ru
      updates_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
      }
      implementor {
        id
        email
        fullName
      }
      collaborators {
        id
        email
        fullName
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isFeatured
      isUrgent
      isPopular
      isLive
      isProject
      isCollaboration
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      creatorImage
      cover
      TIN
      category
      status
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        price
        image
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      updates_en
      updates_ru
      updates_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
      }
      implementor {
        id
        email
        fullName
      }
      collaborators {
        id
        email
        fullName
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDraft = /* GraphQL */ `
  mutation CreateDraft(
    $input: CreateDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    createDraft(input: $input, condition: $condition) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      otherPlayers
      files
      fundraisingType
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const updateDraft = /* GraphQL */ `
  mutation UpdateDraft(
    $input: UpdateDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    updateDraft(input: $input, condition: $condition) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      otherPlayers
      files
      fundraisingType
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteDraft = /* GraphQL */ `
  mutation DeleteDraft(
    $input: DeleteDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    deleteDraft(input: $input, condition: $condition) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      otherPlayers
      files
      fundraisingType
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      domain
      projectIds
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      domain
      projectIds
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      domain
      projectIds
      createdAt
      updatedAt
    }
  }
`;
export const createInitiative = /* GraphQL */ `
  mutation CreateInitiative(
    $input: CreateInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    createInitiative(input: $input, condition: $condition) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      updates_en
      updates_ru
      updates_hy
      collaboratorsTotal
      createdAt
      updatedAt
    }
  }
`;
export const updateInitiative = /* GraphQL */ `
  mutation UpdateInitiative(
    $input: UpdateInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    updateInitiative(input: $input, condition: $condition) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      updates_en
      updates_ru
      updates_hy
      collaboratorsTotal
      createdAt
      updatedAt
    }
  }
`;
export const deleteInitiative = /* GraphQL */ `
  mutation DeleteInitiative(
    $input: DeleteInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    deleteInitiative(input: $input, condition: $condition) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
      }
      updates_en
      updates_ru
      updates_hy
      collaboratorsTotal
      createdAt
      updatedAt
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      currency
      createdAt
      updatedAt
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      currency
      createdAt
      updatedAt
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      currency
      createdAt
      updatedAt
    }
  }
`;
export const createPrismicContent = /* GraphQL */ `
  mutation CreatePrismicContent(
    $input: CreatePrismicContentInput!
    $condition: ModelPrismicContentConditionInput
  ) {
    createPrismicContent(input: $input, condition: $condition) {
      id
      pk
      sk
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
      }
      interestedIn {
        interested_in
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
      }
      email
      phone
      imageUrl
      linkedinLink
      link
      publishedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePrismicContent = /* GraphQL */ `
  mutation UpdatePrismicContent(
    $input: UpdatePrismicContentInput!
    $condition: ModelPrismicContentConditionInput
  ) {
    updatePrismicContent(input: $input, condition: $condition) {
      id
      pk
      sk
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
      }
      interestedIn {
        interested_in
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
      }
      email
      phone
      imageUrl
      linkedinLink
      link
      publishedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePrismicContent = /* GraphQL */ `
  mutation DeletePrismicContent(
    $input: DeletePrismicContentInput!
    $condition: ModelPrismicContentConditionInput
  ) {
    deletePrismicContent(input: $input, condition: $condition) {
      id
      pk
      sk
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
      }
      interestedIn {
        interested_in
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
      }
      email
      phone
      imageUrl
      linkedinLink
      link
      publishedAt
      createdAt
      updatedAt
    }
  }
`;
