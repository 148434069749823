import { useEffect } from 'react'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  SimpleGrid,
  Flex,
  Spinner,
  Stack,
  Box,
} from '@chakra-ui/react'
import { Link } from 'pages'
import { useProjects, useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import FilteredData from '../FilteredData'

const Projects = () => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { projects, isLoading } = useProjects({
    query: {
      bool: {
        must: [
          {
            term: {
              owner: user.id,
            },
          },
          {
            term: {
              isProject: true,
            },
          }
        ],
      },
    },
  })

  if (isLoading)
    return (
      <Flex alignItems="center" justifyContent="center" height="300px">
        <Spinner color="blue.400" />
      </Flex>
    )

  return (
    <>
      <Tabs isFitted>
        <Box overflowX="scroll">
          <TabList borderColor="border.100">
            <Tab
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
            >
              {t('profile@all')}
            </Tab>
            <Tab
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
            >
              {t('profile@active')}
            </Tab>
            <Tab
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
            >
              {t('profile@completed')}
            </Tab>
            <Tab
                 borderBottom="2px solid"
                 borderColor="border.100"
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
            >
              {t('profile@submitted')}
            </Tab>
            <Tab
              borderBottom="2px solid"
              borderColor="border.100"
              textTransform="capitalize"
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="5"
              whiteSpace="nowrap"
            >
              {t('profile@onHold')}
            </Tab>
            {projects?.items?.length > 0 && (
              <Link to="/pre-create-project">
                <Button
                  display={{ base: 'none', md: 'inline-flex' }}
                  color="blue.400"
                  variant="transparent"
                  right="0"
                  top="0"
                  bottom="0"
                  my="auto"
                  position="absolute"
                  textTransform="capitalize"
                >
                  {t('profile@createProject')}
                </Button>
              </Link>
            )}
          </TabList>
        </Box>

        <TabPanels px="1">
          <TabPanel  position="relative" pt="8">
            <Stack
              w="full"
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={['all']}
                data={projects?.items || []}
              />
            </Stack>
          </TabPanel>
          <TabPanel  position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={[
                  'live',
                  'launching_soon',
                  'waiting_for_transfer_confirmation',
                ]}
                data={projects?.items || []}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel  position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={1}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={['ended', 'closed', 'terminated']}
                data={projects?.items || []}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel  position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={[
                  'submitted',
                  'reviewed_by_ss',
                  'reviewed_by_am',
                  'reviewed_by_tns',
                  'reviewed_by_bot',
                  'project_page_development',
                  'review_project_page',
                  'published_by_owner',
                ]}
                data={projects?.items || []}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel position="relative" pt="8">
            <SimpleGrid
              w="full"
              columns={{
                base: 1,
                md: 2,
                xl: 3,
              }}
              spacing="8"
            >
              <FilteredData
                type="fundraisers"
                filterStatus={[
                  'on_hold_by_am',
                  'on_hold_by_tns',
                  'rejected_by_ss',
                  'rejected_by_am',
                  'rejected_by_tns',
                  'rejected_by_bot',
                  'project_page_development',
                  'review_project_page',
                ]}
                data={projects?.items || []}
              />
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default Projects
