import { Flex, Img, Box, Heading } from '@chakra-ui/react'
import UserInfo from 'components/UserInfo'
import { useTranslation } from 'contexts/TranslationContext'
import ImageWrapper from 'components/ImageWrapper'

const Header = ({ project, isContribution }) => {
  const { t, language } = useTranslation()
  return (
    <Box mb="4">
      <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
        <Box align="center" position="relative">
          {(project.cover || project.coverKey) && (
            <ImageWrapper
              src={`/thumbnail/small/${project.coverKey || project.cover}`}
              fallbackSrc={'/assets/images/noImg.svg'}
              alt="donation"
              objectFit="cover"
              borderRadius="12px"
              minW="146px"
              height="84px"
            />
          )}
        </Box>
        <Box
          maxW="500px"
          mt={{ base: '4', sm: '0' }}
          ml={{ base: '0', sm: '3' }}
        >
          <Heading mb="5" fontSize="sm" as="h2">
            <Box fontWeight="normal" as="span" fontSize="sm">
              {t('donation@heading')}
            </Box>
            &nbsp;
            {project.title_en
              ? project[`title_${language}`]
              : project.applicationTitle}
          </Heading>
          {project.owner && !isContribution && (
            <UserInfo
              name={project[`creatorName_${language}`]}
              image={project.creatorImage}
              // info="3 projects ・3 initiatives created  |  Yerevan, Armenia"
            />
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default Header
