import React from 'react'
import { Box, Image } from '@chakra-ui/react'
import Header from './Header'
import Information from './Information'
import Tabs from './Tabs'
import SimiliarCollaborations from './SimiliarCollaborations'
import ActNowButton from './ActNowButton'
import ImageWrapper from 'components/ImageWrapper'

const MobileCollaboration = ({ initiative }) => {
  return (
    <Box>
      <Header initiative={initiative}/>
      <ImageWrapper src={`/thumbnail/small/${initiative.cover}`}/>
      <Box px="5" pt="3">
        <Information initiative={initiative} />
      </Box>
      <Tabs initiative={initiative} />
      <SimiliarCollaborations initiative={initiative} />
      <ActNowButton initiative={initiative} />
    </Box>
  )
}

export default MobileCollaboration
