import { useQuery } from 'react-query'
import { updateUseCase } from '../../core/factories'

const useGetUpdatesByProjectId = (variables) => {
  const { error, refetch, isLoading, data } = useQuery(
    ['updates', variables],
    () => updateUseCase.getUpdatesByProjectId(variables))

  return {
    error,
    refetch,
    isLoading,
    data,
  }
}

export default useGetUpdatesByProjectId
