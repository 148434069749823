import { API } from 'aws-amplify'

const donationService = () => {
  const payment = ({ path, body }) =>
    API.post('paymentAPI', path, {
      body,
    })
  const checkOrderStatus = (orderId) =>
    API.get('paymentAPI', `/orderStatus?id=${orderId}`)
  return {
    payment,
    checkOrderStatus,
  }
}

export default donationService
