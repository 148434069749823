import React from 'react'
import Mobile from './Mobile'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'

const w = window.innerWidth

const CurrencySwitcher = ({
  defaultWidth = 'max-content',
  amount,
  isDonation,
  footer,
  showDetails = true,
  buttonStyles = {},
  onChange = () => {},
}) => {
  const { currency, changeDefaultCurrency } = useCurrencySettings()
  const { t } = useTranslation()

  const props = {
    defaultWidth,
    showDetails,
    buttonStyles,
    isDonation,
    footer,
    currency,
    amount,
    onChange,
    changeDefaultCurrency,
    currencyText: (key) => t(`currency@${key}`),
  }

  return <Mobile {...props} />
}

export default CurrencySwitcher
