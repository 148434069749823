import React from 'react'
import { Box, HStack, Flex, Heading } from '@chakra-ui/react'
import Header from '../Header'
import Trusters from './Trusters'
import CLevel from './CLevel'
import Advisory from './Advisory'
import Procedures from '../Procedures'
import CoreTeam from './CoreTeam'
import Initiators from './Initiators'
import Partners from './Partners'
import Supporters from './Supporters'
import Contact from './Contact'
import Strategic from './Strategic'
import { useTranslation } from 'contexts/TranslationContext'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Back } from 'assets/icons/back.svg'
import NewAdvisory from '../NewAdvisory'

const MobileAbout = () => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Box>
      <HStack
        py="12px"
        px="3"
        borderBottom="1px solid"
        borderColor="border.100"
        alignItems="center"
        justifyContent="space-between"
        overflowX="hidden"
      >
        <Flex
          onClick={() => {
            history.goBack()
          }}
          alignItems="center"
          justifyContent="center"
          width="30px"
        >
          <Back width="10px" height="20px" />
        </Flex>

        <Heading as="h1" fontSize="3xl">
          {t('About us')}
        </Heading>
        <Box align="center" width="30px"></Box>
      </HStack>
      <Header />
      {/* <Trusters /> */}
      {/* <CLevel /> */}
      {/* <Strategic /> */}
      {/* <Advisory /> */}
      {/* <NewAdvisory /> */}
      <Procedures />
      {/* <CoreTeam /> */}
      <Initiators />
      {/* <Partners /> */}
      {/* <Supporters /> */}
      <Contact />
    </Box>
  )
}

export default MobileAbout
