import React from 'react'
import { Button, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Link } from 'pages'
import { InputField } from 'components/InputField'
import { Select } from 'components/Select'
import { useUser, useUpdateUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { COUNTRY_LIST } from '_constants'
import useCustomToast from 'hooks/useCustomToast'

export default function Personal() {
  const { user, getUser } = useUser()
  const { t } = useTranslation()
  const toast = useCustomToast()

  const { updateUser, isLoading } = useUpdateUser()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      ...user,
    },
  })

  const submit = (data) => {
    const { firstName, lastName, phone, id, country } = data
    const input = {
      id,
      firstName,
      lastName,
      phone,
      country,
    }
    updateUser(input, {
      onSuccess: () => {
        getUser()
        toast({
          status: 'success',
          title: t('profile@updatedSuccessfully'),
          isClosable: true,
          duration: 5000,
        })
      },
      onError: (e) => console.log(e),
    })
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack pb="12" mt="6" spacing="6" maxW="600px">
        <InputField
          size="md"
          
          isInvalid={!!errors.firstName}
          errorMessage={t('profile@settings-firstNameErrorMessage')}
          {...register('firstName', { required: true })}
          label={t('profile@settings-firstName')}
        />
        <InputField
          size="md"
          
          isInvalid={!!errors.lastName}
          errorMessage={t('profile@settings-lastNameErrorMessage')}
          {...register('lastName', { required: true })}
          label={t('profile@settings-lastName')}
        />
        <InputField
          size="md"
          
          isDisabled
          {...register('email', { required: true })}
          errorMessage={t('profile@settings-emailErrorMessage')}
          label={t('profile@settings-email')}
        />
        <InputField
          size="md"
          
          {...register('phone')}
          label={t('profile@settings-phoneNumber')}
        />
        <Select
          type="country"
          placeholder={t('profile@settings-country')}
          display={{ base: 'block', sm: 'block' }}
          {...register('country')}
          options={COUNTRY_LIST}
          label={t('profile@settings-country')}
        />
        <Link to="/change-password">
          <Button color="blue.400" variant="transparent">
            {t('profile@settings-changePassword')}
          </Button>
        </Link>
        <Button isLoading={isLoading} type="submit" variant="blue">
          {t('profile@settings-submit')}
        </Button>
      </Stack>
    </form>
  )
}
