import React from 'react'
import {
  Container,
  Heading,
  Text,
  Button,
  SimpleGrid,
  GridItem,
  Flex,
  Image,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const IMAGES = [
  '1_UNION.png',
  '2_AZATAZEN.png',
  '3_EFF_GOV.png',
  '4_Arshakuni.png',
  '5_Bardzunk.png',
  '6_Zhogh.png',
  '7_HENK.png',
  '8_VOMA.png',
]

const Initiators = () => {
  const { t } = useTranslation()
  return (
    <>
      <Box px="1.875rem">
        <Heading mb="20" fontSize="4xl" as="h2">
          {t('about@initiators')}
        </Heading>
        <SimpleGrid
          pb="9.25rem"
          position="relative"
          w="full"
          columns={{
            base: 2,
            md: 3,
          }}
          spacing="24"
        >
          {IMAGES.map((image) => (
            <GridItem key={image}>
              <Image src={`/assets/images/${image}`} />
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
    </>
  )
}

export default Initiators
