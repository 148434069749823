import React from 'react'
import { Auth } from 'aws-amplify'
import {
  Box,
  Button,
  Flex,
  Checkbox,
  IconButton,
  HStack,
  Heading,
  VStack,
  Text,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { COUNTRY_LIST } from '_constants'
import { Select } from 'components/Select'
import { PasswordField } from 'components/PasswordField'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { useQueryParams } from 'hooks'

const Mobile = ({
  register,
  errors,
  handleSubmit,
  onSubmit,
  validateEmail,
  country,
  setCountry,
  phone,
  setPhone,
  phoneError,
  loading,
  password,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useQueryParams()
  return (
    <Box pb="5rem">
      <Flex
        w="full"
        justifyContent="center"
        position="relative"
        alignItems="center"
        mb="10"
        borderBottom="1px solid"
        borderColor="border.100"
        py="3"
      >
        <Flex
          position="absolute"
          left="12px"
          onClick={() => history.goBack()}
          width="30px"
          height="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {t('login@signUp')}
        </Heading>
      </Flex>
      <Box
        as="span"
        display="block"
        textAlign="center"
        fontSize="xl"
        fontWeight="600"
        letterSpacing="0.06px"
        mt="6"
        mb="4"
      >
        {t('signUp@heading')}
      </Box>
      <HStack justifyContent="center" mb="10" spacing="2">
        <IconButton
          onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
          p="0"
          icon={<Facebook width="59px" height="59px" />}
        />
        <IconButton
          onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
          p="0"
          icon={<Google width="59px" height="59px" />}
        />
      </HStack>
      <Box px="5">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <VStack pb="1.875rem" spacing="6">
            <InputField
              border="1px solid #CDCDCD"
              borderRadius="12px"
              size="lg"
              errorMessage={t('signUp@firstNameErrorMessage')}
              label={t('signUp@firstName')}
              isInvalid={!!errors.firstName}
              {...register('firstName', { required: true })}
            />

            <InputField
              border="1px solid #CDCDCD"
              borderRadius="12px"
              size="lg"
              errorMessage={t('signUp@lastNameErrorMessage')}
              label={t('signUp@lastName')}
              isInvalid={!!errors.lastName}
              {...register('lastName', { required: true })}
            />

            <InputField
              border="1px solid #CDCDCD"
              borderRadius="12px"
              size="lg"
              isInvalid={!!errors.email}
              errorMessage={t('signUp@emailErrorMessage')}
              label={t('signUp@email')}
              type="email"
              {...register('email', {
                required: true,
                validate: (value) => validateEmail(value),
              })}
            />


            <PasswordField
              border="1px solid #CDCDCD"
              borderRadius="12px"
              size="lg"
              isInvalid={!!errors.password}
              {...register('password', { required: true })}
              errorMessage={t('signUp@passwordErrorMessage')}
              label={t('signUp@password')}
            />

          </VStack>
          <Flex direction="column" display="flex-start">
            <Checkbox
              {...register('terms_and_conditions', { required: true })}
              isInvalid={!!errors.terms_and_conditions}
              mb="2"
            >
              {t('signUp@agreeWith')}&nbsp;
              <Link
                style={{ color: '#ED8B00' }}
                target="_blank"
                to="/terms-and-conditions"
              >
                {t('signUp@termsConditions')}
              </Link>
            </Checkbox>
            <Checkbox
              mb="1"
              {...register('privacy_policy')}
              isInvalid={!!errors.terms_and_conditions}
            >
              {t('signUp@haveRead')}&nbsp;
              <Link
                style={{ color: '#ED8B00' }}
                target="_blank"
                to="/privacy-policy"
              >
                {t('signUp@privacyPolicy')}
              </Link>
            </Checkbox>
          </Flex>
          <Box mt="9" mb="6" w="full">
            <Button
              w="full"
              isLoading={loading}
              type="submit"
              variant="blue"
              fontSize="md"
              fontWeight="bold"
            >
              {t('login@signUp')}
            </Button>
          </Box>
          <Text
            textAlign="center"
            color="gray.700"
            fontSize="sm"
            fontWeight="semibold"
          >
            {t('signUp@haveAnAccount')}{' '}
            <Button fontSize="sm" color="orange.400">
              <Link to={`/sign-in?from=${params.get('from')}`}>{t('signUp@signIn')}</Link>
            </Button>
          </Text>
        </form>
      </Box>
    </Box>
  )
}

export default Mobile
