import { useQuery } from 'react-query'
import { transactionUseCase } from '../../core/factories'

const useListTransactionsByDate = (variables) => {
  const data = useQuery(['transactions-date', variables],() => transactionUseCase.listTransactionsByDate(variables))

  return data;
}

export default useListTransactionsByDate
