import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Button,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react'
import { API } from 'aws-amplify'
import { InputField } from '../InputField'
import { useTranslation } from 'contexts/TranslationContext'
import { useValidateEmail } from 'hooks'
import { useUser } from '../../hooks'

const InviteModal = ({
  email,
  setEmail,
}) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user } = useUser()
  const validateEmail = useValidateEmail()
  const { onOpen, isOpen, onClose } = useDisclosure()

  const inviteFriend = async () => {
    if (!validateEmail(email)) {
      return setError(true)
    }
    setError(false)

    const body = {
      receiverEmail: email.toLowerCase(),
      senderEmail: user.firstName,
    }
    try {
      setLoading(true)
      await API.post('email', '/inviteEmail', {
        body,
      })
      setLoading(false)
      onClose()
      setEmail('')
    } catch (e) {
      setLoading(false)
      console.log('invite error', e)
    }
  }

  return (
    <>
      <Button
        variant="blue"
        w="250px"
        onClick={onOpen}>
        {t('homepage@inviteFriend')}
      </Button>
      <Modal size="lg" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('homepage@inviteFriend')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="sm" color="gray.700" mb="4">
              {t('homepage@inviteText1')}
            </Text>
            <Text mb="8" fontSize="sm" color="gray.700">
              {t('homepage@inviteText2')}
            </Text>
            <InputField
              isInvalid={error}
              errorMessage={t('profile@settings-emailErrorMessage')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label={t('profile@settings-email')}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={loading}
              w="full"
              mt="5rem"
              type="button"
              onClick={inviteFriend}
              variant="blue"
            >
              {t('homepage@invite')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InviteModal
