import React from 'react'
import { HStack, Heading } from '@chakra-ui/react'
import { ReactComponent as Search } from 'assets/icons/blackSearch.svg'
import { useTranslation } from 'contexts/TranslationContext'

const Header = () => {
  const { t } = useTranslation()
  return (
    <HStack
      mt="5"
      pl="5"
      pr="4"
      alignItems="center"
      justifyContent="center"
    >
      <Heading
        as="h1"
        fontSize="3xl"
        >{t('Collaborations')}
      </Heading>
      {/* <Search width="22px" height="22px" /> */}
    </HStack>
  )
}

export default Header
