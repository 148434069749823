import React from 'react'
import { Heading, Flex, HStack } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Fb } from 'assets/icons/facebookFollow.svg'
import { ReactComponent as Twitter } from 'assets/icons/authTwitter.svg'
import { SocialMediaLinks } from 'components/Footer/SocialMediaLinks'

const SocialMedias = () => {
  const { t } = useTranslation()
  return (
    <Flex
      pt="10"
      pb="7.75rem"
      bg="gray.400"
      direction="column"
      alignItems="center"
    >
      <Heading as="h2" fontSize="3xl" mb="1.875rem">
        {t('Follow Us')}
      </Heading>
      <SocialMediaLinks />
      <HStack spacing="5"></HStack>
    </Flex>
  )
}

export default SocialMedias
