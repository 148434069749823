import { Box } from '@chakra-ui/react'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Back } from 'assets/icons/arrowLeftNew.svg'

export default function BackButton({ to }) {
  const { t } = useTranslation()
  return (
    <Link to={to}>
      <Box
        mb="12"
        display="flex"
        alignItems="center"
        cursor="pointer"
        minH="20px"
        minW="20px"
        fontSize="md"
        fontWeight="500"
      >
        <Back width="10px" height="17px" />
        <Box ml="3" as="span">
          {t('donation@back')}
        </Box>
      </Box>
    </Link>
  )
}
