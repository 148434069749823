import { HStack, useRadioGroup, Box, Flex } from '@chakra-ui/react'
import {
  useCurrencySettings,
  thousandSeparator,
  CurrencyConverter,
} from 'contexts/CurrencySettingsContext'
import * as React from 'react'
import { RadioOption } from './RadioOption'

export const RadioGroup = ({
  perk,
  name,
  options,
  onChange,
  defaultValue,
  price,
  ...rest
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    defaultValue,
  })
  const { currency } = useCurrencySettings()

  return (
    <HStack
      height="61px"
      py="6px"
      borderRadius="12px"
      spacing="2"
      overflow="hidden"
      bg="#EAECEF"
      px="2"
      {...getRootProps(rest)}
    >
      {Object.entries(options).map(([key, value]) => (
        <RadioOption key={key} {...getRadioProps({ value: key })}>
          <Flex alignItems="center" direction="column" borderRadius="12px">
            <Box fontWeight="600" as="span" fontSize="lg">
              {key === 'other' ? value : `${value}%`}
            </Box>
            <Box as="span" fontSize="sm">
              {key !== 'other' &&
                thousandSeparator((price * value) / 100, currency.current) +
                  ` ${currency.current}`}
            </Box>
          </Flex>
        </RadioOption>
      ))}
    </HStack>
  )
}
