import {
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  Box,
  useColorModeValue as mode,
  InputGroup,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { ReactComponent as View } from 'assets/icons/view.svg'
import { ReactComponent as ViewClose } from 'assets/icons/viewClose.svg'

export const PasswordField = React.forwardRef((props, ref) => {
  const {
    label,
    hidden,
    isInvalid,
    errorMessage,
    maxLength,
    char,
    size = 'md',
    ...rest
  } = props
  const [type, setType] = useState(false)
  return (
    <FormControl position="relative" display={hidden ? 'none' : 'block'}>
      {!!maxLength && (
        <Box
          position="absolute"
          right="2"
          fontWeight="semibold"
          fontSize="sm"
          color="gray.700"
          top="calc(100% + 5px)"
        >
          {char} / {maxLength}
        </Box>
      )}
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          left="2"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage}
        </Box>
      )}
      <FormLabel
        whiteSpace="nowrap"
        fontSize={size === 'md' ? 'sm' : 'md'}
        fontWeight="regular"
        color="black"
        bg={{ base: mode("gray.100", 'gray.800'), md: mode("gray.100", 'gray.700') }}
        zIndex={2}
      >
        {label}
      </FormLabel>
      <InputGroup
        size="lg"
        _focus={{ borderColor: 'blue.300' }}
        _hover={{ borderColor: 'blue.300' }}
      >
        <Input
          type={type ? 'text' : 'password'}
          sx={{ boxShadow: 'none !important' }}
          maxLength={maxLength}
          isInvalid={isInvalid}
          hidden={hidden}
          borderColor="border.100"
          borderWidth="1px"
          ref={ref}
          size={size}
          fontSize="lg"
          {...rest}
        />
        <InputRightElement
          onClick={() => setType(!type)}
          children={type ? <ViewClose height="25px" /> : <View height="25px" />}
        />
      </InputGroup>
    </FormControl>
  )
})

PasswordField.displayName = 'PasswordField'
