import React from 'react'
import {
  Flex,
  Heading,
  Text,
  Button,
  HStack,
  Box,
  Select,
} from '@chakra-ui/react'
import { useTranslation, LANGUAGES } from 'contexts/TranslationContext'
import { useUser } from 'hooks'
import { Link } from 'pages'
import Navbar from 'components/Navbar'

import { ReactComponent as Logo } from '../../assets/icons/logoblack.svg'
import { ReactComponent as Arrow } from '../../assets/icons/arrowRight.svg'
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg'
import CurrencySwitcher from 'components/CurrencySwitcher'

const Header = () => {
  const { t, language, setLanguage } = useTranslation()
  const { user } = useUser()
  return (
    <>
      <Box pb="3.125rem" bg="#FAFAFA">
        {user.id === 'guest' ? (
          <>
            <Flex justifyContent="center" w="full" pt="3" pb="7">
              <Logo width={166} />
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              px="5"
            >
              <Heading
                as="h1"
                mb="5"
                fontWeight="600"
                fontSize="3xl"
                textAlign="center"
              >
                {t('Join the community')}
              </Heading>
              <Text mb="10" fontSize="md" textAlign="center">
                {t('Thanks to the platform')}
              </Text>
              <HStack spacing="3">
                <Link to="/sign-up">
                  <Button w="150px" variant="blue" fontSize="md">
                    {t('Join us now')}
                  </Button>
                </Link>
                <Link to="/sign-in">
                  <Button w="150px" variant="border" fontSize="md">
                    {t('more@signIn')}
                  </Button>
                </Link>
              </HStack>
            </Flex>
          </>
        ) : (
          <Box pl="5" pt="1.875rem">
            <Avatar width="80px" height="80px" />
            <Heading fontSize="3xl" as="h1" mt="5">
              {user.firstName}
              <br />
              {user.lastName}
            </Heading>
          </Box>
        )}
      </Box>
      <Flex pb="6rem" w="full" px="5" direction="column">
        {user.id !== 'guest' && (
          <>
            <Link to="/profile">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid"
                borderColor="border.100"
              >
                <Text py="5" fontSize="xl">
                  {t('My projects')}
                </Text>
                <Box width="30px" align="center">
                  <Arrow width="7px" height="12px" />
                </Box>
              </Flex>
            </Link>
            <Link to="/my-settings/personal">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid"
                borderColor="border.100"
              >
                <Text py="5" fontSize="xl">
                  {t('profile@tab3')}
                </Text>
                <Box width="30px" align="center">
                  <Arrow width="7px" height="12px" />
                </Box>
              </Flex>
            </Link>
          </>
        )}
        <Link to="/about">
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="border.100"
          >
            <Text py="5" fontSize="xl">
              {t('About us')}
            </Text>
            <Box width="30px" align="center">
              <Arrow width="7px" height="12px" />
            </Box>
          </Flex>
        </Link>
        <Link to="/contact-us">
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="border.100"
          >
            <Text py="5" fontSize="xl">
              {t('nav@contact')}
            </Text>
            <Box width="30px" align="center">
              <Arrow width="7px" height="12px" />
            </Box>
          </Flex>
        </Link>

        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="border.100"
        >
          <Select
            variant="flushed"
            p="0"
            w="full"
            border="none"
            display="block"
            sx={{ boxShadow: 'none !important' }}
            size="lg"
            height="68px"
            fontSize="xl"
            value={language}
            onChange={(e) => setLanguage(language, e.target.value)}
            icon={
              <Box width="30px" height="30px" align="right" pr="1px">
                <Arrow width="7px" height="12px" />
              </Box>
            }
          >
            {Object.entries(LANGUAGES).map(([key]) => (
              <option key={`language-key-${key}`} value={key}>
                {t(`language@${key}Label`)}
              </option>
            ))}
          </Select>
        </Flex>
        <CurrencySwitcher
          footer
          defaultWidth="100%"
          showDetails={false}
          buttonStyles={{
            ml: 0,
            mt: 2,
            fontWeight: '400',
            border: 'none',
            fontSize: 'lg',
            borderRadius: 0,
          }}
        />
      </Flex>
      <Navbar from="more" />
    </>
  )
}

export default Header
