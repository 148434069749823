import { API, graphqlOperation } from 'aws-amplify'
import {
  getPrismicContent as getPrismicContentQuery,
  getPrismicContent,
  contentByPk,
} from 'graphql/queries'

const AppSyncService = () => {
  const getExperts = async (variables) => {
    const appSyncResponse = await API.graphql(
      graphqlOperation(contentByPk, {
        pk: 'expert',
        limit: variables.limit,
        nextToken: variables.nextToken,
      })
    )
    return appSyncResponse.data.contentByPk
  }
  const getNeeds = async (input) => {
    const appSyncResponse = await API.graphql(
      graphqlOperation(contentByPk, { pk: 'need' })
    )
    return appSyncResponse.data.contentByPk.items
  }

  const getItemById = async (id) => {
    const appSyncResponse = await API.graphql(
      graphqlOperation(getPrismicContent, { id })
    )
    return appSyncResponse.data.getPrismicContent
  }

  const getGiftCard = async (input) => {
    const appSyncResponse = await API.graphql(
      graphqlOperation(contentByPk, { pk: 'gift-card' })
    )
    return appSyncResponse.data.contentByPk.items
  }

  return { getExperts, getNeeds, getItemById, getGiftCard }
}

export default AppSyncService
