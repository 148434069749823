import React from 'react'
import { Box, Text, Img, Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { useNavigation } from 'pages'

const MobileThankYouSubscribe = ({description}) => {
  const { t } = useTranslation()
  const { navigationPush } = useNavigation()

  return (
    <Box w="full">
      <Box
        onClick={() => navigationPush('/')}
        py="0.375rem"
        pl="3"
        borderBottom="1px solid"
        borderColor="border.100"
        w="full"
      >
        <Flex
          onClick={() => navigationPush('/')}
          width="30px"
          height="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
      </Box>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        pt="10"
        px="5"
      >
        <Flex alignItems="center" justifyContent="center">
          <Img src="/assets/images/welcome.png" width="200px" minH="146px" />
        </Flex>
        <Box as="span" fontSize="3xl" fontWeight="500">
          {t('Thank you for collaborating')}
        </Box>

        <Text
          mt="10"
          fontSize="md"
          w="full"
          textAlign="center"
          fontWeight="500"
          color="gray.700"
        >
          {description}
        </Text>
      </Flex>
    </Box>
  )
}

export default MobileThankYouSubscribe
