const initiativeUseCase = (initiativeRepo, imageRepo, hashRepo) => {
  const deleteInitiative = async (initiative) =>
    initiativeRepo.deleteInitiative(initiative)

  const createInitiative = async (project) =>
    initiativeRepo.createInitiative(project)

  const updateStatus = (params) => initiativeRepo.updateStatus(params)

  const updateInitiative = async (initiative) =>
    initiativeRepo.updateInitiative(initiative)

  const retrieveInitiative = async (id) => {
    let initiative = await initiativeRepo.retrieveInitiative(id)

    // if (initiative?.image) {
    //     initiative = {...initiative, image_key: initiative.image, image: await imageRepo.getImageUrl(initiative.image) }
    // }
    if (initiative?.initiatorImage) {
      initiative = {
        ...initiative,
        initiator_image_key: initiative.initiatorImage,
        initiatorImage: await imageRepo.getImageUrl(initiative.initiatorImage),
      }
    }
    if (initiative?.cover) {
      initiative = {
        ...initiative,
        cover: initiative.cover,
      }
    }

    return initiative
  }

  const getInitiatives = async (filter) => {
    const initiatives = await initiativeRepo.getInitiatives(filter)
    return await Promise.all(
      initiatives.map(async (e) => {
        return {
          __typename: 'initiative',
          id: e.id,
          // image: (await imageRepo.getImageUrl(e.image ?? "")) ?? "",
          cover: e.cover,
          category: e.category ?? '',
          project: 'initiative',
          applicationTitle: e.applicationTitle ?? '',
          title_en: e.title_en ?? '',
          title_hy: e.title_hy ?? '',
          title_ru: e.title_ru ?? '',
          tagline_en: e.tagline_en ?? '',
          tagline_hy: e.tagline_hy ?? '',
          tagline_ru: e.tagline_ru ?? '',
          summary: e.summary ?? '',
          region: e.region ?? '',
          status: e.status ?? '',
          collaborators: e.collaborators ?? [],
          isPopular: e.isPopular || false,
          popular: e.popular === 'true' || e.isPopular || false,
          isUrgent: e.isUrgent || false,
          money: 0,
          progress: 0,
          information: '',
          collaboratorsTotal: e.collaboratorsTotal,
          role: 'Admin',
          icon: 'time',
          createdAt: e.createdAt,
        }
      })
    )
  }

  const searchInitiatives = async (condition) => {
    const initiatives = await initiativeRepo.searchInitiatives(condition)
    return {
      nextToken: initiatives.nextToken,
      items: await Promise.all(
        initiatives.items.map(async (e) => {
          return {
            __typename: 'initiative',
            id: e.id,
            // image: (await imageRepo.getImageUrl(e.image ?? "")) ?? "",
            cover: e.cover,
            category: e.category ?? '',
            project: 'initiative',
            collaboratorsTotal: e.collaboratorsTotal,
            applicationTitle: e.applicationTitle ?? '',
            title_en: e.title_en ?? '',
            title_hy: e.title_hy ?? '',
            title_ru: e.title_ru ?? '',
            tagline_en: e.tagline_en ?? '',
            tagline_hy: e.tagline_hy ?? '',
            tagline_ru: e.tagline_ru ?? '',
            summary: e.summary ?? '',
            region: e.region ?? '',
            status: e.status ?? '',
            collaborators: e.collaborators ?? [],
            isPopular: e.isPopular || false,
            popular: e.popular === 'true' || e.isPopular || false,
            isUrgent: e.isUrgent || false,
            money: 0,
            progress: 0,
            information: '',
            role: 'Admin',
            icon: 'time',
            createdAt: e.createdAt,
          }
        })
      )
    }
  }

  const collaboratorRequest = async (params) => {
    const token = await hashRepo.hash({
      text: {
        userEmail: params.contactEmail,
        userId: params.userId,
        initiativeId: params.initiativeId,
        isCollab: params.isCollab,
        fullname: params.fullname,
        addresses: params.addresses,
        description: params.description,
      },
      secret: `${params.contactEmail}_${params.initiativeId}`,
      expiresIn: '10h',
    })
    const link = `${window.location.protocol}//${window.location.host}/${params.language}/collaboration-request?email=${params.contactEmail}&initiativeId=${params.initiativeId}&token=${token}`
    await initiativeRepo.collaboratorRequest({ ...params, link })
  }

  const addCollaborator = async ({ token, email, initiativeId }) => {
    const obj = await hashRepo.verify({
      token,
      secret: `${email}_${initiativeId}`,
    })

    const result = {
      initiativeId: obj.initiativeId,
      userId: obj.userId,
      email: obj.email,
      isCollab: obj.isCollab,
    }
    if (result.isCollab) {
      await initiativeRepo.addCollaborator({
        id: result.initiativeId,
        collaborator: { id: result.userId, email: result.email },
      })
    } else {
      await initiativeRepo.setImplementor({
        id: result.initiativeId,
        collaborator: { id: result.userId, email: result.email },
      })
    }
  }

  return {
    createInitiative,
    updateStatus,
    updateInitiative,
    retrieveInitiative,
    getInitiatives,
    collaboratorRequest,
    addCollaborator,
    deleteInitiative,
    searchInitiatives,
  }
}

export default initiativeUseCase
