import React from 'react'
import { css } from '@emotion/react'
import {
  FormControl,
  FormLabel,
  Select as ChakraSelect,
  Box,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const styles = {
  select: css`
    padding: 0;
  `,
}

export const Select = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const {
    label,
    options,
    isInvalid,
    errorMessage,
    display,
    type,
    size = 'md',
    noPadding = false,
    maxW,
    ...rest
  } = props

  return (
    <FormControl maxW={maxW} display={display} position="relative">
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage}
        </Box>
      )}
      <FormLabel
        mb={label ? '2' : '0'}
        whiteSpace="nowrap"
        fontSize={size === 'md' ? 'sm' : 'md'}
        _focus={{ color: 'blue.300' }}
        fontWeight="regular"
        color="black"
        bg={{ base: mode("gray.100", 'gray.800'), md: mode("gray.100", 'gray.700') }}
        zIndex={2}
      >
        {label}
      </FormLabel>
      <ChakraSelect
        _focus={{ borderColor: 'blue.300' }}
        _hover={{ borderColor: 'blue.300' }}
        sx={{ boxShadow: 'none !important' }}
        isInvalid={isInvalid}
        borderColor="border.100"
        ref={ref}
        size={size}
        fontSize="lg"
        css={noPadding ? styles.select : ''}
        {...rest}
      >
        {options.map((option) => (
          <option key={`${type}-${option}`} value={option}>
            {type === 'country' ? option : t(`${type}@${option}`)}
          </option>
        ))}
      </ChakraSelect>
    </FormControl>
  )
})

Select.displayName = 'Select'
