import { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  Flex,
  Button,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useCurrencySettings, CurrencyConverter } from 'contexts/CurrencySettingsContext'
import { Link } from 'pages'

export default function Collaborators({ initiative }) {
  return (
    <Box pb="25">
      {initiative?.collaborators?.map((collaborator) => (
        <Flex
          key={collaborator.id}
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="border.100"
          py="5"
        >
          <Box fontSize="lg" as="span">
            {collaborator.email} {collaborator.lastName}
          </Box>
          <Box fontSize="lg" as="span"></Box>
        </Flex>
      ))}
    </Box>
  )
}
