import { useState } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import DatePicker from 'components/SingleDate'
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg'

export default function DatePickerGroup({
  watch,
  clearErrors,
  register,
  setValue,
  t,
  errors,
}) {
  const [showDateErrors, setShowDateErrors] = useState('')
  const changeDate = (name, value) => {
    setShowDateErrors(name)
    setValue(name, value)
    if (!value) return
    clearErrors(name)
  }
  return (
    <Flex>
      <Box position="relative">
        <Box
          position="absolute"
          zIndex={4}
          py="2"
          px="10px"
          pointerEvents="none"
          color="gray.700"
        >
          <Calendar />
        </Box>
        <DatePicker
          date={watch('startDate')}
          clearErrors={clearErrors}
          register={register}
          changeDate={changeDate}
          setValue={setValue}
          name={'startDate'}
          minDate={new Date()}
          datePlaceholder={t('projectCreate@StartDate')}
          errorMessage={
            watch('startDate') &&
            watch('endDate') &&
            watch('startDate') > watch('endDate')
              ? showDateErrors === 'startDate'
                ? t('createEditProject@endDateErrorMessage')
                : ''
              : t('createEditProject@thisFieldIsRequired')
          }
          isInvalid={
            !!errors.startDate ||
            (watch('startDate') &&
              watch('endDate') &&
              watch('startDate') > watch('endDate'))
          }
        />
      </Box>
      <Box position="relative" ml="28px">
        <Box
          position="absolute"
          zIndex={4}
          py="2"
          px="10px"
          pointerEvents="none"
          color="gray.700"
        >
          <Calendar />
        </Box>
        <DatePicker
          minDate={new Date()}
          datePlaceholder={t('projectCreate@EndDate')}
          clearErrors={clearErrors}
          register={register}
          changeDate={changeDate}
          setValue={setValue}
          errorMessage={
            watch('startDate') &&
            watch('endDate') &&
            watch('startDate') > watch('endDate')
              ? showDateErrors === 'endDate'
                ? t('createEditProject@endDateErrorMessage')
                : ''
              : t('createEditProject@thisFieldIsRequired')
          }
          name={'endDate'}
          date={watch('endDate')}
          isInvalid={
            !!errors.endDate ||
            (watch('startDate') &&
              watch('endDate') &&
              watch('startDate') > watch('endDate'))
          }
        />
      </Box>
    </Flex>
  )
}
