import React from 'react'
import { Box } from '@chakra-ui/react'
import { useSearchInitiatives } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import WrapperMobile from 'components/WrapperMobile'

const SimiliarCollaborations = ({ initiative }) => {
  const { initiatives, refetch, isLoading } = useSearchInitiatives()

  const filteredInitiatives = initiatives?.items
    .filter((item) => item.status === 'live')
    .filter((item) => item.id !== initiative.id)
    .filter((item) => item.category === initiative.category)

  const { t } = useTranslation()
  return (
    <Box pb="6rem">
      <WrapperMobile
        showAll={true}
        type="collaborations"
        swiper={{
          items: filteredInitiatives,
        }}
        isLoading={isLoading}
        fromSwiper={true}
        title={
          filteredInitiatives?.length > 1
            ? t('Similar Initiatives')
            : t('Similar initiative')
        }
      />
    </Box>
  )
}

export default SimiliarCollaborations
