import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import { useCheckOrder, useCustomToast, useQueryParams, useUser } from 'hooks'

const redirectURL = (res) => {
  const transaction = res.transactionData
  if (res.orderStatus === 2) {
    if (transaction.giftCardTransactionType === 'buy') {
      return `/gift-card/thank-you?name=${transaction.firstName}&id=${transaction.projectId}`
    }
    const query = transaction.utm
      ? `${transaction.utm}&donationToProject=${transaction.donationToProject}&donationToReArmenia=${transaction.donationToProject}`
      : '?'
    return `/donate/thank-you${query}name=${
      transaction.firstName
    }&title=${encodeURI(transaction.projectTitle)}&id=${
      transaction.projectId
    }&monthly=${transaction.subscribeForMonthly}`
  }
  return '/'
}

const CheckTransaction = () => {
  const params = useQueryParams()
  const toast = useCustomToast()
  const { navigationReplace } = useNavigation()
  const { t } = useTranslation()
  const { data: order, isLoading } = useCheckOrder(params.get('orderId'))
  const { getUser } = useUser()
  useEffect(async () => {
    if (isLoading) {
      return
    }
    const isCardAttached = order.transactionData?.attachCard
    console.log(isCardAttached);
    if (isCardAttached) {
      toast({
        title: t(`thankyou@cardAttached@toaster`),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } else {
      toast({
        title: t(`payment@orderStatus-${order.orderStatus}`),
        status: order.orderStatus === 2 ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    await getUser()
    navigationReplace(redirectURL(order))
  }, [order])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}

export default CheckTransaction
