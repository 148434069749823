import React from 'react'
import { Flex, Img, Heading, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'

const Contact = () => {
  const { t } = useTranslation()
  return (
    <Flex bg="blue.300">
      <Flex
        justify="center"
        pb={{ base: '8', md: '0' }}
        w="full"
        mx="auto"
        direction={{ base: 'column', lg: 'row' }}
      >
        <Img
          mr="4.75rem"
          maxW={{ base: '100%', lg: '628px', xl: '728px' }}
          src="/assets/images/work.jpg"
          width="100%"
          height="auto"
        />
        <Flex
          flex={1}
          justifyContent="center"
          align={{ base: 'center', lg: 'unset' }}
          flexDirection="column"
        >
          <Heading
            mt={{ base: '8', lg: '0' }}
            mb="10"
            color="gray.100"
            textAlign={{ base: 'center', lg: 'start' }}
            mx={{ base: '4', lg: '0' }}
            fontSize={{ base: '3xl', sm: '4xl' }}
            as="h1"
            dangerouslySetInnerHTML={{ __html: t('about@ifYouInterested') }}
          ></Heading>
          <Flex direction={{ base: 'column', xl: 'row' }}>
            <Link to="/contact-us">
              <Button mb="4" variant="orange" w="250px">
                {t('about@contact')}
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Contact
