import moment from 'moment'

export default function calculateProjectDurationLeft({
  slug,
  t,
  campaignImplementorPeriod_en,
  fundraisingType,
}) {
  const currentDate = moment(new Date())
  let endingDate = ''

  if (fundraisingType === 'recurring') {
    endingDate = moment(moment().endOf('month'))
  } else {
    endingDate = moment(new Date(campaignImplementorPeriod_en))
  }

  const daysLeft = endingDate.diff(currentDate, 'days')
  if (daysLeft <= 0 || !campaignImplementorPeriod_en) return ''
  if (daysLeft < 5 && daysLeft > 1) {
    return t('daysLeft2').replace('...', daysLeft)
  }
  if (daysLeft === 1) {
    return t('dayLeft').replace('...', daysLeft)
  }
  return t('daysLeft').replace('...', daysLeft)
}
