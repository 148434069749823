const userUseCase = (authRepo, userRepo, emailRepo) => {
  const signUp = async (signUpParams) => {
    const response = await authRepo.signUp(signUpParams)
    delete signUpParams.password
    delete signUpParams.createdAt
    delete signUpParams.updatedAt
    await userRepo.createUser(signUpParams)
    
    return response
  }
  const resendSignUp = async (email) => authRepo.resendSignUp(email)
  const confirmSignUp = (confirmParams) => authRepo.confirmSignUp(confirmParams)
  const forgetPassword = (email) => authRepo.forgetPassword(email)
  const forgetPasswordSubmit = (params) => authRepo.forgetPasswordSubmit(params)
  const signIn = async (signInParams) => authRepo.signIn(signInParams)
  const fbSignIn = () => authRepo.socialSignIn({ provider: "Facebook" })
  const googleSignIn = () => authRepo.socialSignIn({ provider: "Google" })

  return {
    signUp,
    resendSignUp,
    confirmSignUp,
    forgetPassword,
    forgetPasswordSubmit,
    signIn,
    fbSignIn,
    googleSignIn
  }
}

export default userUseCase
