import { API, graphqlOperation } from 'aws-amplify'
import {
  donationSubsscriptionsByUserEmail as donationSubscriptionsByUserEmailQuery,
  donationSubscriptionsByProjectId as donationSubscriptionsByUserProjectId,
} from '../../graphql/queries'
import {
  updateDonationSubscription as update,
} from '../../graphql/mutations'

const donationSubscriptionService = () => {
  const updateDonationSubscription = (donationSubscription) =>
    API.graphql(graphqlOperation(update, { input: donationSubscription }))

  const donationSubscriptionsByUserEmail = (condition) =>
    API.graphql(graphqlOperation(donationSubscriptionsByUserEmailQuery, condition))

  const donationSubscriptionsByProjectId = (condition) =>
    API.graphql(graphqlOperation(donationSubscriptionsByUserProjectId, condition))

  return {
    updateDonationSubscription,
    donationSubscriptionsByUserEmail,
    donationSubscriptionsByProjectId,
  }
}

export default donationSubscriptionService
