import { useState, useMemo, useEffect } from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Select,
} from '@chakra-ui/react'
import { useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { STATUS_ENUMS } from '_constants'

const StatusMenu = ({
  fromSwiper = false,
  card,
  style,
  isLoading,
  type = 'fundraisers',
  onValueChange,
  ...rest
}) => {
  const { user } = useUser()
  const { t } = useTranslation()
  const [selected, setSelected] = useState('')

  const status = useMemo(() => {
    if (user.isSuper) return selected
    if (
      !user.isSuper &&
      selected.toLocaleLowerCase() === 'waiting_for_transfer_confirmation'
    )
      return 'ended'
    return selected
  }, [user, selected])

  useEffect(() => {
    setSelected(card.status)
  }, [card])

  if (!user.isSuper) {
    return (
      <Box
        style={style}
        color="gray.100"
        p="6px 14px"
        height="28px"
        borderRadius="5px"
        fontSize="sm"
        fontWeight="bold"
        background={STATUS_ENUMS[type][status]?.color}
      >
        {t(`status@${status}`)}
      </Box>
    )
  }
  return (
    <>
      <Select
        style={style}
        display={{ base: 'block', sm: 'none' }}
        zIndex={1}
        disabled={isLoading || fromSwiper}
        _disabled={{
          opacity: 1,
        }}
        textTransform="capitalize"
        borderRadius="5px"
        fontSize="sm"
        border="none"
        fontWeight="bold"
        minH="20px"
        maxW="150px"
        height="28px"
        color="gray.100"
        value={status}
        onChange={(e) => {
          setSelected(e.target.value)
          onValueChange(e.target.value)
        }}
        background={STATUS_ENUMS[type][status]?.color}
      >
        {Object.entries(STATUS_ENUMS[type]).map(([key]) => {
          if (
            key === 'live' &&
            (!card.tagline_en || !card.tagline_hy || !card.tagline_ru)
          ) {
            return
          }
          return (
            <option
              key={`menu-${key}`}
              style={{ textTransform: 'capitalize' }}
              value={key}
            >
              {t(`status@${key}`)}
            </option>
          )
        })}
      </Select>
      <Menu {...rest}>
        {({ isOpen }) => (
          <>
            <MenuButton
              style={style}
              display={{ base: 'none', sm: 'block' }}
              isLoading={isLoading}
              disabled={isLoading || fromSwiper}
              _disabled={{
                background: STATUS_ENUMS[type][status]?.color,
              }}
              _hover={{
                _disabled: {
                  background: STATUS_ENUMS[type][status]?.color,
                },
              }}
              p="6px 14px"
              zIndex="1"
              textTransform="capitalize"
              borderRadius="6px"
              fontSize="sm"
              fontWeight="bold"
              minH="20px"
              h="28px"
              color="gray.100"
              background={STATUS_ENUMS[type][status]?.color}
              isActive={isOpen}
              as={Button}
            >
              {t(`status@${status}`)}
            </MenuButton>
            <MenuList
              maxH="400px"
              overflowY="scroll"
              zIndex="100"
              border="none"
              boxShadow="0 12px 24px 0 rgba(0,0,0,0.15)"
            >
              {Object.entries(STATUS_ENUMS[type]).map(([key]) => {
                if (
                  key === 'live' &&
                  (!card.tagline_en || !card.tagline_hy || !card.tagline_ru)
                ) {
                  return
                }
                return (
                  <MenuItem
                    key={`menu-${key}`}
                    textTransform="capitalize"
                    onClick={() => {
                      setSelected(key)
                      onValueChange(key)
                    }}
                  >
                    {t(`status@${key}`)}
                  </MenuItem>
                )
              })}
            </MenuList>
          </>
        )}
      </Menu>
    </>
  )
}

export default StatusMenu
