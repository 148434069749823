import { useQuery } from 'react-query'
import { donationSubscriptionUseCase } from '../../core/factories'

const useDonationSubscriptionsByUserEmail = (condition) => {
  const {
    error,
    refetch,
    isLoading,
    data: donationSubscriptions,
  } = useQuery(
    ['donation_subscriptions_by_user_email', condition],
    async () => {
      return await donationSubscriptionUseCase.donationSubscriptionsByUserEmail(condition)
    })

  return {
    refetch,
    isLoading,
    donationSubscriptions,
    error,
  }
}

export default useDonationSubscriptionsByUserEmail
