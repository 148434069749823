import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Button, Stack, Text, IconButton, Flex, Box } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Link, useNavigation } from 'pages'
import { PasswordField } from 'components/PasswordField'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams, useSignIn, useUser } from 'hooks'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'
import useCustomToast from 'hooks/useCustomToast'

export const SigninForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  const queryParams = useQueryParams()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { signIn } = useSignIn()
  const { getUser } = useUser()
  const { navigationPush, navigationReplace } = useNavigation()
  const toast = useCustomToast()

  const submit = (data) => {
    setLoading(true)
    signIn(
      {
        ...data,
        email: data.email.toLowerCase(),
      },
      {
        onSuccess: async () => {
          await getUser()
          if (!!queryParams.get('token')) {
            return navigationPush(
              `/collaboration-request?token=${queryParams.get(
                'token'
              )}&initiativeId=${queryParams.get(
                'initiativeId'
              )}&email=${queryParams.get('email')}&response=${queryParams.get(
                'response'
              )}`
            )
          }
          const from = queryParams.get('from')
          if (from) {
            if (from === '/donation') {
              const redirectURL = localStorage.getItem('redirectURL')
              navigationReplace(redirectURL || '/')
              return
            }
            navigationReplace(queryParams.get('from'))
            return
          }
          navigationReplace('/')
        },
        onError: (err) => {
          setLoading(false)
          if (err.code === 'UserNotConfirmedException') {
            navigationPush(`/verify?email=${data.email}`)
          }
          toast({
            title: t(`login@${err.code}`),
            status: 'error',
            duration: 5000,
            position: 'top',
          })
        },
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex justifyContent="center" mb={8}>
        <IconButton
          onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
          p="0"
          mx={3}
          icon={<Facebook />}
        />
        <IconButton
          onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
          p="0"
          mx={3}
          icon={<Google />}
        />
      </Flex>
      <Box
        as="span"
        display="block"
        textAlign="center"
        textTransform="uppercase"
        fontSize="md"
        color="gray.700"
        letterSpacing="0.05px"
        mb={6}
      >
        {t('or')}
      </Box>
      {(!!errors.password || !!errors.email) && (
        <Text mb="4" ml="2" color="red.400">
          {t('login@credentialsRequired')}
        </Text>
      )}
      <Stack spacing="4">
        <InputField
          size="lg"
          label={t('login@email')}
          {...register('email', {
            required: true,
            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
          })}
        />
        <PasswordField
          size="lg"
          label={t('login@password')}
          // type="password"
          {...register('password', { required: true })}
        />
        <Button
          isLoading={loading}
          mb={{ base: '4', md: '0' }}
          w={{ base: 'full', md: 'auto' }}
          type="submit"
          size="lg"
          variant="blue"
          colorScheme="blue"
        >
          {t('login@submit')}
        </Button>
        <Button display="inline-block" color="gray.700" mt="3">
          <Link to="/forgot-password">{t('login@forgotPassword')}</Link>
        </Button>
        <Text
          display={{ base: 'inline-block', sm: 'none' }}
          textAlign="center"
          color="gray.700"
          fontSize="sm"
          fontWeight="semibold"
        >
          {t('login@dontHaveAccount')}{' '}
          <Button color="orange.400">
            <Link to="/sign-up">{t('login@signUp')}</Link>
          </Button>
        </Text>
      </Stack>
    </form>
  )
}
