import * as React from 'react'
import { Box, HStack } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { FooterHeading } from './FooterHeading'
import links from './links'

export const LinkGrid = (props) => {
  const { t } = useTranslation()
  return (
    <HStack flexWrap="wrap" {...props}>
      {links.map(({ href, label }) => (
        <FooterHeading
          fontWeight="500"
          style={{ marginBottom: '1rem' }}
          key={`footer-link-${href}`}
        >
          <Link to={href}>
            <Box fontSize="md" whiteSpace="nowrap">
              {t(label)}
            </Box>
          </Link>
        </FooterHeading>
      ))}
    </HStack>
  )
}
