import React from 'react'
import { Box, Container, Text, Img } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import MobileThankYouSubscribe from './Mobile'
import { MainLayout } from 'components/Layouts'

const w = window.innerWidth

const ThankYouSubscribe = () => {
  const { t } = useTranslation()

  if (w < 481) {
    return <MobileThankYouSubscribe />
  }

  return (
    <MainLayout>
      <Container pt="10" maxW="6xl">
        <Img src="/assets/images/thankYou.jpg" width="300px" minH="218px" />
        <Box mb="2rem" fontSize={{ base: '1.5rem', sm: '2rem' }}>
          {t('Thanks for subscription!')}
        </Box>
        <Text fontSize="lg">
          {t(
            'Stay tuned to the latest news and events happening within the Armenian Peace community and don’t miss the chance to get informed about them first!'
          )}
        </Text>
      </Container>
    </MainLayout>
  )
}

export default ThankYouSubscribe
