import React from 'react'
import { HStack, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { useUser } from 'hooks'
import { useNavigation } from 'pages'

const NavbarButtons = ({ project, param }) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { navigationPush } = useNavigation()
  return (
    <HStack
      zIndex="1000"
      bg="gray.100"
      w="full"
      bottom="0"
      left="0"
      borderTop="1px solid"
      borderColor="border.100"
      justifyContent="space-between"
      pt="3"
      pb="3"
      px="3"
      position="fixed"
      spacing="3"
    >
      {project.isCollaboration && project.status === 'live' && (
        <Button
          width="full"
          variant="blue"
          onClick={() => navigationPush(`/act-now?InitiativeId=${project?.id}`)}
        >
          {t('support')}
        </Button>
      )}
      {project.isProject && project.status === 'live' && (
        <Link
          style={{ width: '100%' }}
          to={
            project?.perks?.length > 0
              ? `/donate/${project?.id}${param || ''}`
              : `/donation?projectId=${project.id}${param || ''}`
          }
        >
          <Button w="full" variant="green">
            {t('project@donate')}
          </Button>
        </Link>
      )}
    </HStack>
  )
}

export default NavbarButtons
