import React, { useEffect, useMemo } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Header from './Header'
import { useTranslation } from 'contexts/TranslationContext'
import Wrapper from 'components/WrapperMobile'


const MobileSearch = ({ projects, filters, setFilters }) => {
  const { t } = useTranslation()
  const collaborations = useMemo(
    () =>
      projects
        .filter((project) => project.isCollaboration)
        .sort((a, b) => a.sortOrder - b.sortOrder),
    [projects]
  )
  const fundraisers = useMemo(
    () =>
      projects
        .filter((project) => project.isProject)
        .sort((a, b) => a.sortOrder - b.sortOrder),
    [projects]
  )

  useEffect(() => {
    let queryParams = ''
    for (const [key, value] of Object.entries(filters)) {
      if (value !== null) {
        queryParams += `&${key}=${value}`
      }
    }
    window.history.pushState(
      {},
      '',
      window.location.pathname + queryParams.replace('&', '?')
    )
  }, [filters])
  return (
    <Box>
      <Header filters={filters} setFilters={setFilters} />
      <Text px="5" fontSize="sm">
        We found {collaborations?.length} collaborations and{' '}
        {fundraisers?.length} fundarisings
      </Text>
      <Wrapper
        showAll={true}
        type="collaborations"
        swiper={collaborations}
        // isLoading={isLoading}
        fromSwiper={true}
        title={t('Collaborations')}
      />
      <Wrapper
        showAll={true}
        type="fundraisers"
        swiper={fundraisers}
        // isLoading={isLoading}
        fromSwiper={true}
        title={t('Fundraisers')}
      />
    </Box>
  )
}

export default MobileSearch
