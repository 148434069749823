import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams } from 'hooks'
import Application from './Application'
import Content from './Content'
import Rewards from './Rewards'
import Updates from './Updates'

export default function useRoutes({ status }) {
  const { t } = useTranslation()
  const location = useLocation()
  const { projectId } = useParams()
  const params = useQueryParams()
  const page = location.pathname.split('/')[2].split('-')[1]
  const showBoth = params.has('showBoth')

  const routes = [
    {
      path: ['/:lang/edit-fundraisers/:projectId/application', '/:lang/edit-collaborations/:projectId/application'],
      to: `/edit-${page}/${projectId}/application`,
      component: Application,
      label: t('createEditProject@application'),
    },
  ]

  if (status !== 'rejected_by_ss' && status !== 'submitted') {
    routes.splice(1, 0, {
      path: `/:lang/edit-${page}/:projectId/content`,
      to: `/edit-${page}/${projectId}/content`,
      component: Content,
      label: t('createEditProject@content'),
    })
  }
  if (status !== 'rejected_by_ss' && status !== 'submitted' && page !== 'collaborations') {
    routes.splice(2, 0, {
      path: `/:lang/edit-${page}/:projectId/rewards`,
      to: `/edit-${page}/${projectId}/rewards`,
      component: Rewards,
      label: t('createEditProject@rewards'),
    })
  }
  if (
    status === 'live' ||
    status === 'ended' ||
    status === 'launching_soon' ||
    status === 'waiting_for_transfer' ||
    status === 'closed' ||
    status === 'terminated'
  ) {
    routes.splice(4, 0, {
      path: `/:lang/edit-${page}/:projectId/updates`,
      to: `/edit-${page}/${projectId}/updates`,
      component: Updates,
      label: t('updates'),
    })
  }
  if (showBoth) {
    return routes.map((route) => ({...route, to: route.to + "?showBoth"}))
  }
  return routes
}
