import React from 'react'
import {
  Switch,
  BrowserRouter,
  Route as ReactRoute,
  Link as ReactLink,
  Redirect as ReactRedirect,
  useHistory,
} from 'react-router-dom'
import { useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import ScrollToTop from 'components/ScrollToTop'
import MetaTags, { DefaultHead } from 'components/MetaTags'
import routes from './configs'
import NotFound from './NotFound'
import Route from './Route'

export const RouterProvider = BrowserRouter

export const useNavigation = () => {
  const history = useHistory()
  const { language } = useTranslation()

  const navigationPush = (to = '') => history.push(`/${language}${to}`)
  const navigationReplace = (to = '') => history.replace(`/${language}${to}`)

  return {
    navigationPush,
    navigationReplace,
  }
}

export const Redirect = ({ to }) => {
  const { language } = useTranslation()

  return <ReactRedirect to={`/${language}${to}`} />
}

export const Link = ({ to, children, ...rest }) => {
  const { language } = useTranslation()

  return (
    <ReactLink {...rest} to={`/${language}${to}`}>
      {children}
    </ReactLink>
  )
}

const Routes = () => {
  const { isLoading } = useUser()

  return (
    <>
      <DefaultHead />
      <ScrollToTop />
      <Switch>
        {routes.map(({ path, exact, Component, secured, Layout }) => (
          <Route
            exact={exact}
            key={path}
            secured={secured}
            path={path}
            Layout={Layout}
            component={Component}
          />
        ))}
        <ReactRoute>{!isLoading && <NotFound />}</ReactRoute>
      </Switch>
    </>
  )
}

export default Routes
