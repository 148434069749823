import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { useParams } from 'react-router-dom'

const Fallback = () => {
  const { language } = useTranslation()
  const { projectId } = useParams()
  const { pathname } = useLocation()
  const type = pathname.includes('initiative')
    ? 'collaborations'
    : 'fundraisers'
  
  return <Redirect to={`/${language}/${type}/${projectId}`} />
}

export default Fallback
