import React from 'react'
import {
  Tabs as ChakraTabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import Story from '../Story'
import Update from '../Update'
import Collaborators from './Collaborators'

const Tabs = ({ initiative }) => {
  const { t } = useTranslation()

  return (
    <ChakraTabs maxW="735px">
      <Box overflow="scroll">

        <TabList borderColor="border.100">
          <Tab
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="4"
            flex="1"
          >
            {t('story')}
          </Tab>
          {(initiative.status === 'live' ||
            initiative.status === 'launching_soon' ||
            initiative.status === 'ended') && (
            <Tab
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ outline: 'none' }}
              _selected={{
                color: 'blue.400',
                borderColor: 'blue.400',
                fontWeight: 'bold',
              }}
              py="4"
              flex="1"
            >
              {t('update')}
            </Tab>
          )}
          <Tab
              borderBottom="2px solid"
            isDisabled
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ outline: 'none' }}
            _selected={{
              color: 'blue.400',
              borderColor: 'blue.400',
              fontWeight: 'bold',
            }}
            py="4"
            flex="1"
          >
            {t('Collaborators')}
          </Tab>
        </TabList>
      </Box>
      <TabPanels>
        <TabPanel>
          <Story />
        </TabPanel>
        <TabPanel>
          <Update initiative={initiative} />
        </TabPanel>
        <TabPanel>
          <Collaborators initiative={initiative} />
        </TabPanel>
      </TabPanels>
    </ChakraTabs>
  )
}

export default Tabs
