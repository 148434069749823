import React from 'react';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useNavigation } from 'pages';
import { useTranslation } from 'contexts/TranslationContext';
import Personal from './Personal';
import Payments from './Payments';

const TABS = {
    0: 'personal',
    1: 'payments',
    'personal': 0,
    'payments': 1,
}

const Settings = () => {
    const { t } = useTranslation();
    const { tab } = useParams();
    const { navigationPush } = useNavigation();

    const onChange = (tab) => navigationPush(`/profile/settings/${TABS[tab]}`)

    return (
        <>
            <Tabs
                defaultIndex={TABS[tab]}
                mt={8}
                onChange={onChange}>
                <TabList
                    display="block"
                    borderColor="border.400">
                    <Tab
                        id="personal"
                        display="inline-block"
                        py="3"
                        borderColor="border.100"
                        _active={{ backgroundColor: 'transparent' }}
                        _focus={{
                            outline: 'none',
                        }}
                        _selected={{
                            color: 'blue.400',
                            fontWeight: 'bold',
                            borderColor: 'blue.400',
                        }}
                        flex="1">
                        {t('profile@settings-personal')}
                    </Tab>
                    <Tab
                        id="payments"
                        display="inline-block"
                        py="3"
                        borderColor="border.100"
                        _active={{ backgroundColor: 'transparent' }}
                        _focus={{
                            outline: 'none',
                        }}
                        _selected={{
                            color: 'blue.400',
                            fontWeight: 'bold',
                            borderColor: 'blue.400',
                        }}
                        flex="1">
                        {t('profile@settings-payments')}
                    </Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Personal/>
                    </TabPanel>
                    <TabPanel>
                        <Payments/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default Settings

