import { useState, useEffect } from 'react'
import { Box, Button, Heading, Spinner, Text, Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import List from 'components/List'
import { Link } from 'pages'
import { useGetTransactionsByEmail, useUser } from 'hooks'
import { transactionsHistoryQuery } from 'elastic/queries'
import Pagination from 'components/Pagination/Index'

const PAGE_SIZE = 10
function History() {
  const { t, language } = useTranslation()
  const { user } = useUser()
  const [currentPage, setCurrentPage] = useState(1)
  const { data: transactions, isLoading } = useGetTransactionsByEmail(
    transactionsHistoryQuery({
      from: (currentPage - 1) * PAGE_SIZE,
      size: PAGE_SIZE,
      email: user.email,
    })
  )

  return (
    <Box my="12" width={{ base: '90%', sm: '95%' }} mx="auto">
      {isLoading ? (
        <Flex minH="520px" w="full" align="center" justifyContent="center">
          <Spinner color="blue.400" />
        </Flex>
      ) : (
        <>
          {transactions.items.length === 0 ? (
            <EmptyHistory
              title={t('profile@history@empty')}
              buttonText={t('profile@history@emptyButton')}
            />
          ) : (
            <>
              <Box mb="8">
                <Heading fontSize={{ base: 'lg', sm: '4xl' }}>
                  {t('profile@history@title')}
                </Heading>
              </Box>

              <List transactions={transactions.items} t={t} lang={language} />
              {transactions.total > 10 && (
                <Pagination
                  total={transactions.total}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  showTotal={false}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}

const EmptyHistory = ({ title, buttonText }) => (
  <Box textAlign="center">
    <Heading fontSize={{ base: 'xl', sm: '3xl' }} mb="8">
      {title}
    </Heading>
    <Button
      as={Link}
      to="/fundraisers"
      variant="blue"
      minWidth="216px"
      height="48px"
    >
      {buttonText}
    </Button>
  </Box>
)

export default History
