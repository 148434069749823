import React from 'react'
import {
  Box,
  Button,
  Stack,
  Checkbox,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { Link } from 'pages'
import { InputField } from 'components/InputField'
import { PasswordField } from 'components/PasswordField'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'
import { useTranslation } from 'contexts/TranslationContext'
import { authUseCase } from 'core/factories'

export default function Forms({
  loading,
  handleSubmit,
  onSubmit,
  errors,
  register,
  validateEmail,
}) {
  const { t } = useTranslation()

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex mb={4}>
        <IconButton
          onClick={authUseCase.fbSignIn}
          p="0"
          w="60px"
          h="60px"
          mr="16px"
          icon={<Facebook />}
          id="signup_button_facebook"
        />
        <IconButton
          onClick={authUseCase.googleSignIn}
          p="0"
          w="60px"
          h="60px"
          icon={<Google />}
          id="signup_button_google"
        />
      </Flex>
      <Box
        as="span"
        display="block"
        fontSize="md"
        color="gray.700"
        letterSpacing="0.05px"
        fontWeight="500"
        mb={4}
      >
        {t('login@orLogin')}
      </Box>
      <Stack mb="2" spacing="5">
        <Flex direction={{ base: 'column', sm: 'row' }}>
          <Box w="full" mr={{ base: '0', sm: '3' }} mb={{ base: '8', sm: '0' }}>
            <InputField
              size="md"
              id="signup_input_firstname"
              errorMessage={t('signUp@firstNameErrorMessage')}
              label={t('signUp@firstName')}
              isInvalid={!!errors.firstName}
              {...register('firstName', { required: true })}
            />
          </Box>
          <InputField
            size="md"
            id="signup_input_lastname"
            errorMessage={t('signUp@lastNameErrorMessage')}
            label={t('signUp@lastName')}
            isInvalid={!!errors.lastName}
            {...register('lastName', { required: true })}
          />
        </Flex>
        <InputField
          size="md"
          id="signup_input_email"
          isInvalid={!!errors.email}
          errorMessage={t('signUp@emailErrorMessage')}
          label={t('signUp@email')}
          type="email"
          {...register('email', {
            required: true,
            validate: (value) => validateEmail(value),
          })}
        />
        <Flex direction={{ base: 'column', sm: 'row' }}>
          <Box w="full">
            <PasswordField
              size="lg"
              id="signup_input_password"
              isInvalid={!!errors.password}
              {...register('password', { required: true })}
              errorMessage={t('signUp@passwordErrorMessage')}
              label={t('signUp@password')}
            />
          </Box>
        </Flex>
        <Flex direction="column" pt="2" pb="4">
          <Checkbox
            {...register('terms_and_conditions', { required: true })}
            isInvalid={!!errors.terms_and_conditions}
            id="signup_checkbox_terms"
            mb="2"
          >
            {t('signUp@agreeWith')}&nbsp;
            <Link
              style={{ color: '#ED8B00' }}
              target="_blank"
              to="/terms-and-conditions"
              id="signup_link_terms"
            >
              {t('signUp@termsConditions')}
            </Link>
          </Checkbox>
          <Checkbox
            {...register('privacy_policy')}
            id="signup_checkbox_privacy"
            isInvalid={!!errors.terms_and_conditions}
          >
            {t('signUp@haveRead')}&nbsp;
            <Link
              style={{ color: '#ED8B00' }}
              target="_blank"
              to="/privacy-policy"
              id="signup_link_terms"
            >
              {t('signUp@privacyPolicy')}
            </Link>
          </Checkbox>
        </Flex>
        <Button
          id="signup_input_button"
          isLoading={loading}
          w={{ base: 'full', md: 'auto' }}
          type="submit"
          colorScheme="yellow"
          size="lg"
          variant="blue"
        >
          {t('signUp@submit')}
        </Button>
      </Stack>
    </form>
  )
}
