import { useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigation } from 'pages'
import { useLocation } from 'react-router-dom'
import draft from '.'
import { useCustomToast, useQueryParams } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'

export const useCreateDraft = (input) => useMutation(draft.createDraft)

export const useUpdateDraft = (input) => useMutation(draft.updateDraft)

export const useDeleteDraft = (id) => useMutation(draft.deleteDraft)

export const useGetDraftById = (id) =>
  useQuery(`draft-${id}`, () => draft.getDraftById(id))

export const useFundraisersDraftsByUserId = (userId) =>
  useQuery(`drafts-${userId}`, () => draft.getFundraiserDraftsByUserId(userId))

export const useCollaborationsDraftsByUserId = (userId) =>
  useQuery(`drafts-${userId}`, () =>
    draft.getCollaborationDraftsByUserId(userId)
  )

export const useSaveDraft = () => {
  const isDraftSaved = useRef(true)
  const { search } = useLocation()
  const { navigationReplace } = useNavigation()
  const toast = useCustomToast()
  const params = useQueryParams()
  const { t } = useTranslation()
  const { mutate: createDraft, isLoading: isCreateDraftLoading } =
    useCreateDraft()
  const { mutate: updateDraft, isLoading: isUpdateDraftLoading } =
    useUpdateDraft()
  const addDraftIdToUrl = (id) => {
    if (!search.includes('draftId')) {
      navigationReplace(`/create-project${search}&draftId=${id}`)
    }
  }
  const onSaveDraftSuccess = (data) => {
    addDraftIdToUrl(data.id)
    isDraftSaved.current = true
    toast({
      title: t('toast@draft'),
      status: 'success',
    })
  }

  const saveDraft = async ({ input, callback }) => {
    if (params.get('draftId')) {
      await updateDraft(
        { ...input },
        {
          onSuccess: ({ data }) => onSaveDraftSuccess(data.updateDraft),
          onError: (err) => {
            toast({ title: `Something wen't wrong`, status: 'error' })
          },
          onSettled: callback,
        }
      )
      return
    }
    input.isCollaboration = params.has('isCollaboration')
    input.isProject = params.has('isFundraiser')
    await createDraft(
      { ...input },
      {
        onSuccess: ({ data }) => onSaveDraftSuccess(data.createDraft),
        onError: (err) => {
          toast({ title: `Something wen't wrong`, status: 'error' })
        },
        onSettled: callback,
      }
    )
  }
  return {
    isDraftSaved,
    saveDraft,
    isCreateDraftLoading,
    isUpdateDraftLoading,
  }
}
