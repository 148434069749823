import React from 'react'
import { Heading, Box, Button, Stack, HStack, Flex } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { PasswordField } from 'components/PasswordField'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'

const MobileChangePassword = ({
  register,
  errors,
  handleSubmit,
  submit,
  loading,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Box>
      <Flex
        justifyContent="center"
        py="12px"
        borderBottom="1px solid"
        borderColor="border.100"
      >
        <Flex
          minW="30px"
          left="12px"
          position="absolute"
          alignItems="center"
        >
          <Close onClick={() => history.goBack()} width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {t('changePassword@heading')}
        </Heading>
      </Flex>
      <Flex
        flexDirection="column"
        style={{ minHeight: 'calc(100vh - 54px)' }}
        justifyContent="center"
        px="5"
      >
        <form onSubmit={handleSubmit(submit)}>
          <Stack spacing="6">
            <PasswordField
              borderRadius="12px"
              size="lg"
              isInvalid={!!errors.password}
              errorMessage={t('changePassword@oldPasswordErrorMessage')}
              {...register('password', { required: true })}
              label={t('changePassword@oldPassword')}
            />
            <PasswordField
              borderRadius="12px"
              size="lg"
              errorMessage={t('changePassword@newPasswordErrorMessage')}
              isInvalid={!!errors.newPassword}
              {...register('newPassword', { required: true })}
              label={t('changePassword@newPassword')}
            />
            <Button
              isLoading={loading}
              type="submit"
              variant="blue"
              size="lg"
            >
              {t('changePassword@submit')}
            </Button>
          </Stack>
        </form>
      </Flex>
    </Box>
  )
}

export default MobileChangePassword
