import React, { useState } from 'react'
import { API } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { Flex, Heading, Stack, Button, Box } from '@chakra-ui/react'
import { Link } from 'pages'
import { InputField } from 'components/InputField'
import { TextareaField } from 'components/TextareaFIeld'
import { useTranslation } from 'contexts/TranslationContext'

const Fields = () => {
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = async (data) => {
    setLoading(true)
    const body = {
      text: data.message,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    }

    try {
      const data = await API.post('email', '/sendEmail', {
        body,
      })
      if (data.MessageId) {
        setSubmit(true)
        setLoading(false)
      }
      return data // For unit tests.
    } catch (err) {
      setLoading(false)
      console.log('Error', err)
    }
  }

  return (
    <Flex
      pr={{ base: '0', lg: '5rem' }}
      flexDirection="column"
      borderRightWidth={{ base: '0', md: '0.5px' }}
      borderStyle="solid"
      borderColor="rgba(0, 0, 0, 0.2)"
      flex="1"
    >
      {submit ? (
        <Box minHeight={{ base: '300px', md: '630px' }}>
          <Stack
            display="flex"
            alignItems="center"
            flexDirection="column"
            spacing="10"
          >
            <Heading
              as="h2"
              fontSize="4xl"
              dangerouslySetInnerHTML={{
                __html: t('contactUs@thankYouHeading'),
              }}
            />
            <Link to="/">
              <Button variant="blue">
                {t('contactUs@thankYouButtonText')}
              </Button>
            </Link>
          </Stack>
        </Box>
      ) : (
        <>
          <Heading as="h2" fontSize="4xl">
            {t('contactUs@heading1')}
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mt="12" spacing="6">
              <InputField
                size="sm"
                
                isInvalid={!!errors.firstName}
                errorMessage={t('contactUs@form-firstNameErrorMessage')}
                {...register('firstName', { required: true })}
                label={t('contactUs@form-firstName')}
                labelProps={{ textTransform: 'capitalize' }}
              />
              <InputField
                size="sm"
                
                isInvalid={!!errors.lastName}
                {...register('lastName', { required: true })}
                errorMessage={t('contactUs@form-lastNameErrorMessage')}
                label={t('contactUs@form-lastName')}
                labelProps={{ textTransform: 'capitalize' }}
              />
              <InputField
                size="sm"
                
                {...register('email', {
                  required: true,
                  pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                })}
                isInvalid={!!errors.email}
                errorMessage={t('contactUs@form-emailAddressErrorMessage')}
                label={t('contactUs@form-emailAddress')}
                labelProps={{ textTransform: 'capitalize' }}
              />
              <TextareaField
                {...register('message')}
                label={t('contactUs@form-message')}
              />
              <Button
                isLoading={loading}
                width="full"
                type="submit"
                variant="blue"
                colorScheme="blue"
              >
                {t('contactUs@form-submit')}
              </Button>
            </Stack>
          </form>
        </>
      )}
    </Flex>
  )
}

export default Fields
