import { useState, useEffect, useMemo } from 'react'
import { useQueryParams } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import Web from './Web'
import Mobile from './Mobile'

const w = window.innerWidth

export default function CartAmountForm({
  getValues,
  project,
  donation,
  setValue,
  watch,
  isContribution,
}) {
  const [percent, setPercent] = useState(7.5)
  const queryParams = useQueryParams()
  const [other, setOther] = useState(false)
  const [perk, setPerk] = useState(null)
  const { t } = useTranslation()

  const PRICE = {
    '10$': 5,
    '20$': 7.5,
    '50$': 12.5,
    other: t('donation@other'),
  }

  const handleAmount = (e) => {
    setOther(false)
    if (e === '10$') {
      setPercent(5)
      perk !== null
        ? (e = (Number(perk.price) * 5) / 100)
        : (e = (Number(donation.amount) * 5) / 100)
    } else if (e === '20$') {
      setPercent(7.5)
      perk !== null
        ? (e = (Number(perk.price) * 7.5) / 100)
        : (e = (Number(donation.amount) * 7.5) / 100)
    } else if (e === '50$') {
      setPercent(12.5)
      perk !== null
        ? (e = (Number(perk.price) * 12.5) / 100)
        : (e = (Number(donation.amount) * 12.5) / 100)
    } else if (e === 'other') {
      setPercent(null)
      return setOther(true)
    }
    return setValue('reArmenia', e)
  }
  useEffect(() => {
    if (!project) return
    let newPerk = null
    if (
      queryParams.has('perkIndex') &&
      project.perks[queryParams.get('perkIndex')]
    ) {
      newPerk = project.perks[queryParams.get('perkIndex')]
      newPerk.price = Number(queryParams.get('amount'))
    }
    setPerk(newPerk)
  }, [project])

  useMemo(() => {
    if (percent) {
      setValue('reArmenia', (donation.amount * percent) / 100)
    }
  }, [donation.amount])

  const props = {
    PRICE,
    donation,
    setValue,
    getValues,
    other,
    perk,
    handleAmount,
    watch,
    project,
    isContribution,
  }

  if (w < 481) {
    return <Mobile {...props} />
  }

  return <Web {...props} />
}
