import { useQuery } from 'react-query'
import { successStoryUseCase } from '../../core/factories'
import { useContentful } from 'contexts/ContentfulContext'

const useGetSuccessStory = (slug, language) => {
  const { client } = useContentful();

  const query = useQuery(
    ['get_success_story', slug],
    () => successStoryUseCase({ client }).getSuccessStory(slug, language))

  return query
}

export default useGetSuccessStory
