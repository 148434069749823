import React from 'react'
import { Box, Flex, Button, Text } from '@chakra-ui/react'
import { Link } from 'pages'

export default function SignUpSection({
  openModal,
  redirectURL,
  title,
  description,
  buttonText,
}) {
  return (
    <Flex
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor="border.100"
      my={8}
      py={8}
      alignItems={{ base: 'start', sm: 'center' }}
      justifyContent={{ base: 'start', sm: 'space-between' }}
      Flex
      flexDir={{ base: 'column', sm: 'row' }}
    >
      <Box mr="4">
        <Text fontSize="16px" color="black.100" fontWeight="extrabold">
          {title}
        </Text>
        <Text fontSize="sm" color="gray.700">
          {description}
        </Text>
      </Box>
      <SingUpButton
        onClick={() => {
          openModal('signUp', redirectURL)
        }}
        mobileURL={`/sign-up?from=/donation`}
        buttonText={buttonText}
      />
    </Flex>
  )
}

const w = window.innerWidth

const SingUpButton = ({ buttonText, mobileURL, ...props }) => {
  if (w < 481) {
    return (
      <Link to={mobileURL}>
        <Button
          variant="border"
          mt={{ base: 8, sm: 0 }}
          height="48px"
          minWidth="172px"
          fontWeight="extrabold"
          borderRadius="8px"
          {...props}
          onClick={() => {}}
        >
          {buttonText}
        </Button>
      </Link>
    )
  }
  return (
    <Button
      variant="border"
      mt={{ base: 8, sm: 0 }}
      height="48px"
      minWidth="172px"
      fontWeight="extrabold"
      borderRadius="8px"
      {...props}
    >
      {buttonText}
    </Button>
  )
}
