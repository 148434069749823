import { useEffect } from 'react'
import { useParams } from 'react-router'
import InitiativeContext from '.'
import useGetInitiative from '../../hooks/initiative/useGetInitiative'
import useGetUser from '../../hooks/user/useGetUser'

const InitiativeProvider = ({ children }) => {
  const { initiativeId } = useParams()
  const { refetch, initiative, isLoading } = useGetInitiative({ initiativeId })
  const { user: owner, getUserById: getOwner } = useGetUser()
  const { user: implementor, getUserById: getImplementor } = useGetUser()

  useEffect(() => {
    if (initiative) {
      getOwner(initiative?.owner ?? '')
      getImplementor(initiative?.implementor?.id ?? '')
    }
  }, [initiative])

  return (
    <InitiativeContext.Provider
      value={{ initiative, owner, implementor, refetch, isLoading }}
    >
      {children}
    </InitiativeContext.Provider>
  )
}

export const withInitiative = (Component) => (props) => {
  return (
    <InitiativeProvider>
      <Component {...props} />
    </InitiativeProvider>
  )
}

export default InitiativeProvider
