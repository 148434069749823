import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react'
import ReactGA from 'react-ga4'
import { useNavigation } from 'pages'
import { useQueryParams } from 'hooks'
import {
  SignUpModal,
  SignInModal,
  VerifySignUpModal,
  VerifyResetPasswordModal,
  ResetPasswordModal,
  SubscriptionModal,
  DraftModal,
  DeleteDraftModal,
} from 'modals'
import { useLocation } from 'react-router-dom'

const MODALS = {
  signIn: SignInModal,
  signUp: SignUpModal,
  verify: VerifySignUpModal,
  forgotPassword: ResetPasswordModal,
  resetVerify: VerifyResetPasswordModal,
  subscribe: SubscriptionModal,
  draft: DraftModal,
  deleteDraft: DeleteDraftModal,
}
const DEFAULT_MODAL = 'signIn'

export const ModalContext = createContext({
  openModal: (modalType, nextRedirect, isJoin = false, closeCallback = () => {}, modalParams) => {},
  closeModal: (redirectTo) => {},
})
export const useModal = () => useContext(ModalContext)

const getPath = (pathname, search, action, isOpen, params) => {
  let result =
    pathname.slice(3, pathname.length) === '/'
      ? ''
      : pathname.slice(3, pathname.length)
  const query = search
    .replaceAll(`?action=${params.get('action')}`, '')
    .replaceAll(`&action=${params.get('action')}`, '')

  if (query) {
    result += query
  }

  if (isOpen) {
    result += query ? '&' : '?'
    result += `action=${action}`
  }

  return result
}

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(DEFAULT_MODAL)
  const [email, setEmail] = useState()
  const [firstName, setFirstName] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { pathname, search } = useLocation()
  const { navigationReplace, navigationPush } = useNavigation()
  const params = useQueryParams()
  const redirectTo = useRef()
  const callback = useRef(() => {})
  const [modalProps, setModalProps] = useState({})  
  const closeModal = (redirectParams = {}, callbackOptions) => {
    if (callback.current) {
      callback.current(callbackOptions)
    }
    onClose()
    if (redirectTo.current) {
      let url = redirectTo.current
      if (Object(redirectParams).keys?.length) {
        for (const [key, value] of Object.entries(redirectParams)) {
          url += `&${key}=${value}`
        }
      }
      navigationReplace(url)
      return
    }
    navigationReplace(getPath(pathname, search, null, false, params))
  }

  const openModal = (
    modalType = DEFAULT_MODAL,
    nextRedirect,
    isJoin = false,
    closeCallback,
    modalParams = {}
  ) => {
    if (isJoin) {
      ReactGA.event({
        category: 'Join community',
        action: 'button click',
        label: 'homepage click',
        value: 1,
        nonInteraction: true,
        transport: 'xhr',
      })
    }
    setModalProps(modalParams)
    redirectTo.current = nextRedirect
    if (closeCallback) {
      callback.current = closeCallback
    }
    const currentModalType =
      typeof modalType === 'string' ? modalType : DEFAULT_MODAL

    navigationPush(getPath(pathname, search, currentModalType, true, params))
    setModal(currentModalType)
    onOpen()
  }

  const onModalChange = (Content, authEmail, authFirstName) => {
    setEmail(authEmail)
    setFirstName(authFirstName)
    setModal(Content)
  }

  const memorizedChildren = useMemo(() => children, [])

  useEffect(() => {
    let replace = getPath(pathname, search, modal, isOpen, params)
    if (email) {
      replace += `&email=${email}`
    }
    if (firstName) {
      replace += `&firstName=${firstName}`
    }
    if (replace) {
      navigationReplace(replace)
    }
  }, [modal])

  useEffect(() => {
    const action = params.get('action')
    if (action) {
      openModal(action)
    }
  }, [])

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <ModalComponent
        ContentComponent={MODALS[modal]}
        isOpen={isOpen}
        redirectTo={redirectTo}
        closeModal={closeModal}
        setModal={onModalChange}
        {...modalProps}
      />
      {memorizedChildren}
    </ModalContext.Provider>
  )
}

const ModalComponent = ({
  ContentComponent,
  isOpen,
  closeModal,
  redirectTo,
  setModal,
  ...props
}) => (
  <Modal
    isOpen={isOpen}
    onClose={closeModal}
    size="lg"
    isCentered={window.innerHeight > 730}
  >
    <ModalOverlay />
    <ModalContent borderRadius="16px" paddingX="15px">
      <ContentComponent
        closeModal={closeModal}
        redirectTo={redirectTo.current}
        setModal={setModal}
        {...props}
      />
    </ModalContent>
  </Modal>
)

export default ModalProvider
