import React, { useMemo } from 'react'
import { FormControl } from '@chakra-ui/form-control'
import DatePicker from 'react-datepicker'
import { Box } from '@chakra-ui/react'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Date = ({
  date,
  name,
  setValue,
  register,
  clearErrors,
  minDate,
  changeDate,
  isInvalid,
  errorMessage,
  showTimeSelect,
  datePlaceholder = '',
  ...rest
}) => {
  const datePickerForms = useMemo(() => {
    if (showTimeSelect) {
      return {
        showTimeSelect: true,
        dateFormat: 'Pp',
      }
    }

    return {}
  }, [showTimeSelect])
  return (
    <FormControl {...rest} zIndex={3}>
      {isInvalid && (
        <Box
          top="calc(100% + 5px)"
          fontSize="sm"
          left="2"
          fontWeight="semibold"
          position="absolute"
          color="red.400"
        >
          {errorMessage}
        </Box>
      )}
      <input
        value={date}
        type="hidden"
        {...register(name, {
          required: true,
        })}
      />
      <DatePicker
        placeholderText={datePlaceholder}
        selected={!!date ? date : null}
        minDate={minDate}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        className={isInvalid ? 'invalid' : ''}
        onChange={(updatedDate) => {
          changeDate(name, updatedDate)
        }}
        {...datePickerForms}
      />
    </FormControl>
  )
}

export default Date
