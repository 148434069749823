import { Button, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Success } from 'assets/icons/success.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Error } from 'assets/icons/error-icon.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';

const COLORS = {
	info: '#1D6ECF',
	warning: '#ED8B00',
	success: '#00C35D',
	error: '#DB4151'
};

const ICONS = {
	info: Info,
	warning: Warning,
	success: Success,
	error: Error
};

export default function Toaster({ title, status = 'info', onClose }) {
	const Icon = ICONS[status];

	return (
		<Flex
			bg={COLORS[status]}
			color="gray.100"
			mb="60px"
			mr={{ base: 0, md: '30px' }}
			w={{ base: '95vw', md: '400px' }}
			fontWeight="500"
			borderRadius="12px"
			boxShadow="0 4px 16px 0 rgba(0,0,0,0.2)"
			padding={4}
			alignItems="center"
			justifyContent="space-between"
		>
			<Flex alignItems="center">
				<Flex width="25px" height="25px">
					<Icon
						style={{
							width: '25px',
							height: '25px',
							fill: 'white',
							opacity: '0.5'
						}}
					/>
				</Flex>
				<Text ml="2">{title}</Text>
			</Flex>
			<Button onClick={onClose}>
				<Close style={{ width: '14px', height: '14px' }} />
			</Button>
		</Flex>
	);
}
