import { Flex, Spinner } from '@chakra-ui/react'
import Update from 'pages/Project/Update'
import useGetUpdatesByProjectId from 'hooks/update/useGetProjectUpdates'
import { useGetProject, useUser } from 'hooks'
import { useHistory } from 'react-router-dom'

export default function Updates() {
  const { location } = useHistory()
  const projectId = location.pathname.split('/').at(-2)
  const { data: project } = useGetProject({ projectId })
  const {
    data: updates,
    refetch,
    isLoading,
  } = useGetUpdatesByProjectId({
    projectId,
    sortDirection: 'DESC',
  })
  const { user } = useUser()
  if (isLoading) {
    return (
      <Flex minH="520px" w="full" align="center" justifyContent="center">
        <Spinner color="blue.300" />
      </Flex>
    )
  }

  return (
    <Update
      showAvatar={false}
      user={user}
      refetchUpdates={refetch}
      updates={updates}
      project={project}
      isUpdatesLoading={isLoading}
    />
  )
}
