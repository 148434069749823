import { API, Auth, graphqlOperation } from 'aws-amplify'
import {
  getUser as retrieveUser,
  usersByEmail as usersByEmailQuery,
} from '../../graphql/queries'
import {
  createUser as createUserMutation,
  updateUser as updateUserMutation,
} from '../../graphql/mutations'

const isSocial = (username) => {
  username = username.toLowerCase()

  return username.includes('facebook') || username.includes('google')
}

const userService = () => {
  const createUser = async (input) =>
    API.graphql(graphqlOperation(createUserMutation, { input }))

  const updateUser = async (input) =>
    API.graphql(graphqlOperation(updateUserMutation, { input }))

  const getUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    console.log(cognitoUser)
    const dynamoUser = await getUserByEmail(cognitoUser.attributes.email)
    if (!dynamoUser && isSocial(cognitoUser.username)) {
      const newUser = await createUser({ email: cognitoUser.attributes.email, firstName: cognitoUser.attributes.given_name, lastName: cognitoUser.attributes.family_name })

      return newUser.data.createUser
    }
    return dynamoUser
  }

  const getUserById = async (id) => {
    const user = await API.graphql(graphqlOperation(retrieveUser, { id }))
    return user.data.getUser
  }

  const getUserByEmail = async (email) => {
    const user = await API.graphql(
      graphqlOperation(usersByEmailQuery, { email })
    )
    return user.data.usersByEmail.items[0]
  }

  const editEmail = async (email) => {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      email: email,
    })
  }

  const verifyEmail = async (code) => {
    await Auth.verifyCurrentUserAttributeSubmit('email', code)
  }

  const editUserInfo = async ({ firstName, lastName }) => {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      name: firstName,
      family_name: lastName,
    })
  }

  const changePassowrd = async ({ oldPass, newPass }) => {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPass, newPass)
  }

  const signOut = () =>
    new Promise((resolve, reject) => {
      Auth.signOut()
        .then(() => resolve('signed out'))
        .catch(() => reject('sign out error'))
    })

  return {
    getUser,
    getUserByEmail,
    getUserById,
    editEmail,
    verifyEmail,
    editUserInfo,
    changePassowrd,
    signOut,
    updateUser,
    createUser,
  }
}

export default userService
