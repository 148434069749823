import { I18n } from 'aws-amplify'

import msgEnUs from './messages/en-us.json'
import msgRuRu from './messages/ru-ru.json'
import msgHyAm from './messages/hy-am.json'

export const configure = () => {
  I18n.configure({
    supportedLngs: ['en-US', 'ru-RU', 'hy-AM'],
    fallbackLng: 'en-US',
  })

  I18n.putVocabularies({
    'en-US': msgEnUs,
    'ru-RU': msgRuRu,
    'hy-AM': msgHyAm,
  })
}
