import React from 'react'
import { Container } from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import { ProjectProvider } from './ProjectContext'
import Tabs from './Tabs'

const EditProject = () => {
  return (
    <ProjectProvider>
      <MainLayout>
        <Container pb="5.5rem" maxW="6xl" pt="8">
          <Tabs />
        </Container>
      </MainLayout>
    </ProjectProvider>
  )
}

export default EditProject
