import React from 'react'
import { Box } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const MonthlyLabel = ({...props}) => {
  const { t } = useTranslation()
  return (
    <Box
      ml="2"
      display="flex"
      alignItems="center"
      justifyContent="center"
      as="span"
      fontWeight="700"
      bg="#DDDDDD"
      height="16px"
      minW="63px"
      px="2"
      letterSpacing="0.5px"
      borderRadius="4px"
      fontSize="9px"
      textTransform="uppercase"
      color="white"
      {...props}
    >
      {t('monthly')}
    </Box>
  )
}
export default MonthlyLabel
