import React from 'react'
import { Heading, Button, Box, HStack } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link, useNavigation } from 'pages'
import ProjectSkeletonCard from 'components/ProjectSkeletonCardMobile'
import ProjectCard from 'components/ProjectCard'
import NoScrollbar from 'components/NoScrollbar'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
} from 'contexts/CurrencySettingsContext'
import { useUser, calculateProjectDurationLeft } from 'hooks'

const filter = (swiper) =>
  swiper?.filter((project) => project.status === 'live')

const sort = (swiper) =>
  swiper?.sort((a, b) =>
    !!a.isProject
      ? b.total.goalMetPercentage - a.goalMetPercentage
      : b.collaboratorsTotal - a.collaboratorsTotal
  )

const Wrapper = ({
  title,
  swiper,
  type,
  isLoading,
  showAll,
  bg = '#ffffff',
  pt = '10',
}) => {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()
  const { user } = useUser()

  return (
    <Box bg={bg} pt={pt} pb="3.125rem">
      <Heading
        fontSize="5xl"
        fontWeight="700"
        as="h2"
        mb="6"
        px="5"
        textTransform="capitalize"
      >
        {title}
      </Heading>
      <NoScrollbar
        Component={HStack}
        mb="6"
        spacing="0"
        pl="10"
        alignItems="baseline"
        overflowX="scroll"
        overflowY="hidden"
      >
        {isLoading
          ? [1, 2].map((item) => (
              <Box key={item} ml="-5" pr="5">
                <ProjectSkeletonCard />
              </Box>
            ))
          : type !== 'blog' &&
            sort(filter(swiper))?.map((item) => (
              <Box key={item.id} ml="-5" pr="5">
                <ProjectCard  goalText={t('goal')}
                  card={item}
                  goalPrice={item.activeGoal?.amount || item.goal}
                  raisedMoney={CurrencyConverter({
                    currency: currency.current,
                    amount: parseInt(item.amount),
                  })}
                  ml="5px"
                  CurrencyConverter={CurrencyConverter}
                  thousandSeparatorText={thousandSeparator(
                    currency.current === 'AMD'
                      ? item.amount + Number(item.updateAmount)
                      : item.amount /
                          Number(settings?.currency[currency.current]) +
                          Number(item.updateAmount), currency.current
                  )}
                  SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                  editRoute={type}
                  type={type}
                  calculatedProjectDurationLeft={calculateProjectDurationLeft({
                    t,
                    campaignImplementorPeriod_en:
                      item.campaignImplementorPeriod_en,
                    fundraisingType: item.fundraisingType,
                  })}
                  language={language}
                  isMobile
                  currency={currency}
                  settings={settings}
                  changeDefaultCurrency={changeDefaultCurrency}
                  navigationPush={navigationPush}
                  user={user}
                  cardStatusText={t(`status@${item.status}`)}
                  cardCategoryText={t(`category@${item.category}`)}
                  editText={t('edit')}
                  unsubscribeText={t('unsubscribe')}
                  collaborationProposalsText={t(
                    item?.collaboratorsTotal === 1
                      ? 'collaboration proposal'
                      : 'collaboration proposals'
                  )}
                  collaborationProposalsTextRuEdit={t('collaboration proposals')
                    .split('...')[1]
                    .replace('запросов', 'запроса')}
                  monthlyGoalText={t('monthlyGoal')}
                  raisedText={t('raised')}
                />
              </Box>
            ))}
        {!isLoading &&
          type !== 'blog' &&
          sort(swiper)
            ?.filter((item) => item.status === 'ended')
            .map((item) => (
              <Box key={item.id} ml="-5" pr="5">
                <ProjectCard  goalText={t('goal')}
                  card={item}
                  goalPrice={CurrencyConverter({
                    amount: item.activeGoal?.amount || item.goal,
                    currency: currency.current,
                  })}
                  raisedMoney={CurrencyConverter({
                    currency: currency.current,
                    amount: parseInt(item.amount),
                  })}
                  ml="5px"
                  CurrencyConverter={CurrencyConverter}
                  thousandSeparatorText={thousandSeparator(
                    currency.current === 'AMD'
                      ? item.amount + Number(item.updateAmount)
                      : item.amount /
                          Number(settings?.currency[currency.current]) +
                          Number(item.updateAmount)
                  )}
                  SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                  editRoute={type}
                  type={type}
                  calculatedProjectDurationLeft={calculateProjectDurationLeft({
                    t,
                    campaignImplementorPeriod_en:
                      item.campaignImplementorPeriod_en,
                    fundraisingType: item.fundraisingType,
                  })}
                  language={language}
                  isMobile
                  currency={currency}
                  settings={settings}
                  changeDefaultCurrency={changeDefaultCurrency}
                  navigationPush={navigationPush}
                  user={user}
                  cardStatusText={t(`status@${item.status}`)}
                  cardCategoryText={t(`category@${item.category}`)}
                  editText={t('edit')}
                  unsubscribeText={t('unsubscribe')}
                  collaborationProposalsText={t(
                    item?.collaboratorsTotal === 1
                      ? 'collaboration proposal'
                      : 'collaboration proposals'
                  )}
                  collaborationProposalsTextRuEdit={t('collaboration proposals')
                    .split('...')[1]
                    .replace('запросов', 'запроса')}
                  monthlyGoalText={t('monthlyGoal')}
                  raisedText={t('raised')}
                />
              </Box>
            ))}
        {type === 'blog' &&
          swiper?.map((item) => (
            <Box key={`blog-${item.id}`} ml="-5" pr="5">
              <ProjectCard  goalText={t('goal')}
                card={item}
                goalPrice={item.activeGoal?.amount || item.goal}
                raisedMoney={CurrencyConverter({
                  currency: currency.current,
                  amount: parseInt(item.amount),
                })}
                CurrencyConverter={CurrencyConverter}
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? item.amount + Number(item.updateAmount)
                    : item.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(item.updateAmount), currency.current
                )}
                SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                ml="5px"
                editRoute={type}
                type={type}
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en:
                    item.campaignImplementorPeriod_en,
                  fundraisingType: item.fundraisingType,
                })}
                language={language}
                currency={currency}
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
                navigationPush={navigationPush}
                user={user}
                isMobile
                cardStatusText={t(`status@${item.status}`)}
                cardCategoryText={t(`category@${item.category}`)}
                editText={t('edit')}
                unsubscribeText={t('unsubscribe')}
                collaborationProposalsText={t(
                  item?.collaboratorsTotal === 1
                    ? 'collaboration proposal'
                    : 'collaboration proposals'
                )}
                collaborationProposalsTextRuEdit={t('collaboration proposals')
                  .split('...')[1]
                  .replace('запросов', 'запроса')}
                monthlyGoalText={t('monthlyGoal')}
                raisedText={t('raised')}
              />
            </Box>
          ))}
      </NoScrollbar>
      {showAll && (
        <Link to={`/${type}`}>
          <Button
            ml="5"
            fontSize="md"
            fontWeight="700"
            color="blue.300"
            variant="transparent"
          >
            {t('seeAll')}
          </Button>
        </Link>
      )}
    </Box>
  )
}

export default Wrapper
