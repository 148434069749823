import React, { useEffect, useState } from 'react'
import {
  Flex,
  Input,
  InputGroup,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Select,
  Box,
  Stack,
  InputLeftElement,
  FormLabel,
  FormControl,

} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Filter } from 'assets/icons/filter.svg'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { CATEGORY, PROJECT_TIMING, REGIONS } from '_constants'

const Header = ({
  url,
  type,
  filters,
  setFilters,
  price,
  setPrice,
  onResetFilters,
}) => {
  const { t } = useTranslation()
  const { onOpen, isOpen, onClose } = useDisclosure()

  const [filter, setFilter] = useState({})

  const search = () => {
    setFilters((filters) => ({
      ...filters,
      category: filter.category,
      region: filter.region,
      duration: filter.duration,
      priceMin: price.min,
      priceMax: price.max,
    }))
    onClose()
  }

  const deleteFilters = () => {
    onResetFilters()
    setFilter({ ...filters })
  }

  useEffect(() => {
    setFilter(filters)
  }, [filters])

  return (
    <>
      <Modal size="full" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody display="flex" justifyContent="center" alignItems="center">
            <Stack position="relative" spacing="8">
              <Button
                onClick={onClose}
                bottom="100%"
                right="0"
                position="absolute"
              >
                <Close height="20px" width="20px" />
              </Button>
              <Select
                textAlign="right"
                _focus={{ outline: 'none' }}
                border="none"
                placeholder={t('nav@category')}
                value={filter.category}
                onChange={(e) =>
                  setFilter((filter) => ({
                    ...filter,
                    category: e.target.value,
                  }))
                }
              >
                {CATEGORY.map((item) => (
                  <option key={item} value={item}>
                    {t(`category@${item}`)}
                  </option>
                ))}
              </Select>
              <Select
                textAlign="right"
                _focus={{ outline: 'none' }}
                placeholder={t('nav@region')}
                border="none"
                value={filter.region}
                onChange={(e) =>
                  setFilter((filter) => ({ ...filter, region: e.target.value }))
                }
              >
                {REGIONS.map((item) => (
                  <option key={item} value={item}>
                    {t(`region@${item}`)}
                  </option>
                ))}
              </Select>
              <Select
                textAlign="right"
                display={type === 'projects' ? 'block' : 'none'}
                _focus={{ outline: 'none' }}
                value={filter.duration}
                onChange={(e) =>
                  setFilter({ ...filter, duration: e.target.value })
                }
                border="none"
                placeholder={t('filters@projectTiming')}
              >
                {PROJECT_TIMING.map((item) => (
                  <option key={item} value={item}>
                    {t(`projectTiming@${item}`)}
                  </option>
                ))}
              </Select>
              <Select
                textAlign="right"
                display={type === 'initiatives' ? 'block' : 'none'}
                _focus={{ outline: 'none' }}
                border="none"
                placeholder={t('nav@initiativeTiming')}
              ></Select>
              {type === 'projects' && (
                <Box pl="1rem" pr="0.8rem">
                  <Box as="span">{t('nav@projectBudget')}</Box>
                  <Stack alignItems="center" mt="2" spacing="4">
                    <FormControl position="relative">
                      <FormLabel
                        whiteSpace="nowrap"
                        fontSize="md"
                        fontWeight="regular"
                        bg="gray.100"
                        zIndex={2}
                        px="2"
                      >
                        {t('nav@from')}
                      </FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pt="2"
                          pointerEvents="none"
                          color="gray.700"
                          fontSize="1.2em"
                          children="$"
                        />
                        <Input
                          _focus={{ borderColor: 'blue.300' }}
                          _hover={{ borderColor: 'blue.300' }}
                          sx={{ boxShadow: 'none !important' }}
                          value={price.min}
                          onChange={(e) =>
                            setPrice((price) => ({
                              ...price,
                              min: e.target.value,
                            }))
                          }
                          type="number"
                          pattern="[0-9]*"
                          borderColor="border.100"
                          size="lg"
                          fontSize="lg"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl position="relative">
                      <FormLabel
                        whiteSpace="nowrap"
                        fontSize="md"
                        fontWeight="regular"
                        bg="gray.100"
                        zIndex={2}
                        px="2"
                      >
                        {t('nav@to')}
                      </FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pt="2"
                          pointerEvents="none"
                          color="gray.700"
                          fontSize="1.2em"
                          children="$"
                        />
                        <Input
                          _focus={{ borderColor: 'blue.300' }}
                          _hover={{ borderColor: 'blue.300' }}
                          sx={{ boxShadow: 'none !important' }}
                          value={price.max}
                          onChange={(e) =>
                            setPrice((price) => ({
                              ...price,
                              max: e.target.value,
                            }))
                          }
                          type="number"
                          pattern="[0-9]*"
                          borderColor="border.100"
                          size="lg"
                          fontSize="lg"
                        />
                      </InputGroup>
                    </FormControl>
                  </Stack>
                  {/* <InputSlider
                                            value={{min: filters.priceMin || 0, max: filters.priceMax || 1000000}}
                                            setValue={value => setFilters(filters => ({...filters, priceMin: value.min, priceMax: value.max}))} /> */}
                </Box>
              )}
              <Flex
                pl="1rem"
                w="full"
                alignItems="center"
                pr="0.8rem"
                justifyContent="space-between"
              >
                <Box
                  textTransform="uppercase"
                  fontWeight="bold"
                  as="span"
                  color="blue.400"
                  onClick={deleteFilters}
                >
                  {t('nav@remove')}
                </Box>
                <Button onClick={search} variant="blue">
                  {t('nav@search')}
                </Button>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Flex flexWrap="nowrap">
       
        <Flex
          borderLeft="1px solid"
          borderColor="border.100"
          align="center"
          justify="center"
          height="36px"
          display={{ base: 'flex', sm: 'none' }}
          pl="6"
          pr="8"
          onClick={onOpen}
          zIndex="10"
          position="absolute"
          right="0"
        >
          <Filter height="20px" width="20px" color="gray.700" />
        </Flex>
      </Flex>
    </>
  )
}

export default Header
