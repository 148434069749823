import React, { useEffect, useState } from 'react'
import { SimpleGrid, GridItem, Heading, Flex, Spinner } from '@chakra-ui/react'
import AllProductsWrapper from 'components/AllProductsWrapper'
import ProjectCard from 'components/ProjectCard'
import {
  useQueryParams,
  useUser,
  useProjects,
  calculateProjectDurationLeft,
} from 'hooks'
import MobileSearch from './mobileSearch'
import { CATEGORY, GENERAL_USER_VISIBLE_STATUSES } from '_constants'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
} from 'contexts/CurrencySettingsContext'
import { useNavigation } from 'pages'

const IMAGE_URL =
  'https://images.unsplash.com/photo-1622624751362-328ec4aa688f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1964&q=80'
const w = window.innerWidth

const Search = ({ items, isLoading: isInitialLoading, refetch, total }) => {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()
  const queryParams = useQueryParams()
  const [isLoading, toggleLoading] = useState(isInitialLoading)
  const [filters, setFilters] = useState({
    text: queryParams.get('text'),
    category: queryParams.get('category'),
  })
  const text = queryParams.get('text')
  const { user } = useUser()

  useEffect(() => {
    toggleLoading(true)
    refetch(elasticQuery(text))
  }, [text])

  useEffect(() => {
    toggleLoading(true)
    refetch(elasticQuery(filters.text, filters.category))
  }, [filters])

  useEffect(() => {
    if (total !== 0 && !isInitialLoading) {
      toggleLoading(false)
    }
  }, [items, isInitialLoading])

  if (w < 481) {
    return (
      <MobileSearch
        filters={filters}
        setFilters={setFilters}
        projects={items}
      />
    )
  }

  return (
    <AllProductsWrapper
      type="both"
      url={IMAGE_URL}
      filters={filters}
      setFilters={setFilters}
    >
      {isLoading ? (
        <Flex minH="520px" w="full" align="center" justifyContent="center">
          <Spinner color="blue.400" />
        </Flex>
      ) : items.length > 0 ? (
        <SimpleGrid
          position="relative"
          minH="720px"
          pt="12"
          pb="20"
          w="full"
          columns={{
            base: 1,
            md: 2,
            lg: 3,
            xl: 4,
          }}
          spacing="30px"
        >
          {items.map((card) => (
            <GridItem key={`fundraiser-card-${card.id}`} colSpan={1}>
              {card.sortOrder}
              <ProjectCard  goalText={t('goal')}
                card={card}
                goalPrice={card.activeGoal?.amount || card.goal}
                raisedMoney={CurrencyConverter({
                  currency: currency.current,
                  amount: parseInt(card.amount),
                })}
                CurrencyConverter={CurrencyConverter}
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? card.amount + Number(card.updateAmount)
                    : card.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(card.updateAmount), currency.current
                )}
                SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                big={true}
                edit={user.isSuper}
                type={card.isProject ? 'fundraisers' : 'collaborations'}
                editRoute={card.isProject ? 'fundraisers' : 'collaborations'}
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en:
                    card.campaignImplementorPeriod_en,
                  fundraisingType: card.fundraisingType,
                })}
                language={language}
                currency={currency}
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
                navigationPush={navigationPush}
                user={user}
                cardStatusText={t(`status@${card.status}`)}
                cardCategoryText={t(`category@${card.category}`)}
                editText={t('edit')}
                unsubscribeText={t('unsubscribe')}
                collaborationProposalsText={t(
                  card?.collaboratorsTotal === 1
                    ? 'collaboration proposal'
                    : 'collaboration proposals'
                )}
                collaborationProposalsTextRuEdit={t('collaboration proposals')
                  .split('...')[1]
                  .replace('запросов', 'запроса')}
                monthlyGoalText={t('monthlyGoal')}
                raisedText={t('raised')}
              />
            </GridItem>
          ))}
        </SimpleGrid>
      ) : (
        <Flex
          minH="520px"
          alignItems="center"
          justifyContent="center"
          h="full"
          w="full"
        >
          <Heading as="h2" fontSize="5xl" color="blue.400">
            {t('noResults')}
          </Heading>
        </Flex>
      )}
    </AllProductsWrapper>
  )
}

const elasticQuery = (text, category) => {
  const categoryTerms = {
    terms: {
      category: category ? [category] : CATEGORY,
    },
  }
  const searchingText = text ? text.toLowerCase() : 'technology'
  return {
    size: 50,
    query: {
      bool: {
        must: [
          {
            multi_match: {
              query: searchingText,
              type: 'phrase_prefix',
              fields: [
                'region',
                'category',
                'title_en',
                'title_hy',
                'title_ru',
                'creatorName_en',
                'creatorName_hy',
                'creatorName_ru',
                'ownerName',
                'ownerLastName',
                'tagline_en',
                'tagline_hy',
                'tagline_ru',
                'content_en',
                'content_hy',
                'content_ru',
              ],
            },
          },
          {
            terms: {
              status: GENERAL_USER_VISIBLE_STATUSES,
            },
          },
          categoryTerms,
        ],
      },
    },
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `       
            if (doc['region.keyword'].value.toLowerCase().contains('${searchingText}')) {return  0}    
            else if (doc['category.keyword'].value.toLowerCase().contains('${searchingText}')) {return  1}    
            else if (doc['title_en.keyword'].size() != 0 && doc['title_en.keyword'].value.toLowerCase().contains('${searchingText}') || 
            doc['title_hy.keyword'].size() != 0 && doc['title_hy.keyword'].value.toLowerCase().contains('${searchingText}') || 
            doc['title_ru.keyword'].size() != 0 &&  doc['title_ru.keyword'].value.toLowerCase().contains('${searchingText}') ) 
            {return  2}
            else if (doc['creatorName_en.keyword'].size() != 0 && doc['creatorName_en.keyword'].value.toLowerCase().contains('${searchingText}') || 
            doc['creatorName_hy.keyword'].size() != 0 && doc['creatorName_hy.keyword'].value.toLowerCase().contains('${searchingText}') ||
            doc['ownerLastName.keyword'].size() != 0 && doc['ownerLastName.keyword'].value.toLowerCase().contains('${searchingText}') ||
            doc['ownerName.keyword'].size() != 0 && doc['ownerName.keyword'].value.toLowerCase().contains('${searchingText}') ||
            doc['creatorName_ru.keyword'].size() != 0 && doc['creatorName_ru.keyword'].value.toLowerCase().contains('${searchingText}') ) 
           {return  3}   
           else if (  doc['tagline_en.keyword'].value.toLowerCase().contains('${searchingText}') || 
           doc['tagline_hy.keyword'].value.toLowerCase().contains('${searchingText}') || 
            doc['tagline_ru.keyword'].value.toLowerCase().contains('${searchingText}') ) 
           {return  4}
           else if (
            doc['content_en.keyword'].size() != 0 && doc['content_en.keyword'].value.toLowerCase().contains('${searchingText}') || 
            doc['content_hy.keyword'].size() != 0 && doc['content_hy.keyword'].value.toLowerCase().contains('${searchingText}') || 
            doc['content_ru.keyword'].size() != 0 && doc['content_ru.keyword'].value.toLowerCase().contains('${searchingText}') ) 
           {return  5}  
          return 10;`,
          },
          order: 'asc',
        },
      },
    ],
  }
}

const withProjects = (Component) => ({ ...props }) => {
  const queryParams = useQueryParams()
  const { projects, isLoading, filters, setFilters } = useProjects(
    elasticQuery(queryParams.get('text'))
  )

  const refetch = (newFilters = elasticQuery(queryParams.get('text'))) => {
    setFilters((filters) => {
      return {
        ...filters,
        ...newFilters,
      }
    })
  }

  return (
    <Component
      {...props}
      isRefetchResponse={filters.nextToken ? true : false}
      items={projects?.items || []}
      total={projects?.total || 0}
      nextToken={projects?.nextToken}
      isLoading={isLoading}
      refetch={refetch}
    />
  )
}

export default withProjects(Search)
