import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/hy-am'
import { I18n } from 'aws-amplify'
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import { configure as configureI18n } from '../translations'
import { useLocalStorage } from '../hooks'
import { Box } from '@chakra-ui/react'

configureI18n()

const LOCAL_STORAGE_KEY = `${process.env.REACT_APP_ENV}_lang`
const DEFAULT_LANG = ['en', 'en-US']
const MOMENT_LANGUAGES = { en: 'en', ru: 'ru', hy: 'hy-am' }

export const LANGUAGES = {
  en: {
    code: 'en-US',
    currency: 'USD',
    currencySymbol: '$',
  },
  ru: {
    code: 'ru-RU',
    currency: 'RUB',
    currencySymbol: '₽',
  },
  hy: {
    code: 'hy-AM',
    currency: 'AMD',
    currencySymbol: '$',
  },
}

const getLangFromURL = (location, localStorageLang) => {
  const lang = location.pathname.split('/')[1]
  if (lang !== 'en' && lang !== 'ru' && lang !== 'hy') {
    if (!localStorageLang) {
      return ['en', LANGUAGES.en.code]
    } 
    return [localStorageLang, LANGUAGES[localStorageLang].code]
  }
  if (localStorageLang && localStorageLang === lang) {
    return [localStorageLang, LANGUAGES[localStorageLang].code]
  } else {
    return [lang, LANGUAGES[lang].code]
  }
}

export const TranslationContext = createContext({
  language: '',
  setLanguage: () => {},
  t: () => {},
})

export const useTranslation = () => useContext(TranslationContext)

export const TranslationProvider = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const { put, get } = useLocalStorage()
  const lang = getLangFromURL(location, get(LOCAL_STORAGE_KEY))
  const [appLanguage, setAppLanguage] = useState(lang || DEFAULT_LANG)

  const translate = useCallback((...args) => I18n.get(...args), [])

  const retrieveLanguage = useCallback(() => {
    const localStorageLang = get(LOCAL_STORAGE_KEY)
    if (lang) I18n.setLanguage(lang[1])
    if (!lang) {
      return history.push(
        `/${localStorageLang}${
          location.pathname === '/' ?  window.location.search : location.pathname  + window.location.search
        }`
      )
    }
  }, [appLanguage, lang])

  const setLanguage = useCallback(
    (oldLanguage, newLanguage) => {
      if (newLanguage && ['en', 'ru', 'hy'].includes(newLanguage)) {
        const I18Value = LANGUAGES[newLanguage].code
        const redirectURL =
          window.location.pathname.replace(oldLanguage, newLanguage) +
          window.location.search

        I18n.setLanguage(I18Value)
        moment.locale(MOMENT_LANGUAGES[newLanguage])
        setAppLanguage([newLanguage, I18Value])
        history.push(redirectURL)
        put(LOCAL_STORAGE_KEY, newLanguage)
      }
    },
    [setAppLanguage]
  )

  useEffect(() => {
    if (!lang) return

    I18n.setLanguage(lang[1])
    if (!get(LOCAL_STORAGE_KEY)) {
      put(LOCAL_STORAGE_KEY, lang[0])
    } else if (get(LOCAL_STORAGE_KEY) !== lang[0]) {
      put(LOCAL_STORAGE_KEY, lang[0])
    }
  }, [lang])

  useEffect(() => {
    const localStorageLang = get(LOCAL_STORAGE_KEY)

    let key = 'US'
    if (localStorageLang === 'ru' || localStorageLang === 'hy') {
      key = localStorageLang.toUpperCase()
    }
    const i18Key = `${localStorageLang}-${key}`

    setAppLanguage([localStorageLang, i18Key])
    moment.locale(MOMENT_LANGUAGES[localStorageLang])
  }, [localStorage])

  if (location.pathname === '/') {
    return <Redirect to={`/${(lang[0] || 'en')}${window.location.search}`} />
  }

  return (
    <TranslationContext.Provider
      value={{
        setLanguage,
        language: appLanguage[0] || 'en',
        retrieveLanguage,
        t: translate,
      }}
    >
      <Box>{children}</Box>
    </TranslationContext.Provider>
  )
}
