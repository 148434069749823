import React, { useState, useCallback } from 'react';
import { API } from 'aws-amplify';
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext';
import { useUser } from 'hooks';
import Web from './web'
import Mobile from './mobile'
import useCustomToast from 'hooks/useCustomToast'

const w = window.innerWidth;

const OrderGiftCard = () => {
 const toast = useCustomToast();
  const { language } = useTranslation();
  const { user } = useUser();
  const [loading, toggleLoading] = useState(false);

  const {
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
        amount: 0,
        reArmenia: 0,
        email: user.id === 'guest' ? '' : user.email,
        firstName: user.id === 'guest' ? '' : user.firstName,
        lastName: user.id === 'guest' ? '' : user.lastName,
    }
  })

  const onSubmit = useCallback(
      async (data) => {
          toggleLoading(true)
          let amount = parseInt(data.amount)
          if (data.reArmenia) {
              amount += parseInt(data.reArmenia)
          }
  
          const getFieldValue = (field) => {
              if (user.id === 'guest') {
                  if (field === 'email') return data.email.trim()
                  if (data.donateAnonymously) return 'Anonymous'
                  return data[field]
              }
              return user[field];
          }

          const description = {
              title: 'Gift Card',
              email: getFieldValue('email'),
          }
  
          const body = {
              amount: parseInt(`${amount + '00'}`),
              language: 'en',
              description: JSON.stringify(description),
              redirectUrl: `${window.location.protocol}//${window.location.host}/${language}/check-transaction`,
              transactionInfo: {
                  donationToReArmenia: parseInt(data.reArmenia),
                  giftCardAmount: parseInt(data.amount),
                  userId: user?.id ?? '',
                  email: getFieldValue('email'),
                  firstName: getFieldValue('firstName'),
                  lastName: getFieldValue('lastName'),
                  message: data.yourMessage,
                  from: data.from,
                  toEmail: data.toEmail,
                  giftCardTransactionType: 'buy',
              },
          }
  
          if (
              !body.transactionInfo.email ||
              !body.transactionInfo.firstName ||
              !body.transactionInfo.lastName
          ) {
              return toast({
                  title: 'First name, last name and email are required!',
                  status: 'error',
                  isCloseable: true,
                  duration: 5000,
              })
          }
          try {
              const data = await API.post('paymentAPI', '/payment', {
                  body,
              })
              if (user.id === 'guest') {
                  try {
                      await API.post('mailerLiteAPI', '/subscribeNews', {
                          body: {
                              email: body.transactionInfo.email,
                              language: `SUBSCRIBE_EMAIL_${language.toUpperCase()}`
                          }
                      })
                  } catch (e) {
                      console.log('>>> SUBSCRIPTION ERROR', e)
                  }
              }
              const result = JSON.parse(data.data)
              if (result.formUrl) {
                window.location = result.formUrl.replace('_binding', '')
              }
          } catch (e) {
              console.log(e)
          } finally {
              toggleLoading(false)
          }
      },
      [],
  )

  const props = {
    loading,
    register,
    donation: { amount: watch('amount'), reArmenia: watch('reArmenia') },
    errors,
    watch,
    setValue,
    onSubmit,
    handleSubmit,
  }

  if (w < 481) {
    return (
      <Mobile {...props} />
    )
  }

  return (
    <Web {...props} />
  )
}

export default OrderGiftCard
