import React from 'react'
import {
  Box
} from '@chakra-ui/react'
import Header from './Header'
import Basic from './Basic'
import BottomButtons from './BottomButtons'
import Collaboration from './Collaboration'
import Fundraiser from './Fundraiser'
import ContactInformation from './ContactInformation'

const MobileCreate = ({
  register,
  errors,
  setValue,
  goals,
  appendGoal,
  removeGoal,
  unregister,
  type,
  setType,
  oldId,
  collFields,
  collAppend,
  fundraisingType,
  setLegal,
  legal,
  getValues,
  submit,
}) => {
  return (
    <Box
      w="full"
      as="form"
      onSubmit={submit}>
      <Header/>
      <Box
        py="6"
        px="5">
        <Basic
          register={register}
          errors={errors}
          setValue={setValue}
          unregister={unregister}
          type={type}
          setType={setType}
          oldId={oldId}/>
        {
          type.collaboration && (
            <Collaboration
              register={register}
              collaborations={collFields}
              errors={errors}
              append={collAppend}
              type={type}
              setType={setType}/>
          )
        }
        {
          type.fundraising && (
            <Fundraiser
              unregister={unregister}
              register={register}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              goals={goals}
              fundraisingType={fundraisingType}
              append={appendGoal}
              remove={removeGoal}
              type={type}
              setType={setType}
              oldId={oldId}/>
          )
        }
        <ContactInformation
            register={register}
            errors={errors}
            setLegal={setLegal}
            legal={legal}
            setValue={setValue}
            getValues={getValues}
          />
        <BottomButtons
            type={type}
            setType={setType}
            oldId={oldId}/>
      </Box>
    </Box>
  )
}

export default MobileCreate
