import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import CreditCards from 'components/CreditCards'

export default function Payments() {
  const { t } = useTranslation()

  return (
    <Box mt={6} maxW="600px">
      <Heading as="h2" fontSize="xl" mb={8}>
        {t('profile@settings-payments-attached-cards')}
      </Heading>
      <CreditCards removable showIcons privacyPolicy={false} makeDefault />
      <Box my={8} height="1px" color="border.100" />
    </Box>
  )
}
