import { useQuery } from 'react-query'
import { blogUseCase } from '../../core/factories'
import { useContentful } from 'contexts/ContentfulContext'

const useGetBlog = ({ slug }) => {
  const { client } = useContentful();

  const query = useQuery(
    [`blog_${slug}`, slug],
    () => blogUseCase({ client }).getBlog(slug))

  return query
}

export default useGetBlog
