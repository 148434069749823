import { API, graphqlOperation } from 'aws-amplify'
import {
  listUpdates as listProjectUpdates,
  updatesByProjectId,
  listUpdatesByDate as listUpdatesByDateQuery,
} from '../../graphql/queries'
import {
  createUpdate as createProjectUpdate,
  deleteUpdate as deleteProjectUpdate,
  updateUpdate as updateProjectUpdate,
} from '../../graphql/mutations'

const updateService = () => {
  const createUpdate = async (input) => {
    await API.graphql(
      graphqlOperation(createProjectUpdate, {
        input: { ...input, type: 'update' },
      })
    )
  }

  const deleteUpdate = async (input) => {
    await API.graphql(graphqlOperation(deleteProjectUpdate, { input }))
  }

  const editUpdate = async (input) => {
    await API.graphql(graphqlOperation(updateProjectUpdate, { input }))
  }

  const listUpdates = async () =>
    API.graphql(graphqlOperation(listProjectUpdates, { limit: 10 }))

  const listUpdatesByDate = () =>
    API.graphql(
      graphqlOperation(listUpdatesByDateQuery, {
        limit: 10,
        sortDirection: 'DESC',
        type: 'update',
      })
    )

  const getUpdatesByProjectId = async (variables) => {
    const projects = await API.graphql(
      graphqlOperation(updatesByProjectId, variables)
    )
    return projects
  }

  return {
    createUpdate,
    deleteUpdate,
    editUpdate,
    listUpdates,
    getUpdatesByProjectId,
    listUpdatesByDate,
  }
}

export default updateService
