import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import LineClamp from 'components/LineClamp'

export default function ListView({ head = [], body = [], isLoading = false }) {
  return (
    <TableContainer w="full" position="relative" minH="500px">
      {isLoading && (
        <Flex
          height="full"
          w="full"
          align="center"
          justifyContent="center"
          position="absolute"
          background="rgba(255,255,255,0.5)"
          zIndex={10}
        >
          <Spinner color="blue.300" />
        </Flex>
      )}
      <Table variant="simple" border="1px solid #CDCDCD">
        <Thead bg="#F3F3F3">
          <Tr>
            {head.map((item, index) => (
              <Th key={`table-heading-${index}`} textAlign="center">
                {item}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {body.map((_, index) => (
            <Tr key={`table-body-tr-${index}`}>
              {body[index].map((item, index) => (
                <LineClamp
                  Component={Td}
                  key={`table-body-${index}`}
                  textAlign="center"
                >
                  {item}
                </LineClamp>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
