import { useMemo, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Flex, Box } from '@chakra-ui/react'
import { useGetProject } from 'hooks'
import { useUpdateProject } from 'hooks'
import Donate from './Donate'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import Header from './Header'

const MobileDonation = () => {
  const { projectId } = useParams()
  const {
    refetch,
    data: getProjectResponse,
    isLoading,
  } = useGetProject({ projectId })
  const {
    updateProject,
    isLoading: isUpdateLoading,
    data: updateProjectResponse,
  } = useUpdateProject()
  const history = useHistory()

  const project = useMemo(() => {
    if (updateProjectResponse) return updateProjectResponse.data.updateProject
    if (getProjectResponse) return getProjectResponse
    return null
  }, [getProjectResponse, updateProjectResponse])

  if (isLoading) {
    return <></>
  }

  return (
    <Flex align="center" justify="center" flexDirection="column" pb="3">
      <Header/>
      <Donate project={project} />
    </Flex>
  )
}

export default MobileDonation
