import { useMutation } from 'react-query'
import { userUseCase } from '../../core/factories'

const useUpdateUser = () => {
  const {
    mutate: updateUser,
    data,
    isLoading,
  } = useMutation(userUseCase.updateUser)

  return {
    updateUser,
    isLoading,
    data,
  }
}

export default useUpdateUser
