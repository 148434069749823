import {
  userRepo,
  authRepo,
  transactionRepo,
  projectRepo,
  initiativeRepo,
  imageRepo,
  hashRepo,
  filesRepo,
  settingsRepo,
  blogRepo,
  testimonialRepo,
  successStoryRepo,
  giftCardRepo,
  donationSubscriptionRepo,
  emailRepo,
  bindingCardRepo,
  updateRepo,
  donationRepo,
} from '../repositories'
import user from './userUseCase'
import auth from './authUseCase'
import transaction from './transactionUseCase'
import project from './projectUsecase'
import donationSubscription from './donationSubscriptionUseCase'
import initiative from './initiativeUsecase'
import image from './imageUseCase'
import files from './filesUseCase'
import settings from './settingsUseCase'
import blog from './blogUseCase'
import testimonial from './testimonialUseCase'
import successStory from './successStoryUseCase'
import giftCard from './giftCardUseCase'
import email from './emailUseCase'
import bindingCard from './bindingCardUseCase'
import hash from './hashUseCase'
import update from './updateUseCase'
import donation from './donationUseCase'
import {
  authService,
  userService,
  transactionService,
  projectService,
  inititativeService,
  imageService,
  hashService,
  filesService,
  settingsService,
  contentfulService,
  giftCardService,
  donationSubscriptionService,
  emailService,
  bindingCardService,
  updateService,
} from '../services'
import donationService from 'core/services/donationService'

export const hashRepository = hashRepo(hashService())
const imageRepository = imageRepo(imageService())
const donationSubscriptionRepository = donationSubscriptionRepo(
  donationSubscriptionService()
)

export const hashUseCase = hash(hashRepo(hashService()))

export const settingsUseCase = settings(settingsRepo(settingsService()))

export const blogUseCase = ({ client }) =>
  blog(blogRepo(contentfulService({ client })))

export const testimonialUseCase = ({ client }) =>
  testimonial(testimonialRepo(contentfulService({ client })))

export const successStoryUseCase = ({ client }) =>
  successStory(successStoryRepo(contentfulService({ client })))

export const userUseCase = user(userRepo(userService()))

export const authUseCase = auth(
  authRepo(authService()),
  userRepo(userService()),
  emailRepo(emailService())
)

export const transactionUseCase = transaction(
  transactionRepo(transactionService())
)

export const giftCardUseCase = giftCard(
  giftCardRepo(giftCardService()),
  transactionRepo(transactionService())
)

export const projectUseCase = project(
  projectRepo(projectService()),
  imageRepository,
  donationSubscriptionRepository
)

export const bindingCardUseCase = bindingCard(
  bindingCardRepo(bindingCardService())
)

export const donationSubscriptionUseCase = donationSubscription(
  donationSubscriptionRepo(donationSubscriptionService()),
  projectUseCase
)

export const initiativeUseCase = initiative(
  initiativeRepo(inititativeService()),
  imageRepository,
  hashRepository
)

export const donationUseCase = donation(donationRepo(donationService()))

export const imageUseCase = image(imageRepository)

export const filesUseCase = files(filesRepo(filesService()))

export const emailUseCase = email(emailRepo(emailService()))

export const updateUseCase = update(updateRepo(updateService()))
