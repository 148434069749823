import React, { useCallback, useState, useMemo } from 'react'
import { API } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import {
  Text,
  Box,
  VStack,
  Flex,
  Checkbox,
  Button,
  Img,
} from '@chakra-ui/react'
import { RadioGroup } from './RadioGroup'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { useValidateEmail, useUser } from 'hooks'
import { InputField } from '../InputField'
import useCustomToast from 'hooks/useCustomToast'

const PaymentForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  const { t, language } = useTranslation()
  const toast = useCustomToast()
  const validateEmail = useValidateEmail()
  const [amount, setAmount] = useState('More')
  const [loading, setLoading] = useState(false)
  const { user } = useUser()

  const price = useMemo(
    () => ({
      '2,000 AMD': '2,000 AMD',
      '5,000 AMD': '5,000 AMD',
      '10,000 AMD': '10,000 AMD',
      more: t('other'),
    }),
    []
  )

  const handleAmount = (e) => {
    if (e === '2,000 AMD') {
      e = 2000
    } else if (e === '5,000 AMD') {
      e = 5000
    } else if (e === '10,000 AMD') {
      e = 10000
    }
    setAmount(e)
  }

  const submit = useCallback(
    async (data) => {
      if (!!data.email && !validateEmail(data.email)) {
        return toast({
          title: 'Invalid email format',
          status: 'error',
          isCloseable: true,
          duration: 5000,
        })
      }
      data.amount = amount
      if (amount === 'more') data.amount = data.more
      const inputAmount = parseInt(data.amount)

      const body = {
        amount: parseInt(`${inputAmount + '00'}`),
        language: 'en',
        description: `Donation for Armenian Peace,${
          user.id === 'guest' ? data.email : user.email
        }`,
        redirectUrl: `${window.location.protocol}//${window.location.host}/${language}/check-transaction`,
        transactionInfo: {
          projectId: 'transaction-to-reArmenia',
          projectTitle: 'reArmenia',
          donationToProject: 0,
          donationToReArmenia: parseInt(data.amount),
          userId: user?.id ?? '',
          email: user.id === 'guest' ? data.email.trim() : user?.email,
          firstName: user.id === 'guest' ? data.firstName : user?.firstName,
          lastName: user.id === 'guest' ? data.lastName : user?.lastName,
        },
      }

      if (
        !body.transactionInfo.email ||
        !body.transactionInfo.firstName ||
        !body.transactionInfo.lastName
      ) {
        return toast({
          title: 'First name, last name and email are required!',
          status: 'error',
          isCloseable: true,
          duration: 5000,
        })
      }
      setLoading(true)

      try {
        const data = await API.post('paymentAPI', '/payment', {
          body,
        })

        const result = JSON.parse(data.data)
        setLoading(false)
        if (result.formUrl) {
          window.location = result.formUrl.replace('_binding', '')
        }
      } catch (e) {
        console.log(e)
      }
    },
    [language, amount]
  )

  return (
    <form onSubmit={handleSubmit(submit)}>
      <VStack spacing="30px" width="full" px="1">
        <Text fontSize="3xl" fontWeight="bold" mb="5" pt="2">
          {t('AP has a 0% platform fee for project creators')}
        </Text>
        <Text fontSize="lg" fontWeight="400" mb="1.875rem">
          {t(
            'AP will continue offering its services thanks to donors who will leave an optional amount here'
          )}
        </Text>
        <RadioGroup
          defaultValue="More"
          width="100%"
          name="amount"
          options={price}
          onChange={handleAmount}
          mb="1.875rem"
        />
        {amount === 'more' && (
          <InputField
            borderRadius="12px"
            label={t('payment@currency')}
            errorMessage={t('payment@amountRequired')}
            isInvalid={!!errors.more}
            pattern="[0-9]*"
            {...register('more', { required: true, pattern: /[0-9]/ })}
          />
        )}

        {user.id === 'guest' && (
          <>
            <VStack
              py="1.875rem"
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor="border.100"
              direction="column"
              w="full"
              spacing="6"
            >
              <InputField
                borderRadius="12px"
                label={t('payment@firstName')}
                errorMessage={t('payment@firstNameErrorMessage')}
                isInvalid={!!errors.firstName}
                {...register('firstName', { required: true })}
              />
              <InputField
                borderRadius="12px"
                label={t('payment@lastName')}
                errorMessage={t('payment@lastNameErrorMessage')}
                isInvalid={!!errors.lastName}
                {...register('lastName', { required: true })}
              />
              <InputField
                borderRadius="12px"
                label={t('payment@email')}
                errorMessage={t('payment@emailErrorMessage')}
                isInvalid={!!errors.email}
                {...register('email', { required: true })}
              />
            </VStack>
          </>
        )}
        <Checkbox
          my="1.875rem"
          isInvalid={!!errors.terms}
          {...register('terms', { required: true })}
        >
          <Box fontSize="sm" as="span">
            {t('payment@agreeToThe')}
            <Box zIndex="10" color="blue.400" as="span">
              <Link target="_blank" to="/terms-and-conditions">
                {' '}
                {t('payment@terms')}
              </Link>
            </Box>
            {t('payment@and')}
            <Box color="blue.400" as="span">
              <Link target="_blank" to="/privacy-policy">
                {' '}
                {t('payment@privacyPolicy')}
              </Link>
            </Box>
          </Box>
        </Checkbox>
        <Flex
          py="1.875rem"
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="border.100"
          w="full"
          alignItems="center"
          flexDirection="column"
          pb="6rem"
        >
          <Box minH="20px">
            <Img height="20px" src="/assets/images/cards.png" alt="cards" />
          </Box>
          <Text mt="6" mb="4" fontSize="sm">
            {t('payment@allRightsReserved').replace(
              '2023',
              new Date().getFullYear()
            )}
          </Text>
          {/* <Text mb="4" fontWeight="bold" fontSize="sm">
            {t('payment@weProtect')}
          </Text> */}
          {/* <Box minH="40px">
            <Img
              maxH="40px"
              src="/assets/images/security.jpeg"
              alt="security"
            />
          </Box> */}
        </Flex>
        <Flex
          zIndex="1000"
          bg="gray.100"
          w="full"
          bottom="0"
          left="0"
          borderTop="1px solid"
          borderColor="border.100"
          justifyContent="space-between"
          pt="3"
          pb="2.125rem"
          px="3"
          position="fixed"
        >
          <Button isLoading={loading} w="full" type="submit" variant="green">
            {t('donation@donate')}
          </Button>
        </Flex>
      </VStack>
    </form>
  )
}

export default PaymentForm
