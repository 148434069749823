import React from 'react'
import { HStack, Flex, Heading } from '@chakra-ui/react'
import { ReactComponent as Settings } from 'assets/icons/settings.svg'
import { ReactComponent as Back } from '../../../assets/icons/back.svg'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'

const Header = () => {
  const { t } = useTranslation()
  return (
    <Flex
      position="relative"
      borderBottom="1px solid"
      borderColor="border.100"
      alignItems="center"
      justifyContent="center"
      py="12px"
    >
      <Flex
        justifyContent="center"
        position="absolute"
        alignItems="center"
        left="12px"
        as={Link}
        to="/more"
        minW="30px"
        minH="30px"
      >
        <Back width="10px" height="20px" />
      </Flex>
      <Heading fontSize="3xl" as="h2">{t('nav@profile')}</Heading>
    </Flex>
  )
}

export default Header
