import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Heading,
  Button,
  HStack,
  Stack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { BiImage } from 'react-icons/bi'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { ReactComponent as Add } from 'assets/icons/add.svg'
import FileUpload from 'components/FileUpload'
import { useProject } from '../ProjectContext'
import BottomBar from '../BottomBar'
import useCustomToast from 'hooks/useCustomToast'

const Rewards = () => {
  const { project, onUpdate } = useProject()
  const { t } = useTranslation()
 const toast = useCustomToast()
  const [perks, setPerks] = useState([])
  const [isLoading, toggleLoading] = useState(false)

  const onChange = (key, value, id) => {
    const newPerks = perks.map((item) => {
      if (item.id === id) {
        item = { ...item, [key]: value }
      }
      return item
    })
    setPerks(newPerks)
  }

  const validatePerksImage = (project) => {
    let isValid = true
    if (project.perks) {
      for (const perk of project.perks) {
        if (!perk.image) isValid = false
      }
    }

    return isValid
  }

  const addPerk = () => {
    const newPerks = [
      ...perks,
      {
        id: uuid(),
        title: '',
        image: '',
        price: 0,
      },
    ]
    setPerks(newPerks)
  }

  const removePerk = (id) => {
    setPerks(perks.filter((item) => item.id !== id))
  }

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault()
      const values = {
        id: project.id,
        perks,
      }
      values.perks = perks?.map((e) => ({
        id: e.id ?? '',
        title_en: e.title_en ?? '',
        title_hy: e.title_hy ?? '',
        title_ru: e.title_ru ?? '',
        price: e.price ?? 0,
        image: e.image ?? '',
      }))
      if (!validatePerksImage(values)) {
        return toast({
          title: t('createEditProject@rewardsImageRequired'),
          status: 'error',
          isClosable: true,
          duration: 5000,
        })
      }

      onUpdate(values)
    },
    [project, perks]
  )

  useEffect(() => {
    setPerks(project.perks || [])
  }, [project])

  return (
    <form onSubmit={onSubmit}>
      <Heading mb="3" fontSize="5xl" as="h2">
        {t('project@rewards')}
      </Heading>
      <HStack spacing="8">
        <Box fontSize="xl" as="span">
          {t('createEditProject@addNewReward')}
        </Box>
        <Button
          isDisabled={isLoading}
          onClick={addPerk}
          minH="28px"
          height="28px"
          w="28px"
          minW="28px"
          bg="blue.400"
          borderRadius="100%"
          variant="transparent"
        >
          <Add />
        </Button>
      </HStack>
      <Stack maxW="474px" my="8" spacing="8">
        {perks?.map((perk, index) => (
          <Stack
            key={`title-${perk.id}-index-${index}`}
            py="8"
            borderTop="1px solid"
            borderColor="border.100"
            spacing="8"
          >
            <InputField
              char={perk.title_en?.length}
              maxLength="100"
              value={perk.title_en}
              onChange={(e) => onChange('title_en', e.target.value, perk.id)}
              label={t('English title')}
            />
            <InputField
              char={perk.title_hy?.length}
              maxLength="100"
              value={perk.title_hy}
              onChange={(e) => onChange('title_hy', e.target.value, perk.id)}
              label={t('Armenian title')}
            />
            <InputField
              char={perk.title_ru?.length}
              maxLength="100"
              value={perk.title_ru}
              onChange={(e) => onChange('title_ru', e.target.value, perk.id)}
              label={t('Russian title')}
            />
            <FormControl position="relative">
              <FormLabel
                whiteSpace="nowrap"
                fontSize="sm"
                bg="white"
                zIndex={2}
                fontWeight="regular"
                px="2"
                color="black"
              >
                {t('createEditProject@rewardPrice')}
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  pt="2"
                  pointerEvents="none"
                  color="gray.700"
                  fontSize="sm"
                  children={"AMD"}
                />
                <Input
                  _focus={{ borderColor: 'blue.300' }}
                  _hover={{ borderColor: 'blue.300' }}
                  sx={{ boxShadow: 'none !important' }}
                  value={perk.price}
                  onChange={(e) =>
                    onChange(
                      'price',
                      e.target.value.replace(/[^0-9.]/g, ''),
                      perk.id
                    )
                  }
                  borderColor="border.100"
                  pattern="[0-9]*"
                  size="lg"
                  fontSize="lg"
                />
              </InputGroup>
            </FormControl>
            <FileUpload
              isLoading={isLoading}
              toggleLoading={toggleLoading}
              accept={'image/*'}
              imageUrl={perk.image}
              onUpload={async (key) => {
                const newPerks = []
                // @ts-ignore
                for (const [i, item] of perks.entries()) {
                  if (i === index) {
                    item.image = key
                  }
                  newPerks.push(item)
                }

                setPerks(newPerks)
              }}
            >
              <HStack spacing="2" align="center">
                <BiImage size={20} />
                <Box
                  fontSize="sm"
                  cursor="pointer"
                  ml="2"
                  as="span"
                  color="black"
                  fontWeight="regular"
                >
                  {t('createEditProject@uploadImage')}
                </Box>
              </HStack>
            </FileUpload>
            <Button
              onClick={() => removePerk(perk.id)}
              color="red"
              variant="transparent"
            >
              {t('remove')}
            </Button>
          </Stack>
        ))}
      </Stack>
      <BottomBar />
    </form>
  )
}
export default Rewards
