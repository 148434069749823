import React, { useState, useEffect } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  VKShareButton,
} from 'react-share'
import { Box, Heading, Text, HStack, Button } from '@chakra-ui/react'
import UserInfo from 'components/UserInfo'
import { useInitiative } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as FB } from 'assets/icons/transparentFacebook.svg'
import { ReactComponent as Linkedin } from 'assets/icons/transparentLinkedin.svg'
import { ReactComponent as Twitter } from 'assets/icons/transparentTwitter.svg'
import { ReactComponent as VK } from 'assets/icons/transparentVk.svg'
import { ReactComponent as Copy } from 'assets/icons/copy.svg'
import useCustomToast from 'hooks/useCustomToast'

const Information = ({ initiative }) => {
  const [sharingLink, setShareLink] = useState('')

  const toast = useCustomToast()

  const { owner, implementor } = useInitiative()
  const { t, language } = useTranslation()

  async function share() {
    if ('clipboard' in navigator) {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return await navigator.clipboard.writeText(
        `${process.env.REACT_APP_BASE_URL}/${language}/collaborations/${
          initiative.slug || initiative.id
        }`
      )
    } else {
      toast({
        title: t('copied'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      return document.execCommand(
        'copy',
        true,
        `${process.env.REACT_APP_BASE_URL}/${language}/collaborations/${
          initiative.slug || initiative.id
        }`
      )
    }
  }

  useEffect(() => {
    if (!initiative) return
    setShareLink(
      `${process.env.REACT_APP_BASE_URL}/${language}/collaborations/${
        initiative.slug || initiative.id
      }`
    )
  }, [])

  return (
    <>
      <Box
        mb="3"
        fontSize="sm"
        textTransform="uppercase"
        fontWeight="bold"
        color="blue.400"
        as="span"
      >
        {t(`category@${initiative.category}`)}
      </Box>
      <Heading mb="0.5625rem" fontSize="3xl" as="h1">
        {initiative[`title_${language}`]
          ? initiative[`title_${language}`]
          : initiative.applicationTitle}
      </Heading>
      <Text mb="5" fontSize="lg" opacity="0.6">
        {initiative[`tagline_${language}`]}
      </Text>
      {
        owner && (
          <UserInfo
            image={initiative.initiatorImage}
            name={initiative[`initiatorName_${language}`]}
          />
        ) /* FIXME: no info info="1 projects ・1 initiatives  |  Yerevan, Armenia" */
      }
      {implementor && (
        <>
          <Box my="5" bg="border.100" height="1px" w="full" />
          <UserInfo
            name={`${implementor.firstName} ${implementor.lastName}`}
            info={t('implementor')}
          />
        </>
      )}
      {/* {initiative && initiative?.collaboratorsTotal && (
        <>
          <Box fontSize="sm" mt="6" color="gray.700">
            {initiative.collaboratorsTotal}{' '}
            {initiative.collaboratorsTotal > 1
              ? t('initiative@collaborators')
              : t('collaborator')}
          </Box>
        </>
      )} */}
      <Box
        mt="7"
        mb="7"
        border="1px solid"
        borderRadius="16px"
        borderColor="border.100"
        py="6"
        px="8"
      >
        <Box
          mb="3"
          fontSize="lg"
          fontWeight="medium"
          as="span"
          textTransform="capitalize"
        >
          {t('initiative@aboutCollaboration')}
        </Box>
        <Text color="gray.700" fontSize="sm">
          {t('initiative@aboutCollaborationDetails')}
        </Text>
      </Box>
      <HStack mb="10" spacing="4" alignItems="center">
        <Button w="20px" onClick={share}>
          <Copy />
        </Button>
        <FacebookShareButton
          width="30px"
          url={sharingLink}
          description={'Armenian Peace'}
        >
          <FB />
        </FacebookShareButton>
        <TwitterShareButton url={sharingLink} description={'Armenian Peace'}>
          <Twitter />
        </TwitterShareButton>
        <LinkedinShareButton url={sharingLink} description={'Armenian Peace'}>
          <Linkedin />
        </LinkedinShareButton>
        <VKShareButton url={sharingLink} description={'Armenian Peace'}>
          <VK />
        </VKShareButton>
      </HStack>
    </>
  )
}

export default Information
