import React, { useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'
import { useCustomToast, useEditUpdate, useEditUpdateWithDate } from 'hooks'
import UpdateEditor from 'components/UpdateEditor'
import useEmptyUpdate from 'components/UpdateEditor/useEmptyUpdate'
import useCreateUpdate from 'hooks/update/useCreateUpdate'

function getStringFromHtml(strHtml) {
  const span = document.createElement('span')
  span.innerHTML = strHtml
  return span.textContent || span.innerText
}

const EditUpdateModal = ({
  onClose,
  isOpen,
  update,
  refetchUpdates,
  createdAt,
  action,
  projectId,
}) => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const generateEmptyUpdate = useEmptyUpdate()
  const updateRef = useRef(update)
  const { mutate: editUpdate, isLoading: isEditLoading } = useEditUpdate()
  const { mutate: editUpdateWithDate, isLoading: isEditWDateLoading } =
    useEditUpdateWithDate()
  const { mutate: createUpdate, isLoading: isCreateLoading } = useCreateUpdate()

  const onUpdate = () => {
    const input = updateRef.current
    delete input.formattedDate
    delete input.fromNow
    if (
      input.data_en.includes(';base64,') ||
      input.data_ru.includes(';base64,') ||
      input.data_hy.includes(';base64,')
    ) {
      return toast({ status: 'error', title: 'Invalid content' })
    }
    if (
      !(
        getStringFromHtml(input.data_en).length &&
        getStringFromHtml(input.data_ru).length &&
        getStringFromHtml(input.data_hy).length
      )
    ) {
      return toast({
        status: 'error',
        title: 'Please fill updates in 3 languages',
      })
    }
    if (action === 'create') {
      input.projectId = projectId
      createUpdate(input, {
        onSuccess: (d) => {
          toast({ status: 'success', title: t('update@publishSuccessMessage') })
          refetchUpdates()
          onClose()
        },
        onError: (err) => {
          toast({ status: 'error', title: 'Something went wrong' })
          console.log(err)
        },
      })
      return
    }

    const [oldCreatedAt, nextDate] = [
      new Date(createdAt).toISOString(),
      new Date(updateRef.current.createdAt).toISOString(),
    ]

    if (oldCreatedAt !== nextDate) {
      editUpdateWithDate(
        { oldCreatedAt, ...input },
        {
          onSuccess: (d) => {
            toast({ status: 'success', title: t('update@editSuccessMessage') })
            refetchUpdates()
            onClose()
          },
          onError: (err) => {
            toast({ status: 'error', title: 'Something went wrong' })
            console.log(err)
          },
        }
      )
      return
    }

    editUpdate(input, {
      onSuccess: (d) => {
        toast({ status: 'success', title: t('update@editSuccessMessage') })
        refetchUpdates()
        onClose()
      },
      onError: (err) => {
        toast({ status: 'error', title: 'Something went wrong' })
        console.log(err)
      },
    })
  }

  useEffect(() => {
    updateRef.current = update || generateEmptyUpdate()
  }, [update])

  const isLoading = isEditLoading || isEditWDateLoading || isCreateLoading
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      isCentered={window.innerHeight > 730}
    >
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalHeader>
          <Heading fontWeight="bold" fontSize="lg">
            {action === 'create'
              ? t('update@creatingTitle')
              : t('update@editingTitle')}
          </Heading>
        </ModalHeader>
        <ModalCloseButton onClick={onClose} id="edit_update_modal_close" />
        <ModalBody pb={4}>
          <UpdateEditor update={update} updateRef={updateRef} />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} id="edit_update_modal_discard">
            {t('update@discard')}
          </Button>
          <Button
            variant="transparent"
            ml={4}
            color="green.300"
            isLoading={isLoading}
            onClick={onUpdate}
            id="edit_update_modal_save"
          >
            {action === 'create' ? t('update@publish') : t('update@save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditUpdateModal
