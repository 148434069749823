import { Auth } from 'aws-amplify'
import {
  Box,
  Button,
  Stack,
  Checkbox,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { Link} from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { COUNTRY_LIST} from '_constants'
import { Select } from 'components/Select'
import { PasswordField } from 'components/PasswordField'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'

export const SignupForm = ({
  register,
  errors,
  handleSubmit,
  onSubmit,
  validateEmail,
  country,
  setCountry,
  phone,
  setPhone,
  phoneError,
  loading,
  password,
}) => {
  const { t } = useTranslation()
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex justifyContent="center" mb={8}>
        <IconButton
          onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
          p="0"
          mx={3}
          icon={<Facebook />}
        />
        <IconButton
          onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
          p="0"
          mx={3}
          icon={<Google />}
        />
      </Flex>
      <Box
        as="span"
        display="block"
        textAlign="center"
        textTransform="uppercase"
        fontSize="md"
        color="gray.700"
        letterSpacing="0.05px"
        mb={6}
      >
        {t('or')}
      </Box>
      <Stack pb="4" spacing="6">
        <Flex direction={{ base: 'column', sm: 'row' }}>
          <Box w="full" mr={{ base: '0', sm: '8' }} mb={{ base: '8', sm: '0' }}>
            <InputField
              size="lg"
              errorMessage={t('signUp@firstNameErrorMessage')}
              label={t('signUp@firstName')}
              isInvalid={!!errors.firstName}
              {...register('firstName', { required: true })}
            />
          </Box>
          <InputField
            size="lg"
            errorMessage={t('signUp@lastNameErrorMessage')}
            label={t('signUp@lastName')}
            isInvalid={!!errors.lastName}
            {...register('lastName', { required: true })}
          />
        </Flex>
        <InputField
          size="lg"
          isInvalid={!!errors.email}
          errorMessage={t('signUp@emailErrorMessage')}
          label={t('signUp@email')}
          type="email"
          {...register('email', {
            required: true,
            validate: (value) => validateEmail(value),
          })}
        />
        <Flex direction={{ base: 'column', sm: 'row' }}>
          {/* <SelectField
                        size="lg"
                        type="country"
                        display={{base: 'none', sm: 'none'}}
                        errorMessage={t('signUp@countryErrorMessage')}
                        label={t('signUp@country')}
                        placeholder={t('signUp@country')}
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        options={COUNTRY_OBJECTS} /> */}
          <Select
            size="lg"
            type="country"
            autoComplete="off"
            mb={{ base: '8', sm: '0' }}
            display={{ base: 'block', sm: 'block' }}
            errorMessage={t('signUp@countryErrorMessage')}
            label={t('signUp@country')}
            placeholder={t('signUp@country')}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            options={COUNTRY_LIST}
          />
          <Box w="full" ml={{ base: '0', sm: '8' }}>
            <InputField
              autoComplete="off"
              size="lg"
              value={phone}
              pattern="[0-9+]*"
              isInvalid={phoneError}
              onChange={(e) =>
                setPhone(e.target.value.replace(/[^0-9+]/g, ''))
              }
              errorMessage={t('signUp@phoneNumberErrorMessage')}
              label={t('signUp@phoneNumber')}
            />
          </Box>
        </Flex>
        <Flex direction={{ base: 'column', sm: 'row' }}>
          <Box w="full" mr={{ base: '0', sm: '8' }} mb={{ base: '8', sm: '0' }}>
            <PasswordField
              size="lg"
              isInvalid={!!errors.password}
              {...register('password', { required: true })}
              errorMessage={t('signUp@passwordErrorMessage')}
              label={t('signUp@password')}
            />
          </Box>
          <PasswordField
            size="lg"
            isInvalid={!!errors.confirm_password}
            {...register('confirm_password', {
              required: true,
              validate: (value) => value === password.current,
            })}
            errorMessage={t('signUp@confirmPasswordErrorMessage')}
            label={t('signUp@confirmPassword')}
          />
        </Flex>
        <Flex direction="column">
          <Checkbox
            {...register('terms_and_conditions', { required: true })}
            isInvalid={!!errors.terms_and_conditions}
            mb="2"
          >
            {t('signUp@agreeWith')}&nbsp;
            <Link
              style={{ color: '#ED8B00' }}
              target="_blank"
              to="/terms-and-conditions"
            >
              {t('signUp@termsConditions')}
            </Link>
          </Checkbox>
          <Checkbox
            {...register('privacy_policy')}
            isInvalid={!!errors.terms_and_conditions}
          >
            {t('signUp@haveRead')}&nbsp;
            <Link
              style={{ color: '#ED8B00' }}
              target="_blank"
              to="/privacy-policy"
            >
              {t('signUp@privacyPolicy')}
            </Link>
          </Checkbox>
        </Flex>
        <Button
          isLoading={loading}
          mb={{ base: '4', md: '0' }}
          w={{ base: 'full', md: 'auto' }}
          type="submit"
          colorScheme="yellow"
          size="lg"
          variant="blue"
          fontSize="md"
          fontWeight="bold"
        >
          {t('signUp@submit')}
        </Button>
      </Stack>
    </form>
  )
}
