import React from 'react'
import { useState, useCallback } from 'react'
import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Box,
  Input,
  Button,
  Text,
  Flex,
  Stack,
} from '@chakra-ui/react'
import { useNavigation } from 'pages'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import {
  SUPPORTED_CURRENCY_SYMBOLS,
  thousandSeparator,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import PerkCard from 'components/PerkCard'
import { useUtm } from 'hooks'

const Donate = ({ project }) => {
  const [amount, setAmount] = useState()
  const location = useLocation()
  const param = useUtm(location)
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const newDate = new Date()
  const { currency, settings } = useCurrencySettings()
  const [showInputZero, setShowInputZero] = useState(false)

  const onSelect = useCallback(
    (perkIndex) => {
      let query = param ? param + '&' : '?'
      const currentCurrency =
        currency.current !== 'AMD' ? settings.currency[currency.current] : 1
      const perkPrice = (
        project.perks[perkIndex].price / currentCurrency
      ).toFixed(2)
      navigationPush(
        `/donation${query}perkIndex=${perkIndex}&projectId=${project.id}&amount=${perkPrice}`
      )
    },
    [project, currency]
  )

  const onSubmit = useCallback(() => {
    if (!amount) return
    let query = param ? param + '&' : '?'
    navigationPush(`/donation${query}amount=${amount}&projectId=${project.id}`)
  }, [amount])

  return (
    <Box pt="30px" px="5">
      <FormControl
        onKeyDown={(e) => {
          if (e.key === 'Enter' && amount) {
            return onSubmit()
          }
        }}
      >
        <FormLabel
          fontSize="xl"
          fontWeight="600"
          color="black"
          bg="gray.100"
          zIndex={2}
        >
          {t('donation@enterDonation')}
        </FormLabel>
        <Flex flexDirection="column" align="center">
          <InputGroup
            _focus={{ borderColor: 'blue.300' }}
            _hover={{ borderColor: 'blue.300' }}
            sx={{ boxShadow: 'none !important' }}
            // mr="2"
            mb="4"
            alignItems="center"
          >
            <InputLeftElement
              top="unset"
              width="100px"
              left="16px"
              pointerEvents="none"
              children={
                <Box as="span" fontSize="3xl" fontWeight="bold">
                  {currency.current}
                </Box>
              }
            />
            <Input
              borderColor="border.100"
              borderRadius="12px"
              _focus={{
                borderColor: 'blue.300',
              }}
              _hover={{ borderColor: 'blue.300' }}
              sx={{ boxShadow: 'none !important' }}
              pl="6.5rem"
              textAlign="right"
              fontWeight="extrabold"
              fontSize="6xl"
              height="86px"
              maxLength="12"
              value={
                showInputZero
                  ? ''
                  : thousandSeparator(amount, currency.current, false)
              }
              onChange={(e) => {
                setShowInputZero(!e.target.value)
                setAmount(e.target.value.replace(/[^0-9]|^0+(?!$)/g, ''))
              }}
              onFocus={(e) => setShowInputZero(e.target.value === '0')}
              onBlur={() => setShowInputZero(false)}
              pattern="[0-9,]*"
            />
          </InputGroup>
          <Button
            w="full"
            minH="40px"
            _hover={{
              backgroundColor: '#26CB75',
              _disabled: {
                backgroundColor: '#CDCDCD',
              },
            }}
            _disabled={{
              backgroundColor: '#CDCDCD',
            }}
            isDisabled={!amount}
            variant="green"
            onClick={onSubmit}
          >
            {t('donation@donate')}
          </Button>
        </Flex>
      </FormControl>
      <Text
        display={project?.perks?.length > 0 ? 'inline-block' : 'none'}
        mt="10"
        mb="2"
        fontSize="xl"
        fontWeight="600"
      >
        {t('donation@orSelectReward')}
      </Text>
      <Stack spacing="4">
        {project.perks?.map((perk, index) => (
          <PerkCard
            key={`donation-modal-title-${perk.title}-${index}`}
            image={perk.imageKey ?? perk.image}
            title={perk[`title_${language}`] ?? ''}
            price={perk.price ?? 0}
            date={newDate}
            onSelect={() => onSelect(index)}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default Donate
