import React from 'react'
import { v4 as uuid } from 'uuid'
import {
  Stack,
  Box,
  Heading,
  Text,
  Flex,
  useRadioGroup,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import Goals from 'components/GoalsForm'
import RadioCard from './RadioCard'
import FilesUpload from '../BudgetFiles'

const Fundraiser = ({
  register,
  unregister,
  errors,
  setValue,
  goals,
  fundraisingType,
  append,
  remove,
  oldId,
  getValues,
}) => {
  const { t } = useTranslation()
  const options = ['oneTime', 'recurring']

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'fundraisingType',
    defaultValue: 'oneTime',
    onChange: (e) => {
      if (e === 'recurring') {
        setValue('requestedAmount', null)
        setValue('projectTeam', '')
        unregister('requestedAmount')
      }

      const newGoals = e === 'oneTime' ? [] : [{ amount: '', description_en: '', description_ru: '', description_hy: '' }]
      setValue('goals', newGoals)
      setValue('fundraisingType', e)
    },
  })
  const group = getRootProps()
  return (
    <Box
      maxW="600px"
      width="100%"
      mb={10}>
      <Heading mb="3" fontSize="5xl" as="h2">
        {t('Add Fundraising')}
      </Heading>
      {!!oldId && (
        <Text mb="8" color="gray.100" fontSize="md">
          {t('Collaboration ID:')} {oldId}
        </Text>
      )}
      <Stack spacing="6">
        <Flex
          alignItems="center"
          border="1px solid"
          borderColor="border.100"
          borderRadius="7px"
          w="full"
          spacing="0"
          overflow="hidden"
          {...group}
        >
          {options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                {t(value)}
              </RadioCard>
            )
          })}
        </Flex>
        {fundraisingType === 'oneTime' ? (
          <>
            <InputField
              pattern="[0-9]*"
              isInvalid={!!errors.requestedAmount}
              type="number"
              label={t('createEditProject@requestedAmount')}
              errorMessage={t('createEditProject@requestedAmountErrorMessage')}
              {...register('requestedAmount', {
                required: true,
                valueAsNumber: true,
              })}
            />
            <Goals
              goals={goals}
              errors={errors}
              remove={remove}
              register={register}
              append={append}
              getValues={getValues}
            />
            <InputField
              isInvalid={!!errors.projectTeam}
              errorMessage={t(
                'createEditProject@teamBehindProjectErrorMessage'
              )}
              label={t('createEditProject@teamBehindProject')}
              {...register('projectTeam', { required: true })}
            />
            <FilesUpload register={register} errors={errors} setValue={setValue} getValues={getValues} />
          </>
        ) : (
          <Goals
            goals={goals}
            errors={errors}
            remove={remove}
            register={register}
            append={append}
            getValues={getValues}
          />
        )}
      </Stack>
    </Box>
  )
}

export default Fundraiser
