import { useEffect, useState } from 'react'
import {
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Box,
  Input,
  Text,
} from '@chakra-ui/react'
import { RadioGroup } from './RadioGroup'
import { useTranslation } from 'contexts/TranslationContext'
import PerkCard from 'components/PerkCard'
import OneTimeRecurring from 'pages/Donation/OneTimeRecurring'
import Progress from 'components/Progress'
import { projectProgress, calculateProjectDurationLeft } from 'hooks'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  SUPPORTED_CURRENCIES,
} from 'contexts/CurrencySettingsContext'
import { BlueSelect } from 'components/Select/BlueSelect'

export default function Web({
  PRICE,
  donation,
  setValue,
  getValues,
  other,
  perk,
  handleAmount,
  project,
  watch,
  isContribution,
}) {
  const { t, language } = useTranslation()
  const [showInputZero, setShowInputZero] = useState(false)
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()

  const newProject = {
    ...project,
    amount: donation.amount,
  }
  const selectOption = (option) => {
    changeDefaultCurrency(option)
    setValue('amount', 0, '')
  }
  const progressData = projectProgress(newProject, currency?.current)
  useEffect(() => {
    if (!project) return
    project.hideStats = true
  }, [])

  return (
    <Stack w="full" mt="8" spacing="8">
      <Box w="full">
        {perk !== null ? (
          <>
            <Box mb="1" as="span" fontWeight="500" fontSize="xl">
              {t('donation@perk')}
            </Box>
            <PerkCard
              title={perk[`title_${language}`] ?? ''}
              price={perk.price ?? 0}
              image={perk.imageKey ?? perk.image}
              date={new Date()}
              isSelectable={false}
              convertPrice={false}
            />
          </>
        ) : (
          <>
            <FormControl>
              <FormLabel
                fontSize="xl"
                fontWeight="semibold"
                color="black"
                bg="gray.100"
                zIndex={2}
              >
                {t(`donation@${getValues('paymentType')}@inputLabel`)}
                {currency.current !== 'AMD' && (
                  <Text fontSize="sm" fontWeight="medium">
                    {t('donation@note')}{' '}
                  </Text>
                )}
              </FormLabel>
              <InputGroup alignItems="center">
                <InputLeftElement
                  top="unset"
                  width="100px"
                  ml={4}
                  children={
                    <BlueSelect
                      variant="big"
                      display="flex"
                      type="currency"
                      amount={+progressData.amount}
                      label={currency.current}
                      options={SUPPORTED_CURRENCIES.map((option) => ({
                        title: t(`currency@${option}`),
                        amount: `${CurrencyConverter({
                          currency: option,
                          amount: +progressData.amount,
                        })} ${option}`,
                        value: option,
                      }))}
                      selectOption={selectOption}
                      currentSelectedOption={t(`currency@${currency.current}`)}
                    />
                  }
                />
                <Input
                  _focus={{
                    boxShadow: 'none',
                    color: 'black',
                    fontWeight: 'extrabold',
                  }}
                  borderColor="border.100"
                  _hover={{
                    borderColor: 'blue.300',
                  }}
                  maxLength="12"
                  value={
                    showInputZero ? '' : thousandSeparator(donation.amount)
                  }
                  pr="6"
                  color={Number(donation.amount) === 0 ? '#CDCDCD' : 'black'}
                  fontWeight={
                    Number(donation.amount) === 0 ? '400' : 'extrabold'
                  }
                  pattern="[0-9,]*"
                  onFocus={(e) => setShowInputZero(e.target.value === '0')}
                  onBlur={() => setShowInputZero(false)}
                  onChange={(e) => {
                    setShowInputZero(!e.target.value)
                    setValue(
                      'amount',
                      e.target.value.replace(/[^0-9]|^0+(?!$)/g, '')
                    )
                  }}
                  pl="6.5rem"
                  textAlign="right"
                  fontSize="2rem"
                  height="86px"
                  borderRadius="12px"
                />
              </InputGroup>
            </FormControl>
            {project.isProject && (
              <Progress
                project={{ ...project, updateAmount: progressData.amount }}
                raisedMoney={CurrencyConverter({
                  currency: currency.current,
                  amount: parseInt(project.amount),
                })}
                optionsTexts={SUPPORTED_CURRENCIES.map((option) => ({
                  text: t(`currency@${option}`),
                  amount: CurrencyConverter({
                    amount: project.amount,
                    currency: option,
                  }),
                }))}
                donationPage
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? project.amount + Number(progressData.amount)
                    : project.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(progressData.amount),
                  currency.current
                )}
                dynamicalAmount={progressData.progress}
                monthlyGoalText={t('monthlyGoal')}
                goalPrice={project.activeGoal?.amount || project.goal}
                goalText={t('goal')}
                raisedText={t('raised')}
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en: {
                    ...project,
                    updateAmount: progressData.amount,
                  }.campaignImplementorPeriod_en,
                  fundraisingType: {
                    ...project,
                    updateAmount: progressData.amount,
                  }.fundraisingType,
                })}
                currency={currency}
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
              />
            )}
          </>
        )}
      </Box>

      {!isContribution && (
        <>
          <FormControl>
            <FormLabel
              fontSize="xl"
              fontWeight="semibold"
              color="gray.800"
              bg="gray.100"
              zIndex={2}
            >
              {t('donation@donateToReArmenia')}
            </FormLabel>
            <Text mb="6" color="gray.700" fontSize="md">
              {t('donation@donateToReArmeniaFee')}
            </Text>
            <RadioGroup
              width="100%"
              name="amount"
              price={perk !== null ? perk.price : donation.amount}
              defaultValue="20$"
              options={PRICE}
              onChange={handleAmount}
              perk={perk !== null}
            />
          </FormControl>
          {other && (
            <FormControl>
              <FormLabel
                fontSize="xl"
                fontWeight="semibold"
                color="black"
                bg="gray.100"
                zIndex={2}
                px="2"
              >
                {t('donation@other')}
              </FormLabel>
              <InputGroup alignItems="center">
                <InputLeftElement
                  top="unset"
                  width="100px"
                  pointerEvents="none"
                  children={
                    <Box as="span" fontSize="xl">
                      ({currency.current})
                    </Box>
                  }
                />
                <Input
                  _focus={{
                    boxShadow: 'none',
                  }}
                  maxLength="12"
                  _hover={{
                    borderColor: 'blue.300',
                  }}
                  borderColor="border.100"
                  value={thousandSeparator(donation.reArmenia)}
                  pattern="[0-9,]*"
                  onChange={(e) =>
                    setValue(
                      'reArmenia',
                      e.target.value.replace(/[^0-9]|^0+(?!$)/g, '')
                    )
                  }
                  pl="6.5rem"
                  textAlign="right"
                  fontSize="xl"
                  height="60px"
                />
              </InputGroup>
            </FormControl>
          )}
        </>
      )}
      {project.fundraisingType === 'recurring' && (
        <Box borderColor="#DDDDDD" borderTopWidth="1px" pt="6">
          <Box
            display="inline-block"
            mb="2"
            as="span"
            fontSize="xl"
            fontWeight="semibold"
            color="black"
            bg="gray.100"
            zIndex={2}
          >
            {t('donation@selectType')}
          </Box>
          <OneTimeRecurring
            paymentType={watch('paymentType')}
            setValue={setValue}
            price={thousandSeparator(
              Number(donation.amount) + Number(donation.reArmenia),
              currency.current
            )}
          />
        </Box>
      )}
    </Stack>
  )
}
