const donationSubscriptionRepo = (donationSubscriptionService) => {
  const updateDonationSubscription = (project) => donationSubscriptionService.updateDonationSubscription(project)

  const donationSubscriptionsByUserEmail = (condition) => donationSubscriptionService.donationSubscriptionsByUserEmail(condition)

  const donationSubscriptionsByProjectId = (condition) => donationSubscriptionService.donationSubscriptionsByProjectId(condition)

  return {
    donationSubscriptionsByUserEmail,
    donationSubscriptionsByProjectId,
    updateDonationSubscription,
  }
}

export default donationSubscriptionRepo
