import { useEffect } from 'react'
import { Box, Container, Heading, Text, Flex } from '@chakra-ui/react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Projects from './Projects'
import Settings from './Settings'
import Initiatives from './Initiatives'
import Subscriptions from './Subscriptions'
import { useUser } from 'hooks'
import { useNavigation, Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Arrow } from 'assets/icons/arrowRight.svg'
import MobileProfile from './MobileProfile'
import { MainLayout } from 'components/Layouts'
import History from './History'
import { Footer } from 'components/Footer'

const w = window.innerWidth

const Profile = () => {
  const location = useLocation()
  const { navigationPush } = useNavigation()
  const { t, language } = useTranslation()
  const { user } = useUser()

  useEffect(() => {
    if (
      location.pathname === `/${language}/profile` ||
      location.pathname === '/profile/'
    )
      navigationPush('/profile/fundraisers')
    if (location.pathname === `/${language}/profile/settings`)
      navigationPush('/profile/settings/personal')
  }, [])

  if (w < 481) {
    return <MobileProfile />
  }

  return (
    <MainLayout>
      <Container py="8" maxW="7xl">
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent="space-between"
        >
          <Flex
            align="flex-start"
            w="full"
            maxW={{ base: 'full', lg: '217px' }}
          >
            <Flex
              overflow="hidden"
              mx={{ base: '0', sm: '8', lg: '0' }}
              flex="1"
              direction="column"
              mb="4"
              border="1px solid"
              borderColor="border.100"
              borderRadius="16px"
            >
              <Box p={user.firstName ? 4 : 0}>
                <Heading fontSize="md" as="h3">
                  {user.firstName} {user.lastName}
                </Heading>
              </Box>
              <Link to="/profile/fundraisers">
                <Flex
                  fontWeight="500"
                  fontSize="md"
                  alignItems="center"
                  justifyContent="space-between"
                  px="4"
                  py="2"
                  bg={
                    location.pathname.includes('profile/fundraisers')
                      ? 'rgba(0,0,0, 0.1)'
                      : 'transparent'
                  }
                >
                  {t('profile@tab1')}
                  {location.pathname.includes('profile/fundraisers') && (
                    <Arrow />
                  )}
                </Flex>
              </Link>
              <Link to="/profile/collaborations">
                <Box
                  fontWeight="500"
                  fontSize="md"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  px="4"
                  py="2"
                  bg={
                    location.pathname.includes('profile/collaborations')
                      ? 'rgba(0,0,0, 0.1)'
                      : 'transparent'
                  }
                >
                  {t('profile@tab2')}
                  {location.pathname.includes('profile/collaborations') && (
                    <Arrow />
                  )}
                </Box>
              </Link>
              {/* <Link to="/profile/history">
                <Box
                  fontSize="md"
                  fontWeight="500"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  px="4"
                  py="2"
                  bg={
                    location.pathname.includes('profile/history')
                      ? 'rgba(0,0,0, 0.1)'
                      : 'transparent'
                  }
                >
                  {t('profile@history')}
                  {location.pathname.includes('profile/history') && <Arrow />}
                </Box>
              </Link> */}
              <Link to="/profile/subscriptions">
                <Box
                  fontSize="md"
                  fontWeight="500"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  px="4"
                  py="2"
                  bg={
                    location.pathname.includes('profile/subscriptions')
                      ? 'rgba(0,0,0, 0.1)'
                      : 'transparent'
                  }
                >
                  {t('profile@tab4')}
                  {location.pathname.includes('profile/subscriptions') && (
                    <Arrow />
                  )}
                </Box>
              </Link>
              <Link to="/profile/settings/personal">
                <Box
                  fontSize="md"
                  fontWeight="500"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  px="4"
                  py="2"
                  bg={
                    location.pathname.includes('profile/settings')
                      ? 'rgba(0,0,0, 0.1)'
                      : 'transparent'
                  }
                >
                  {t('profile@tab3')}
                  {location.pathname.includes('profile/settings') && <Arrow />}
                </Box>
              </Link>
            </Flex>
          </Flex>
          <Flex mx={{ base: '0', sm: '8' }} flex="1" direction="column">
            {/* <Flex
              height="160px"
              maxW="1016px"
              bgImage="url(/assets/images/profile_cover.png)"
              bgSize="cover"
              bgPos="center"
              bgRepeat="no-repeat"
              borderRadius="16px"
              alignItems="center"
              px="10"
            >
              <Text
                maxW="560px"
                fontWeight="semibold"
                color="#ffffff"
                fontSize={{ base: 'xl', sm: '4xl' }}
              >
                {t('profile@heading')}
              </Text>
            </Flex> */}
            <Box minH="200px">
              <Switch>
                <Route
                  exact
                  path="/:lang/profile/fundraisers"
                  component={Projects}
                />
                <Route
                  exact
                  path="/:lang/profile/collaborations"
                  component={Initiatives}
                />
                {/* <Route
                  exact
                  path="/:lang/profile/history"
                  component={History}
                /> */}
                <Route
                  exact
                  path="/:lang/profile/settings/:tab"
                  component={Settings}
                />
                <Route
                  exact
                  path="/:lang/profile/subscriptions"
                  component={Subscriptions}
                />
              </Switch>
            </Box>
          </Flex>
        </Flex>
      </Container>
      <Footer />
    </MainLayout>
  )
}

export default Profile
