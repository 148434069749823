import { Box, Flex, Button, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import {
  useCurrencySettings,
  thousandSeparator,
} from 'contexts/CurrencySettingsContext'
import ApplyGiftCard from './ApplyGiftCard'
import Header from './Header'

export default function Web({
  onDonate,
  donation,
  giftCard,
  type,
  isLoading,
  isValid,
  isGiftCardDisabled,
  giftCardAmount,
  setGiftCardAmount,
  appliedGiftCard,
  project,
  isContribution,
}) {
  const { t } = useTranslation()
  const { currency } = useCurrencySettings()

  return (
    <Box position="sticky" height="100vh" top="20px" maxW="573px" width="full">
      <Box
        mx="auto"
        pt="6"
        px="6"
        pb="5"
        border="1px solid"
        borderColor="border.100"
        borderRadius="12px"
      >
        <Header project={project} isContribution={isContribution} />
        <Box mb="2" mt="6" bg="#CDCDCD" w="full" height="1px" />
        {!isContribution && (
          <>
            <Flex justifyContent="space-between">
              <Box fontWeight="regular" color="gray.800" fontSize="lg">
                {t(
                  `donation@${
                    isGiftCardDisabled ? 'yourMonthlyDonation' : 'yourDonation'
                  }`
                )}
              </Box>

              <Box
                as="span"
                fontWeight="medium"
                fontSize="lg"
                whiteSpace="nowrap"
              >
                {thousandSeparator(Number(donation.amount), currency.current)}{' '}
                {currency.current}
              </Box>
            </Flex>

            <Flex mb="2" justifyContent="space-between">
              <Box fontWeight="regular" color="gray.800" fontSize="lg">
                {t(
                  `donation@${
                    isGiftCardDisabled ? 'reArmeniaTipMonthly' : 'reArmeniaTip'
                  }`
                )}
              </Box>
              <Box
                as="span"
                fontWeight="medium"
                fontSize="lg"
                whiteSpace="nowrap"
              >
                {thousandSeparator(
                  Number(donation.reArmenia),
                  currency.current
                )}{' '}
                {currency.current}
              </Box>
            </Flex>
          </>
        )}
        {giftCardAmount && giftCardAmount > 0 ? (
          <Flex justifyContent="space-between">
            <Box fontWeight="regular" color="gray.800" fontSize="lg">
              {t('donation@bonus')}
            </Box>
            <Box
              as="span"
              fontWeight="medium"
              fontSize="lg"
              whiteSpace="nowrap"
            >
              {thousandSeparator(Number(giftCardAmount), currency.current)}{' '}
              {currency.current}
            </Box>
          </Flex>
        ) : (
          <></>
        )}
        <Flex justifyContent="space-between">
          <Box fontWeight="bold" color="gray.800" fontSize="lg">
            {t(`donation@${isGiftCardDisabled ? 'totalMonthly' : 'total'}`)}
          </Box>
          <Box fontWeight="bold" fontSize="lg">
            {thousandSeparator(
              (Number(donation.reArmenia) ?? 0) +
                (Number(donation.amount) ?? 0),
              currency.current
            )}{' '}
            {currency.current}
          </Box>
        </Flex>
      </Box>
      {/* 
      {!isGiftCardDisabled && (
        <ApplyGiftCard
          isGiftCardDisabled={isGiftCardDisabled}
          appliedGiftCard={appliedGiftCard}
          setGiftCardAmount={setGiftCardAmount}
        />
      )} */}
    </Box>
  )
}
