import React from 'react'
import { Heading, Flex, Box, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import Left from './Left'
import Right from './Right'

const Procedures = () => {
  const { t } = useTranslation()
  return (
    <Flex
      pb="9.25rem"
      flexDirection="column"
      align="center"
      justifyContent="center"
      w="full"
    >
      <Heading
        mb="8"
        textAlign="center"
        as="h2"
        fontSize={{ base: '4xl', sm: '6xl' }}
        textTransform="uppercase"
        color="#DDDEE3"
      >
        {t('about@process')}
      </Heading>
      <Left
        title={t('about@screening')}
        description={t('about@screeningText')}
        image="/assets/images/screening.png"
      />

      <Right
        title={t('about@manager')}
        description={t('about@managerText')}
        image="/assets/images/manager.png"
      />

      <Left
        title={t('about@trustCheck')}
        description={t('about@trustCheckText')}
        image="/assets/images/trust.png"
      />

      {/* <Right
        title={t('about@evaluation')}
        description={t('about@evaluationText')}
        image="/assets/images/advisory.png"
      /> */}

      {/* <Left
        title={t('about@approval')}
        description={t('about@approvalText')}
        image="/assets/images/approval.png"
      /> */}

      {/* <Box
                mt="16"
                mb="10"
                bg="#DDDEE3"
                borderRadius="100px"
                h="67px"
                w="67px"/>
            <Button>
            {t('about@wholeProcess')}
            </Button> */}
    </Flex>
  )
}

export default Procedures
