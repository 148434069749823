import React, { useMemo } from 'react'
import { Box, Flex, Heading, Text, Button, Image } from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'
import { Link } from 'pages'
import { ReactComponent as User } from 'assets/icons/blueUser.svg'
import ImageWrapper from 'components/ImageWrapper'
import Progress from 'components/Progress'
import { PROGRESS_VISIBLE_STATUSES, STATUS_ENUMS } from '_constants'

const createEditDraftParams = ({
  draftId,
  isProject,
  isCollaboration,
  fundraisingType,
}) => {
  let queryParams = `?draftId=${draftId}`
  if (isProject) {
    queryParams += `&isFundraiser=true&fundraisingType=${fundraisingType}`
  }
  if (isCollaboration) {
    queryParams += `&isCollaboration=true`
  }

  return queryParams
}

const Edit = ({
  card,
  type,
  unsubscribe,
  onAlertOpen,
  editText,
  unsubscribeText,
  donationSubscription,
}) => (
  <Flex mt="4" justifyContent="flex-end">
    {!unsubscribe ? (
      <Link
        to={`/edit-${type}/${card.id}/application${
          card?.collaborations?.length > 0 &&
          (card?.totalAmount || card?.goals?.length > 0)
            ? '?showBoth'
            : ''
        }`}
      >
        <Button mr="4" color="orange.400" variant="transparent" fontSize="md">
          {editText}
        </Button>
      </Link>
    ) : (
      <Button
        mr="4"
        color="orange.400"
        variant="transparent"
        fontSize="md"
        onClick={(e) => onAlertOpen(e, donationSubscription)}
      >
        {unsubscribeText}
      </Button>
    )}
  </Flex>
)
const EditDraft = ({ card, onDraftDelete, remove, continueTranslation }) => (
  <Flex mt="4" justifyContent="space-between">
    <Link
      to={`/create-project${createEditDraftParams({
        draftId: card.id,
        isProject: card.isProject,
        isCollaboration: card.isCollaboration,
        fundraisingType: card.fundraisingType,
      })}`}
    >
      <Button mr="4" color="orange.400" variant="transparent" fontSize="md">
        {continueTranslation}
      </Button>
    </Link>

    <Button
      mr="4"
      color="red.400"
      variant="transparent"
      fontSize="md"
      onClick={() => {
        onDraftDelete(card.id)
      }}
    >
      {remove}
    </Button>
  </Flex>
)
const CollaborationProposals = ({
  collaboratorsTotal,
  language,
  collaborationProposalsText,
  collaborationProposalsTextRuEdit,
}) => {
  if (language === 'ru' && [2, 3, 4].includes(collaboratorsTotal)) {
    return (
      <Box minH="24px">
        <Flex alignItems="center">
          <User />
          <Text ml="4" fontWeight="300" as="span" fontSize="md">
            <>
              <Text fontWeight="700" fontSize="lg" as="span">
                {collaboratorsTotal}
              </Text>{' '}
              {collaborationProposalsTextRuEdit}
            </>
          </Text>
        </Flex>
      </Box>
    )
  }

  return (
    <Box minH="24px">
      {collaboratorsTotal > 0 && (
        <Flex alignItems="center">
          <User />
          <Text ml="4" fontWeight="300" as="span" fontSize="md">
            <Text fontWeight="700" fontSize="lg" as="span">
              {collaboratorsTotal}
            </Text>{' '}
            {collaborationProposalsText.split('...')[1]}
          </Text>
        </Flex>
      )}
    </Box>
  )
}

const ProjectCard = ({
  isMobile = false,
  card,
  edit = false,
  unsubscribe = false,
  donationSubscription,
  onAlertOpen,
  UnsubscribeText,
  language,
  currency,
  user,
  onDraftDelete,
  created = '',
  continueTranslation = '',
  remove = '',
  startedFromText = '',
  monthlyPaidText = '',
  cardStatusText = '',
  cardCategoryText = '',
  editText = '',
  unsubscribeText = '',
  collaborationProposalsText = '',
  collaborationProposalsTextRuEdit = '',
  type = 'fundraisers',
  monthlyGoalText = '',
  goalText = '',
  raisedText = '',
  calculatedProjectDurationLeft,
  settings,
  changeDefaultCurrency,
  raisedMoney = 0,
  goalPrice = 0,
  CurrencyConverter,
  thousandSeparatorText,
  SUPPORTED_CURRENCIES,
  ...props
}) => {
  const isFundraiser = type === 'fundraisers'

  const showProgress = useMemo(() => {
    if (type === 'subscription') {
      return false
    }
    return (
      card.isProject &&
      !unsubscribe &&
      PROGRESS_VISIBLE_STATUSES.includes(card.status)
    )
  }, [card])

  return (
    <Box
      as={card.status === 'draft' ? Box : Link}
      to={`/${type}/${card.slug || card.id}`}
      {...props}
    >
      <Box
        overflow="hidden"
        borderRadius="12px"
        border="1px solid"
        borderColor="border.100"
        minW="280px"
        mb={isMobile ? '10' : '0'}
      >
        <Box overflow="hidden" position="relative" w="100%" h="184px">
          {card.id !== 'ap' && (
            <Box
              position="absolute"
              top="16px"
              left="20px"
              color="gray.100"
              p="6px 14px"
              borderRadius="6px"
              fontSize="sm"
              fontWeight="bold"
              background={STATUS_ENUMS[type][card.status]?.color}
            >
              {cardStatusText}
            </Box>
          )}
          {card.id !== 'ap' && card.category && (
            <Flex
              left="20px"
              bottom="16px"
              position="absolute"
              alignItems="center"
            >
              <Heading
                color="gray.100"
                key={`card-heading-${language}`}
                textTransform="uppercase"
                as="h3"
                fontSize="sm"
                letterSpacing="1px"
              >
                {cardCategoryText}
              </Heading>
            </Flex>
          )}

          <LazyLoad h="184px" once>
            {card.cover ? (
              <ImageWrapper
                borderTopRadius="12px"
                cursor="pointer"
                objectFit="cover"
                width="100%"
                bgColor="border.100"
                h="184px"
                ignoreFallback={true}
                src={`/thumbnail/small/${card.cover}`}
              />
            ) : (
              <Image
                cursor="pointer"
                objectFit="cover"
                borderTopRadius="12px"
                width="100%"
                bgColor="border.100"
                h="184px"
                ignoreFallback={true}
                src="/assets/images/noImg.svg"
              />
            )}
          </LazyLoad>
        </Box>
        <Flex
          h={card.status === 'draft' ? 'auto' : '232px'}
          px="5"
          pt="4"
          pb="5"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box width="100%">
            <Heading
              noOfLines={2}
              key={`card-title-${language}`}
              color="gray.800"
              as="h2"
              h="48px"
              fontSize="2xl"
            >
              {card[`title_${language}`]
                ? card[`title_${language}`]
                : card.applicationTitle}
            </Heading>
            {!edit && (
              <Text
                key={`card-tagline-${language}`}
                noOfLines={3}
                mt="2"
                mb="2"
                fontSize="md"
                color="gray.700"
                lineHeight="18px"
              >
                {!unsubscribe ? (
                  card[`tagline_${language}`]
                ) : (
                  <UnsubscribeText
                    unsubscribe={unsubscribe}
                    currency={currency}
                    startedFromText={startedFromText}
                    monthlyPaidText={monthlyPaidText}
                  />
                )}
              </Text>
            )}
          </Box>
          <Box>
            {showProgress && isFundraiser && (
              <Progress
                project={card}
                raisedMoney={raisedMoney}
                thousandSeparatorText={thousandSeparatorText}
                SUPPORTED_CURRENCIES={SUPPORTED_CURRENCIES}
                monthlyGoalText={monthlyGoalText}
                goalPrice={goalPrice}
                raisedText={raisedText}
                calculatedProjectDurationLeft={calculatedProjectDurationLeft}
                currency={currency}
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
              />
            )}

            {!isFundraiser && (
              <CollaborationProposals
                collaboratorsTotal={card?.collaboratorsTotal}
                collaborationProposalsText={collaborationProposalsText}
                language={language}
                collaborationProposalsTextRuEdit={
                  collaborationProposalsTextRuEdit
                }
              />
            )}
            {card.status === 'draft' ? (
              <>
                <Text fontSize="md" fontWeight={300}>
                  {created.replace('...', card.formattedCreationDate)}
                </Text>
                <EditDraft
                  card={card}
                  remove={remove}
                  continueTranslation={continueTranslation}
                  onDraftDelete={onDraftDelete}
                />
              </>
            ) : (
              <>
                {(edit || unsubscribe) && (
                  <Edit
                    card={card}
                    type={type}
                    editText={editText}
                    unsubscribeText={unsubscribeText}
                    unsubscribe={unsubscribe}
                    onAlertOpen={onAlertOpen}
                    donationSubscription={donationSubscription}
                  />
                )}
              </>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default ProjectCard
