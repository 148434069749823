import React from 'react'
import {
  Box,
  Heading,
  Text,
  Flex,
  useColorModeValue as mode,
  Button,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { SignupForm } from './SignupForm'
import AuthWrapper from 'components/AuthWrapper'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'

const Web = ({
  register,
  errors,
  handleSubmit,
  onSubmit,
  validateEmail,
  country,
  setCountry,
  phone,
  setPhone,
  phoneError,
  loading,
  password,
}) => {
  const { t } = useTranslation()

  return (
    <AuthWrapper
      ceoCompany="CEO of Kamala"
      ceoName="Samvel Papoyan"
      ceoDescription="“reArmenia is short description Though I’ll stop short of calling it a rule lorem ipsum`.”"
      url="/assets/images/auth.jpg"
    >
      <Box w="full" maxW="lg">
        {/* <Button display={{base: 'none', sm: 'inline-flex'}} position="absolute" top="20px" left="20px" color="black" variant="transparent" as={Link} to="/">
                    <Logo/>
                </Button> */}
        <Link to="/">
          <Button
            display={{ base: 'inline-flex', md: 'none' }}
            position="absolute"
            top="20px"
            right="20px"
            variant="transparent"
          >
            <Close width="20px" />
          </Button>
        </Link>
        <Heading
          as="h1"
          fontSize="5xl"
          fontWeight="extrabold"
          letterSpacing="tight"
          textAlign="center"
        >
          {t('signUp@heading')}
        </Heading>

        <Box
          minW={{ md: '350px' }}
          rounded="xl"
          bg={{ md: mode("gray.100", 'gray.700') }}
          pt={{ base: '8', md: '12' }}
          pb="8"
        >
          <SignupForm
            register={register}
            errors={errors}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            validateEmail={validateEmail}
            country={country}
            setCountry={setCountry}
            phone={phone}
            setPhone={setPhone}
            phoneError={phoneError}
            loading={loading}
            password={password}
          />
        </Box>
      </Box>
      <Flex
        position="absolute"
        bottom="2rem"
        spacing="4"
        direction={{ base: 'column-reverse', md: 'row' }}
        mt="6"
        align="center"
        justify="space-between"
      >
        <Text color="gray.700" fontSize="sm" fontWeight="semibold">
          {t('signUp@haveAnAccount')}{' '}
          <Button fontSize="sm" color="orange.400">
            <Link to="/sign-in">{t('signUp@signIn')}</Link>
          </Button>
        </Text>
      </Flex>
    </AuthWrapper>
  )
}

export default Web
