import { useToast } from '@chakra-ui/react';
import Toaster from 'components/Toaster';

const useCustomToast = () => {
   const toast = useToast({
        position: "bottom-right"
    })

    const customToast = ({ title, status, duration, isClosable }) => {
        toast({
            render: (props) => <Toaster title={title} status={status} {...props} />,
            duration,
            isClosable
        })
    }

    return customToast
}

export default useCustomToast;