import React from 'react'
import { Link } from 'pages'
import { Text, Flex, Box, Heading, Container, Img } from '@chakra-ui/react'
import Navbar from 'components/Navbar'
import { Footer } from 'components/Footer'
import { useTranslation } from 'contexts/TranslationContext'

const Terms = () => {
  const { language } = useTranslation()
  if (language === 'hy') {
    return <TermsAM />
  }
  return (
    <Flex
      direction="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Navbar />
      <Box mb="3rem" bg="gray.800" as="section" position="relative" w="full">
        <Box h="full" py="32" position="relative" zIndex={1}>
          <Flex
            h="full"
            alignItems="center"
            maxW={{ base: 'xl', md: '4xl' }}
            mx="auto"
            px={{ base: '6', md: '8' }}
            color="gray.100"
          >
            <Box maxW="3xl">
              <Heading
                as="h1"
                fontSize={{ base: '5xl', md: '6xl' }}
                fontWeight="extrabold"
              >
                Terms and Conditions
              </Heading>
            </Box>
          </Flex>
        </Box>
        <Flex
          id="image-wrapper"
          position="absolute"
          insetX="0"
          insetY="0"
          w="full"
          h="full"
          overflow="hidden"
          align="center"
        >
          <Box position="relative" w="full" h="full">
            <Img
              src="https://images.unsplash.com/photo-1585909695677-2b0492f96e3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="Main Cover"
              w="full"
              h="full"
              objectFit="cover"
              objectPosition="center"
              position="absolute"
            />
            <Box
              position="absolute"
              w="full"
              h="full"
              bg="linear-gradient(134.85deg, #002D72 0%, rgba(0,45,114,0) 100%)"
            />
          </Box>
        </Flex>
      </Box>
      <Container pb="5rem" px={{ base: '6', md: '8' }} maxW="4xl">
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          1. The Platform / Armenian Peace
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          By using this website (armenianpeace.am) offered by Union of Security
          and Sports Sector Representatives" non-government organization
          (together with its affiliates, representatives, consultants,
          employees, officers and directors - collectively, “AP,” “we,” or
          “us”), you’re agreeing to these legally binding rules (the “Terms”).
          armenianpeace.am is an online platform (the “Platform”) to: (i) as to
          donation part of the Platform - allow an individual, legal entity,
          for-profit or non-profit organization (the "Project creator") to post
          their own fundraising project \ campaign ("Project") (aimed at having
          a social impact) to accept monetary donations ("Donations") from
          donors ("Donors"). Project creators will not provide goods or services
          in exchange for Donations, instead Project creators may offer perks to
          Donors in thanks for contribution of funds; (ii) as to collaboration
          part of the Platform - allow an individual, legal entity, for-profit
          or non-profit organization (the “Initiator”) to post their initiative
          (an existing problem and its possible solution aimed at having a
          social impact) to find partners (implementers and collaborators) for
          cooperation and solving the problem. Should any User violate any of
          these Terms, we (AP) reserve the right to terminate, suspend, or take
          any other action related to the corresponding User’s account at our
          sole discretion. The User may not access the Platform if the latter
          one has been prohibited from doing so by us. To protect the health and
          integrity of the Platform and to help ensure that Project creators,
          Donors, Initiators and all other Users enjoy a safe and secure
          environment, AP reserves the following rights:
          <br /> ● We can make changes to the Platform without notice or
          liability.
          <br /> ● We have the right to decide who is eligible to use the
          Platform. We may cancel accounts or decline to offer services on the
          Platform at any time (especially if you’re abusing them). We can
          change our eligibility criteria at any time.
          <br /> ● We have the right to reject, cancel, pause, interrupt,
          remove, terminate or suspend any Project or Initiative at any time and
          for any reason.
        </Text>

        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          1.1 Payments, fees, and tips to AP
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          AP is not a payment processor. Instead, AP uses third-party payment
          processing partners to process Donations (“Payment Processor”). You
          acknowledge and agree that we exchange information with Payment
          Processors and any third party in order to facilitate the activity of
          the Platform. Project creators are not charged any fee as a portion of
          the Donations they raise on the Platform. User fee for posting Project
          and Initiative is 1000 AMD (Armenian Dram), but provided free of
          charge taking into consideration that AP’s and the Platform’s
          operations aimed at having a social impact. Project creators are
          charged a transaction fee by the Payment Processor. Transfer fees, or
          bank delivery fees, are also applied each time the Payment Processor
          sends funds to a Project creator’s bank account. AP’s and
          correspondingly the Platform’s self-sustainability is based on tips
          from Donors. Rather than charging a commission fee from Donation
          transactions, AP enables Donors to add a tip on a voluntary basis.
          Tips contributed to AP by Donors are non-refundable. There are two
          types of fundraising options available for the Project creator on the
          Platform - “Fixed Funding” (all or nothing) and “Flexible Funding”
          (keep it all). With a fixed funding project, the Project creator only
          keeps the funds raised if the funding goal is met. If a Project
          (fundraising campaign) does not meet its goal by its deadline, all its
          Donors will be refunded or, upon written consent of the Donors (by
          email), corresponding donations may be transferred to AP’s account to
          implement AP’s statutory goals and/or to another ongoing Project/s.
          With a flexible funding project, whether the Project creator meets the
          goal or not, the latter one can receive all the amount that has been
          achieved throughout the duration of the Project (fundraising
          campaign), provided that (in case the Project does not meet its goal
          by its deadline) the Project creator can provide AP with confirmation
          and proof that the funds raised can be spent on the implementation of
          a certain part of the Project with the corresponding (sufficient)
          impact. Project creators are not guaranteed to immediate receive
          Donations made to a Project, even after the Project has ended and the
          fundraising goal has been reached. There may be a delay between the
          end of a Project (fundraising campaign) and remittance of Donations to
          a Project creator for a number of reasons including, but not limited
          to, refunds, chargeback disputes, fraud, Terms or other policy
          violations, or any other situation. Remittance of collected Donations
          to a Project creator will be carried out in accordance with the manner
          and terms established by the corresponding agreement between AP and
          the Project creator. All Donations are directed to the transit account
          of the Payment Processor (AP’s partner bank) and then to be remitted
          to Project creator’s account. It is general policy to carry out
          remittance of the collected Donations to Project creators in
          installments, as the Project’s milestones are successfully implemented
          and Project creator’s related reporting materials are received by AP.
        </Text>

        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          1.2 AP is an administrative platform only
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          armenianpeace.am is an administrative platform only. The Platform
          facilitates the Projects and permits Donors to make donations to the
          Project creators. AP is not a broker, agent, financial institution, or
          creditor. Regardless of anything AP isn’t liable for any damages or
          losses related to your use of the Platform. We don’t become involved
          in disputes between users, or between users and any third party
          relating to the use of the Platform. The Platform is offered to help
          Project creators raise funds and Initiators find
          collaborators/implementors. AP merely provides the technology to allow
          Project creators to connect with Donors and Initiators to connect with
          collaborators/implementors. We do not support (endorse) any Project,
          Project creator, Initiator, User, or cause. We make necessary efforts
          to check the information provided by Project creators, Initiators and
          other Users (as mentioned below), but we make no guarantee that any
          information provided through the Platform is accurate. No content is
          intended to provide financial, legal, tax, or other professional
          advice. The Platform or its services may be unavailable for scheduled
          maintenance and other reasons, including unplanned outages and other
          malfunctions. We are not responsible if the Platform or its services
          are unavailable, or if you lose any data, information, or User content
          for any reason.
        </Text>
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          1.3 AP’s efforts
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          AP makes the necessary efforts to investigate the Project and/or
          Project creator, Initiator or User Content, check the Projects for
          their relevance, the optimality of the solution proposed by the
          Project creator, and whether the Project creator has a team, able to
          successfully implement the proposed Project. Within the framework of
          checking the followings are carried out: verification of the statutory
          documents of the Project creator, verification of the authorization of
          persons representing the interests of the Project creator,
          verification of the submitted Project budget, evaluation by advisors
          and other checks. As we investigate the Project / Project creator or
          User Content, we may consider all available material including but not
          limited to social media, related news, and any other information that
          we, in our sole discretion, deem relevant in our review. As mentioned,
          we assess each Project by an independent advisory board, for which we
          involve advisors (professionals) from the corresponding industry. The
          scope of checks carried out for each Project may differ depending on
          industry/area. Project creators are also required to provide AP and
          Donors with Project / campaign progress and financial reports by the
          date (in a timely manner) specified by AP. The Platform and Donors
          depend heavily upon progress and financial reports to evaluate
          progress. Failure to submit progress or financial reports or any
          related information and document in a timely manner or incorrect
          information in these reports and documents can result termination or
          pause of the Project by AP’s sole discretion. We take possible
          fraudulent activity and the misuse of funds raised very seriously. If
          you have reason to believe that a Project creator is not raising or
          using the funds for their stated purpose, please send us an email at
          info@armenianpeace.am to alert our team of this potential issue and we
          will investigate. We do our best to achieve that the Donations will be
          used in accordance with the relevant Project’s purpose prescribed by a
          Project creator, but we do not guarantee that. We also do not
          guarantee that a Project will obtain a certain amount of Donations or
          any Donations at all.
        </Text>

        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          2. Any User
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          To use many services of the Platform, you will need to register and
          create an account with a username and password. You must provide us
          with accurate and complete information, and you must keep your account
          information updated. You may not impersonate anyone else, choose an
          offensive username or violate any individual’s rights. You are solely
          responsible for maintaining the confidentiality of your account and
          for all activities associated with or occurring under your account.
          You acknowledge that all information and content accessed by you using
          the Platform is at your own risk. You are solely responsible for
          compliance with all applicable law in relation to your use of the
          Platform. You are further solely responsible for all User Content that
          you upload, post, publish, display, transmit or otherwise use. You
          agree to fully cooperate with any request we make for evidence we deem
          necessary to verify your compliance with these Terms. If you suspect
          or discover any unauthorized use of your account, you should notify AP
          immediately by contacting us at info@armenianpeace.am. We are not
          responsible for any loss or damage arising from your failure to comply
          with the foregoing requirements or as a result of the use of your
          account with or without your knowledge. Don’t do any of the following
          things on the Platform:
          <br />● Don’t break the law. Don’t take any action that infringes or
          violates other people’s rights, violates the law, or breaches any
          contract or legal duty you have toward anyone.
          <br />● Don’t lie to people. Don’t post information you know is false,
          misleading, or inaccurate. Don’t do anything deceptive or fraudulent.
          <br />● Don’t victimize anyone. Don’t do anything threatening,
          abusive, harassing, defamatory, libelous, tortious, obscene, profane,
          or invasive of another person’s privacy.
          <br /> ● Don’t spam. Don’t distribute unsolicited or unauthorized
          advertising or promotional material, or any junk mail, spam, or chain
          letters. Don’t run mail lists, listservs, or any kind of
          auto-responder or spam on or through the Platform.
          <br /> ● Don’t abuse other users’ personal information. When you use
          the Platform and especially if you create a Project or Initiative you
          may receive information about other Platform users, including things
          like their names, email addresses, and postal addresses. This
          information is provided for the purpose of participating in a relevant
          Project or Initiative: don’t use it for other purposes, and don’t
          abuse it.
        </Text>

        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          2.1 Intellectual property
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          AP doesn’t own the content you submit to us (the “Content”). When you
          use any of the services of the Platform, incl. (but not limited to)
          submit a Project for review, or launch a Project or Initiative on the
          Platform, you agree to the following terms:
          <br />● We can use the Content you’ve submitted. You grant to us, and
          others acting on our behalf, the worldwide, non-exclusive, perpetual,
          irrevocable, royalty-free, sublicensable, transferable right to use,
          exercise, commercialize, and exploit the copyright, publicity,
          trademark, and database rights with respect to your Content;
          <br />● When we use the Content, we can make changes, like editing or
          translating it. You grant us the right to edit, modify, reformat,
          excerpt, delete, or translate any of your Content;
          <br />● You won’t submit stuff you don’t hold the copyright for
          (unless you have permission). Your Content will not contain
          third-party copyrighted material, or material that is subject to other
          third-party proprietary rights unless you have permission from the
          rightful owner of the material, or you are otherwise legally entitled
          to post the material (and to grant AP all the license rights outlined
          here);
          <br />● Any royalties or licensing on your Content are your
          responsibility. You will pay all royalties and other amounts owed to
          any person or entity based on your Content;
          <br />● You guarantee that if we use your Content, we’re not violating
          anyone’s rights or copyrights;
          <br />● You’re responsible for the information you post on the
          Platform. All information submitted to the Platform \ AP, whether
          publicly posted or privately transmitted, is the sole responsibility
          of Content creator;
          <br />● We’re not responsible for mistakes in your Content. AP will
          not be liable for any errors or omissions in any Content.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          3. Project creator
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          You, as a Project creator, represent, warrant, and covenant that
          <br />● All information you provide in connection with your Project or
          beneficiary is accurate, complete, and not likely to deceive AP or any
          User of the Platform;
          <br />● Register on the Platform using your true identities, including
          your name, address, and any image or video purporting to depict the
          Project creator or the Project. You agree to keep registration
          information current and up to date. If AP at any time discovers that
          the information you provided about you or the purpose of your Project
          is incorrect or violates any of these Terms, your Project may be
          suspended and/or terminated with immediate effect;
          <br />● All Donations contributed to your Project will be used solely
          as described in the materials that you post or otherwise provide on
          the Platform and/or to AP;
          <br />● If you withdraw donations on behalf of someone other than you
          (i.e., the Beneficiary), all Donations will be given to and/or spent
          on behalf of the Beneficiary;
          <br />● It is your responsibility to determine what, if any, taxes
          apply to the Donations you receive through your use of the Platform.
          It is solely your responsibility to assess, collect, report, or remit
          the correct tax, if any, to the appropriate tax authority;
          <br />● You will not infringe the rights of others;
          <br />● You will comply with all relevant and applicable law and
          financial reporting obligations, including but not limited to laws and
          regulations relating to registration, tax reporting, political
          contributions, and asset disclosures for your project;
          <br />● To the extent you share with us any personal data of any third
          party for any purpose, including the names, email addresses, and phone
          numbers of your personal contacts, you have the authority (including
          any necessary consents), as required under applicable law, to provide
          us with such personal data and allow us to use such personal data for
          the purposes for which you shared it with us. You authorize AP, and AP
          reserves the right to provide information relating to your Project to
          any third parties within AP’s activities;
          <br />● You agree with and confirm the provisions of the Paragraph 2.1
          of this Terms (Intellectual property). Don’t offer prohibited
          Projects. Don’t offer any Project that is illegal, violate any of
          policies, rules, or guidelines, or violate any applicable law,
          statute, ordinance or regulation. You may receive information about
          Donors, including things like their names, email addresses, and postal
          addresses. This information is provided for the purpose of
          participating in (incl. donating) your project: don’t use it for other
          purposes, and don’t abuse it. The Project verification is carried out
          in accordance with the internal documents and policies of AP. Please
          evaluate your time and effort possibilities in advance before applying
          to AP to post and run your Project on the Platform and be prepared for
          a possible refusal due to non-compliance with Terms, AP’s policies,
          internal rules, or strategy. After posting a Project on the Platform
          Project creators are also required to provide Donors and AP with
          Project progress and finance reports (in a timely manner) and are due
          on the date specified by AP. The Platform and Donors depend heavily
          upon progress and finance reports to evaluate progress. Failure to
          submit progress or finance reports or any other related information or
          documents in a timely manner or incorrect information in these reports
          or documents can result in a termination of the Project by AP’s sole
          discretion. In the event of Project termination by AP (for any
          reason), the Project creator is obliged to immediately refund all the
          Donations raised (if any) by the termination moment to the
          corresponding Donors and/or transfer related donations to AP’s bank
          account (depending on AP’s Email notification). The Project creator
          undertakes to disclose to AP any conflict of interests along with any
          personal interests, affiliation, or activity with AP and/or Service
          provider.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          3.1 Content exclusion article
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          The following are examples of Project/Initiative/User Content that
          cannot be accepted by AP:
          <br />∙ any -related Projects and Initiatives, as (i) AP cannot ensure
          transparency for such projects; (ii) promoting Projects and
          Initiatives of a nature on social media may be deemed an illegal
          activity in some countries
          <br />∙ any Projects or Initiatives in support of or in opposition to
          any political party or individual candidate;
          <br />∙ promotion of drugs, narcotics, steroids, controlled
          substances, pharmaceuticals or similar products or therapies that are
          either illegal, prohibited or enjoined by an applicable regulatory
          body;
          <br />∙ promotion of investment schemes, loans, equity contracts (i.e.
          investment opportunities or other related services);
          <br />∙ promotion of gambling, betting, lottery and other related
          content or services;
          <br />∙ content that reflects or promotes behavior that AP deems, in
          its sole discretion, to be an abuse of power or in support of hate,
          violence, harassment, bullying, discrimination, terrorism or
          intolerance;
          <br />∙ any other activity that AP may deem, in its sole discretion,
          to be unacceptable. This list is not exhaustive and we reserve the
          right to refuse any application, freeze/remove any Project or
          Initiative and/or investigate any User who, in AP’s sole discretion,
          violates any of the terms or spirit of these Terms and/or for which
          transparency cannot be ensured.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          4. Donor
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          All Donations are at your own risk. We (AP) do our best to verify the
          information that Project creators or any other User supply, and to
          achieve that the Donations will be used in accordance with any Project
          purpose prescribed by a Project creator, but AP is not responsible for
          any offers, promises or promotions made or offered by Project creators
          or any other Platform User and you understand and agree that AP shall
          not be responsible for the use of your donations or the amount of
          funds raised for the Project creator. We expressly disclaim any
          liability or responsibility for the outcome or success of any Project.
          You, as a Donor, must make the final determination as to the value and
          appropriateness of contributing to any Project creator. If you have
          reason to believe that a Project creator is not raising or using the
          funds for the stated purpose, please use info@armenianpeace.am to
          alert our team of this potential issue and we will investigate. Donors
          shall provide AP (if any corresponding AP’s email notification) with
          information, necessary for the fulfillment by AP of its duties, incl.
          providing necessary information to the authorities. Donor acknowledges
          and agrees that Donor’s personal information can be shared with the
          corresponding Project creator to which the Donor donates and other
          third parties, involved in the implementation of the corresponding
          Project and may be used by such Project creator or third party within
          the framework of the corresponding Project. AP is not responsible, and
          shall not be liable, for any Project creator’s or third party’s use of
          any Donor’s information. In the event of the Project termination by
          the reason of violation by the Project creator of this Terms or other
          AP’s policies, AP will take necessary efforts (with no guarantee) to
          refund the donations contributed by the Donor to the corresponding
          Project creator. When a Donation is due to be refunded to Donor in
          accordance with this Terms (by any reason) AP has the right, upon
          written consent of the Donor (by email) or if there is no reply to the
          correspodindg email request within 10 calendar days, to organize
          transferring of the corresponding donation to AP’s account to
          implement AP’s statutory goals and/or to another ongoing Project/s.
        </Text>

        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          5. Initiator
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          You, as an Initiator, post (initiate) on the Platform the existing
          problem and its possible solution aimed at having a social impact to
          find partners (collaborators) for cooperation and solving
          (Initiative). You, as an Initiator represent, warrant, and covenant
          that:
          <br />● All information you provide in connection with your Initiative
          is accurate, complete, and not likely to deceive AP or any User of the
          Platform;
          <br />● Register on the Platform using your true identities, including
          your name, address, and any image or video purporting to depict the
          problem and its solution. You agree to keep registration information
          current and up to date. If AP at any time discovers that the
          information you provided about you or the purpose of your initiative
          is incorrect, it doesn’t meet AP’s policies or violates any of these
          Terms, your initiative may be rejected, suspended, and/or terminated
          with immediate effect;
          <br />● Before submitting an Initiative application on the Platform,
          you undertake to make sure that the issue/problem is valid and
          relevant;
          <br />● You will maintain (administer) your Initiative page on the
          Platform (including timely and efficiently filling with the necessary
          information), interact with Platform Users efficient and friendly;
          <br />● You will timely respond to notifications and proposals
          submitted by implementers and collaborators after the Initiative is
          posted on the Platform;
          <br />● You grant us the right to edit, modify, reformat, excerpt,
          delete, or translate any of your content on the Platform;
          <br />● You’re responsible for the stuff you post. All information
          submitted by you to the Platform or AP directly, whether publicly
          posted or privately transmitted, is your sole responsibility;
          <br />● We’re not responsible for mistakes in your content. AP will
          not be liable for any errors or omissions in any of your content;
          <br />● You agree with and confirm the provisions of the Paragraph 2.1
          of this Terms (Intellectual property). Don’t place a prohibited
          initiative. Don’t offer any initiative that is illegal, violate any of
          policies, rules, or guidelines, or violate any applicable law,
          statute, ordinance, or regulation. To run an initiative, you fill an
          application on the Platform and provide AP (if there is a
          corresponding request) with all necessary documents, information, and
          materials (including for the purpose of conducting compliance and
          trust and safety procedures). The Initiative verification is carried
          out in accordance with the internal documents and policies of AP.
          Please evaluate your time and effort possibilities in advance before
          applying your Initiative on the Platform and be prepared for a
          possible refusal of your Initiative due to non-compliance with Terms,
          AP’s policies, internal rules, or strategy. You may receive
          information about potential Implementers and Collaborators, including
          things like their names, email addresses, and postal addresses. This
          information is provided for the purpose of cooperating and
          participating in your Initiative: don’t use it for other purposes, and
          don’t abuse it. You, as an Initiator, undertake to objectively and
          reasonably select and appoint an Implementor of the Initiative in
          order to implement the Initiative you have created (to solve the
          problem). You also may become an implementor or collaborator yourself
          if you objectively and reasonably have all the necessary means and
          resources. AP has the right to inspect the Implementer, proposed by
          the Initiator, to test his ability to timely and efficiently perform
          the proposed work / provide the proposed services and, based on the
          results of such inspection, refuse the Initiator to appoint the
          corresponding Implementer with a proposal to submit another candidate.
          Initiator undertakes to disclose to AP any conflict of interests along
          with any personal interests, affiliation or activity with AP and/or
          Implementer.
        </Text>

        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          6. Implementer
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          You, as an Implementer obliges to implement/organize the
          implementation of the initiative, placed on the Platform, as you
          objectively and reasonably have all necessary means and resources for
          that. The Implementer is responsible for the timely and complete
          implementation of the Initiative (solving the existing problem)
          according to the solution proposed by the corresponding initiator,
          both independently and with the involvement of collaborators,
          remaining responsible to donors and AP for timely and high-quality
          implementation of the Initiative. Implementer provides AP (if there is
          a corresponding request) with all necessary documents, information and
          materials for the purpose of conducting compliance and trust and
          safety procedures and also to verify that the Implementer has all the
          necessary resources to successfully implement the initiative. You, as
          an Implementor, undertake to objectively and reasonably select and
          appoint a Collaborator/-s (if needed) for the Initiative in order to
          successfully implement the Initiative you are responsible for. You may
          also implement the Initiative without involving of Collaborators in
          case you objectively and reasonably have all necessary means and
          resources to do so. AP has the right to inspect the Collaborator/-s,
          proposed by the Implementor, to test his/their ability to timely and
          efficiently perform the proposed work / provide the proposed services
          and, based on the results of such inspection, refuse the Implementor
          to appoint the corresponding Collaborator with a request to submit
          another candidate. The Implementor is obliged to timely respond to
          notifications and proposals submitted by Collaborators after the
          initiative is posted on the Platform; When an Initiative is completed
          (all the necessary parties for the implementation of the Initiative
          are appointed and organized) and, if the Initiative needs to be placed
          on the Platform to raise funds (donations) for implementation, The
          Implementor becomes a Project creator, unless otherwise agreed with
          AP. You, as an Implementor represent, warrant, and covenant that:
          <br />● All information you provide on/through the Platform is
          accurate, complete, and not likely to deceive AP or any User of the
          Platform;
          <br />● Register on the Platform using your true identities, including
          your name, address. You agree to keep registration information current
          and up to date. If AP at any time discovers that the information you
          provided about you is incorrect, doesn’t meet AP’s policies, or
          violates any of these Terms, your proposal may be rejected, your
          profile (account) may be terminated with immediate effect;
          <br />● You will maintain (administer) your profile page on the
          Platform (including timely and efficiently filling with the necessary
          information), interact with all users through the Platform efficient
          and friendly;
          <br />● You grant us the right to edit, modify, reformat, excerpt,
          delete, or translate any of your content on the Platform;
          <br />● You’re responsible for the stuff you post. All information
          submitted to the Platform or AP directly, whether publicly posted or
          privately transmitted, is the sole responsibility of the person from
          whom that content originated;
          <br />● We’re not responsible for mistakes in your content. AP will
          not be liable for any errors or omissions in any of your content.
          Please evaluate your time and effort possibilities, means, and
          resources in advance before applying for implementation of initiative
          on the Platform and be prepared for a possible refusal due to
          non-compliance with Terms, AP’s policies, internal rules, or strategy.
          The Implementer undertakes to disclose to AP any conflict of interests
          along with any personal interests, affiliation, or activity with AP
          and/or Collaborator.
        </Text>

        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          7. Collaborator
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          You, as a Collaborator obliges to collaborate with the corresponding
          Implementor and other collaborators (if any) to collectively implement
          the Initiative, placed on the Platform, as you objectively and
          reasonably have all necessary means and resources for that.
          Collaborator is responsible for the timely and complete implementation
          of a certain part of the corresponding Initiative according to the
          solution proposed by the Initiator, remaining responsible to
          Implementor. Collaborator provides Implementor and AP (if there is a
          corresponding request) with all necessary documents, information, and
          materials for the purpose of conducting compliance and trust and
          safety procedures and also to verify that the Collaborator has all the
          necessary resources to successfully implement a certain part of the
          Initiative. Collaborator obliges to timely respond to users (incl.
          initiators and implementors) notifications and proposals submitted on
          / through the Platform. You, as a Collaborator represent, warrant, and
          covenant that:
          <br />● all information you provide on/through the Platform is
          accurate, complete, and not likely to deceive AP or any User of the
          Platform;
          <br />● register on the Platform using your true identities, including
          your name, address. You agree to keep registration information current
          and up to date. If AP at any time discovers that the information you
          provided about you is incorrect, doesn’t meet AP’s policies or
          violates any of these Terms, your collaboration may be canceled, your
          profile (account) may be terminated with immediate effect;
          <br />● you will maintain (administer) your profile page on the
          Platform (including timely and efficiently filling with the necessary
          information), interact with all users through the Platform efficient
          and friendly;
          <br />● you grant us the right to edit, modify, reformat, excerpt,
          delete, or translate any of your content on the Platform;
          <br />● you’re responsible for the stuff you post. All information
          submitted to the Platform or AP directly, whether publicly posted or
          privately transmitted, is the sole responsibility of the person from
          whom that content originated;
          <br />● we’re not responsible for mistakes in your content. AP will
          not be liable for any errors or omissions in any of your content.
          Please evaluate your time and effort possibilities, means and
          resources in advance before applying for collaboration on the Platform
          and be prepared for a possible refusal due to non-compliance with
          Terms, AP’s policies, internal rules or strategy. Collaborator
          undertakes to disclose to AP any conflict of interests along with any
          personal interests, affiliation or activity with AP and/or Initiator
          and/or Implementor.
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          8. Service Provider
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          AP enables the opportunity for Project creators, Initiators or
          Implementors to leverage from a wide range of services aimed at
          facilitation of Project or Initiative implementation process through
          the list of Service providers here. The Service providers are legal
          entities or individuals, specialized in different areas, including but
          not limited to marketing, visual content creation, copywriting, legal,
          finance, GR, etc. You, as a Service provider, having objectively and
          reasonably all necessary means and resources, undertake to provide
          corresponding services to the Project creator, Initiator or
          Implementor who have contacted you for that. The Service provider is
          responsible for the timely and complete provision of services
          according to the corresponding agreement, signed between the Service
          provider and Project creators/Initiators/Implementors. The Service
          provider independently decides on what basis to provide the service:
          paid, partnership or charity. All rights and obligations will be on
          the sides (Service providers and Project
          creators/Initiators/Implementors). The Platform serves as a venue for
          meet up between Service providers and Project
          creators/Initiators/Implementors. Regardless of anything AP isn’t
          liable for any damages or losses related to cooperation between
          Service providers and Project creators/Initiators/Implementors. We
          don’t become involved in disputes between Platform users. The Service
          provider undertakes to disclose to AP any conflict of interests along
          with any personal interests, affiliation, or activity with Initiators
          and/or Implementors and/or AP. The Service provider also undertakes to
          provide AP (if there is a corresponding request) with all necessary
          documents, information, and materials for the purpose of conducting
          compliance and trust and safety procedures and also to verify that the
          Service provider has all the necessary resources to provide
          corresponding services. You, as a Service provider represent, warrant,
          and covenant that:
          <br />● all information you provide on/through the Platform (incl. but
          not limited to your professional skills and experience) is accurate,
          complete, and not likely to deceive AP or any User of the Platform;
          <br />● register on the Platform using your true identities, including
          your name, address. You agree to keep registration information current
          and up to date. If AP at any time discovers that the information you
          provided about you is incorrect, doesn’t meet AP’s policies or
          violates any of these Terms, your profile (account) may be terminated
          with immediate effect;
          <br />● you will maintain (administer) your profile page on the
          Platform (including timely and efficiently filling with the necessary
          information), interact with all users through the Platform efficient
          and friendly;
          <br />● you grant us the right to edit, modify, reformat, excerpt,
          delete, or translate any of your content on the Platform;
          <br />● you’re responsible for the stuff you post. All information
          submitted to the Platform or AP directly, whether publicly posted or
          privately transmitted, is the sole responsibility of the person from
          whom that content originated;
          <br />● we’re not responsible for mistakes in your content. AP will
          not be liable for any errors or omissions in any of your content.
        </Text>

        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          9. Limitation of Liability
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          To the fullest extent permitted by law, in no event will AP, its
          affiliates, directors, employees be liable for any indirect,
          incidental, punitive, consequential, special, or exemplary damages of
          any kind, including but not limited to damages (i) resulting from your
          access to, use of, or inability to access or use the Platform (it’s
          services); (ii) for any lost profits, data loss, or cost of
          procurement or substitute goods or services; or (iii) for any conduct
          of content of any third party on the Platform. In no event shall AP’s
          liability for direct damages be in excess of (in the aggregate) fifty
          thousand Armenian Drams (AMD 50 000).
        </Text>
        <br />
        <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
          10. Disputes resolution. Governing law
        </Heading>
        <Text textAlign="justify" lineHeight="8">
          We at AP encourage you to contact us if you’re having an issue before
          resorting to the courts. Our support team is on hand and ready to
          answer your questions. You can contact us via email
          info@armenianpeace.am so that we can personally reply to you and
          attempt to resolve your issue. In the unfortunate situation where
          legal action does arise, these Terms (and all other rules, policies,
          or guidelines incorporated by reference) will be governed by and
          construed in accordance with the laws of the Republic of Armenia. You
          agree that any action at law arising out of or relating to these
          Terms, or your use or non-use of the Platform, shall be filed only in
          the courts located in the Republic of Armenia.
        </Text>
      </Container>
      <Footer />
    </Flex>
  )
}

const TermsAM = () => (
  <Flex
    direction="column"
    width="100%"
    justifyContent="center"
    alignItems="center"
  >
    <Navbar />
    <Box mb="3rem" bg="gray.800" as="section" position="relative" w="full">
      <Box h="full" py="32" position="relative" zIndex={1}>
        <Flex
          h="full"
          alignItems="center"
          maxW={{ base: 'xl', md: '4xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          color="gray.100"
        >
          <Box maxW="3xl">
            <Heading
              as="h1"
              fontSize={{ base: '5xl', md: '6xl' }}
              fontWeight="extrabold"
            >
              Դրույթներ & Պայմաններ
            </Heading>
          </Box>
        </Flex>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box position="relative" w="full" h="full">
          <Img
            src="https://images.unsplash.com/photo-1585909695677-2b0492f96e3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
            alt="Main Cover"
            w="full"
            h="full"
            objectFit="cover"
            objectPosition="center"
            position="absolute"
          />
          <Box
            position="absolute"
            w="full"
            h="full"
            bg="linear-gradient(134.85deg, #002D72 0%, rgba(0,45,114,0) 100%)"
          />
        </Box>
      </Flex>
    </Box>
    <Container pb="5rem" px={{ base: '6', md: '8' }} maxW="4xl">
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        1. Հարթակը/«Հայկական խաղաղություն»
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        Օգտվելով «Անվտանգային և ռազմամարզական ոլորտի ներկայացուցիչների
        միավորում» հասարակական կազմակերպության (մասնաճյուղերի,
        ներկայացուցիչների, խորհրդատուների, աշխատակիցների, պաշտոնյաների և
        տնօրենների հավաքական անունը՝ «Հայկական խաղաղություն» կամ «մենք») կողմից
        առաջարկվող կայքից (armenianpeace.am)՝ Դուք տալիս ես Ձեր համաձայնությունը
        ներքոնշյալ իրավաբանորեն պարտավորեցնող կանոններին («Դրույթներ»):
        armenianpeace.am կայքը առցանց համագործակցության հարթակ է (այսուհետ՝
        «Հարթակ»), որը . նվիրատվությունների մասով թույլ է տալիս ֆիզիկական,
        իրավաբանական անձանց, առևտրային կամ ոչ առևտրային կազմակերպություններին
        («Նախագծի հեղինակ») հրապարակել իրենց դրամահավաքի նախագիծը/արշավը
        («Նախագիծ») (որը հետապնդում է սոցիալական ազդեցության նպատակ)՝
        նվիրատուներից («Նվիրատուներ») դրամական նվիրատվություններ
        («Նվիրատվություններ») ստանալու համար։ Նախագծի հեղինակները չեն տրամադրում
        ապրանքներ կամ ծառայություններ Նվիրատվությունների դիմաց, սակայն կարող են
        բոնուսներ առաջարկել Նվիրատուներին ֆինանսավորում տրամադրելու դիմաց։
        i.համագործակցության առումով թույլ է տալիս ֆիզիկական, իրավաբանական
        անձանց, առևտրային կամ ոչ առևտրային կազմակերպություններին («Նախաձեռնող»)
        հրապարակել իրենց նախաձեռնությունը (առկա խնդիրը և դրա հնարավոր լուծումը,
        որը հետապնդում է սոցիալական ազդեցության նպատակ)՝ համագործակցության և
        խնդրի լուծման համար գործընկերներ (իրականացնողներ և համագործակցողներ)
        ձեռքբերելու նպատակով: Եթե որևէ Օգտատեր խախտի սույն Դրույթներից որևէ
        մեկը, մենք («Հայկական խաղաղությունը») իրավունք ենք վերապահում մեր
        հայեցողությամբ դադարեցնելու, կասեցնելու համապատասխան Օգտատիրոջ հաշիվը
        կամ հանձն առնելու որևէ այլ գործողություն: Օգտատերը չի կարող մուտք գործել
        Հարթակ, եթե մեր կողմից արգելափակվել է վերջինիս հասանելիությունը: Հարթակի
        աշխատանքն ու ամբողջականությունը պաշտպանելու, ինչպես նաև Նախագծի
        հեղինակների, Նվիրատուների, Նախաձեռնողների և մյուս բոլոր Օգտատերերի համար
        ապահով և անվտանգ միջավայրը ապահովելու նպատակով «Հայկական
        խաղաղությունը»-ն ունի հետևյալ իրավունքները. <br />
        ● Մենք կարող ենք փոփոխություններ կատարել Հարթակում առանց ծանուցման կամ
        պատասխանատվություն կրելու: <br />● Մենք իրավունք ունենք որոշելու, թե ով
        է իրավասու օգտագործել Հարթակը: Մենք կարող ենք ցանկացած պահի չեղարկել
        հաշիվները կամ հրաժարվել Հարթակում ծառայություններ մատուցելուց (հատկապես
        եթե Դուք չարաշահում եք դրանք)։ Մենք ցանկացած պահի կարող ենք փոխել
        իրավասության չափանիշները: <br />● Մենք իրավունք ունենք ցանկացած ժամանակ
        և ցանկացած պատճառով մերժելու, չեղարկելու, դադարեցնելու, ընդհատելու,
        հեռացնելու կամ կասեցնելու որևէ Նախագիծ կամ Նախաձեռնություն: «Հայկական
        խաղաղությունը»-ն պատասխանատվություն չի կրում նշված գործողություններից
        որևէ մեկի հետևանքով պատճառված վնասի համար: Մենք որդեգրել ենք այսօրինակ
        որևէ գործողության պատճառները չմեկնաբանելու ընդհանուր քաղաքականություն։
        Այնուամենայնիվ, եթե մենք սահմանափակում ենք ստուգված հաշվի սեփականատիրոջ
        հասանելիությունը Հարթակի որևէ ծառայություն նկատմամբ, կամ եթե մտադիր ենք
        չեղարկելու ստուգված հաշիվը, մենք մեր հայեցողությամբ կարող ենք տեղեկացնել
        հաշվի օգտատիրոջը այդ գործողության պատճառների մասին, և թե վերջինս ինչպես
        կարող է լուծել ցանկացած խնդիր (անհրաժեշտության դեպքում), եթե չկա արգելք՝
        ելնելով անվտանգության շահերից:
      </Text>

      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        1.1 Վճարումներ, վարձատրություն և հավելավճարներ «Հայկական
        խաղաղություն»-ին
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        «Հայկական խաղաղություն»-ն չի հանդիսանում վճարային ծառայություններ
        մատուցող։ «Հայկական խաղաղություն»-ը համագործակցում է վճարային
        ծառայություններ մատուցող երրորդ կողմերի հետ նվիրատվությունների վճարման
        համար («Վճարային ծառայություն մատուցող»): Դուք ընդունում և համաձայնում
        եք, որ մենք տեղեկատվություն ենք փոխանակում Վճարային ծառայություն
        մատուցողների և ցանկացած երրորդ կողմի հետ՝ Հարթակի գործունեությունը
        համակարգելու նպատակով: Նախագծի հեղինակներից որևէ վճար չի գանձվում այն
        Նվիրատվությունների շրջանակում, որոնք նրանք հավաքագրում են Հարթակում:
        Նախագծի և Նախաձեռնության մասին հրապարակում կատարելու համար Օգտատիրոջից
        գանձվող գումարը կազմում է 1000 դրամ, սակայն այն տրամադրվում է անվճար՝
        հաշվի առնելով, որ «Հայկական խաղաղություն»-ի և Հարթակի գործողությունները
        միտված են սոցիալական ազդեցություն ունենալուն: Նախագծի հեղինակներից
        գանձվում է գործարքի վճար Վճարային ծառայություն մատուցողի կողմից:
        Փոխանցման վճարները կամ բանկային առաքման վճարները նույնպես գանձվում են
        ամեն անգամ, երբ Վճարային ծառայություն մատուցողը գումար է փոխանցում
        Նախագծի հեղինակի բանկային հաշվին: «Հայկական խաղաղություն»-ի և
        համապատասխանաբար Հարթակի ինքնակայունությունը կախված է Նվիրատուների
        կատարած հավելավճարներից։ Նվիրատվության գործարքներից միջնորդավճար
        գանձելու փոխարեն «Հայկական խաղաղություն»-ը հնարավորություն է տալիս
        Նվիրատուներին կամավոր հիմունքներով հավելավճար փոխանցել: Նվիրատուների
        կողմից «Հայկական խաղաղություն»-ին տրամադրված հավելավճարները հետ չեն
        վերադարձվում: Հարթակում Նախագծի հեղինակի համար հասանելի են դրամահավաքի
        երկու տարբերակ՝ «Ֆիքսված ֆինանսավորում» (ամեն ինչ կամ ոչինչ) և «Ճկուն
        ֆինանսավորում» (պահել ամեն ինչ): Ֆիքսված ֆինանսավորմամբ նախագծի դեպքում
        Նախագծի հեղինակը պահպանում է հայթայթած միջոցները միայն այն դեպքում, երբ
        թիրախային գումարը նվիրաբերվել է: Եթե Նախագիծը (դրամահավաքի արշավը) չի
        հասնում իր ֆինանսավորման նպատակին նախքան վերջնաժամկետը, ապա գումարը
        վերադարձվում է բոլոր Նվիրատուներին, կամ վերջիններիս գրավոր
        համաձայնությամբ (էլեկտրոնային փոստով) համապատասխան նվիրատվությունները
        կարող են փոխանցվել «Հայկական խաղաղություն»-ի հաշվեհամարին՝ իրականացնելու
        «Հայկական խաղաղություն»-ի կանոնադրությամբ սահմանված նպատակները, և/կամ
        մեկ այլ ընթացիկ Նախագծի/երի: Ճկուն ֆինանսավորմամբ Նախագծի դեպքում անկախ
        նրանից՝ Նախագծի հեղինակը հասել է իր ֆինանսավորման նպատակին, թե ոչ, կարող
        է ստանալ այն ամբողջ գումարը, որը ձեռք է բերվել Նախագծի ընթացքում
        (դրամահավաքի արշավ) այն պայմանով, որ (եթե Նախագիծը չի հասնում իր
        ֆինանսավորման նպատակին նախքան վերջնաժամկետը) Նախագծի հեղինակը կարող է
        հաստատել և տրամադրել ապացույց «Հայկական խաղաղություն»-ին առ այն, որ
        հավաքագրված միջոցները կարող են ծախսվել Նախագծի որոշակի մասի իրականացման
        վրա՝ (բավարար) սոցիալական ազդեցության նպատակով։ Նախագծի հեղինակները
        չունեն երաշխիք անմիջապես ստանալու Նախագծի համար կատարված
        Նվիրատվությունները նույնիսկ Նախագծի ավարտից և դրամահավաքի նպատակի
        բավարարումից հետո: Նախագծի ավարտից հետո (դրամահավաքի արշավ) Նախագծի
        հեղինակին Նվիրատվությունների փոխանցումը կարող է հետաձգվել մի շարք
        պատճառներով՝ ներառյալ, բայց չսահմանափակվելով՝ փոխհատուցում, գումարի
        վերադարձի հետ կապված խնդիրներ, կեղծարարություն, Դրույթների կամ
        քաղաքականության խախտումներ։ Հավաքագրված Նվիրատվությունների փոխանցումը
        Նախագծի հեղինակին իրականացվում է «Հայկական խաղաղություն»-ի և Նախագծի
        հեղինակի միջև կնքված համապատասխան պայմանագրով սահմանված կարգով և
        ժամկետներով: Բոլոր նվիրատվություններն ուղղվում են Վճարային
        ծառայություններ մատուցողի («Հայկական խաղաղություն»-ի գործընկեր բանկ)
        տարանցիկ հաշվին, այնուհետև փոխանցվում են Նախագծի հեղինակի հաշվին:
        Ընդհանուր քաղաքականության համաձայն՝ հավաքագրված Նվիրատվությունները
        փոխանցվում են Նախագծի հեղինակներին մասնաբաժիններով, երբ Նախագծի փուլերը
        հաջողությամբ իրականացվում են, և Նախագծի հեղինակին վերաբերող
        հաշվետվությունները ուղարկվում են «Հայկական խաղաղություն»-ին:
      </Text>

      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        1.2 «Հայկական խաղաղություն»-ը միայն վարչական հարթակ է
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        armenianpeace.am-ը միայն վարչական հարթակ է։ Հարթակը համակարգում է
        Նախագծերը և թույլ է տալիս Նվիրատուներին նվիրատվություններ տրամադրել
        Նախագծի հեղինակներին: «Հայկական խաղաղություն»-ը չի հանդիսանում միջնորդ,
        գործակալ, ֆինանսական հաստատություն կամ վարկատու: Անկախ ամեն ինչից՝
        «Հայկական խաղաղություն»-ը պատասխանատվություն չի կրում Ձեր կողմից Հարթակի
        օգտագործման հետ կապված որևէ վնասի կամ կորստի համար։ Մենք չենք միջամտում
        օգտատերերի, կամ օգտատերերի և որևէ երրորդ կողմի միջև Հարթակի օգտագործմանն
        առնչվող վեճերին: Հարթակը օգնում է Նախագծի հեղինակներին ֆինանսական
        միջոցներ հայթայթել, իսկ Նախաձեռնողներին՝ գտնել
        համագործակցողներ/իրագործողներ: «Հայկական խաղաղություն»-ը տրամադրում է
        միայն տեխնոլոգիական միջոց, որը թույլ է տալիս Նախագծի հեղինակներին կապ
        հաստատել Նվիրատուների հետ, իսկ Նախաձեռնողներին՝
        համագործակցողների/իրագործողների հետ: Մենք չենք աջակցում (հաստատում) որևէ
        Նախագծի, Նախագծի հեղինակի, Նախաձեռնողի, Օգտատիրոջ կամ գործընթացի: Մենք
        անհրաժեշտ ջանքեր ենք գործադրում՝ ստուգելու Նախագծի հեղինակների,
        Նախաձեռնողների և այլ Օգտատերերի կողմից տրամադրված տեղեկատվությունը
        (ինչպես նշված է ստորև), բայց չենք երաշխավորում Հարթակում տրամադրված որևէ
        տեղեկության ճշգրտությունը: Ոչ մի բովանդակություն նախատեսված չէ
        տրամադրելու ֆինանսական, իրավական, հարկային կամ այլ մասնագիտական
        խորհրդատվություն: Հարթակը կամ դրա ծառայությունները կարող են անհասանելի
        լինել պլանային սպասարկման և այլ պատճառներով` ներառյալ չպլանավորված
        անջատումները և այլ անսարքությունները: Մենք պատասխանատվություն չենք կրում
        Հարթակի կամ դրա ծառայություններնի անհասանելիության համար, կամ եթե Դուք
        որևէ պատճառով կորցնում եք որևէ տվյալ, տեղեկատվություն կամ Օգտատիրոջ
        բովանդակություն:
      </Text>
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        1.3 «Հայկական խաղաղության» ջանքերը
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        «Հայկական խաղաղություն»-ը անհրաժեշտ ջանքեր է գործադրում՝ ուսումնասիրելու
        Նախագծի և/կամ Նախագծի հեղինակի, Նախաձեռնողի կամ Օգտատիրոջ
        բովանդակությունը, ստուգելու Նախագծերի համապատասխանությունը, Նախագծի
        հեղինակի կողմից առաջարկվող լուծման օպտիմալությունը, և պարզում է, թե
        արդյոք Նախագծի հեղինակն ունի այնպիսի թիմ, որը կարող է հաջողությամբ
        իրականացնել առաջարկվող Նախագիծը: Վերոնշյալի ստուգման շրջանակներում
        իրականացվում են Նախագծի հեղինակի կանոնադրական փաստաթղթերի, Նախագծի
        հեղինակի շահերը ներկայացնող անձանց լիազորությունների, ներկայացված
        Նախագծի բյուջեի ստուգում, խորհրդատուների կողմից գնահատում և այլն։
        Նախագծի/Նախագծի հեղինակի կամ Օգտատիրոջ բովանդակությունը ուսումնասիրելիս
        մենք կարող ենք դիտարկել բոլոր հասանելի նյութերը՝ ներառյալ, բայց
        չսահմանափակվելով՝ սոցիալական լրատվամիջոցները, առնչվող նորությունները և
        ցանկացած այլ տեղեկատվություն, որը մենք մեր հայեցողությամբ կարևոր ենք
        համարում վերանայել: Ինչպես նշվեց, յուրաքանչյուր Նախագիծ գնահատվում է
        անկախ խորհրդատվական խորհրդի կողմից, որում ներգրավված են համապատասխան
        ոլորտի խորհրդատուները (մասնագետները): Յուրաքանչյուր Նախագծի համար
        իրականացվող ստուգումների շրջանակը կարող է տարբերվել՝ կախված ոլորտից:
        Նախագծի հեղինակները նաև պարտավոր են «Հայկական խաղաղություն»-ին և
        Նվիրատուներին տրամադրել Նախագծի/դրամահավաքի արշավի ընթացքի վերաբերյալ
        զեկույցներ և ֆինանսական հաշվետվություններ «Հայկական խաղաղություն»-ի
        կողմից սահմանված ժամկետում (ժամանակին): Հարթակը և Նվիրատուները Նախագծի
        առաջընթացը գնահատում են՝ ելնելով մեծապես կատարողականի վերաբերյալ
        զեկույցներից և ֆինանսական հաշվետվություններից: Կատարողականի վերաբեյալ
        զեկույցների, ֆինանսական հաշվետվությունների կամ առնչվող որևէ
        տեղեկատվության և փաստաթղթի ժամանակին չներկայացնելը կամ այս
        հաշվետվություններում և փաստաթղթերում առկա սխալ տեղեկատվությունը կարող է
        հանգեցնել Ծրագրի դադարեցման կամ ընդհատման «Հայկական խաղաղություն»-ի
        հայեցողությամբ: Մենք շատ լուրջ ենք վերաբերվում հավանական կեղծարարությանը
        և հավաքագրված միջոցների չարաշահմանը: Եթե հիմքեր կան ենթադրելու, որ
        Նախագծի հեղինակը չի հայթայթում կամ օգտագործում ֆինանսական միջոցները
        նշված նպատակի համար, խնդրում ենք տեղեկացնել հնարավոր խնդրի մասին՝
        ուղարկելով նամակ info@armenianpeace.am էլեկտրոնային հասցեին, և մենք
        կուսումնասիրենք խնդիրը: Մենք անում ենք հնարավորինս ամեն ինչ, որպեսզի
        Նվիրատվություններն օգտագործվեն Նախագծի հեղինակի կողմից սահմանված
        համապատասխան նպատակների համար, բայց մենք չենք տրամադրում որևէ երաշխիք:
        Մենք նաև չենք երաշխավորում, որ Նախագիծը կկարողանա հայթայթել որոշակի
        քանակությամբ կամ, ընդհանրապես, որևէ Նվիրատվություն:
      </Text>

      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        2. Ցանկացած Օգտատեր
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        Հարթակի բազմաթիվ ծառայություններից օգտվելու համար անհրաժեշտ է գրանցվել և
        ստեղծել օգտանունով և գաղտնաբառով հաշիվ: Դուք պետք է մեզ տրամադրեք ճշգրիտ
        և ամբողջական տեղեկատվություն և պարբերաբար թարմացնեք Ձեր հաշվի տվյալները:
        Դուք չեք կարող հաշիվ բացել այլ մարդու անվամբ, ընտրել վիրավորական
        օգտանուն կամ խախտել որևէ անձի իրավունքները: Դուք բացառապես պատասխանատու
        եք Ձեր հաշվի գաղտնիությունը պահպանելու և Ձեր հաշվին առնչվող կամ դրա
        ներքո տեղի ունեցող բոլոր գործողությունների համար: Դուք ընդունում եք, որ
        ամբողջ տեղեկատվությունը և բովանդակությունը, որը ձեռք եք բերել Հարթակն
        օգտագործելիս, գտնվում է Ձեր հայեցողության ներքո: Դուք բացառապես
        պատասխանատու եք Հարթակի օգտագործմանն առնչվող բոլոր կիրառելի օրենքներին
        համապատասխանելու համար: Բացի այդ, Դուք բացառապես պատասխանատու եք
        Օգտատիրոջ բովանդակության համար, որը վերբեռնում եք, տեղադրում,
        հրապարակում, ցուցադրում, փոխանցում կամ այլ կերպ օգտագործում: Դուք
        համաձայնում եք մեզ ապացույցներ տրամադրել այն դեպքում, երբ
        անհրաժեշտություն է ստեղծվում ստուգել Ձեր համապատասխանությունը սույն
        Դրույթներին: Եթե կասկածում եք կամ հայտնաբերում եք Ձեր հաշվի չլիազորված
        օգտագործման դեպք, ապա պետք է անհապաղ տեղեկացնեք «Հայկական
        խաղաղություն»-ին՝ info@armenianpeace.am էլ․ հասցեով: Մենք
        պատասխանատվություն չենք կրում որևէ կորստի կամ վնասի համար, որը առաջանում
        է վերոնշյալ պահանջները չբավարարելու կամ առանց Ձեր իմացության Ձեր հաշվի
        օգտագործման հետևանքով: Հարթակում արգելվում են ներքոնշյալ
        գործողությունները։
        <br />● Մի՛ խախտեք օրենքը։ Մի՛ ձեռնարկեք որևէ գործողություն, որը խախտում
        է այլ մարդկանց իրավունքները, օրենքը, կամ հակասում է որևէ պայմանագրի կամ
        իրավական պարտականության, որը Դուք ունեք որևէ մեկի նկատմամբ:
        <br />● Մի՛ ստեք մարդկանց։ Մի հրապարակեք կեղծ, ապակողմնորոշիչ կամ ոչ
        ճշգրիտ տեղեկություն: Մի՛ ձեռնարկեք որևէ խաբուսիկ կամ խարդախ
        գործողություն:
        <br />● Մի զոհեք որևէ մեկին։ Մի ձեռնարկեք այնպիսի գործողություն, որը
        սպառնում է, վիրավորում, ոտնձգում, զրպարտում, վիրավորում որևէ անձի կամ
        խախտում վերջինիս գաղտնիությունը:
        <br />● Մի՛ ուղարկեք սպամ: Մի՛ տարածեք որևէ կամայական կամ չլիազորված
        գովազդ կամ գովազդային նյութ, անցանկալի էլ․ փոստ, սպամ կամ շղթայական
        նամակներ: Մի՛ գործարկեք էլ հասցեների ցուցակներ, ցուցակների սերվերներ,
        ավտոմատ գեներացվող պատասխաններ կամ սպամ Հարթակում կամ Հարթակի միջոցով:
        <br />● Մի՛ չարաշահեք այլ օգտատերերի անձնական տվյալները: Երբ դուք
        օգտագործում եք Հարթակը և մասնավորապես ստեղծում եք Նախագիծ կամ
        Նախաձեռնություն, Դուք կարող եք տեղեկատվություն ստանալ Հարթակի այլ
        օգտատերերի մասին՝ ներառյալ վերջիններիս անունները, էլեկտրոնային և ոչ
        էլեկտրոնային փոստի հասցեները: Այս տեղեկատվությունը տրամադրվում է
        համապատասխան Նախագծին կամ Նախաձեռնությանը մասնակցելու նպատակով․ մի՛
        օգտագործեք այն այլ նպատակների համար և մի չարաշահեք:
      </Text>

      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        2.1 Մտավոր սեփականություն
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        «Հայկական խաղաղություն»-ին չի պատկանում այն բովանդակությունը, որը Դուք
        ներկայացնում եք մեզ («Բովանդակություն»): Երբ Դուք օգտվում եք Հարթակի
        ծառայություններից, այդ թվում՝ (բայց չսահմանափակվելով) ներկայացնում եք
        Նախագիծ ուսումնասիրության համար, կամ սկսում եք Նախագիծ կամ
        Նախաձեռնություն Հարթակում, Դուք համաձայնում եք հետևյալ դրույթներին.
        <br />● Մենք կարող ենք օգտագործել Ձեր կողմից ներկայացված
        Բովանդակությունը: Դուք մեզ և մեր անունից գործող այլ անձանց տալիս եք
        համաշխարհային, ոչ բացառիկ, հավերժական, անդառնալի, առանց հոնորարների,
        ենթալիցենզավորվող, փոխանցելի իրավունք՝ օգտագործելու, իրականացնելու,
        առևտրայնացնելու և շահագործելու Ձեր բովանդակության հետ կապված
        հեղինակային, հրապարակայնության, ապրանքանիշի և տվյալների բազայի
        իրավունքները։
        <br />● Երբ մենք օգտագործում ենք Բովանդակությունը, մենք կարող ենք
        փոփոխություններ կատարել, օրինակ՝ խմբագրել կամ թարգմանել այն: Դուք մեզ
        իրավունք եք տալիս խմբագրելու, փոփոխելու, վերափոխելու, տարանջատելու,
        ջնջելու կամ թարգմանելու Ձեր Բովանդակությունը։
        <br />● Դուք չեք կարող ներկայացնել այնպիսի Բովանդակություն, որի
        հեղինակային իրավունքը Ձեզ չի պատկանում (բացառությամբ երբ ունեք
        թույլտվություն): Ձեր Բովանդակությունը չպետք է պարունակի այնպիսի նյութ,
        որի հեղինակային կամ սեփականության իրավունքը պատկանում է երրորդ կողմին՝
        բացառությամբ եթե Դուք թույլտվություն եք ստացել նյութի օրինական
        սեփականատիրոջից, կամ այլապես իրավունք ունեք հրապարակելու նյութը (և
        տրամադրելու «Հայկական խաղաղություն»-ին այստեղ նշված լիցենզիայի բոլոր
        իրավունքները)։
        <br />● Ձեր Բովանդակությանն առնչվող ցանկացած հոնորար կամ արտոնագիր Ձեր
        պատասխանատվության տակ է: Դուք պետք է վճարեք Ձեր Բովանդակության հետ
        կապված բոլոր հոնորարները և ցանկացած այլ գումար, որը պարտք եք ցանկացած
        անձի կամ կազմակերպության։
        <br />● Դուք երաշխավորում եք, որ եթե մենք օգտագործում ենք ձեր
        Բովանդակությունը, մենք չենք խախտում որևէ մեկի իրավունքները կամ
        հեղինակային իրավունքները։
        <br />● Դուք պատասխանատու եք Հարթակում Ձեր տեղադրած տեղեկատվության
        համար: Հարթակին/« Հայկական խաղաղություն»-ին ներկայացված բոլոր
        տեղեկությունները, այդ թվում՝ հրապարակային տեղադրված և մասնավոր
        փոխանցված, բացառապես Բովանդակություն ստեղծողի պատասխանատվության տակ են։
        <br />● Մենք պատասխանատվություն չենք կրում Ձեր Բովանդակության սխալների
        համար: «Հայկական խաղաղություն»-ը պատասխանատվություն չի կրում որևէ
        Բովանդակության մեջ առկա որևէ սխալի կամ բացթողման համար:
      </Text>
      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        3. Նախագծի հեղինակ
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        Դուք՝ որպես Նախագծի ստեղծող, ներկայացնում եք, երաշխավորում և խոստանում
        եք հետևյալը․
        <br />● Ձեր Նախագծի կամ շահառուի հետ կապված Ձեր տրամադրած բոլոր
        տեղեկությունները ճշգրիտ են, ամբողջական և չեն խաբում «Հայկական
        խաղաղություն»-ին կամ Հարթակի որևէ Օգտատիրոջը։
        <br />● Դուք գրանցվում եք Հարթակում՝ ներկայացնելով Ձեր իրական
        ինքնությունը՝ ներառյալ Ձեր անունը, հասցեն և Նախագծի հեղինակին կամ
        Նախագծին վերաբերող նկար կամ տեսանյութ: Դուք համաձայնում եք թարմացնել
        գրանցման տեղեկատվությունը: Եթե «Հայկական խաղաղություն»-ը ցանկացած պահի
        հայտնաբերի, որ Ձեր տրամադրած տեղեկատվությունը կամ Ձեր Նախագծի նպատակը
        ճիշտ չէ կամ խախտում է սույն Դրույթներից որևէ մեկը, Ձեր Նախագիծը կարող է
        կասեցվել և/կամ դադարեցվել անմիջապես:
        <br />● Ձեր Նախագծում ներդրված բոլոր Նվիրատվությունները պետք է
        օգտագործվեն բացառապես այնպես, ինչպես նկարագրված է այն նյութերում, որոնք
        Դուք տեղադրում եք կամ այլ կերպ տրամադրում եք Հարթակին և/կամ «Հայկական
        խաղաղություն»-ին:
        <br />● Եթե Դուք վերցնում եք Նվիրատվությունները այլ անձի (այսինքն՝
        Շահառուի) անունից, ապա բոլոր Նվիրատվությունները տրամադրվում են և/կամ
        ծախսվում են Շահառուի անունից։
        <br />● Ձեր պարտականությունն է որոշել, թե ինչպիսի հարկեր են կիրառվում,
        եթե այդպիսիք կան, այն Նվիրատվությունների նկատմամբ, որոնք Դուք ստանում եք
        Հարթակի օգտագործման միջոցով: Բացառապես Ձեր պարտականությունն է գնահատել,
        հավաքել, զեկուցել կամ փոխանցել ճշգրիտ հարկերը, եթե այդպիսիք կան,
        համապատասխան հարկային մարմնին։
        <br />● Դուք չպետք է ոտնահարեք ուրիշների իրավունքները։
        <br />● Դուք պետք է ենթարկվեք բոլոր համապատասխան և կիրառելի օրենքներըին
        և կատարեք ֆինանսական հաշվետվության պարտավորությունները՝ ներառյալ, բայց
        չսահմանափակվելով՝ գրանցմանն առնչվող օրենքները և կանոնակարգերը, հարկային
        հաշվետվությունները, քաղաքական ներդրումները և ակտիվների բացահայտումը Ձեր
        Նախագծի համար։
        <br />● Այնքանով, որքանով Դուք մեզ հետ կիսում եք որևէ երրորդ կողմի
        անձնական տվյալները որևէ նպատակով՝ ներառյալ Ձեր անձնական կոնտակտների
        անունները, էլեկտրոնային փոստի հասցեները և հեռախոսահամարները, Դուք ունեք
        իրավասություն (ներառյալ ցանկացած անհրաժեշտ համաձայնություն), ինչպես
        պահանջվում է կիրառելի օրենքով, տրամադրելու մեզ նման անձնական տվյալներ և
        թույլ տալու օգտագործել դրանք այն նպատակներով, որոնց համար Դուք դրանք
        տրամադրել եք: Դուք լիազորում եք «Հայկական խաղաղություն»-ին, և «Հայկական
        խաղաղություն»-ը իրավունք է վերապահում տրամադրելու Ձեր Նախագծին վերաբերող
        տեղեկատվությունը ցանկացած երրորդ կողմի «Հայկական խաղաղություն»-ի
        գործունեության շրջանակներում։
        <br />● Դուք համաձայնում եք և հաստատում եք սույն Դրույթների 2.1 Կետի
        դրույթները (Մտավոր սեփականություն): Մի՛ առաջարկեք արգելված Նախագծեր: Մի՛
        առաջարկեք որևէ Նախագիծ, որն անօրինական է, խախտում է որևէ
        քաղաքականություն, կանոն կամ ուղեցույց, կամ հակասում է որևէ գործող
        օրենքի, կանոնադրության, կարգադրության կամ կանոնակարգի: Դուք կարող եք
        տեղեկություններ ստանալ Նվիրատուների մասին՝ ներառյալ վերջիններիս
        անունները, էլեկտրոնային և ոչ էլեկտրոնային փոստի հասցեները: Այս
        տեղեկատվությունը տրամադրվում է Ձեր նախագծին մասնակցելու (ներառյալ՝
        նվիրաբերելու) նպատակով. մի՛ օգտագործեք այն այլ նպատակների համար և մի՛
        չարաշահեք: Նախագծի ստուգումն իրականացվում է «Հայկական խաղաղություն»-ի
        ներքին փաստաթղթերին և քաղաքականությանը համապատասխան: Խնդրում ենք նախօրոք
        գնահատել Ձեր ժամանակը և ջանքերը նախքան «Հայկական խաղաղություն»-ին
        դիմելը՝ Ձեր Նախագիծը Հարթակում տեղադրելու և գործարկելու համար։ Պատրաստ
        եղեք հնարավոր մերժմանը Դրույթներին, «Հայկական խաղաղություն»-ի
        քաղաքականությանը, ներքին կանոններին կամ ռազմավարությանը
        չհամապատասխանելու պատճառով: Հարթակում Նախագիծը տեղադրելուց հետո Նախագծի
        հեղինակները պարտավոր են նաև Նվիրատուներին և «Հայկական խաղաղություն»-ին
        տրամադրել Նախագծի կատարողականի և ֆինանսական հաշվետվությունները
        (ժամանակին) «Հայկական խաղաղություն»-ի կողմից սահմանված ժամկետում:
        Հարթակը և Նվիրատուները գնահատում են Նախագծի առաջընթացը գլխավորապես
        կատարողականի և ֆինանսական հաշվետվությունների հիման վրա: Կատարողականի և
        ֆինանսական հաշվետվությունների կամ առնչվող այլ տեղեկությունների կամ
        փաստաթղթերի ժամանակին չներկայացնելը կամ նշված հաշվետվություններում ու
        փաստաթղթերում առկա սխալ տեղեկատվությունը կարող է հանգեցնել Ծրագրի
        դադարեցման «Հայկական խաղաղություն»-ի հայեցողությամբ: «Հայկական
        խաղաղություն»-ի կողմից Նախագծի դադարեցման դեպքում (ցանկացած պատճառով)
        Նախագծի հեղինակը պարտավոր է անհապաղ վերադարձնել մինչև ավարտի պահին
        հավաքագրված բոլոր Նվիրատվությունները (եթե այդպիսիք կան) համապատասխան
        Նվիրատուներին և/կամ փոխանցել առնչվող նվիրատվությունները «Հայկական
        խաղաղություն»-ի բանկային հաշվին (ինչպես նշված է «Հայկական
        խաղաղություն»-ի էլեկտրոնային փոստի ծանուցման մեջ): Ծրագրի հեղինակը
        պարտավորվում է բացահայտել «Հայկական խաղաղություն»-ին ցանկացած շահերի
        բախում «Հայկական խաղաղություն»-ի և/կամ Ծառայություն մատուցողի ցանկացած
        անձնական շահերի, պատկանելության կամ գործունեության հետ: 1.
        Բովանդակության բացառման հոդված Ստորև նշված են
        Նախագծի/Նախաձեռնության/Օգտատիրոջ բովանդակության օրինակներ, որոնք չեն
        ընդունվում «Հայկական խաղաղության» կողմից.
        <br />● ցանկացած Նախագիծ կամ Նախաձեռնություն՝ ի պաշտպանություն կամ ի
        հակադրություն որևէ քաղաքական կուսակցության կամ անհատ թեկնածուի,
        <br />● թմրամիջոցների, թմրանյութերի, ստերոիդների, վերահսկվող նյութերի,
        դեղագործական կամ համանման ապրանքների կամ թերապիայի միջոցների խթանում,
        որոնք կամ անօրինական են, կամ իրավականորեն արգելված գործող կարգավորող
        մարմնի կողմից,
        <br />● ներդրումային սխեմաների, վարկերի, սեփական կապիտալի պայմանագրերի
        (այսինքն՝ ներդրումային հնարավորությունների կամ հարակից այլ
        ծառայությունների) խթանում,
        <br />● մոլախաղերի, խաղադրույքների, վիճակախաղի և այլ հարակից
        բովանդակության կամ ծառայությունների խթանում,
        <br />● բովանդակություն, որն արտացոլում կամ խթանում է այնպիսի վարքագիծ,
        որը «Հայկական խաղաղություն»-ը հայեցողությամբ համարվում է իշխանության
        չարաշահում կամ ատելության, բռնության, ոտնձգության, բուլինգի,
        խտրականության, ահաբեկչության կամ անհանդուրժողականության աջակցություն,
        <br />● ցանկացած այլ գործունեություն, որը «Հայկական խաղաղություն»-ը իր
        հայեցողությամբ կարող է անընդունելի համարել: Այս ցանկը սպառիչ չէ, և մենք
        մեզ իրավունք ենք վերապահում մերժելու ցանկացած դիմում,
        սառեցնելու/հեռացնելու ցանկացած Նախագիծ կամ Նախաձեռնություն և/կամ
        հետաքննելու ցանկացած Օգտատիրոջ, ով «Հայկական խաղաղության» հայեցողությամբ
        խախտում է սույն Դրույթներից որևէ մեկը կամ Դրույթների ոգին և/կամ չի
        ապահովում թափանցիկություն։
      </Text>
      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        3.1 Նվիրատու
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        Բոլոր Նվիրատվությունները Ձեր սեփական պատասխանատվության տակ են: Մենք
        («Հայկական խաղաղություն»-ը) գործադրում ենք բոլոր ջանքերը՝ ստուգելու
        Նախագծի հեղինակների կամ որևէ այլ Օգտատիրոջ տրամադրած տեղեկատվությունը և
        համոզվելու, որ Նվիրատվությունները կօգտագործվեն Նախագծի հեղինակի կողմից
        սահմանված ցանկացած նպատակի համաձայն, սակայն «Հայկական խաղաղություն»-ը
        պատասխանատվություն չի կրում Նախագծի հեղինակների կամ Հարթակի որևէ այլ
        օգտատիրոջ կողմից որևէ առաջարկի, խոստման կամ առաջխաղացման համար, և Դուք
        հասկանում և համաձայնում եք, որ «Հայկական խաղաղություն»-ը
        պատասխանատվություն չի կրում Ձեր նվիրատվությունների օգտագործման կամ
        Նախագծի հեղինակի համար հավաքագրված միջոցների չափի համար: Մենք
        ուղղակիորեն հրաժարվում ենք որևէ պարտավորվածությունից կամ
        պատասխանատվությունից որևէ Նախագծի արդյունքի կամ հաջողության համար: Դուք՝
        որպես Նվիրատու, պետք է վերջնական որոշում կայացնեք ցանկացած Նախագծի
        հեղինակի համար ներդրում կատարելու արժեվորության և նպատակահարմարության
        վերաբերյալ: Եթե հիմքեր ունեք ենթադրելու, որ Նախագծի հեղինակը չի
        հայթայթում կամ օգտագործում ֆինանսական միջոցները նշված նպատակի համար,
        խնդրում ենք տեղեկացնել մեր թիմին այդ մասին info@armenianpeace.am էլ.
        հասցեով, և մենք կուսումնասիրենք խնդիրը: Նվիրատուները «Հայկական
        խաղաղության»-ը տրամադրում են տեղեկատվություն (եթե ստացել են «Հայկական
        խաղաղություն»-ից էլեկտրոնային ծանուցում), որն անհրաժեշտ է «Հայկական
        խաղաղություն»-ին՝ իր պարտականությունների կատարման համար, այդ թվում՝
        անհրաժեշտ տեղեկատվություն են տրամադրում իշխանություններին։ Նվիրատուն
        ընդունում և համաձայնում է, որ իր անձնական տվյալները կարող են տրամադրվել
        համապատասխան Նախագծի հեղինակին, որին Նվիրատուն նվիրաբերում է ֆինանսական
        միջոցներ, ինչպես նաև այլ երրորդ կողմի, որը ներգրավված է համապատասխան
        Նախագծի իրականացման մեջ, և Նախագծի հեղինակը կամ որևէ երրորդ կողմը կարող
        են օգտագործել այդ տվյալները համապատասխան Նախագծի շրջանակներում:
        «Հայկական խաղաղություն»-ը չի կրում պատասխանատվություն և
        պարտավորվածություն որևէ Նախագծի հեղինակի կամ երրորդ կողմի կողմից որևէ
        Նվիրատուի տեղեկատվության օգտագործման համար: Նախագծի հեղինակի կողմից
        սույն Դրույթները կամ «Հայկական խաղաղություն»-ի այլ քաղաքականությունները
        խախտելու պատճառով Նախագծի դադարեցման դեպքում «Հայկական խաղաղություն»-ը
        անհրաժեշտ ջանքեր կգործադրի (առանց երաշխիքի)՝ վերադարձնելու Նվիրատուի
        կողմից ներդրված նվիրատվությունները: Երբ որոշում է կայացվում վերադարձնել
        Նվիրատվությունը Նվիրատուին՝ համաձայն սույն Դրույթների (ցանկացած
        պատճառով), «Հայկական խաղաղություն»-ը իրավունք ունի Նվիրատուի գրավոր
        համաձայնությամբ (էլ. փոստով) կամ էլեկտրոնային փոստի նամակին 10
        օրացույցային օրվա ընթացքում չպատասխանելու դեպքում կազմակերպել
        համապատասխան նվիրատվության փոխանցումը «Հայկական խաղաղություն»-ի
        հաշվեհամարին՝ «Հայկական խաղաղություն»-ի կանոնադրական նպատակներն
        իրականացնելու և/կամ մեկ այլ ընթացիկ Նախագծի/երի համար։
      </Text>
      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        4. Նախաձեռնող
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        Դուք՝ որպես Նախաձեռնող, Հարթակում տեղադրում եք (նախաձեռնում եք) առկա
        խնդիրը և դրա հնարավոր լուծումը սոցիալական ազդեցության համար, որպեսզի
        գտնեք գործընկերներ (համագործակիցներ) համագործակցության և խնդրի լուծման
        համար (Նախաձեռնություն): Դուք՝ որպես Նախաձեռնող, ներկայացնում եք,
        երաշխավորում և խոստանում եք հետևյալը․
        <br />● Ձեր Նախաձեռնության հետ կապված Ձեր կողմից տրամադրված բոլոր
        տեղեկությունները ճշգրիտ են, ամբողջական և չեն կարող խաբել «Հայկական
        խաղաղություն»-ին կամ Հարթակի որևէ Օգտատիրոջը,
        <br />● Դուք գրանցվում եք Հարթակում՝ ներկայացնելով Ձեր իրական
        ինքնությունը՝ ներառյալ Ձեր անունը, հասցեն և Նախագծի հեղինակին կամ
        Նախագծին վերաբերող նկար կամ տեսանյութ: Դուք համաձայնում եք թարմացնել
        գրանցման տեղեկատվությունը: Եթե «Հայկական խաղաղություն»-ը ցանկացած պահի
        հայտնաբերի, որ Ձեր տրամադրած տեղեկատվությունը կամ Ձեր Նախագծի նպատակը
        ճիշտ չէ կամ խախտում է սույն Դրույթներից որևէ մեկը, Ձեր Նախագիծը կարող է
        կասեցվել և/կամ դադարեցվել անմիջապես:
        <br />● Նախքան Հարթակում Նախաձեռնության հայտ ներկայացնելը Դուք պարտավոր
        եք համոզվել, որ խնդիրը/հիմնախնդիրը վավեր է և համապատասխան,
        <br />● Դուք կվարեք (կկառավարեք) Ձեր Նախաձեռնության էջը Հարթակում
        (ներառյալ ժամանակին և արդյունավետ կերպով անհրաժեշտ տեղեկատվության
        լրացումը), կփոխգործակցվեք Հարթակի օգտատերերի հետ արդյունավետորեն և
        բարեկամաբար,
        <br />● Նախաձեռնությունը Հարթակում տեղադրելուց հետո Դուք ժամանակին
        կպատասխանեք իրականացնողների և համագործակցողների կողմից ներկայացված
        ծանուցումներին և առաջարկներին,
        <br />● Դուք մեզ իրավունք եք տալիս խմբագրելու, փոփոխելու, վերափոխելու,
        տարանջատելու, ջնջելու կամ թարգմանելու Ձեր ցանկացած բովանդակություն
        Պլատֆորմում,
        <br />● Դուք պատասխանատու եք Հարթակում Ձեր տեղադրած տեղեկատվության
        համար: Հարթակին/«Հայկական խաղաղություն»-ին ներկայացված բոլոր
        տեղեկությունները, այդ թվում՝ հրապարակային տեղադրված և մասնավոր
        փոխանցված, բացառապես Ձեր պատասխանատվության տակ են,
        <br />● Մենք պատասխանատվություն չենք կրում Ձեր բովանդակությունում առկա
        սխալների համար: «Հայկական խաղաղություն»-ը պատասխանատվություն չի կրում
        Ձեր որևէ բովանդակությունում առկա որևէ սխալի կամ բացթողման համար,
        <br />● Դուք համաձայնում եք և հաստատում եք սույն Դրույթների 2.1 կետի
        դրույթները (Մտավոր սեփականություն): Մի՛ տեղադրեք արգելված
        նախաձեռնություն։ Մի՛ առաջարկեք որևէ նախաձեռնություն, որն անօրինական է,
        խախտում է որևէ քաղաքականություն, կանոն կամ ուղեցույց, կամ հակասում է
        որևէ գործող օրենքի, կանոնադրության, կարգադրության կամ կանոնակարգի:
        Նախաձեռնությամբ հանդես գալու համար Դուք լրացնում եք հայտը Հարթակում և
        տրամադրում «Հայկական խաղաղություն»-ին (եթե համապատասխան հարցում կա)
        բոլոր անհրաժեշտ փաստաթղթերը, տեղեկությունները և նյութերը (այդ թվում՝
        համապատասխանության, վստահության և անվտանգության ընթացակարգերը վարելու
        նպատակով): Նախաձեռնության ստուգումն իրականացվում է «Հայկական
        խաղաղություն»-ի ներքին փաստաթղթերին և քաղաքականությանը համապատասխան:
        Խնդրում ենք նախօրոք գնահատել Ձեր ժամանակը և ջանքերը նախքան Հարթակում
        դիմում ներկայացնելը և պատրաստ եղեք Ձեր Նախաձեռնության հնարավոր մերժմանը
        Դրույթներին, «Հայկական խաղաղություն»-ի քաղաքականությանը, ներքին
        կանոններին կամ ռազմավարությանը չհամապատասխանելու պատճառով: Դուք կարող եք
        տեղեկություններ ստանալ հավանական Իրականացնողների և Համագործակցողների
        մասին՝ ներառյալ վերջիններիս անունները, էլեկտրոնային և ոչ էլեկտրոնային
        փոստի հասցեները: Այս տեղեկատվությունը տրամադրվում է Ձեր Նախաձեռնությանը
        մասնակցելու և համագործակցելու նպատակով. մի՛ օգտագործեք այն այլ
        նպատակների համար և մի՛ չարաշահեք: Դուք՝ որպես Նախաձեռնող, պարտավորվում
        եք օբյեկտիվորեն և ողջամտորեն ընտրել և նշանակել Նախաձեռնության
        Իրականացնողին՝ Ձեր հեղինակած Նախաձեռնությունն իրականացնելու համար
        (խնդիրը լուծելու համար): Դուք կարող եք նաև ինքներդ դառնալ իրականացնող
        կամ համագործակցող, եթե օբյեկտիվորեն և ողջամտորեն ունեք բոլոր անհրաժեշտ
        միջոցներն ու ռեսուրսները: «Հայկական խաղաղություն»-ը իրավունք ունի
        ստուգելու Նախաձեռնողի կողմից առաջարկված Իրականացնողին, գնահատելու
        վերջինիս կարողությունը ժամանակին և արդյունավետորեն կատարելու առաջարկվող
        աշխատանքը/մատուցելու առաջարկվող ծառայությունները և, ելնելով այդ ստուգման
        արդյունքներից, դեմ լինել համապատասխան Իրականացնողի նշանակմանը
        Նախաձեռնողի կողմից և հանդես գալ այլ թեկնածու ներկայացնելու առաջարկով։
        Նախաձեռնողը պարտավորվում է բացահայտել «Հայկական խաղաղություն»-ին
        ցանկացած շահերի բախում «Հայկական խաղաղություն»-ի և/կամ Իրականացնողի
        ցանկացած անձնական շահի, պատկանելության կամ գործունեության հետ:
      </Text>

      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        5. Իրականացնող
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        Դուք՝ որպես Իրականացնող, պարտավորվում եք կազմակերպել Հարթակում տեղադրված
        նախաձեռնության իրականացումը, քանի որ օբյեկտիվորեն և ողջամտորեն ունեք դրա
        համար անհրաժեշտ բոլոր միջոցներն ու ռեսուրսները։ Իրականացնողը
        պատասխանատու է Նախաձեռնության ժամանակին և ամբողջական իրականացման համար
        (առկա խնդրի լուծման համար)՝ նախաձեռնողի առաջարկած լուծմանը համապատասխան
        ինչպես ինքնուրույն, այնպես էլ համագործակցողների ներգրավմամբ՝
        շարունակելով պատասխանատվություն կրել նվիրատուների և «Հայկական
        խաղաղություն»-ի առաջ Նախաձեռնության ժամանակին և բարձր որակով իրականացման
        համար։ Իրականացնողը տրամադրում է «Հայկական խաղաղություն»-ին
        (համապատասխան պահանջի դեպքում) բոլոր անհրաժեշտ փաստաթղթերը,
        տեղեկատվությունը և նյութերը՝ իրականացնելու համապատասխանության,
        վստահության և անվտանգության ընթացակարգերը, ինչպես նաև ստուգելու, որ
        Իրականացնողն ունի բոլոր անհրաժեշտ ռեսուրսները նախաձեռնությունը
        հաջողությամբ իրականացնելու համար: Դուք՝ որպես Իրականացնող, պարտավորվում
        եք օբյեկտիվորեն և ողջամտորեն ընտրել և նշանակել Համագործակցողին/ներին
        (անհրաժեշտության դեպքում), որպեսզի հաջողությամբ իրականացնեք
        Նախաձեռնությունը, որի համար պատասխանատու եք: Դուք կարող եք նաև
        իրականացնել Նախաձեռնությունը՝ առանց համագործակցողներին ներգրավելու, եթե
        դրա համար օբյեկտիվորեն և ողջամտորեն ունեք բոլոր անհրաժեշտ միջոցներն ու
        ռեսուրսները: «Հայկական խաղաղություն»-ը իրավունք ունի ստուգելու
        Իրականացնողի կողմից առաջարկված Համագործակցողին/ներին, ստուգելու
        վերջինիս/վերջիններիս կարողությունը ժամանակին և արդյունավետորեն կատարելու
        առաջարկվող աշխատանքը/մատուցելու առաջարկվող ծառայությունները և, ելնելով
        ստուգման արդյունքներից, դեմ լինել տվյալ Համագործակցողի նշանակմանը
        Իրականացնողի կողմից և հանդես գալ այլ թեկնածու ներկայացնելու պահանջով։
        Իրականացնողը պարտավոր է ժամանակին արձագանքել Համագործակցողների
        ծանուցումներին և առաջարկներին Հարթակում Նախաձեռնության տեղադրումից հետո։
        Երբ Նախաձեռնությունն ավարտվում է (Նախաձեռնության իրականացման համար
        անհրաժեշտ բոլոր կողմերը նշանակվում և կազմակերպվում են), և եթե
        նախաձեռնությունը պետք է տեղադրվի Հարթակում ֆինանսական միջոցների
        (նվիրատվությունների) հայթայթման համար, Իրականացնողը դառնում է Նախագծի
        հեղինակ, եթե այլ բան համաձայնեցված չէ «Հայկական խաղաղություն»-ի հետ։
        Դուք՝ որպես Իրականացնող, ներկայացնում եք, երաշխավորում և խոստանում եք
        հետևյալը․ <br />● Բոլոր տեղեկությունները, որոնք Դուք տրամադրում եք
        Հարթակում/Հարթակի միջոցով, ճշգրիտ են, ամբողջական և չեն խաբում «Հայկական
        խաղաղություն»-ին կամ Հարթակի որևէ Օգտատիրոջը, <br />● Դուք գրանցվում եք
        Հարթակում՝ ներկայացնելով Ձեր իրական ինքնությունը՝ ներառյալ Ձեր անունը,
        հասցեն և Նախագծի հեղինակին կամ Նախագծին վերաբերող նկար կամ տեսանյութ:
        Դուք համաձայնում եք թարմացնել գրանցման տեղեկատվությունը: Եթե «Հայկական
        խաղաղություն»-ը ցանկացած պահի հայտնաբերի, որ Ձեր տրամադրած
        տեղեկատվությունը կամ Ձեր Նախագծի նպատակը ճիշտ չէ կամ խախտում է սույն
        Դրույթներից որևէ մեկը, Ձեր Նախագիծը կարող է կասեցվել և/կամ դադարեցվել
        անմիջապես: <br />● Դուք կվարեք (կկառավարեք) Ձեր Նախաձեռնության էջը
        Հարթակում (ներառյալ ժամանակին և արդյունավետ կերպով անհրաժեշտ
        տեղեկատվության լրացումը), կփոխգործակցեք Հարթակի օգտատերերի հետ
        արդյունավետորեն և բարեկամաբար, <br />● Դուք մեզ իրավունք եք տալիս
        խմբագրելու, փոփոխելու, վերափոխելու, տարանջատելու, ջնջելու կամ
        թարգմանելու Ձեր ցանկացած բովանդակություն Պլատֆորմում, <br />● Դուք
        պատասխանատու եք Հարթակում Ձեր տեղադրած տեղեկատվության համար: Հարթակին/«
        Հայկական խաղաղություն»-ին ներկայացված բոլոր տեղեկությունները, այդ թվում՝
        հրապարակային տեղադրված և մասնավոր փոխանցված, բացառապես Ձեր
        պատասխանատվության տակ են, ● Մենք պատասխանատվություն չենք կրում Ձեր
        բովանդակությունում առկա սխալների համար: «Հայկական խաղաղություն»-ը
        պատասխանատվություն չի կրում Ձեր որևէ բովանդակությունում առկա որևէ սխալի
        կամ բացթողման համար, Խնդրում ենք նախօրոք գնահատել Ձեր ժամանակը, ջանքերը,
        ֆինանսական միջոցները և ռեսուրսները նախքան Հարթակում նախաձեռնության համար
        դիմելը։ Պատրաստ եղե՛ք հնարավոր մերժմանը Դրույթներին, «Հայկական
        խաղաղություն»-ի քաղաքականությանը, ներքին կանոններին կամ ռազմավարությանը
        չհամապատասխանելու պատճառով: Իրականացնողը պարտավորվում է բացահայտել
        «Հայկական խաղաղություն»-ին ցանկացած շահերի բախում «Հայկական
        խաղաղություն»-ի և/կամ Համագործակցողի ցանկացած անձնական շահի,
        պատկանելության կամ գործունեության հետ:
      </Text>

      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        6. Համագործակից
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        Դուք՝ որպես Համագործակից, պարտավորվում եք համագործակցել համապատասխան
        Իրականացնողի և այլ համագործակցողների հետ (եթե այդպիսիք կան)՝ Հարթակում
        տեղադրված Նախաձեռնությունը համատեղ իրականացնելու համար, քանի որ Դուք
        օբյեկտիվորեն և ողջամտորեն ունեք դրա համար անհրաժեշտ բոլոր միջոցներն ու
        ռեսուրսները: Համագործակցողը պատասխանատու է համապատասխան Նախաձեռնության
        որոշակի մասի ժամանակին և ամբողջական իրականացման համար՝ համաձայն
        Նախաձեռնողի կողմից առաջարկված լուծմանը և կրելով պատասխանատվություն
        Իրականացնողի առաջ: Համագործակցողը Իրականացնողին և «Հայկական
        խաղաղություն»-ին (համապատասխան պահանջի դեպքում) տրամադրում է բոլոր
        անհրաժեշտ փաստաթղթերը, տեղեկատվությունն ու նյութերը՝ իրականացնելու
        համապատասխանության, վստահության և անվտանգության ընթացակարգերը, ինչպես
        նաև ստուգելու, որ Համագործակցողն ունի բոլոր անհրաժեշտ ռեսուրսները՝
        Նախաձեռնության որոշակի մասը հաջողությամբ իրականացնելու համար։
        Համագործակիցը պարտավորվում է ժամանակին պատասխանել օգտատերերի (ներառյալ՝
        նախաձեռնողների և իրականացնողների) ծանուցումներին և առաջարկներին, որոնք
        ներկայացվում են Հարթակում/Հարթակի միջոցով: Դուք՝ որպես Համագործակից,
        ներկայացնում եք, երաշխավորում և խոստանում հետևյալը․ <br />
        ● Բոլոր տեղեկությունները, որոնք Դուք տրամադրում եք Հարթակում/Հարթակի
        միջոցով, ճշգրիտ են, ամբողջական և չեն խաբում «Հայկական խաղաղություն»-ին
        կամ Հարթակի որևէ Օգտատիրոջը, <br />● Դուք գրանցվում եք Հարթակում՝
        ներկայացնելով Ձեր իրական ինքնությունը՝ ներառյալ Ձեր անունը և հասցեն:
        Դուք համաձայնում եք թարմացնել գրանցման տեղեկատվությունը: Եթե «Հայկական
        խաղաղություն»-ը ցանկացած պահի հայտնաբերի, որ Ձեր տրամադրած
        տեղեկատվությունը կամ Ձեր Նախագծի նպատակը ճիշտ չէ կամ խախտում է սույն
        Դրույթներից որևէ մեկը, Ձեր Նախագիծը կարող է կասեցվել և/կամ դադարեցվել
        անմիջապես, <br />● Դուք կվարեք (կկառավարեք) Ձեր Նախաձեռնության էջը
        Հարթակում (ներառյալ ժամանակին և արդյունավետ կերպով անհրաժեշտ
        տեղեկատվության լրացումը), կփոխգործակցեք Հարթակի օգտատերերի հետ
        արդյունավետորեն և բարեկամաբար, <br />● Դուք մեզ իրավունք եք տալիս
        խմբագրելու, փոփոխելու, վերափոխելու, տարանջատելու, ջնջելու կամ
        թարգմանելու Ձեր ցանկացած բովանդակություն Պլատֆորմում, <br />● Դուք
        պատասխանատու եք Հարթակում Ձեր տեղադրած տեղեկատվության համար:
        Հարթակին/«Հայկական խաղաղություն»-ին ներկայացված բոլոր տեղեկությունները,
        այդ թվում՝ հրապարակային տեղադրված և մասնավոր փոխանցված, բացառապես Ձեր
        պատասխանատվության տակ են, <br />● Մենք պատասխանատվություն չենք կրում Ձեր
        բովանդակությունում առկա սխալների համար: «Հայկական խաղաղություն»-ը
        պատասխանատվություն չի կրում Ձեր որևէ բովանդակությունում առկա որևէ սխալի
        կամ բացթողման համար, Խնդրում ենք նախօրոք գնահատել Ձեր ժամանակը, ջանքերը,
        ֆինանսական միջոցները և ռեսուրսները նախքան Հարթակում նախաձեռնության համար
        դիմելը։ Պատրաստ եղեք հնարավոր մերժմանը Դրույթներին, «Հայկական
        խաղաղություն»-ի քաղաքականությանը, ներքին կանոններին կամ ռազմավարությանը
        չհամապատասխանելու պատճառով: Համագործակիցը պարտավորվում է բացահայտել
        «Հայկական խաղաղություն»-ին ցանկացած շահերի բախում «Հայկական
        խաղաղություն»-ի և/կամ Համագործակցողի ցանկացած անձնական շահի,
        պատկանելության կամ գործունեության հետ:
      </Text>

      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        7. Ծառայություն մատուցող
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        «Հայկական խաղաղություն»-ը հնարավորություն է տալիս Նախագծի հեղինակներին,
        Նախաձեռնողներին կամ Իրականացնողներին առավելագույնս օգտվելու
        ծառայությունների լայն շրջանակից, որոնք նպատակ ունեն դյուրացնելու Նախագծի
        կամ Նախաձեռնության իրականացման գործընթացը Ծառայություններ մատուցողների
        ցանկի միջոցով: Ծառայություն մատուցողները իրավաբանական կամ ֆիզիկական
        անձինք են, որոնք մասնագիտացած են տարբեր ոլորտներում՝ ներառյալ, բայց
        չսահմանափակվելով՝ մարքեթինգ, տեսողական բովանդակության ստեղծում,
        հեղինակային իրավունք, իրավաբանություն, ֆինանսներ, ընդհանուր կանոնակարգեր
        և այլն: Դուք՝ որպես Ծառայություն մատուցող, օբյեկտիվորեն և ողջամտորեն
        ունենալով բոլոր անհրաժեշտ միջոցներն ու ռեսուրսները, պարտավորվում եք
        համապատասխան ծառայություններ մատուցել Ծրագրի հեղինակին, Նախաձեռնողին կամ
        Իրականացնողին, ով դիմել է Ձեզ: Ծառայությունների մատակարարը պատասխանատու
        է ծառայությունների ժամանակին և ամբողջական մատուցման համար՝ համաձայն
        Ծառայությունների մատակարարի և Ծրագրի
        հեղինակների/Նախաձեռնողների/Իրագործողների միջև կնքված համապատասխան
        պայմանագրի: Ծառայությունների մատակարարն ինքնուրույն է որոշում մատուցել
        ծառայությունը վճարովի, գործընկերային կամ բարեգործական հիմունքով: Բոլոր
        իրավունքները և պարտականությունները կլինեն կողմերի պատասխանատվության տակ
        (Ծառայություն մատուցողներ և Նախագծի
        հեղինակներ/Նախաձեռնողներ/Իրագործողներ): Հարթակը ծառայում է որպես
        հանդիպման վայր Ծառայություններ մատուցողների և Նախագծի
        հեղինակների/Նախաձեռնողների/Իրագործողների համար: Անկախ ամեն ինչից՝
        «Հայկական խաղաղություն»-ը պատասխանատվություն չի կրում Ծառայություններ
        մատուցողների և Ծրագրի ստեղծողների/Նախաձեռնողների/Իրագործողների
        համագործակցության հետ կապված որևէ վնասի կամ կորստի համար: Մենք չենք
        ներգրավվում Հարթակի օգտատերերի միջև վեճերում: Ծառայությունների
        մատակարարը պարտավորվում է բացահայտել «Հայկական խաղաղություն»-ին ցանկացած
        շահերի բախում «Հայկական խաղաղություն»-ի և/կամ Համագործակցողի ցանկացած
        անձնական շահի, պատկանելության կամ գործունեության հետ: Ծառայությունների
        մատակարարը պարտավորվում է տրամադրել «Հայկական խաղաղություն»-ին
        (համապատասխան պահանջի դեպքում) բոլոր անհրաժեշտ փաստաթղթերը,
        տեղեկատվությունն ու նյութերը՝ իրականացնելու համապատասխանության,
        վստահության և անվտանգության ընթացակարգերը, ինչպես նաև ստուգելու, որ
        Ծառայությունների մատակարարն ունի բոլոր անհրաժեշտ ռեսուրսները՝
        համապատասխան ծառայություններ մատուցելու համար։ Դուք՝ որպես
        Ծառայությունների մատակարար, ներկայացնում եք, երաշխավորում և խոստանում
        հետևյալը․
        <br />● Բոլոր տեղեկությունները, որոնք Դուք տրամադրում եք
        Հարթակում/Հարթակի միջոցով, ճշգրիտ են, ամբողջական և չեն խաբում «Հայկական
        խաղաղություն»-ին կամ Հարթակի որևէ Օգտատիրոջը,
        <br />● Դուք գրանցվում եք Հարթակում՝ ներկայացնելով Ձեր իրական
        ինքնությունը՝ ներառյալ Ձեր անունը և հասցեն: Դուք համաձայնում եք
        թարմացնել գրանցման տեղեկատվությունը: Եթե «Հայկական խաղաղություն»-ը
        ցանկացած պահի հայտնաբերի, որ Ձեր տրամադրած տեղեկատվությունը կամ Ձեր
        Նախագծի նպատակը ճիշտ չէ կամ խախտում է սույն Դրույթներից որևէ մեկը, Ձեր
        Նախագիծը կարող է կասեցվել և/կամ դադարեցվել անմիջապես,
        <br />● Դուք կվարեք (կկառավարեք) Ձեր էջը Հարթակում (ներառյալ ժամանակին և
        արդյունավետ կերպով անհրաժեշտ տեղեկատվության լրացումը), կփոխգործակցեք
        Հարթակի օգտատերերի հետ արդյունավետորեն և բարեկամաբար,
        <br />● Դուք մեզ իրավունք եք տալիս խմբագրելու, փոփոխելու, վերափոխելու,
        տարանջատելու, ջնջելու կամ թարգմանելու Ձեր ցանկացած բովանդակություն
        Պլատֆորմում,
        <br />● Դուք պատասխանատու եք Հարթակում Ձեր տեղադրած տեղեկատվության
        համար: Հարթակին/«Հայկական խաղաղություն»-ին ներկայացված բոլոր
        տեղեկությունները, այդ թվում՝ հրապարակային տեղադրված և մասնավոր
        փոխանցված, բացառապես Ձեր պատասխանատվության տակ են,
        <br />● Մենք պատասխանատվություն չենք կրում Ձեր բովանդակությունում առկա
        սխալների համար: «Հայկական խաղաղություն»-ը պատասխանատվություն չի կրում
        Ձեր որևէ բովանդակությունում առկա որևէ սխալի կամ բացթողման համար։
      </Text>
      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        8. Պարտավորվածության սահմանափակում
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        Օրենքի առավելագույն թույլտվությամբ՝ «Հայկական խաղաղություն»-ը, վերջինիս
        դուստր ձեռնարկությունները, տնօրենները, աշխատակիցները ոչ մի դեպքում
        պատասխանատվություն չեն կրում որևէ անուղղակի, պատահական, պատժիչ,
        հետևանքային, հատուկ կամ օրինակելի վնասի համար` ներառյալ, բայց
        չսահմանափակվելով՝ (i) այնպիսի վնաս, որն առաջացել է Հարթակ մուտք գործելու
        և դրա ծառայություններից օգտվելու կամ Հարթակի/ծառայությունների
        անհասանելիության հետևանքով, (ii) ցանկացած կորցրած շահույթ, տվյալների
        կորուստ կամ գնման կամ փոխարինող ապրանքների կամ ծառայությունների համար
        ծախսեր, կամ (iii) այնպիսի վնաս, որը առաջացել է Հարթակում որևէ երրորդ
        կողմի բովանդակության պատճառով: «Հայկական խաղաղություն»-ի կողմից ուղղակի
        վնասների հատուցումը ոչ մի դեպքում չպետք է գերազանցի (ընդհանուր առմամբ)
        հիսուն հազար հայկական դրամը (50 000 ՀՀ դրամ):
      </Text>

      <br />
      <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        9. Վեճերի լուծում։ Կարգավորող օրենք
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        Մենք «Հայկական խաղաղություն»-ում խորհուրդ ենք տալիս կապ հաստատել մեզ հետ
        որևէ խնդրի դեպքում նախքան դատարան դիմելը: Մեր աջակցության թիմը պատրաստ է
        պատասխանել Ձեր հարցերին: Դուք կարող եք կապ հաստատել մեզ հետ
        info@armenianpeace.am էլ.փոստի միջոցով, որպեսզի մենք անձամբ պատասխանենք
        Ձեզ և փորձենք լուծել Ձեր խնդիրը: Վատագույն դեպքում, երբ ծագում է
        իրավական խնդիր, սույն Դրույթները (և բոլոր մյուս կանոնները,
        քաղաքականությունները կամ ուղեցույցները, որոնք ներառված են հղումներով)
        կկարգավորվեն և կմեկնաբանվեն Հայաստանի Հանրապետության օրենքներին
        համապատասխան: Դուք համաձայնում եք, որ սույն Դրույթներից բխող կամ դրանց
        հետ կապված ցանկացած իրավական գործողություն, կամ Ձեր կողմից Հարթակի
        օգտագործումը կամ չօգտագործումը պետք է ներկայացվի միայն Հայաստանի
        Հանրապետությունում գործող դատարաններում:
      </Text>
      <br />
      {/* <Heading as="h2" fontSize="3xl" textAlign="center" my="4">
        10. Disputes resolution. Governing law
      </Heading>
      <Text textAlign="justify" lineHeight="8">
        We at AP encourage you to contact us if you’re having an issue before
        resorting to the courts. Our support team is on hand and ready to answer
        your questions. You can contact us via email info@armenianpeace.am so
        that we can personally reply to you and attempt to resolve your issue.
        In the unfortunate situation where legal action does arise, these Terms
        (and all other rules, policies, or guidelines incorporated by reference)
        will be governed by and construed in accordance with the laws of the
        Republic of Armenia. You agree that any action at law arising out of or
        relating to these Terms, or your use or non-use of the Platform, shall
        be filed only in the courts located in the Republic of Armenia.
      </Text> */}
    </Container>
    <Footer />
  </Flex>
)

export default Terms
