import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Link } from 'pages'
import { ReactComponent as Logo } from 'assets/icons/logoblack.svg'

const AuthWrapper = ({ children }) => {
  return (
    <Flex
      w="full"
      minH="85vh"
      direction={'column'}
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Box as={Link} mb={8} to="/">
        <Logo alt="logo" width="166px" />
      </Box>
      <Flex justifyContent="center" alignItems="center">
        {children}
      </Flex>
    </Flex>
  )
}

export default AuthWrapper
