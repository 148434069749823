const contentRepo = (AppSyncService, ElasticService) => {
  const getNeeds = (input) => AppSyncService.getNeeds(input)
  const getExperts = (variables) => AppSyncService.getExperts(variables)
  const getItemById = (input) => AppSyncService.getItemById(input)
  const getGiftCard = (input) => AppSyncService.getGiftCard(input)

  const getContentFromElastic = (condition) =>
    ElasticService.getContentFromElastic(condition)

  return {
    getNeeds,
    getExperts,
    getItemById,
    getGiftCard,
    getContentFromElastic,
  }
}

export default contentRepo
