import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import Payment from '../Payment'

export default function PaymentModal({ size, isOpen, onClose = () => {} }) {
  const { t } = useTranslation()

  return (
    <Modal
      size={size}
      isCentered
      isOpen={isOpen}
      scrollBehavior="inside"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent margin={0} borderRadius={{ base: '0px', md: '16px' }}>
        <ModalHeader
          as="div"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            as="h5"
            fontWeight="bold"
            alignSelf="center"
            maxW="80%"
            textAlign="center"
            fontSize="4xl"
          >
            {t('donationModal@heading')}
          </Box>
          <Box
            as="p"
            my={2}
            mx={2}
            fontSize="lg"
            fontWeight="300"
            textAlign="center"
          >
            {t('donationModal@note')}
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0">
          <Payment />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
