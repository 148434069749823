import React, { useState } from 'react'
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Text,
  ListItem,
  UnorderedList,
  Button,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import Mobile from './Mobile'

const w = window.innerWidth

const CreateProject = () => {
  const [isFundraiser, setIsFundraiser] = useState(true)
  const [isCollaboration, setIsCollaboration] = useState(false)
  const [fundraisingType, setFundraisingType] = useState('oneTime')
  const { navigationPush } = useNavigation()
  const { t } = useTranslation()

  if (w < 481) {
    return <Mobile />
  }

  const next = () => {
    let urlParams = '?'
    if (isFundraiser) {
      urlParams += `isFundraiser=true&fundraisingType=${fundraisingType}`
    }
    if (isCollaboration) {
      if (urlParams !== '?') {
        urlParams += '&'
      }
      urlParams += `isCollaboration=true`
    }
    navigationPush(`/create-project${urlParams}`)
  }
  return (
    <MainLayout>
      <Flex justifyContent="center" mt={10} pb="100px">
        <Flex flexDir="column" maxW="648px">
          <Heading color="blue.400" fontSize="6xl" fontWeight={700}>
            {t('projectCreate@apply')}
          </Heading>
          <Text color="gray.800" mt={4}>
            {t('projectCreate@applyText')}
          </Text>
          <Text fontSize="xl" color="gray.800" fontWeight={700} mt={8}>
            {t('projectCreate@selectComponents')}
          </Text>
          <Text color="gray.700" mt={1} mb={6} fontSize="md">
            {t('projectCreate@selectComponentsText')}
          </Text>
          <Box borderWidth={1} borderColor="gray.500" borderRadius={12}>
            <Flex p={4} cursor="pointer">
              <Checkbox
                mt={1}
                mr={3}
                alignItems="flex-start"
                onChange={() => {
                  setIsFundraiser(!isFundraiser)
                  setFundraisingType('oneTime')
                  setTimeout(() => {
                    window.scrollTo({
                      top: document.body.scrollHeight,
                      behavior: 'smooth',
                    })
                  }, 0)
                }}
                isChecked={isFundraiser}
              >
                <Flex flexDir="column" mt="-5px">
                  <Text color="gray.800" fontSize="xl" fontWeight={700}>
                    {t('projectCreate@checkboxFundraiser')}
                  </Text>
                  <Text color="gray.700" fontSize="sm">
                    {t('projectCreate@checkboxFundraiserText')}
                  </Text>
                </Flex>
              </Checkbox>
            </Flex>
            <Box w="100%" h="1px" bg="gray.500"></Box>
            <Flex p={4} cursor="pointer">
              <Checkbox
                mt={1}
                mr={3}
                alignItems="flex-start"
                onChange={() => {
                  setIsCollaboration(!isCollaboration)
                }}
                isChecked={isCollaboration}
                control={{ mt: 10 }}
              >
                <Flex flexDir="column" mt="-5px">
                  <Text color="gray.800" fontSize="xl" fontWeight={700}>
                    {t('projectCreate@checkboxCollaboration')}
                  </Text>
                  <Text color="gray.700" fontSize="sm">
                    {t('projectCreate@checkboxCollaborationText')}
                  </Text>
                </Flex>
              </Checkbox>
            </Flex>
          </Box>
          <Box hidden={!isFundraiser}>
            <Text fontSize="xl" color="gray.800" fontWeight={700} mt={8}>
              {t('projectCreate@selectFundType')}
            </Text>
            <Text color="gray.700" mt={1} mb={6} fontSize="md">
              {t('projectCreate@selectFundTypeText')}
            </Text>
            <RadioGroup onChange={setFundraisingType} value={fundraisingType}>
              <Box borderWidth={1} borderColor="gray.500" borderRadius={12}>
                <Flex
                  p={4}
                  alignItems="flex-start"
                  cursor="pointer"
                  onClick={() => setFundraisingType('oneTime')}
                >
                  <Radio mt={1} mr={3} value="oneTime"></Radio>
                  <Flex flexDir="column">
                    <Text color="gray.800" fontSize="xl" fontWeight={700}>
                      {t('projectCreate@oneTime')}
                    </Text>
                    <Text color="gray.700" fontSize="sm">
                      {t('projectCreate@oneTimeText')}
                    </Text>
                  </Flex>
                </Flex>
                <Box w="100%" h="1px" bg="gray.500"></Box>
                <Flex
                  p={4}
                  alignItems="flex-start"
                  cursor="pointer"
                  onClick={() => setFundraisingType('recurring')}
                >
                  <Radio mt={1} mr={3} value="recurring"></Radio>
                  <Flex flexDir="column">
                    <Text color="gray.800" fontSize="xl" fontWeight={700}>
                      {t('projectCreate@recurring')}
                    </Text>
                    <Text color="gray.700" fontSize="sm">
                      {t('projectCreate@recurringText')}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </RadioGroup>
          </Box>
        </Flex>
        <Flex
          flexDir="column"
          ml="144px"
          bg="gray.400"
          borderRadius={12}
          py={5}
          w="260px"
          h="fit-content"
        >
          <Text color="gray.800" fontSize="md" fontWeight={600} px={5}>
            {t('projectCreate@important')}
          </Text>
          <UnorderedList mt={4} mb={6} px={5}>
            <ListItem mb={4} fontSize="sm" color="gray.800">
              {t('projectCreate@importantText1')}
            </ListItem>
            <ListItem mb={4} fontSize="sm" color="gray.800">
              {t('projectCreate@importantText2')}
            </ListItem>
            <ListItem mb={4} fontSize="sm" color="gray.800">
              {t('projectCreate@importantText3')}
            </ListItem>
          </UnorderedList>
          <Box w="100%" h="1px" bg="gray.500"></Box>
          <Text fontSize="md" fontWeight={600} mt={6} px={5}>
            {t('projectCreate@importantText4').split('...')[0]}
            <Text color="blue.400" as="span">
              <a href="/assets/files/guide.pdf" target="_blank">
                {t('projectCreate@importantText4').split('...')[1]}
              </a>{' '}
            </Text>
            {t('projectCreate@importantText4').split('...')[2]}
          </Text>
        </Flex>
      </Flex>
      <Flex
        position="fixed"
        bottom="0"
        justifyContent="flex-end"
        w="100%"
        boxShadow="0px 2px 24px rgba(0, 0, 0, 0.1)"
        bg="gray.100"
      >
        <Button
          px="14"
          variant="green"
          my={3}
          mr="128px"
          isDisabled={!(isFundraiser || isCollaboration)}
          onClick={next}
        >
          {t('projectCreate@next')}
        </Button>
      </Flex>
    </MainLayout>
  )
}

export default CreateProject
