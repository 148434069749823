import { UnorderedList, ListItem, Box } from '@chakra-ui/react'
import moment from 'moment'

const DETAILS_FIELDS = {
  contactEmail: 'contact email',
  summary: 'summary',
  createdAt: 'created at',
  updatedAt: 'updated at',
}

const DetailItem = ({ label, value }) => {
  if (!value || !DETAILS_FIELDS[label]) {
    return <></>
  }

  return (
    <ListItem mb={3}>
      <Box
        as="span"
        textTransform="capitalize"
        color="orange.400"
        fontWeight="bold"
      >
        {DETAILS_FIELDS[label]}
      </Box>
      <Box as="p">
        {label === 'createdAt' || label === 'updatedAt'
          ? moment(value).format('DD-MM-YYYY HH:mm:ss')
          : value}
      </Box>
    </ListItem>
  )
}

export default function Details({ initiative }) {
  return (
    <UnorderedList ml="0" listStyleType="none">
      {Object.entries(initiative).map(([key, value]) => (
        <DetailItem key={key} label={key} value={value} />
      ))}
    </UnorderedList>
  )
}
