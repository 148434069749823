import moment from 'moment'

const DOUBLED_PROJECTS = [
  '3699d58d-d9d0-45e0-aba8-f36e41401211',
  '940391dc-f9e1-446c-a2b1-d023f7e9ddf1',
]
const DOUBLED_PROJECTS_MAX = [20_000_000, 14_000_000]
const duplicatedProjectAmount = (amount, max) => {
  if (amount < max) {
    return amount * 2
  }

  return max * 2 + (amount - max)
}

const getCurrentGoal = (project, amount) => {
  const { goals } = project
  if (!goals?.length) {
    return { amount: project.goal || project.totalAmount }
  }
  let goal = goals.find(
    (goal) =>
      // TODO: make sure 2nd goal cannot be greater than 1
      // so this logic works correctly
      // also confirm how it should work
      amount < goal.amount
  )
  // if all goals are met, showing last goal
  if (!goal) {
    goal = goals[goals.length - 1]
  }
  return goal
}

const doubleProject = (project, newAmount, progress) => {
  let newDoubledProject = project
  const doublingMax = DOUBLED_PROJECTS_MAX[DOUBLED_PROJECTS.indexOf(project.id)]
  newAmount = duplicatedProjectAmount(project.amount, doublingMax)
  progress = (newAmount / project.goal) * 100
  newDoubledProject.amount = newAmount
  newDoubledProject.total.goalMetPercentage = parseFloat(progress)
  return newDoubledProject
}
const parseMonthlyOneTimeToJSON = (input) => {
  let result = input
  if (typeof input === 'string') {
    try {
      result = JSON.parse(input)
    } catch (e) {
      console.log('Error while parsing to json')
    }
  }

  return result
}
const getMonthlyProjectTotalAmount = (project) => {
  try {
    const currentMonthDate = moment().format('M-YYYY').toString()
    const oneTimeDonationsObject = parseMonthlyOneTimeToJSON(
      project.total.monthlyOneTime
    )
    const currentMonthDonations = oneTimeDonationsObject[currentMonthDate]
    if (!currentMonthDonations) {
      // every month all susbcribers are donating
      // even if they haven't yet it should be there
      return project.total.subscriptions
    }

    return currentMonthDonations + project.total.subscriptions
  } catch (err) {
    console.log(err)
    return project.total.subscriptions
  }
}

const projectUseCase = (projectRepo) => {
  const deleteProject = async (project) =>
    await projectRepo.deleteProject(project)

  const createProject = async (project) =>
    await projectRepo.createProject(project)

  const updateProjectStatus = async ({ id, status }) =>
    projectRepo.updateProjectStatus({ id, status })

  const updateProject = async (project) => {
    let response = await projectRepo.updateProject(project)
    return response
  }

  const getProjectsBySlug = (slug) =>
    projectify(projectRepo.getProjectBySlug(slug))

  const retrieveProject = async (id) => {
    if (!id) {
      return {}
    }
    if (validateUUID(id)) {
      return projectify(projectRepo.retrieveProject(id))
    }

    return projectify(projectRepo.getProjectBySlug(id))
  }

  const updateProjectPerks = (project) =>
    projectRepo.updateProjectPerks(project)

  const getProjects = async (filter, cacheKey) => {
    let condition = {}
    if (filter) {
      condition = filter
    }

    try {
      const projects = await projectRepo.getProjectsFromElastic(condition)
      let response = await Promise.all(
        projects.data.hits.map(({ _source }) => projectify(_source))
      )

      if (cacheKey && !condition.from) {
        const isProject = condition?.query?.bool?.must[0]

        if (isProject && isProject.match) {
          const fundraisingType =
            condition.query.bool.must[1].match?.fundraisingType
          localStorageSetItem(
            `${cacheKey}_${fundraisingType}`,
            JSON.stringify(response)
          )
        } else {
          localStorageSetItem(cacheKey, JSON.stringify(response))
        }
      }
      return { items: response, total: projects.data.total }
    } catch (err) {
      console.log('err', err)
    }
    return { items: [], total: 0 }
  }

  const getProjectsByUserId = async (filter, withNextToken = false) => {
    let condition = {}
    if (filter) {
      condition = filter
    }

    try {
      const projects = await projectRepo.getProjectsByUserId(condition)
      let response = await Promise.all(
        projects.data.projectByUserId.items.map(projectify)
      )
      if (withNextToken) {
        response = {
          items: response,
          nextToken: projects.data.listProjects.nextToken,
        }
      }
      return response
    } catch (err) {
      console.log('err', err)
    }
    return []
  }

  const getProjectsByPopular = async (filter, withNextToken = false) => {
    let condition = {}
    if (filter) {
      condition = filter
    }

    try {
      const projects = await projectRepo.getProjectsByPopular(condition)

      let response = await Promise.all(
        projects.data.projectBypopular.items.map(projectify)
      )
      if (withNextToken) {
        response = {
          items: response,
          nextToken: projects.data.projectBypopular.nextToken,
        }
      }
      return response
    } catch (err) {
      console.log('err', err)
    }
    return []
  }

  const getProjectsByFeatured = async (filter, withNextToken = false) => {
    let condition = {}
    if (filter) {
      condition = filter
    }

    try {
      const projects = await projectRepo.getProjectsByFeatured(condition)
      let response = await Promise.all(
        projects.data.projectByFeatured.items.map((project) =>
          projectify(project)
        )
      )

      if (withNextToken) {
        response = {
          items: response,
          nextToken: projects.data.projectByFeatured.nextToken,
        }
      }
      return response
    } catch (err) {
      console.log('featured:err', err)
    }
    return []
  }

  const searchProjects = async (filter) => {
    let condition = {}
    if (filter) {
      condition = filter
    }

    try {
      const projects = await projectRepo.getProjectsFromElastic(condition)
      let response = await Promise.all(
        projects.data.hits.map(({ _source }) => projectify(_source))
      )
      return { items: response, total: projects.data.total }
    } catch (err) {
      console.log('err', err)
    }
    return { items: [], total: 0 }
  }

  const localStorageSetItem = (key, value) =>
    window.localStorage.setItem(key, value)
  const localStorageGetItem = (key) => window.localStorage.getItem(key)

  return {
    createProject,
    updateProject,
    retrieveProject,
    updateProjectStatus,
    updateProjectPerks,
    getProjects,
    deleteProject,
    searchProjects,
    getProjectsByUserId,
    getProjectsByPopular,
    getProjectsBySlug,
    getProjectsByFeatured,
    localStorageSetItem,
    localStorageGetItem,
  }
}
const validateUUID = (uuid) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    uuid
  )
}

const normalizeProject = (project) => ({
  ...project,
  mainAmount: project?.amount ?? 0,
  collaborations: project.collaborations ?? '',
  category: project.category ?? '',
  goalType: project.goalType ?? '',
  description: project.problem ?? '',
  applicationTitle: project.applicationTitle ?? '',
  title_en: project.title_en ?? '',
  title_ru: project.title_ru ?? '',
  title_hy: project.title_hy ?? '',
  tagline_en: project.tagline_en ?? '',
  tagline_ru: project.tagline_ru ?? '',
  tagline_hy: project.tagline_hy ?? '',
  summary: project.summary ?? '',
  sequence: project.sequence ?? 1,
  isUrgent: project.isUrgent ?? false,
  isFeatured: project.isFeatured ?? false,
  isPopular: project.isPopular ?? false,
  region: project.region ?? '',
  status: project.status ?? '',
  goal: project.goal ?? 0,
  totalAmount: project.totalAmount ?? '',
  duration: project.duration,
  durationType: project.durationType,
  createdAt: project.createdAt,
  collaboratorsTotal: project.collaboratorsTotal,
  goals: project.goals ?? [],
  total: project.total ?? {},
  fundraisingType: project.fundraisingType ?? '',
})
const projectify = async (getProject) => {
  let project = await getProject
  if (project) {
    let newAmount = project.amount

    if (DOUBLED_PROJECTS.includes(project.id)) {
      project = doubleProject(project, newAmount)
      newAmount = project.amount
    }

    const isOneTime = project.fundraisingType === 'oneTime'
    const hasGoals = project.goals?.length > 0
    if (isOneTime && hasGoals) {
      // TODO: repo can change project structure as needed, use case should receive correct project object
      // use case SHOULD do calculations
      // should not do mappings
      // repo CAN do mappings
      // cannot do calculations
      project.goals =
        project.goals?.map((goal) => ({
          ...goal,
          isReached: goal.amount <= project.amount,
        })) || []
    }

    const isRecurring = project.fundraisingType === 'recurring'
    if (isRecurring) {
      const isMonthlyProjectHasOneTimePayments =
        project.total?.monthlyOneTime !== undefined
      const hasSubscribers = project.total?.subscriptions !== undefined

      if (isMonthlyProjectHasOneTimePayments && hasSubscribers) {
        newAmount = getMonthlyProjectTotalAmount(project)
      }
    }
    if (project.isCollaboration) {
      const mappedCollabs = project.collaborations.map((collab) => ({
        ...collab,
        needs_en: collab.needs_en || collab.needs,
        needs_ru: collab.needs_ru || collab.needs,
        needs_hy: collab.needs_hy || collab.needs,
        needsDescription_en:
          collab.needsDescription_en || collab.needsDescription,
        needsDescription_ru:
          collab.needsDescription_ru || collab.needsDescription,
        needsDescription_hy:
          collab.needsDescription_hy || collab.needsDescription,
      }))
      project.collaborations = mappedCollabs
    }
    project.activeGoal = getCurrentGoal(project, newAmount)
    project.amount = newAmount
  }
  if (project) return normalizeProject(project)
}

export default projectUseCase
