import axios from 'axios'
import { API } from "aws-amplify"

const emailService = () => {
  const updatesEmail = (params) => API.post('email', '/updatesEmail', {
    body: params
  })
  const subscribeMailerLite = async (email, firstName, language = 'en') => {
    await API.post('mailerLiteAPI', '/subscribe', {
      body: {
        email: email,
        firstName: firstName,
        language: `MAILER_SUB_GROUP_${language.toUpperCase()}`,
      },
    })
  }
  
  const subscribeMailerLiteNews =  ({email, language = "en"}) => 
     API.post('mailerLiteAPI', '/subscribeNews', {
      body: {
        email,
        language: `SUBSCRIBE_EMAIL_${language.toUpperCase()}`
      }
    })

  const subscribeHubspot = ({ email, firstName = '', lastName = '' }) => {
    const params = {
      submittedAt: Date.now(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstName,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: lastName,
        },
      ],
    }
    return axios.post(process.env.REACT_APP_HUBSPOT, params)
  }

  return {
    updatesEmail,
    subscribeMailerLite,
    subscribeHubspot,
    subscribeMailerLiteNews
  }
}

export default emailService
