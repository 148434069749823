import React from 'react'
import AuthWrapper from 'components/AuthWrapper'
import { useTranslation } from 'contexts/TranslationContext'
import {
  Box,
  Heading,
  Text,
  Flex,
  useColorModeValue as mode,
  Button,
  Stack,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'

const w = window.innerWidth
export default function SetPassword() {
  const { t } = useTranslation()

  return (
    <AuthWrapper>
      <Box w="full" maxW="lg" 
          mx="6"
          >
        <Heading
          as="h1"
          fontSize="4xl"
          fontWeight="extrabold"
          letterSpacing="tight"
          textAlign="center"
          mb="12"
        >
          {t('setPassword@heading')}
        </Heading>

        <Box
          w={{ md: '440px' }}
          bg={{ md: mode('gray.100', 'gray.700') }}
          pb="8"
        >
          <Stack spacing="8">
            <InputField
              size="lg"
              errorMessage={t('setPassword@passwordRequired')}
              label={t('setPassword@newPassword')}
            />
            <Button type="submit" variant="blue" size="lg" fontSize="md">
              {t('setPassword@buttonText')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </AuthWrapper>
  )
}
