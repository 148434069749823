import React, { useEffect } from 'react'
import { Stack, Box, Flex, Heading, Button, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { ReactComponent as PlusDisabled } from 'assets/icons/plusDisabled.svg'
import { ReactComponent as Remove } from 'assets/icons/remove-goal.svg'
import { ReactComponent as RemoveDisabled } from 'assets/icons/remove-goal-disabled.svg'
import { TextAreaComponent } from 'components/TextAreaComponent'
import { UpdatedTextArea } from './UpdatedTextArea'

const Collaboration = ({
  register,
  collaborations,
  errors,
  append,
  remove,
}) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (collaborations.length === 0) append({ needs: '', needsDescription: '' })
  }, [])
  return (
    <Box maxW="600px" width="100%">
      <Flex flexDir="column">
        <Heading fontSize="6xl" as="h2">
          {t('Collaboration')}
        </Heading>
        <Text mt={2} fontSize="md" color="gray.700">
          {t('CollaborationText')}
        </Text>
        <Text mt={8} fontSize="xl" fontWeight={700} color="gray.800">
          {t('CollaborationText2')}
        </Text>
      </Flex>
      <Stack mb="5.5rem" spacing="6" mt="6" alignItems="flex-start">
        {collaborations.map((item, index) => (
          <>
            <Flex w="full" key={'key' + index}>
              <Flex
                minW="24px"
                h="24px"
                bg="gray.300"
                borderRadius="100px"
                alignItems="center"
                justifyContent="center"
                mr={4}
                mt={2.5}
              >
                <Text color="gray.800" fontSize="sm" fontWeight="semibold">
                  {index + 1}
                </Text>
              </Flex>
              <Box w="full">
                <Flex direction="column" w="calc(100% - 34px)">
                  <InputField
                    maxLength={45}
                    placeholder={t('Collaboration need')}
                    isInvalid={!!errors?.collaborations?.[index]?.needs}
                    errorMessage={t('field is required')}
                    {...register(`collaborations.${index}.needs`, {
                      required: true,
                    })}
                  />
                  <UpdatedTextArea
                    color="gray.800"
                    isInvalid={
                      !!errors?.collaborations?.[index]?.needsDescription
                    }
                    placeholder={t('createEditProject@describeGoal')}
                    errorMessage={t('createEditProject@thisFieldIsRequired')}
                    maxLength={100}
                    {...register(`collaborations.${index}.needsDescription`, {
                      required: false,
                      maxLength: 100,
                    })}
                  />
                </Flex>
              </Box>
              <Button
                minW="24px"
                w="24px"
                h="24px"
                right="34px"
                maxH="24px"
                borderRadius="100px"
                ml={2.5}
                mt={2.5}
                isDisabled={collaborations.length < 2}
                onClick={() => remove(index)}
              >
                {collaborations.length >= 2 ? (
                  <Remove width="24px" />
                ) : (
                  <RemoveDisabled width="24px" />
                )}
              </Button>
            </Flex>
            <Box w="full" h="1px" backgroundColor="border.100" mt={6} />
          </>
        ))}

        <Button
          onClick={() => append({ needs: '', needsDescription: '' })}
          color="gray.800"
          disabled={collaborations.length >= 5}
        >
          <Box mr="4">
            {collaborations.length >= 5 ? <PlusDisabled /> : <Plus />}
          </Box>
          {t('Add more options')}
        </Button>
      </Stack>
    </Box>
  )
}

export default Collaboration
